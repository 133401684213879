import { Thunk, thunk } from "easy-peasy";
import { Injections } from "./";

export interface IUnitModel {
  changePowerState: Thunk<IUnitModel, IPowerPayload, Injections>;
  getUnitStats: Thunk<IUnitModel, IStatsParams, Injections>;
  updateUnit: Thunk<IUnitModel, IUpdatePayload, Injections>;
  setActiveSetpoint: Thunk<IUnitModel, IUpdatePayload, Injections>;
  setActiveOperationMode: Thunk<IUnitModel, IUpdatePayload, Injections>;
  setActiveOperationStatus: Thunk<IUnitModel, IUpdatePayload, Injections>;
  setActiveFanMode: Thunk<IUnitModel, IUpdatePayload, Injections>;
  setActiveSwingMode: Thunk<IUnitModel, IUpdatePayload, Injections>;
  refreshUnit: Thunk<IUnitModel, IIdPayload, Injections>;
  getUserUnits: Thunk<IUnitModel, void, Injections>;
  updateUnitSystem: Thunk<IUnitModel, IChangeSystem, Injections>;
  deleteUnit: Thunk<IUnitModel, string, Injections>;
  getUnitById: Thunk<IUnitModel, string, Injections>;
  clearFilter: Thunk<IUnitModel, string, Injections>;
  fetchUnitDiagByParams: Thunk<IUnitModel, { unitId: string; startTime: number, endTime: number, params: string[] }>;
}

interface IIdPayload {
  unitId: string;
}

interface IPowerPayload {
  unitId: string;
  state: number;
}

interface IStatsParams {
  unitId: string;
  startTime: number;
  endTime: number;
  size: number;
}
interface IUpdatePayload {
  unitId: string;
  data: object | number;
}

interface IChangeSystem {
  unitId: string;
  oldSystem: string;
  newSystem: string;
}

const unitStore: IUnitModel = {
  changePowerState: thunk((actions, payload, { injections }) => {
    const { sdkUnit } = injections;
    const { unitId, state } = payload;
    return sdkUnit.setActiveOperationStatus(unitId, state);
  }),
  refreshUnit: thunk((actions, payload, { injections }) => {
    const { sdkUnit } = injections;
    const { unitId } = payload;
    return sdkUnit.refreshUnit(unitId);
  }),
  getUnitStats: thunk((actions, payload, { injections }) => {
    const { sdkUnit } = injections;
    const { unitId, startTime, endTime, size } = payload;
    return sdkUnit.getUnitStats(unitId, startTime, endTime, size);
  }),
  updateUnit: thunk((actions, payload, { injections }) => {
    const { unitId, data } = payload;
    const { sdkUnit } = injections;
    return sdkUnit.update(unitId, data);
  }),
  setActiveSetpoint: thunk((actions, payload, { injections }) => {
    const { unitId, data: setpoint } = payload;
    const { sdkUnit } = injections;

    return sdkUnit.setActiveSetpoint(unitId, +setpoint as number);
  }),
  setActiveOperationMode: thunk((actions, payload, { injections }) => {
    const { unitId, data: mode } = payload;
    const { sdkUnit } = injections;

    return sdkUnit.setActiveOperationMode(unitId, +mode as number);
  }),
  setActiveOperationStatus: thunk(async (actions, payload, { injections }) => {
    const { unitId, data: operationStatus } = payload;
    const { sdkUnit } = injections;

    return sdkUnit.setActiveOperationStatus(unitId, +operationStatus as number);
  }),
  setActiveSwingMode: thunk(async (actions, payload, { injections }) => {
    const { unitId, data: mode } = payload;
    const { sdkUnit } = injections;

    return sdkUnit.setActiveSwingMode(unitId, +mode as number);
  }),
  setActiveFanMode: thunk(async (actions, payload, { injections }) => {
    const { unitId, data: mode } = payload;
    const { sdkUnit } = injections;

    return sdkUnit.setActiveFanMode(unitId, +mode as number);
  }),
  getUserUnits: thunk((actions, payload, { injections }) => {
    const { sdkUnit } = injections;

    return sdkUnit.getUnits();
  }),
  updateUnitSystem: thunk((actions, payload, { injections }) => {
    const { unitId, oldSystem, newSystem } = payload;
    const { sdkSystem } = injections;

    if (!oldSystem) {
      return sdkSystem.addUnit(newSystem, unitId);
    } else if (!newSystem) {
      return sdkSystem.removeUnit(oldSystem, unitId);
    }
    return sdkSystem.removeUnit(oldSystem, unitId).then(() => {
      return sdkSystem.addUnit(newSystem, unitId);
    });
  }),
  deleteUnit: thunk((actions, payload, { injections }) => {
    const { sdkUnit } = injections;

    return sdkUnit.delete(payload);
  }),
  getUnitById: thunk((actions, payload, { injections }) => {
    const { sdkUnit } = injections;

    return sdkUnit.getUnitById(payload);
  }),
  clearFilter: thunk((actions, payload, { injections }) => {
    const { sdkUnit } = injections;
    return sdkUnit.clearFilter(payload);
  }),
  fetchUnitDiagByParams: thunk((actions, payload, { injections }) => {
    const { sdkUnit } = injections;
    const { unitId, startTime, endTime, params } = payload;
    return sdkUnit.getUnitStatsByParams(unitId, startTime, endTime, params);
  })
};

export default unitStore;

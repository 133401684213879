import { createStyles } from "@material-ui/styles";
import { fade } from "@material-ui/core/styles";

export default (theme: any) =>
  createStyles({
    screenContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      background: theme.gradient.default
    },
    root: {
      flexFlow: "column",
      alignItems: "center",
      maxWidth: "768px",
      background: theme.gradient.default
    },
    scrolledSection: {
      width: "100%",
      height: "auto",
      overflow: "auto",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center"
    },
    searchBox: {
      display: "flex",
      position: "relative",
      borderRadius: "4px",
      backgroundColor: fade(theme.palette.colors.white, 0.1),
      "&:hover": {
        backgroundColor: fade(theme.palette.colors.white, 0.25)
      },
      width: "90%",
      height: "50px",
      margin: "20px 15px",
      paddingLeft: "15px",
      boxSizing: "border-box"
    },
    searchIconStyle: {
      height: "100%",
      position: "absolute",
      right: "15px",
      top: "15px",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "22px"
    },
    inputRoot: {
      color: theme.palette.colors.white,
      fontFamily: "sans-serif",
      fontSize: "14px",
      width: "90%"
    }
  });

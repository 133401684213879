import React from "react";
import { makeStyles } from "@material-ui/core";
import { splashScreenStyle } from "./SplashScreen.style";
import clsx from "clsx";
import assetsService from "../../services/assetsService";

interface splashScreenProps {
  isSplashScreen: boolean;
  splashScreenShow: boolean;
}

const SplashScreen = (props: splashScreenProps) => {
  const logoSrc = assetsService.getResourcePath("logo.png");
  const logoTitleSrc = assetsService.getResourcePath("logoTitle.png");

  const useStyles = makeStyles(splashScreenStyle);
  const classes = useStyles();

  return (
    <div className={clsx(classes.splashScreenWrapper)}>
      <img alt="logo" src={logoSrc} className={classes.splashScreenItem} />
      <img alt="logo title" src={logoTitleSrc} className={classes.splashScreenItem} />
    </div>
  );
};

export default SplashScreen

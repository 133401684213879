import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputLabel,
  makeStyles,
  TextField,
  Typography
} from "@material-ui/core";
import { Close, Send } from "@material-ui/icons";
import clsx from "clsx";
import { Form, Formik } from "formik";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import {
  EmailIcon,
  FacebookIcon,
  FacebookShareButton,
  WhatsappIcon,
  WhatsappShareButton
} from "react-share";
import { t } from "ttag";
import * as Yup from "yup";
import { ConfirmationDialog, Toolbar } from "../../components";
import { UserPermissions } from "../../components/UserPermissions";
import { useStoreActions } from "../../Stores/typedHooks";
import { ArrowBack, Delete } from "../../svgComponents";
import { Button, Checkbox, DatePicker, FormikField } from "../../widgets";
import editSubUserStyle from "./editSubUser.style";

const EditSubUser = (props: any) => {
  const [user, setUser] = useState<any>({});
  const [deleteDialog, setDeleteDialog] = useState<any>(false);
  const [emailDialog, setEmailDialog] = useState<boolean>(false);
  const [shareEmail, setEmail] = useState<any>("");
  const [permissionsObj, setPermissionsObj] = useState<any>("");
  const [expiryTimestamp, setExpiryTimestamp] = useState<any>("");
  const [isExpiriedEnabled, enableExpired] = useState<boolean>(false);
  const [expiryError, setExpiryError] = useState<string>("");
  const [isGusetMissingPermissions, setIsGusetMissingPermissions] = useState<boolean>(false);

  const getUserById = useStoreActions((actions) => actions.userStore.getUserById);
  const updateSubUser = useStoreActions(
    (actions) => actions.userStore.updateSubUser
  );
  const deleteUserById = useStoreActions(
    (actions) => actions.userStore.deleteUser
  );
  const deleteInviteById = useStoreActions(
    (actions) => actions.inviteStore.deleteInvite
  );
  const getInvite = useStoreActions((actions) => actions.inviteStore.getInvite);

  const addMessage = useStoreActions((action) => action.messageStore.addMessage);
  const emailInvite = useStoreActions(
    (actions) => actions.inviteStore.emailInvite
  );
  const { startLoader, finishLoader } = useStoreActions(
    (actions) => actions.loaderStore
  );

  const { history, match } = props;
  const { params } = match;
  const { customerId, userId, inviteToken } = params;

  const useStyles = makeStyles(editSubUserStyle);
  const classes = useStyles();

  const validationSchema = Yup.object({
    firstName: Yup.string().required("required"),
    lastName: Yup.string().required("required"),
    email: Yup.string().test(
      "email validation",
      t`email must be a valid email`,
      function(value) {
        if (!value) {
          return true;
        }

        const emailSchema = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailSchema.test(String(value).toLowerCase())) {
          return true;
        }
        return false;
      }
    )
  });

  const permissionsRef: any = useRef();

  useEffect(() => {
    if (!userId || !customerId) {
      return;
    }
    startLoader();

    getUserById(userId)
      .then((user: any) => {
        setUser(user);
        setExpiryTimestamp(user.expiryTimestamp);
        enableExpired(user.expiryTimestamp ? true : false);
      })
      .catch(() => history.push("/users-management"))
      .finally(() => finishLoader());
  }, [customerId, finishLoader, getUserById, history, startLoader, userId]);

  useEffect(() => {
    if (!inviteToken) {
      return;
    }

    getInvite(inviteToken).then((invite: any) => {
      setUser(invite);
      setExpiryTimestamp(invite.expiryTimestamp);
      enableExpired(invite.expiryTimestamp ? true : false);
      setEmail(invite.email);
    });
  }, [inviteToken, getInvite]);

  const goBack = () => {
    history.push("/users-management");
  };

  const handleFormdSubmit = (values: any) => {
    let hasErrors = false;
    setExpiryError("");
    const editedPermissions = permissionsRef.current.preparePermissions();

    if (isExpiriedEnabled && !expiryTimestamp) {
      setExpiryError(t`expire date is required`);
      hasErrors = true;
    }

    // If the user is guest and missing permissions
    if (editedPermissions.customers[customerId] === "customerGuest" &&
      (
        !Object.keys(editedPermissions.sites).length
        && !Object.keys(editedPermissions.units).length
        && !Object.keys(editedPermissions.groups).length
      )) {
      setIsGusetMissingPermissions(true);
      hasErrors = true;
    }

    if (hasErrors) {
      return;
    }

    const { expiryTimestamp: defaultExpiry } = user;
    const expiry = !isExpiriedEnabled
      ? defaultExpiry
        ? ""
        : undefined
      : expiryTimestamp + "";

    startLoader();

    updateSubUser({
      userId,
      data: {
        ...values,
        permissions: editedPermissions,
        expiryTimestamp: expiry
      }
    })
      .then(() => goBack())
      .catch((err: any) =>
        addMessage({
          message: err.message
        })
      )
      .finally(() => finishLoader());
  };
  const deleteUser = () => {
    startLoader();
    deleteUserById(userId)
      .then(() => {
        history.push("/users-management");
      })
      .catch((err: any) =>
        addMessage({
          message: err.message
        })
      )
      .finally(() => finishLoader());
  };

  const deleteInvite = () => {
    startLoader();
    deleteInviteById(user.id)
      .then(() => {
        history.push("/users-management");
      })
      .catch((err: any) =>
        addMessage({
          message: err.message
        })
      )
      .finally(() => finishLoader());
  };

  const sendEmail = () => {
    startLoader();
    emailInvite({ inviteId: user.id, email: shareEmail })
      .then(() => {
        goBack();
      })
      .finally(() => finishLoader());
  };

  const { firstName, lastName, username, email } = user;
  const isDisabled = !!inviteToken;

  const selectedPermission =
    !_.isEmpty(user) &&
    (user.permissions.customers[customerId] === "customerGuest"
      ? "guest"
      : user.permissions.customers[customerId] === "customerAdmin"
        ? "admin"
        : "manager");

  const isGuest: boolean = selectedPermission === "guest";

  const selectedSites =
    !_.isEmpty(user) && isGuest && user.permissions.sites
      ? Object.keys(user.permissions.sites)
      : [];
  const selectedUnits =
    !_.isEmpty(user) && isGuest && user.permissions.units
      ? Object.keys(user.permissions.units)
      : [];
  const selectedGroups =
    !_.isEmpty(user) && isGuest && user.permissions.groups
      ? Object.keys(user.permissions.groups)
      : [];

  return (
    <div className={classes.screenContainer}>
      <div className={classes.container}>
        <Toolbar
          title={!!inviteToken ? t`Edit a pending invitation` : t`Edit User Info`}
          leftIconComponent={<ArrowBack />}
          leftAction={goBack}
          rightAction={() => setDeleteDialog(true)}
          rightIconComponent={<Delete className={classes.deleteIconStyle} />}
        />
        <Formik
          initialValues={{
            firstName,
            lastName,
            username,
            email
          }}
          onSubmit={(values) => handleFormdSubmit(values)}
          enableReinitialize={true}
          validationSchema={validationSchema}
          render={({ values, setFieldValue, ...formikProps }) => {
            return (
              <Form className={classes.formContainer}>
                {!inviteToken && (
                  <FormikField
                    name="username"
                    variant="outlined"
                    label={t`Username`}
                    disabled={isDisabled}
                    formikProps={formikProps}
                    className={classes.wrapperStyle}
                    inputClass={classes.inputClass}
                    errorStyle={classes.errorStyle}
                    labelClass={classes.labelClass}
                  />
                )}
                <FormikField
                  name="firstName"
                  variant="outlined"
                  label={t`First Name`}
                  disabled={isDisabled}
                  formikProps={formikProps}
                  className={classes.wrapperStyle}
                  inputClass={classes.inputClass}
                  errorStyle={classes.errorStyle}
                  labelClass={classes.labelClass}
                />
                <FormikField
                  name="lastName"
                  variant="outlined"
                  label={t`Last Name`}
                  disabled={isDisabled}
                  formikProps={formikProps}
                  className={classes.wrapperStyle}
                  inputClass={classes.inputClass}
                  errorStyle={classes.errorStyle}
                  labelClass={classes.labelClass}
                />
                <FormikField
                  name="email"
                  variant="outlined"
                  label={t`Email`}
                  disabled={isDisabled}
                  formikProps={formikProps}
                  className={classes.wrapperStyle}
                  inputClass={classes.inputClass}
                  errorStyle={classes.errorStyle}
                  labelClass={classes.labelClass}
                />
                <InputLabel
                  className={clsx(classes.sectionTitleStyle, {
                    [classes.labelDisabled]: !!inviteToken
                  })}
                >
                  <Checkbox
                    checked={isExpiriedEnabled}
                    value={"User Expires"}
                    onChange={() => enableExpired(!isExpiriedEnabled)}
                    disabled={!!inviteToken}
                  />
                  User expires
                </InputLabel>
                <DatePicker
                  date={+expiryTimestamp}
                  onSet={(timestamp: any) => setExpiryTimestamp(timestamp)}
                  disabled={!isExpiriedEnabled || !!inviteToken}
                />
                <Typography className={classes.errorStyle}>
                  {expiryError}
                </Typography>

                {/* permissions sections */}
                {!inviteToken && !!selectedPermission && (
                  <UserPermissions
                    ref={permissionsRef}
                    selectedPermission={selectedPermission}
                    selectedUnits={selectedUnits}
                    selectedGroups={selectedGroups}
                    selectedSites={selectedSites}
                  />
                )}
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.submitButtonStyle}
                  disabled={isDisabled}
                >
                  {t`Submit`}
                </Button>

                {!!inviteToken && (
                  <div className={classes.shareSection}>
                    <Typography
                      className={classes.shareHeader}
                    >{t`Share the invite link`}</Typography>
                    <div className={classes.shareContainer}>
                      <IconButton
                        className={classes.shareIconButton}
                        onClick={() => setEmailDialog(true)}
                      >
                        <EmailIcon borderRadius={6} />
                      </IconButton>
                      <FacebookShareButton
                        url={user.inviteUrl || ""}
                        onShareWindowClose={() => goBack()}
                      >
                        <FacebookIcon borderRadius={6} />
                      </FacebookShareButton>
                      <WhatsappShareButton
                        url={user.inviteUrl || ""}
                        onShareWindowClose={() => goBack()}
                      >
                        <WhatsappIcon borderRadius={6} />
                      </WhatsappShareButton>
                    </div>
                  </div>
                )}
              </Form>
            );
          }}
        />
      </div>
      <ConfirmationDialog
        openDialog={deleteDialog}
        onCancel={() => setDeleteDialog(false)}
        onClose={() => setDeleteDialog(false)}
        onConfrim={!!inviteToken ? deleteInvite : deleteUser}
        title={!!inviteToken ? t`Delete` + " " + t`Invite` : t`Delete` +  " " + t`User`}
        text={!!inviteToken ? user.firstName + " " + user.lastName + " " + t`invite` + " " + t`will be deleted` + ", " + t`are you sure?` :
        user.firstName + " " + user.lastName + " " + t`user` + " " + t`will be deleted` + ", " + t`are you sure?`}
        confrimLabel={t`Delete`}
        cancelLabel={t`Cancel`}
        dialogType={"error"}
      />
      <ConfirmationDialog
        onCancel={_.noop}
        onConfrim={() => setIsGusetMissingPermissions(false)}
        onClose={() => setIsGusetMissingPermissions(false)}
        title="Missing users permissions"
        text="Please choose site or unit permissions for guest user"
        confrimLabel="OK"
        cancelLabel=""
        dialogType="normal"
        openDialog={isGusetMissingPermissions}
        hideCancle={true}
      />

      <Dialog
        open={emailDialog}
        onClose={() => setEmailDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{t`send email to?`}</DialogTitle>
        <DialogContent>
          <Typography>{t`Enter the details of the user you want to invite`}</Typography>
          <TextField
            value={shareEmail}
            onChange={(e) => setEmail(e.target.value)}
            label={t`Email`}
            fullWidth
            className={classes.emailTextField}
          />
        </DialogContent>
        <DialogActions>
          <IconButton onClick={() => setEmailDialog(false)} color="primary">
            <Close />
          </IconButton>
          <IconButton onClick={() => sendEmail()} color="secondary" autoFocus>
            <Send />
          </IconButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditSubUser;

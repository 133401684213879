import React from "react";

const SvgDryCopy2 = props => (
  <svg width={40} height={40} {...props}>
    <g
      fill="none"
      fillRule="evenodd"
      stroke={props.maincolor|| "#FDFDFE"}
      strokeLinecap="round"
      strokeWidth={2}
      opacity={props.opacity}
    >
      <path d="M23.598 9.62c-3.193-4.366-6.532-7.498-6.532-7.498S4.809 14.18 4.809 23.27c0 8.209 6.377 13.14 12.257 13.14 6.77 0 12.256-4.932 12.256-13.14 0-1.704-.382-3.464-1.011-5.21m-1.306-2.984A36.187 36.187 0 0025.42 12.3" />
      <path d="M20.484 24.658s1.791-4.77 7.524-6.557c5.734-1.79 7.705-6.727 7.705-6.727m-19.831 8.213s1.79-4.769 7.524-6.557c5.734-1.788 7.705-6.726 7.705-6.726" />
    </g>
  </svg>
);

export default SvgDryCopy2;

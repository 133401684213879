import { createStyles } from "@material-ui/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";

export const homeSrcreenStyles = (theme: any) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      backgroundImage: theme.gradient.main,
      maxWidth: "768px"
    },
    selectionMenu: {
      width: "100%",
      position: "relative",
      display: "flex",
      flex: 1
    },
    selectionMenuContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      transition: "height .15s ease-out",
      height: "100%",
      marginTop: "25px"
    },
    slideWrapper: {
      display: "flex",
      flex: 1,
      position: "relative",
      marginTop: "4px"
    },
    selectionMenuHeader: {
      display: "flex",
      flexDirection: "row",
      width: "94%",
      justifyContent: "flex-end",
      alignItems: "center",
      padding: "2%"
    },
    selectionMenuTitle: {
      fontSize: "20px",
      marginLeft: "13px",
      color: fade(theme.palette.colors.white, 0.9),
      display: "flex",
      alignItems: "center"
    },
    controlContainer: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      borderTopRightRadius: "25px",
      borderTopLeftRadius: "25px",
      backgroundColor: theme.palette.colors.mainScreenColorDark
    },
    pageContent: {
      display: "flex",
      flex: 1,
      width: "100%",
      flexDirection: "column",
      position: "relative"
    },
    expandMoreIcon: {
      color: theme.palette.colors.white,
      fontSize: "30px",
      zIndex: 5
    },
    expandMoreIconWrapper: {
      background: theme.gradient.dropDownBG,
      width: "43px",
      height: "43px",
      marginTop: "20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "100px"
    },
    controlSliderWrapper: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      alignItems: "center",
      position: "absolute",
      bottom: 0,
      right: 0,
      height: "100%",
      width: "100%",
      zIndex: 1111
    },
    controlSectionHeader: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      height: "20px",
      backgroundColor: theme.palette.colors.transparent
    },
    powerOffButton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "60px",
      height: "34px",
      borderRadius: "6px",
      boxShadow: "0 2px 3px 0 rgba(0, 0, 0, 0.1)",
      backgroundColor: theme.palette.colors.powerOffButton,
      "&:hover": {
        backgroundColor: fade(theme.palette.colors.powerOffButton, 0.7)
      },
      "&:focus": {
        backgroundColor: theme.palette.secondary.dark
      },
      "&:active": {
        backgroundColor: theme.palette.colors.powerOffButton
      }
    },

    powerOffButtonText: {
      height: "20px",
      fontSize: "14px",
      fontWeight: 500,
      color: theme.palette.colors.white
    },
    noSitesMesasge: {
      fontSize: "20px",
      color: theme.palette.colors.white,
      padding: "15px"
    }
  });

import { makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import { Toolbar } from "../../components/";
import { useStoreActions, useStoreState } from "../../Stores/typedHooks";
import { AddIcon, ArrowBack } from "../../svgComponents";
import { ClickableRow } from "../../widgets";
import { groupManagementStyles } from "./groupManagement.style";

interface IProps {
  history: any;
  match: any;
}

interface IGroup {
  name: string;
  id: string;
  units: [];
  sensors: [];
}

const GroupManagement: React.FC<IProps> = ({ history, match }) => {
  const [customerGroups, setCustomerGroup] = useState<any>({});

  const loggedUser = useStoreState((state) => state.userStore.user);

  const { customer: customerId } = loggedUser;

  const getCustomerGroups = useStoreActions(
    (actions) => actions.customerStore.getCustomerGroups
  );
  const { startLoader, finishLoader } = useStoreActions(
    (action) => action.loaderStore
  );

  const addMessage = useStoreActions(
    (actions) => actions.messageStore.addMessage
  );

  const useStyles = makeStyles(groupManagementStyles);
  const classes = useStyles();

  useEffect(() => {
    startLoader();
    getCustomerGroups(customerId)
      .then((groups: any) => {
        setCustomerGroup(groups);
      })
      .catch((err: any) => {
        addMessage({
          message: err.message
        });
      })
      .finally(() => finishLoader());
  }, [customerId, addMessage, startLoader, finishLoader, getCustomerGroups]);

  const goBack = () => {
    history.push({ pathname: `/site`, menuOpen: true });
  };

  const addEditGroup = (groupId?: string) => {
    history.push(`/group-management/group/${!!groupId ? groupId : ""}`);
  };

  const groupsArray: IGroup[] = Object.values(customerGroups);

  return (
    <div className={classes.container}>
      <Toolbar
        title={t`Group Management`}
        leftAction={() => goBack()}
        leftIconComponent={<ArrowBack />}
        rightIconComponent={<AddIcon />}
        rightAction={() => addEditGroup()}
      />
      <div className={classes.groupsTitle}>
        <Typography className={classes.labelStyle}>{t`Groups`}</Typography>
      </div>

      <div className={classes.groupsList}>
        {groupsArray.length !== 0 &&
          groupsArray.map((group) => {
            const { id, name } = group;
            return (
              <ClickableRow
                key={id}
                title={name}
                handleClick={() => addEditGroup(id)}
                rowStyle={classes.rowStyle}
              />
            );
          })}
      </div>
    </div>
  );
};

export default GroupManagement;

function validateEmail(email: string) {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function formValidtion(phone: string, email: string) {
  if (!phone) {
    return {
      pass: false,
      errorType: "phone",
      errorMsg: "phone cannot be empty"
    };
  }

  if (phone.length < 11) {
    return {
      pass: false,
      errorType: "phone",
      errorMsg: "please add valid phone number"
    };
  }

  if (email.length >= 1 && !validateEmail(email)) {
    return {
      pass: false,
      errorType: "email",
      errorMsg: "please add valid email"
    };
  }

  return { pass: true, errorType: "", errorMsg: "" };
}

import React from "react";
import { Field } from "formik";
import { Grid, Typography } from "@material-ui/core/";
import PhoneInput from "react-phone-number-input";
import clsx from "clsx";

interface IFormikFieldProps {
  formikProps: any;
  name?: string;
  inputClass?: any;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  value?: any;
  isFeildArray?: boolean;
  errorStyle?: string;
  classes?: any;
  inputContainerClass?: any;
  errorContainerStyle?: any;
}

const FormikPhoneInput: React.SFC<IFormikFieldProps> = ({
  formikProps,
  name = "phone",
  inputClass,
  className,
  errorStyle,
  inputContainerClass,
  errorContainerStyle,
  placeholder = "Phone Number",
  disabled = false,
  value = null,
  isFeildArray = false,

  ...props
}) => {
  const getFieldValue = (form: any) =>
    isFeildArray ? value : form.values[name];

  return (
    <Field
      validateOnBlur
      validateOnChange
      name={name}
      render={(fieldProps: any) => {
        const { form } = fieldProps;
        const error = form.errors[name];
        const hasError = !!error;

        return (
          <Grid container className={className}>
            <div
              className={clsx(
                inputContainerClass,
                hasError ? errorContainerStyle : ""
              )}
            >
              <PhoneInput
                disabled={disabled}
                placeholder={placeholder}
                value={getFieldValue(form) || ""}
                className={inputClass}
                onChange={value =>
                  formikProps.handleChange({
                    target: { value: value, name: name }
                  })
                }
              />
            </div>
            {hasError && (
              <Typography className={errorStyle}>{String(error)}</Typography>
            )}
          </Grid>
        );
      }}
    />
  );
};

export default FormikPhoneInput;

import { createStyles } from "@material-ui/styles";
import { fade } from "@material-ui/core";

export default (theme: any) =>
  createStyles({
    scheduleInfoContainer: {
      display: "flex",
      flexFlow: "column nowrap",
      width: "100%",
      maxWidth: "768px",
      background: theme.gradient.addSchedule
    },
    pageContent: {
      overflow: "auto",
      flexFlow: "column nowrap",
      padding: "20px",
      flex: 1,
      alignItems: "center",
      background: "transparent"
    },
    fieldContainerStyle: { width: "100%" },
    inputStyle: {
      fontSize: "18px",
      color: theme.palette.colors.white
    },
    iconStyle: { fontSize: "22px", color: theme.palette.colors.white },

    errorLabelStyle: {
      color: theme.palette.colors.error,
      fontSize: "12px",
      fontWeight: "bold",
      textAlign: "center",
      marginTop: "10px",
      minHeight: "15px"
    },
    dividerStyle: { marginBottom: "5px", marginTop: "5px" },
    daysContainer: {
      padding: "0px 0px",
      margin: "0px 0px",
      justifyContent: "space-between"
    },
    dayLabelStyle: {
      color: theme.palette.colors.white,
      backgroundColor: fade(theme.palette.colors.white, 0.1),
      width: "12%",
      fontSize: "14px",
      textTransform: "none",
      padding: "0px 0px",
      margin: "5px 0px",
      "&:hover": {
        background: fade(theme.palette.colors.white, 0.1),
        color: theme.palette.colors.white
      }
    },
    tabRoot: {
      minWidth: "45px",
      minHeight: "45px",
      marginRight: "7px"
    },
    highlited: {
      background: theme.palette.secondary.main,
      color: theme.palette.colors.white,
      "&:hover": {
        background: theme.palette.secondary.main,
        color: theme.palette.colors.white
      }
    },
    inputsContainer: {
      display: "flex",
      flexFlow: "row no-wrap",
      justifyContent: "center",
      alignItems: "center"
    },
    controlArrowButton: {
      "&:disabled": {
        "& path": {
          stroke: theme.palette.colors.gray
        }
      }
    },
    setpointNotSet: {
      color: theme.palette.colors.gray
    },
    setpointStyle: {
      width: "fit-content",
      minHeight: "94px",
      fontSize: "80px",
      fontWeight: "normal",
      lineHeight: "normal"
    },
    checkboxContainer: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center"
    },
    setpointContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingRight: "13px"
    },
    fieldStyle: { width: "30px", "& input": { fontSize: "16px" } },

    valueTitle: {
      fontSize: "16px",
      color: theme.palette.colors.white,
      fontWeight: "normal",
      lineHeight: "normal",
      height: "100%",
      display: "block"
    },
    startEndTimeContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center"
    },
    timeContainer: {
      height: "44px",
      width: "48%",
      color: theme.palette.colors.lightWhite,
      borderRadius: "22px",
      backgroundColor: fade(theme.palette.colors.white, 0.1),
      fontSize: "16px"
    },
    statusStyle: {
      fontSize: "18px",
      fontHeight: "normal",
      fontWeight: "normal"
    },
    selectModeStyle: {
      fontSize: "18px",
      lineHeight: "normal",
      fontWeight: "normal",
      width: "100%",
      marginBottom: "18px"
    },
    setPointSection: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between"
    },
    saveButton: {
      width: "100%",
      height: "100%",
      display: "flex",
      minHeight: "60px",
      flexDirection: "column",
      justifyContent: "flex-end"
    },
    container: {
      width: "100%",
      color: theme.palette.colors.white,
      padding: "9px 10px 16px 10px",
      borderRadius: "6px",
      background: fade(theme.palette.colors.lightWhite, 0.1),
      marginTop: "20px",
      maxWidth: "768px",
      minHeight: "max-content"
    },

    bodyRow: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "13px"
    },
    scheduleStatusStyle: {
      color: theme.palette.colors.white,
      fontSize: "18px"
    },
    controlSec: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      height: "100%"
    },
    powerOnPowerOffTimeIndication: {
      border: `1px solid ${theme.palette.secondary.main}`
    },
    checkboxStyle: { marginLeft: "5px" },
    selectModeContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between"
    },
    tempSymbolStyle: { fontSize: "30px" },
    timeSelected: { border: `solid 1px ${theme.palette.secondary.main}` },
    iosTimeInput: {
      position: "absolute",
      width: "100%",
      height: "100%",
      zIndex: 10,
      visibilty: "none",
      borderRadius: "22px",
      "& input": {
        background: "blue",
        borderRadius: "22px",
        height: "100%",
        padding: 0,
        opacity: 0
      }
    },
    iosTimeLabel: {
      position: "absolute",
      width: "100%",
      height: "100%",
      zIndex: 9,
      borderRadius: "22px",
      color: theme.palette.colors.lightWhite,
      fontSize: "16px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    iosHourButton: {
      position: "relative",
      display: "flex",
      height: "44px",
      width: "48%",
      borderRadius: "22px",
      backgroundColor: fade(theme.palette.colors.white, 0.1),
      boxShadow:
        "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)"
    }
  });

import React from "react";

const SvgUserPhoto = props => (
  <svg width={34} height={34} {...props}>
    <g fill="#AAA2AA" fillRule="evenodd">
      <circle cx={17} cy={17} r={17} fillOpacity={0.2} />
      <path
        d="M25.365 21.301c.167.427.277.877.326 1.334.06.41.106.823.138 1.236l.011.116v.056c0 1.11-.898 2.009-2.007 2.009H10.166c-1.108 0-2.006-.9-2.006-2.009v-.056l.008-.121c.035-.413.081-.826.138-1.236.051-.456.162-.903.329-1.329.617-1.345 5.16-3.285 8.365-3.285s7.748 1.94 8.365 3.285M17 16.508a4.174 4.174 0 1 1 0-8.348 4.175 4.175 0 0 1 0 8.348"
        opacity={0.5}
      />
    </g>
  </svg>
);

export default SvgUserPhoto;

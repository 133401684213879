import {
  AppBar,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  makeStyles,
  Typography
} from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { t } from "ttag";
import styles from "./toolbar.style";

interface ToolbarProps {
  title: string;
  leftAction?: any;
  rightAction?: any;
  rightButtonLabel?: string;
  rightIconComponent?: any;
  leftIconComponent?: any;
  addRightPadding?: boolean;
}

const Toolbar = (props: ToolbarProps) => {
  const {
    title,
    leftAction,
    rightAction,
    rightButtonLabel,
    rightIconComponent,
    leftIconComponent,
    addRightPadding
  } = props;

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const isIOS = () => {
    return [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod"
    ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  };

  return (
    <AppBar position="static" className={clsx(classes.appBarStyle, isIOS() && classes.iosPadding)}>
      <Container>
        <Grid container justify="space-between" alignItems="center">
          <IconButton
            edge="start"
            aria-label="menu"
            onClick={leftAction}
            className={classes.leftActionStyle}
          >
            {leftIconComponent}
          </IconButton>

          <Box className={classes.titleWrapper}>
            <Typography
              className={classes.titleStyle}
            >{title}</Typography>
          </Box>

          <div className={classes.rightActionStyle}>
            {rightButtonLabel && (
              <Button
                color="primary"
                className={classes.buttonText}
                onClick={rightAction}
              >
                {rightButtonLabel}
              </Button>
            )}
            {rightIconComponent && (
              <IconButton
                edge="start"
                aria-label="menu"
                className={clsx(classes.rightButtonStyle, {
                  [classes.addPadding]: addRightPadding
                })}
                onClick={rightAction}
              >
                {rightIconComponent}
              </IconButton>
            )}
          </div>
        </Grid>
      </Container>
    </AppBar>
  );
};

export default Toolbar;

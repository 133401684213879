import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { Form, Formik } from "formik";
import { tz } from "moment-timezone";
import React, { useState } from "react";
import { t } from "ttag";
import * as Yup from "yup";
import { Toolbar } from "../../components";
import deviceImg from "../../images/coolmaster.png";
import { useStoreActions } from "../../Stores/typedHooks";
import { ArrowBack } from "../../svgComponents";
import { FormikField, Select } from "../../widgets";
import { countries } from "../SiteInfoForm/countryList";
import createSiteStyles from "./createSite.style";
import { stateList } from "./stateList";

interface CreateSiteProps {
  history: any;
  match: any;
}

const timeZones = tz.names().map((timezone) => ({
  value: timezone,
  label: `${timezone}  ${tz(timezone).format("Z")}`
}));

const CreateSite: React.FC<CreateSiteProps> = ({ history, match }) => {
  const [state, setState] = useState<string>("");
  const [stateOption, setStateOption] = useState<any>("");
  const [timezone, setTimezone] = useState<string>("");
  const [timezoneOption, setTimezoneOption] = useState<any>("");
  const [countryOption, setCountryOption] = useState<any>("");

  const startLoader = useStoreActions(
    (actions) => actions.loaderStore.startLoader
  );
  const finishLoader = useStoreActions(
    (actions) => actions.loaderStore.finishLoader
  );
  const addMessage = useStoreActions(
    (actions) => actions.messageStore.addMessage
  );
  const createNewSite = useStoreActions(
    (actions) => actions.siteStore.createNewSite
  );
  const addNewDevice = useStoreActions(
    (actions) => actions.deviceStore.addNewDevice
  );

  const useStyles = makeStyles(createSiteStyles);
  const classes = useStyles();

  const { url } = match;
  const addSiteWithDevice = url.includes("device-registration");

  const validationSchema = Yup.object({
    name: Yup.string().required(t`required field`),
    country: Yup.string().required(t`required field`),
    city: Yup.string().required(t`required field`),
    address: Yup.string().required(t`required field`),
    timezone: Yup.string().required(t`required field`),
    postalCode: Yup.string().required(t`required field`),
    state: Yup.string().when("country", {
      is: (value) => value && value === "US",
      then: Yup.string().required(
        "Required"
      ),
      otherwise: Yup.string()
    })
  });

  const handleFormdSubmit = (values: any) => {

    const { customerId } = match.params;
    const siteData = { ...values, timezone, state: countryOption.value === "US" && stateOption ? stateOption.value : "" };

    startLoader();

    createNewSite({ siteData, customerId })
      .then((newSite: any) => {
        const { id } = newSite;

        addSiteWithDevice && addDevice(id);
        !addSiteWithDevice && history.push(`/site-management`);
      })
      .catch((err: any) => {
        addMessage({
          message: err.message
        });
      })
      .finally(() => finishLoader());
  };

  const addDevice = (siteId: any) => {
    const serial = localStorage.getItem("deviceSerial") || "";
    const pin = localStorage.getItem("devicePin") || "";

    addNewDevice({ name: serial, serial, pin, siteId })
      .then((device: any) => {
        finishLoader();
        history.push(`/device-registration/device/${device.id}/system-detection`);
      })
      .catch((err: any) => {
        finishLoader();
        addMessage({
          message: err.message
        });
      });
  };

  const handleStateSelect = (value: any) => {
    setStateOption(value);
    setState(value ? value.value : "");
  };

  const handleTimezoneSelect = (value: any) => {
    setTimezoneOption(value);
    setTimezone(value ? value.value : "");
  };

  const handleCountrySelectChange = (value: any) => {
    setCountryOption(value);
  };

  const goBack = () => {
    history.push("/site-management");
  };

  return (
    <div className={classes.screenContainer}>
      <Grid container className={classes.userFormContainer}>
        <Toolbar
          title={addSiteWithDevice ? t`Connect Device` : t`Add Site`}
          leftIconComponent={<ArrowBack />}
          leftAction={goBack}
        />
        <Grid item className={classes.scrolledSection}>
          <div className={classes.pageContent}>
            <div className={classes.header}>
              <Typography
                className={classes.pageTitle}
              >{t`Site Information`}</Typography>
              <Typography
                className={classes.subTitle}
              >{t`Please add site information`}</Typography>
              <div className={classes.deviceImageContainer}>
                <div className={classes.deviceImageShadow} />
                <img
                  src={deviceImg}
                  alt={"HVAC"}
                  className={classes.deviceImgStyle}
                />
              </div>
            </div>
          </div>
          <Formik
            initialValues={{
              name: "",
              description: "",
              country: "",
              city: "",
              address: "",
              postalCode: "",
              timezone: "",
              state: ""
            }}
            onSubmit={(values) => handleFormdSubmit(values)}
            enableReinitialize={true}
            validationSchema={validationSchema}
            render={({ values, errors, touched, setFieldValue, ...formikProps }) => {
              return (
                <Form className={classes.form}>
                  <Grid container className={classes.inputsContainer}>
                    <FormikField
                      name="name"
                      placeholder={t`Site Name`}
                      variant="outlined"
                      formikProps={formikProps}
                      className={classes.inputWrapperStyle}
                      inputClass={classes.inputClass}
                      errorStyle={classes.errorStyle}
                      wrapperErrorClass={classes.wrapperErrorClass}
                    />
                    <FormikField
                      name="address"
                      placeholder={t`Street Address`}
                      variant="outlined"
                      formikProps={formikProps}
                      className={classes.inputWrapperStyle}
                      inputClass={classes.inputClass}
                      errorStyle={classes.errorStyle}
                      wrapperErrorClass={classes.wrapperErrorClass}
                    />
                    <div className={classes.selectContainer}>
                      <Select
                        clear={true}
                        label={t`Country`}
                        value={countryOption || ""}
                        suggestions={countries}
                        handleSelectChange={(value: any) => {
                          setFieldValue("country", value.value);
                          handleCountrySelectChange(value);
                        }}
                        error={errors.country && touched.country ? errors.country as string : ""}
                        selectClass={errors.country && touched.country ? classes.selectErrorStyle : ""}
                      />
                    </div>

                    <FormikField
                      name="city"
                      placeholder={t`City`}
                      variant="outlined"
                      formikProps={formikProps}
                      className={classes.inputWrapperStyle}
                      inputClass={classes.inputClass}
                      errorStyle={classes.errorStyle}
                      wrapperErrorClass={classes.wrapperErrorClass}
                    />
                    {countryOption.value === "US" && <div className={classes.selectContainer}>
                      <Select
                        label={t`State`}
                        value={stateOption || ""}
                        suggestions={stateList}
                        clear={true}
                        handleSelectChange={(value: any) => {
                          setFieldValue("state", value.value);
                          handleStateSelect(value);
                        }}
                        error={errors.state && touched.state ? errors.state : ""}
                        selectClass={errors.state && touched.state ? classes.selectErrorStyle : ""}
                      />
                    </div>}
                    <FormikField
                      name="postalCode"
                      placeholder={t`Zip`}
                      variant="outlined"
                      formikProps={formikProps}
                      className={classes.inputWrapperStyle}
                      inputClass={classes.inputClass}
                      errorStyle={classes.errorStyle}
                    />

                    <FormikField
                      name="description"
                      placeholder={t`Description`}
                      variant="outlined"
                      formikProps={formikProps}
                      className={classes.inputWrapperStyle}
                      inputClass={classes.inputClass}
                      errorStyle={classes.errorStyle}
                    />
                    <div className={classes.selectContainer}>
                      <Select
                        label={t`Timezone`}
                        clear={true}
                        value={timezoneOption || ""}
                        suggestions={timeZones}
                        handleSelectChange={(value: any) => {
                          setFieldValue("timezone", value.value);
                          handleTimezoneSelect(value);
                        }}
                        error={errors.timezone && touched.timezone ? errors.timezone : ""}
                        selectClass={errors.timezone && touched.timezone ? classes.selectErrorStyle : ""}
                      />
                    </div>
                  </Grid>
                  <Grid item className={classes.buttonGrid}>
                    <Button
                      type="submit"
                      variant="contained"
                      className={classes.submitButtonStyle}
                    >
                      {t`Save`}
                    </Button>
                  </Grid>
                </Form>
              );
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default CreateSite;

import { createStyles } from "@material-ui/styles";
import { fade } from "@material-ui/core";

export const unitSelectionStyle = (theme: any) =>
  createStyles({
    unitSelectionContainer: {
      minWidth: "90%",
      backgroundImage: theme.gradient.default,
      display: "flex",
      flexDirection: "column",
      maxHeight: "40%",
      margin: "10px 0",
      color: theme.palette.colors.white
    },
    listHeaderStyle: {
      paddingTop: 0,
      paddingBottom: 0
    },
    listItemRoot: {
      padding: "0 10px"
    },
    listItemTextPrimary: {
      fontSize: "15px"
    },
    listItemTextSecondary: {
      fontSize: "15px",
      color: fade(theme.palette.colors.lightWhite, 0.5),
      textOverflow: "ellipsis",
      overflow: "hidden",
      display: "-webkit-box",
      "-webkit-line-clamp": 1,
      "-webkit-box-orient": "vertical",
      height: "25px",
      width: "50%"
    },
    expand: {
      transform: "rotate(0deg)",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest
      })
    },
    expandOpen: {
      transform: "rotate(180deg)"
    },

    unitsListWrapper: {
      height: "100%",
      overflowY: "auto",
      paddingTop: "10px"
    },
    unitsListCollapseContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
    }
  });


import coolremoteSDK from "coolremote-sdk";
import React from "react";

const Impersonate: React.FC<any> = (props) => {

  const { params: { token = "" } = {} } = props.match;
  const Impersonate = () => {
    coolremoteSDK.User.logout();
    coolremoteSDK.xhr.setToken(token);
    localStorage.clear();
    localStorage.setItem("token", token);
    window.location.href = "/dashboard";
  };
  Impersonate();
  return (<> </>);
};

export default Impersonate;

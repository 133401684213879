import React from "react";
import { StoreProvider } from "easy-peasy";
import { Routes } from "./screens/";
import store from "./Stores";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
require("promise.prototype.finally");

const App = () => {
  return (
    <StoreProvider store={store}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Routes />
      </MuiPickersUtilsProvider>
    </StoreProvider>
  );
};

export default App;

import {
  Collapse,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  makeStyles,
  TextField as MUTextField,
  Typography
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from "react";
import { t } from "ttag";

import clsx from "clsx";
import _ from "lodash";
import { Toolbar } from "../../components";
import { useStoreActions, useStoreState } from "../../Stores/typedHooks";
import { ArrowBack } from "../../svgComponents";
import { Button, Checkbox, Select } from "../../widgets";
import styles from "./userPermissions.style";

const permissions = [
  { value: "admin", label: "Admin" },
  { value: "manager", label: "Manager" },
  { value: "guest", label: "Guest" }
];

const UserPermissions = forwardRef((props: any, ref: any) => {
  const [userUnits, setUserUnits] = useState<any>({});

  const [permissionSelected, setPermissionSelected] = useState<any>({});
  const [selectedSites, setSelectedSites] = useState<any>([]);
  const [selectedGroups, setSelectedGroups] = useState<any>({});
  const [selectedUnits, setSelectedUnits] = useState<any>({});
  const [openedSites, setOpenedSites] = useState<any>(false);
  const [getGroupsFlag, setGroupsFlag] = useState<boolean>(true);
  const [toGetSites, setToGetSites] = useState<boolean>(true);

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const startLoader = useStoreActions(
    (actions) => actions.loaderStore.startLoader
  );
  const finishLoader = useStoreActions(
    (actions) => actions.loaderStore.finishLoader
  );
  const getAllSites = useStoreActions(
    (actions) => actions.siteStore.getUserSites
  );

  const getFullGroups = useStoreActions(
    (actions) => actions.groupStore.getFullGroups
  );

  const getAllUnits = useStoreActions(
    (actions) => actions.unitStore.getUserUnits
  );
  const mappedSiteGroups = useStoreState(
    (state) => state.groupStore.groupSiteMapping
  );

  const allSites = useStoreState((state) => state.siteStore.userSites);
  const allGroups = useStoreState((state) => state.groupStore.groups);
  const groupSiteMap = useStoreState(
    (state) => state.groupStore.groupSiteMapping
  );
  const user = useStoreState((state) => state.userStore.user);
  const { customer: customerId } = user;

  useEffect(() => {
    if (!_.isEmpty(allSites)) {
      setToGetSites(false);
      return;
    }
    if (!toGetSites) {
      return;
    }

    getAllSites();
  }, [allSites, getAllSites, toGetSites]);

  useEffect(() => {
    if (_.isEmpty(allSites) || !_.isEmpty(userUnits)) {
      return;
    }
    startLoader();
    getAllUnits()
      .then((units: any) => {
        setUserUnits(units);
      })
      .finally(() => finishLoader());
  }, [allSites, startLoader, finishLoader, getAllUnits, userUnits]);

  useEffect(() => {
    if (!_.isEmpty(groupSiteMap)) {
      setGroupsFlag(false);
      return;
    }
    if (!getGroupsFlag) {
      return;
    }
    setGroupsFlag(false);
    getFullGroups();
  }, [groupSiteMap, startLoader, finishLoader, getFullGroups, getGroupsFlag]);

  useEffect(() => {
    const { selectedPermission } = props;
    setPermissionSelected({
      value: selectedPermission,
      label: selectedPermission
    });
  }, []);

  useEffect(() => {
    if (_.isEmpty(userUnits)) {
      return;
    }
    const { selectedUnits, selectedSites, selectedGroups } = props;
    startLoader();

    let mappedUnits: any = {};
    selectedUnits.map((unitId: any) => {
      if (!userUnits[unitId]) {
        return;
      }
      const { site: siteId } = userUnits[unitId];
      let siteUnits: any = [];
      if (mappedUnits[siteId]) {
        siteUnits = mappedUnits[siteId];
      }
      siteUnits.push(unitId);
      mappedUnits[siteId] = siteUnits;
      return mappedUnits;
    });
    setSelectedUnits(mappedUnits);

    let selectedMappedSiteGroups: any = {};

    selectedGroups.map((groupId: any) => {
      if (!allGroups[groupId]) {
        return;
      }

      for (let key in mappedSiteGroups) {
        if (
          mappedSiteGroups.hasOwnProperty(key) &&
          mappedSiteGroups[key].includes(groupId)
        ) {
          let groupsArray: any = [];
          if (selectedMappedSiteGroups[key]) {
            groupsArray = selectedMappedSiteGroups[key];
          }
          groupsArray.push(groupId);
          selectedMappedSiteGroups[key] = groupsArray;
        }
      }

      return selectedMappedSiteGroups;
    });
    setSelectedGroups(selectedMappedSiteGroups);

    setSelectedSites(selectedSites);
    finishLoader();
  }, [userUnits, allGroups]);

  const switchSiteControl = (id: string) => {
    let selSites = [...selectedSites];
    const idIndex = selSites.indexOf(id);
    if (idIndex >= 0) {
      selSites.splice(idIndex, 1);
    } else {
      selSites.push(id);
    }
    setSelectedSites(selSites);
    setSelectedUnits({ ...selectedUnits, [id]: [] });
    setSelectedGroups({ ...selectedGroups, [id]: [] });
  };

  const switchGroupControl = (
    id: string,
    siteId: string,
    isDisabled: boolean
  ) => {
    if (isDisabled) {
      return;
    }
    let selGroups = { ...selectedGroups };
    if (_.isEmpty(selGroups[siteId])) {
      selGroups[siteId] = [];
    }
    const idIndex = selGroups[siteId].indexOf(id);
    if (idIndex >= 0) {
      selGroups[siteId].splice(idIndex, 1);
    } else {
      selGroups[siteId].push(id);
    }
    setSelectedGroups(selGroups);
  };

  const switchUnitControl = (
    id: string,
    siteId: string,
    isDisabled: boolean
  ) => {
    if (isDisabled) {
      return;
    }

    let selUnits = { ...selectedUnits };
    if (_.isEmpty(selUnits[siteId])) {
      selUnits[siteId] = [];
    }
    const idIndex = selUnits[siteId].indexOf(id);
    if (idIndex >= 0) {
      selUnits[siteId].splice(idIndex, 1);
    } else {
      selUnits[siteId].push(id);
    }
    setSelectedUnits(selUnits);
  };

  const getAdminPermission = () => {
    return {
      customers: {
        [customerId]: "customerAdmin"
      }
    };
  };

  const getManagerPermission = () => {
    return {
      customers: { [customerId]: "customerManager" }
    };
  };

  const getCustomPermissions = () => {
    let customerPermission: any = {};
    let sitesPermissions: any = {};
    let groupsPermissions: any = {};
    let unitsPermissions: any = {};

    customerPermission[customerId] = "customerGuest";
    for (let x in selectedSites) {
      sitesPermissions[selectedSites[x]] = "siteAdmin";
    }

    const mergedGroups: any = Object.values(selectedGroups).flat();
    for (let x in mergedGroups) {
      groupsPermissions[mergedGroups[x]] = "groupAdmin";
    }

    const mergedUnits: any = Object.values(selectedUnits).flat();
    for (let x in mergedUnits) {
      unitsPermissions[mergedUnits[x]] = "unitAdmin";
    }

    const permissionObject: any = {
      customers: customerPermission,
      sites: sitesPermissions,
      groups: groupsPermissions,
      units: unitsPermissions
    };

    return permissionObject;
  };

  const preparePermissions = () => {
    let permissionsObj;

    const { value: role } = permissionSelected;
    if (role === "admin") {
      permissionsObj = getAdminPermission();
    } else if (role === "manager") {
      permissionsObj = getManagerPermission();
    } else {
      permissionsObj = getCustomPermissions();
    }
    return permissionsObj;
  };

  const handlePermissionChange = (option: any) => {
    setPermissionSelected(option);
    setSelectedSites([]);
    setSelectedGroups({});
    setSelectedUnits({});
  };

  const toggleSite = (siteId: string) => {
    setOpenedSites({ ...openedSites, [siteId]: !openedSites[siteId] });
  };

  useImperativeHandle(ref, () => ({
    preparePermissions
  }));

  const isOptionGuest =
    !_.isEmpty(permissionSelected) && permissionSelected.value === "guest";

  const generalGroups = groupSiteMap["groups"];

  return (
    <div className={classes.permissionsSection}>
      <Typography
        className={classes.sectionTitleStyle}
      >{t`Permissions`}</Typography>
      <Divider orientation="horizontal" variant="fullWidth" />
      <FormControl required className={classes.roleSelect}>
        <Select
          value={permissionSelected || { label: "User Role", value: "" }}
          suggestions={permissions}
          handleSelectChange={handlePermissionChange}
          clear={false}
          search={false}
          placeholder={t`Permission`}
        />
      </FormControl>

      <Collapse
        in={isOptionGuest}
        timeout="auto"
        unmountOnExit
        className={classes.sitesContainer}
        classes={{ wrapper: classes.unitsGroupsWrapper }}
      >
        <Grid className={classes.sitesListContainer}>
          {!_.isEmpty(allSites) &&
            Object.values(allSites).map((site: any) => {
              const { name: siteName, id: siteId, units: siteUnits } = site;

              const isSiteSelected = selectedSites.indexOf(siteId) >= 0;
              const siteGroups = groupSiteMap[siteId];
              const isSiteOpen = !!openedSites[siteId];
              return (
                <div className={classes.siteContainer} key={`site-${siteId}`}>
                  <div
                    className={clsx(
                      classes.selectionRow,
                      !isSiteOpen && classes.noBorder
                    )}
                    onClick={() => toggleSite(siteId)}
                  >
                    <Typography className={classes.siteNameStyle}>
                      {`Site ${siteName}`}
                    </Typography>

                    <ExpandMore
                      className={clsx(
                        classes.expand,
                        isSiteOpen && classes.expandOpen
                      )}
                    />
                  </div>

                  <Collapse
                    in={isSiteOpen}
                    timeout="auto"
                    unmountOnExit
                    className={classes.guestSection}
                    classes={{
                      wrapper: classes.unitsGroupsWrapper,
                      wrapperInner: classes.collapseWrapperInnerStyle
                    }}
                  >
                    <div className={classes.option} key={`all-${siteId}`}>
                      <div
                        className={classes.selectionRow}
                        onClick={() => switchSiteControl(siteId)}
                      >
                        <Typography className={classes.unitNameStyle}>
                          {t`Entire site`}
                        </Typography>
                        <Checkbox
                          checked={isSiteSelected}
                          value={siteId}
                          onChange={() => { }}
                        />
                      </div>
                    </div>
                    {!_.isEmpty(siteGroups) &&
                      !_.isEmpty(allGroups) &&
                      !_.isEmpty(siteGroups) &&
                      siteGroups.map((groupId: any) => {
                        const { name: groupName } = allGroups[groupId];
                        const isSelected =
                          !_.isEmpty(selectedGroups[siteId]) &&
                          selectedGroups[siteId].indexOf(groupId) >= 0;
                        return (
                          <div className={classes.option} key={groupId}>
                            <div
                              className={classes.selectionRow}
                              onClick={() =>
                                switchGroupControl(
                                  groupId,
                                  siteId,
                                  isSiteSelected
                                )
                              }
                            >
                              <Typography className={classes.unitNameStyle}>
                                {groupName}
                              </Typography>
                              <Checkbox
                                checked={isSelected}
                                value={groupId}
                                disabled={isSiteSelected}
                                onChange={() => { }}
                              />
                            </div>
                          </div>
                        );
                      })}
                    {!_.isEmpty(siteUnits) &&
                      !_.isEmpty(userUnits) &&
                      siteUnits.map((unitId: any) => {
                        if (!userUnits[unitId]) {
                          return;
                        }

                        const { name: unitName } = userUnits[unitId];
                        const isSelected =
                          !_.isEmpty(selectedUnits[siteId]) &&
                          selectedUnits[siteId].indexOf(unitId) >= 0;
                        return (
                          <div className={classes.option} key={unitId}>
                            <div
                              className={classes.selectionRow}
                              onClick={() =>
                                switchUnitControl(
                                  unitId,
                                  siteId,
                                  isSiteSelected
                                )
                              }
                            >
                              <Typography className={classes.unitNameStyle}>
                                {unitName}
                              </Typography>
                              <Checkbox
                                checked={isSelected}
                                value={unitId}
                                disabled={isSiteSelected}
                                onChange={() => { }}
                              />
                            </div>
                          </div>
                        );
                      })}
                  </Collapse>
                </div>
              );
            })}

          {!_.isEmpty(allGroups) && !_.isEmpty(generalGroups) && (
            <div
              className={clsx(
                classes.siteContainer,
                !isOptionGuest && classes.hidden
              )}
              key={`groups-section`}
            >
              <div
                className={clsx(
                  classes.selectionRow,
                  !openedSites["groups"] && classes.noBorder
                )}
                onClick={() => toggleSite("groups")}
              >
                <Typography className={classes.siteNameStyle}>
                  {`Groups`}
                </Typography>

                <ExpandMore
                  className={clsx(
                    classes.expand,
                    !!openedSites["groups"] && classes.expandOpen
                  )}
                />
              </div>

              <Collapse
                in={!!openedSites["groups"]}
                timeout="auto"
                unmountOnExit
                className={clsx(classes.guestSection)}
                classes={{
                  wrapper: classes.unitsGroupsWrapper,
                  wrapperInner: classes.collapseWrapperInnerStyle
                }}
              >
                {generalGroups.map((groupId: any) => {
                  const group = allGroups[groupId];
                  const { name: groupName } = group;
                  const isSelected =
                    !_.isEmpty(selectedGroups["groups"]) &&
                    selectedGroups["groups"].indexOf(groupId) >= 0;
                  return (
                    <div className={classes.option} key={groupId}>
                      <div
                        className={classes.selectionRow}
                        onClick={() =>
                          switchGroupControl(groupId, "groups", false)
                        }
                      >
                        <Typography className={classes.unitNameStyle}>
                          {groupName}
                        </Typography>
                        <Checkbox
                          checked={isSelected}
                          value={groupId}
                          onChange={() => { }}
                        />
                      </div>
                    </div>
                  );
                })}
              </Collapse>
            </div>
          )}
        </Grid>
      </Collapse>
    </div>
  );
});

export default UserPermissions;

import {
  Collapse,
  IconButton,
  InputLabel,
  makeStyles,
  Typography
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import clsx from "clsx";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import deepCompare from "react-fast-compare";
import { t } from "ttag";
import { ConfirmationDialog, Toolbar } from "../../components/";
import { useStoreActions, useStoreState } from "../../Stores/typedHooks";
import { ArrowBack, Check, Delete } from "../../svgComponents/";
import { Button, TextField } from "../../widgets/";
import { groupAddEditStyles } from "./groupAddEdit.style";

interface IGroupAddEditProps {
  history: any;
  match: any;
}

const GroupAddEdit: React.FC<IGroupAddEditProps> = (props) => {
  const [groupName, setGroupName] = useState<string>("");
  const [group, setGroup] = useState<any>(null);
  const [discardPopUp, setDiscardPopUp] = useState<boolean>(false);
  const [dialogType, setDialogType] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [emptyError, setEmptyError] = useState<string>("");
  const [selectedUnits, setSelectedUnits] = useState<any>({});
  const [selectedSensors, setSelectedSensors] = useState<any>({});
  const [units, setUnits] = useState<any>({});
  const [sensors, setSensors] = useState<any>({});

  const loggedUser = useStoreState((state) => state.userStore.user);
  const sites = useStoreState((state) => state.siteStore.userSites);
  const types = useStoreState((state) => state.typesStore.types);

  const getGroup = useStoreActions((actions) => actions.groupStore.getGroup);
  const getSites = useStoreActions((actions) => actions.siteStore.getUserSites);
  const getUnits = useStoreActions((actions) => actions.unitStore.getUserUnits);
  const getSensors = useStoreActions((actions) => actions.sensorStore.getAllSensors);
  const startLoader = useStoreActions((actions) => actions.loaderStore.startLoader);
  const finishLoader = useStoreActions((actions) => actions.loaderStore.finishLoader);
  const addMessage = useStoreActions((actions) => actions.messageStore.addMessage);
  const groupUpdate = useStoreActions((actions) => actions.groupStore.updateGroup);
  const createGroup = useStoreActions((actions) => actions.customerStore.createGroup);

  const { history, match } = props;
  const { params } = match;
  const { groupId } = params;
  const [selectedSite, setSelectedSite] = useState<any>("");
  const isEdit = !!groupId;
  const { customer: customerId } = loggedUser;
  const sitesArray = Object.values(sites);
  const { unitTypes, sensorTypes } = types;

  useEffect(() => {
    if (!_.isEmpty(sites)) {
      return;
    }
    startLoader();
    getSites().finally(() => finishLoader());
  }, [finishLoader, getSites, sites, startLoader]);

  useEffect(() => {
    if (!getSensors || !getUnits) {
      return;
    }

    Promise.all([getUnits(), getSensors()])
      .then(([units, allSensors]: any) => {
        const sensors: any = {};
        Object.values(allSensors).forEach((sensor: any) => {
          if (sensorTypes[sensor.type]?.enableView) {
            sensors[sensor.id] = sensor;
          }
        });

        setUnits(units);
        setSensors(sensors);
        return { units, sensors };
      })
      .then(({ sensors, units }: any) => {
        if (!!groupId) {
          getGroup(groupId)
            .then((group: any) => {
              setGroup(group);
              const { units: groupUnits, sensors: groupSensors, name } = group;
              var selectedUnitsTemp: any = Object.values(groupUnits).reduce((val: any, unitId: any) => {
                if (units[unitId]) {
                  val[unitId] = true;
                }
                return val;
              }, {});
              var selectedSensorsTemp: any = Object.values(groupSensors).reduce((val: any, sensorId: any) => {
                if (sensors[sensorId] && sensorTypes[sensors[sensorId]?.type]?.enableView) {
                  val[sensorId] = true;
                }
                return val;
              }, {});
              setSelectedUnits({ ...selectedUnitsTemp });
              setSelectedSensors({ ...selectedSensorsTemp });
              setGroupName(name);
            })
            .catch((err: any) => {
              addMessage({
                message: err.message
              });
            });
        }
      });
  }, [getUnits, getSensors]);


  const useStyles = makeStyles(groupAddEditStyles);
  const classes = useStyles();

  const checkIfDirty = () => {
    const isNameDirty =
      (!isEdit && groupName.length > 0) || (isEdit && groupName !== group.name);
    const isUnitsDirty =
      (!isEdit && Object.keys(selectedUnits).length > 0) ||
      (isEdit &&
        !deepCompare(Object.keys(group.units), Object.keys(selectedUnits)));
    const isSensorsDirty =
      (!isEdit && Object.keys(selectedSensors).length > 0) ||
      (isEdit &&
        !deepCompare(Object.keys(group.sensors), Object.keys(selectedSensors)));
    return isNameDirty || isUnitsDirty || isSensorsDirty;
  };

  const goBack = () => {
    if (checkIfDirty()) {
      setDiscardPopUp(true);
      setDialogType("discard");
    } else {
      history.push(`/group-management`);
    }
  };

  const handleInputsChange = (event: any) => {
    const { value } = event.target;
    setGroupName(value);
  };

  const updateGroup = () => {
    if (Object.values(selectedUnits).length === 0 && Object.values(selectedSensors).length === 0) {
      setEmptyError(t`Please select one unit or sensor at least`);
      return;
    }
    if (!groupName.length) {
      setError("requried field");
      return;
    }
    startLoader();
    groupUpdate({
      groupId,
      data: {
        name: groupName,
        type: "",
        description: "",
        units: Object.keys(selectedUnits),
        sensors: Object.keys(selectedSensors)
      }
    })

      .catch((error: any) => {
        addMessage({ message: error.message });
      })
      .finally(() => {
        finishLoader();
        history.push(`/group-management`);
      });
  };

  const createNewGroup = () => {
    if (Object.values(selectedUnits).length === 0 && Object.values(selectedSensors).length === 0) {

      setEmptyError(t`Please select one unit or sensor at least`);
      return;
    }

    if (!groupName.length) {
      setError("requried field");
      return;
    }

    startLoader();

    createGroup({
      id: customerId,
      data: {
        name: groupName,
        units: Object.keys(selectedUnits),
        sensors: Object.keys(selectedSensors),
        type: "",
        description: ""
      }
    })
      .catch((error: any) => {
        addMessage({ message: error.message });
      })
      .finally(() => {
        finishLoader();
        history.push(`/group-management`);
      });
  };

  const closeDialog = () => {
    setDiscardPopUp(false);
    setDialogType("");
  };
  const discardForm = () => {
    history.push(`/group-management`);
  };

  const rightAction = isEdit ? updateGroup : createNewGroup;

  const toggleSite = (siteId: string) => {
    if (siteId === selectedSite) {
      setSelectedSite(null);
    } else {
      setSelectedSite(siteId);
    }
  };
  const deleteGroup = useStoreActions(
    (actions) => actions.groupStore.deleteGroup
  );
  const deleteStoreGroup = useStoreActions(
    (actions) => actions.groupStore.deleteCurrentGroup
  );

  const confirmDeletion = () => {
    setDiscardPopUp(false);
    setDialogType("");

    startLoader();

    deleteGroup(groupId)
      .then(() => {
        deleteStoreGroup(groupId);
      })
      .catch((err: any) => {
        addMessage({
          message: err.message
        });
      })
      .finally(() => {
        finishLoader();
        history.push("/group-management");
      });
  };

  const deleteCurrentGroup = () => {
    setDiscardPopUp(true);
    setDialogType("delete");
  };

  const selectUnit = (unitId: string) => {
    if (selectedUnits[unitId]) {
      delete selectedUnits[unitId];
      setSelectedUnits({ ...selectedUnits });
      return;
    }
    selectedUnits[unitId] = true;
    setSelectedUnits({ ...selectedUnits });
  };

  const selectSensor = (sensorId: string) => {
    if (selectedSensors[sensorId]) {
      delete selectedSensors[sensorId];
      setSelectedSensors({ ...selectedSensors });
      return;
    }
    selectedSensors[sensorId] = true;
    setSelectedSensors({ ...selectedSensors });
  };

  return (
    <div className={classes.container}>
      <Toolbar
        title={isEdit ? t`Edit Group` : t`Add Group`}
        leftAction={() => goBack()}
        leftIconComponent={<ArrowBack />}
        rightAction={isEdit ? deleteCurrentGroup : null}
        rightIconComponent={isEdit ? <Delete /> : null}
        addRightPadding
      />
      <div className={classes.nameSection}>
        <InputLabel className={classes.labelStyle}>
          Group Name
          <TextField
            name="groupName"
            value={groupName}
            onChange={handleInputsChange}
            className={classes.textStyle}
            placeholder={t`Create name for the group`}
            margin="none"
            variant="outlined"
            error={error}
          />
        </InputLabel>
      </div>
      <div className={classes.selectUnitsSection}>
        <div className={classes.sitesContainer}>
          <Typography className={classes.mainLabelStyle}>
            {t`Select Units`}
            {emptyError && <Typography className={classes.errorText}>{t`Please select one unit or sensor at least`}</Typography>}
          </Typography>
          {sitesArray.map((site: any, i: number) => {
            const { id: siteId, name, units: siteUnits } = site;
            let mappedSiteUnits: { [index: string]: string } = {};
            for (let unitId of siteUnits) {
              if (units[unitId] && units[unitId].type === 1) {
                mappedSiteUnits[unitId] = units[unitId];
              }
            }

            const siteSensors = Object.values(sensors).filter((sensor: any) => sensor.site === siteId);
            let mappedSiteSensors: { [index: string]: string } = {};
            siteSensors.map((sensor: any) => {
              if (sensor.id) {
                mappedSiteSensors[sensor.id] = sensors[sensor.id];
              }
            });

            const mappedSiteSensorsArray = Object.values(mappedSiteSensors);
            const mappedSiteUnitsArray = Object.values(mappedSiteUnits);
            mappedSiteUnitsArray.sort(function (a: any, b: any) {
              return a.line - b.line;
            });

            mappedSiteSensorsArray.sort(function (a: any, b: any) {
              let nameA = a.name.toUpperCase();
              let nameB = b.name.toUpperCase();
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              return 0;
            });
            const isSiteOpen =
              sitesArray.length === 1 || selectedSite === siteId;
            return (
              <div
                key={`site-item-${siteId}`}
                className={classes.siteContainer}
              >
                <div
                  onClick={() => toggleSite(siteId)}
                  className={clsx(
                    classes.siteHeader,
                    !isSiteOpen && classes.closedSite
                  )}
                >
                  <Typography className={classes.siteNameStyle}>
                    {name}
                  </Typography>
                  <IconButton
                    id={`site-button-arrow-down-${siteId}`}
                    className={isSiteOpen ? classes.expandOpen : classes.expand}
                  >
                    <ExpandMore
                      id={`site-${siteId}`}
                      className={classes.expandMoreStyle}
                    />
                  </IconButton>
                </div>
                <Collapse
                  style={{ overflow: "auto" }}
                  in={isSiteOpen}
                  timeout="auto"
                  unmountOnExit
                  classes={{
                    entered:
                      sitesArray.length === 1 ? "" : classes.collapseWrapper
                  }}
                >
                  {mappedSiteUnitsArray && !_.isEmpty(mappedSiteUnitsArray) && (
                    mappedSiteUnitsArray.map((unit: any, i: number) => {
                      if (_.isEmpty(unit)) {
                        return (
                          <div
                            key={`${siteId}-${i}-pending`}
                            className={classes.unitContainer}
                          >
                            <Typography
                              className={classes.unitNameStyle}
                            >{t`pending...`}</Typography>
                          </div>
                        );
                      }
                      const { name: unitName, type, id: unitId } = unit;

                      if (type === unitTypes.outdoor) {
                        return null;
                      }

                      return (
                        <div
                          key={`${siteId}-${unitId}`}
                          className={classes.unitContainer}
                          onClick={() => selectUnit(unitId)}
                        >
                          <Typography className={classes.unitNameStyle}>
                            {unitName}
                          </Typography>
                          {selectedUnits[unitId] && <Check />}
                        </div>
                      );
                    })
                  )}
                  {mappedSiteSensorsArray && !_.isEmpty(mappedSiteSensorsArray) ? (
                    mappedSiteSensorsArray.map((sensor: any, i: number) => {
                      const { name: sensorName, id: sensorId } = sensor;
                      return (
                        <div
                          key={`${siteId}-${sensorId}`}
                          className={classes.unitContainer}
                          onClick={() => selectSensor(sensorId)}
                        >
                          <Typography className={classes.unitNameStyle}>
                            {sensorName}
                          </Typography>
                          {selectedSensors[sensorId] && <Check />}
                        </div>
                      );
                    })
                  )
                    :
                    <Typography
                      className={classes.noUnitsStyle}
                    >{t`this site has no Units/Snsors`}</Typography>
                  }

                </Collapse>
              </div>
            );
          })}
        </div>
        <Button
          className={classes.buttonStyle}
          variant="contained"
          onClick={rightAction}
        >{t`Save Changes`}</Button>
      </div>
      <ConfirmationDialog
        onCancel={closeDialog}
        onConfrim={dialogType === "delete" ? confirmDeletion : discardForm}
        onClose={closeDialog}
        title={dialogType === "delete" ? "Delete" : "Discard"}
        text={
          dialogType === "delete"
            ? "Are you sure you want to delete this group?"
            : "Are you sure you want to discard changes made on this page?"
        }
        confrimLabel={dialogType === "delete" ? "Delete" : "Discard"}
        cancelLabel="No"
        dialogType={dialogType === "delete" ? "error" : "normal"}
        openDialog={discardPopUp}
      />
    </div>
  );
};

export default GroupAddEdit;

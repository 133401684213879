import React, { useState, useEffect } from "react";
import _ from "lodash";
import { t } from "ttag";
import clsx from "clsx";
import {
  Grid,
  InputLabel,
  Typography,
  TextField,
  LinearProgress,
  makeStyles
} from "@material-ui/core";
import { useStoreActions, useStoreState } from "../../Stores/typedHooks";
import { Button } from "../../widgets";
import { Toolbar } from "../../components";
import { ArrowBack } from "../../svgComponents";
import styles from "./systemDetection.style";
import deviceImg from "../../images/coolmaster.png";

interface SiteProps {
  history: any;
  match: any;
}

const maxScanningTime = 120000;
const waitingInterval = 5000;
let passedTime = 0;

const SystemDetection: React.FC<SiteProps> = ({ history, match }) => {
  const [progressPercentage, setProgressPercentage] = useState<number>(0);
  const [device, setDevice] = useState<any>({});
  const [deviceFound, setDeviceFound] = useState<boolean>(false);

  const types = useStoreState(state => state.typesStore.types);

  const addMessage = useStoreActions(
    actions => actions.messageStore.addMessage
  );
  const getDeviceTree = useStoreActions(
    actions => actions.deviceStore.getDeviceTree
  );
  const refreshSystems = useStoreActions(
    actions => actions.deviceStore.refreshSystems
  );

  const { unitTypes } = types;
  const { params } = match;
  const { deviceId } = params;

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  useEffect(() => {
    getDeviceTree(deviceId)
      .then((response: any) => {
        const { systems = {} } = response;

        if (!_.isEmpty(systems)) {
          refreshSystems(deviceId).then(() => {
            setProgressPercentage(50);
            getDeviceTree(deviceId).then((response: any) => {
              setDevice(response);
              setProgressPercentage(100);
            });
          });
        }

        passedTime += waitingInterval;
        setDeviceFound(true);
      })
      .catch((err: any) => {
        setProgressPercentage(100);

        addMessage({
          message: err.message
        });

        history.push("/site-management")
      });
  }, [addMessage, deviceId, getDeviceTree, history, refreshSystems]);

  useEffect(() => {
    if (passedTime === 0) {
      return;
    }

    if (!deviceFound) {
      return;
    }

    if (!_.isEmpty(device)) {
      return;
    }

    function progress() {
      if (progressPercentage > 95 && _.isEmpty(device)) {
        refreshSystems(deviceId).then(() =>
          getDeviceTree(deviceId).then((response: any) => {
            setDevice(response);
          })
        );
        setProgressPercentage(100);
        clearInterval(timer);
        return;
      }

      getDeviceTree(deviceId).then((response: any) => {
        const { systems } = response;
        if (systems && !_.isEmpty(systems)) {
          setDevice(response);
          setProgressPercentage(100);
          clearInterval(timer);
        }

        passedTime += waitingInterval;
        setProgressPercentage((passedTime / maxScanningTime) * 100);
      });
    }
    const timer = setInterval(progress, waitingInterval);
    return () => {
      clearInterval(timer);
    };
  }, [
    device,
    deviceFound,
    deviceId,
    getDeviceTree,
    progressPercentage,
    refreshSystems
  ]);

  const goBack = () => {
    history.push("/site-management");
  };

  const goNext = () => {
    const { location: { pathname } } = history
    const isDeviceRegister = pathname.includes("device-registration")
    history.push(`/${isDeviceRegister ? "device-registration/" : ""}device/${deviceId}/edit-detected-systems`);
  };

  const { units = {}, systems = {}, name = "" } = device;
  const systemsArray = Object.values(systems);
  const unitsArray = Object.values(units);
  const systemsNumber = systemsArray.length;
  const indoorUnitsNumber = unitsArray.filter(
    (unit: any) => unit.type === unitTypes.indoor
  ).length;
  const isReady = !_.isEmpty(systems);

  return (
    <div
      className={clsx(classes.screenContainer, {
        [classes.gradient]: !isReady
      })}
    >
      <Grid container className={classes.grid}>
        <Toolbar
          title={t`Connect Device`}
          leftIconComponent={<ArrowBack />}
          leftAction={goBack}
        />
        <Grid item className={classes.scrolledSection}>
          <div className={classes.pageContent}>
            <div className={classes.header}>
              <Typography className={classes.pageTitle}>
                {isReady
                  ? t`Congratulations!`
                  : t`Lets Connect your HVAC Device`}
              </Typography>
              <Typography className={classes.subTitle}>
                {isReady &&
                  systemsNumber + t`systems connected to device: CoolMasterNet ` + name}
              </Typography>
              <div className={classes.deviceImageContainer}>
                <div className={classes.deviceImageShadow} />
                <img
                  src={deviceImg}
                  alt={"HVAC"}
                  className={classes.deviceImgStyle}
                />
              </div>
            </div>
          </div>
          {!isReady ? (
            <>
              <Typography className={classes.connectingText}>
                {t`Connecting your device`}
              </Typography>
              <LinearProgress
                variant="determinate"
                value={progressPercentage}
                className={classes.progressBar}
              />
            </>
          ) : (
              <>
                <Grid container className={classes.indoorUnitsContainer}>
                  <Typography
                    className={classes.indoorTypo}
                  >{t`Indoor Units `}</Typography>
                  <TextField
                    variant="outlined"
                    disabled
                    InputProps={{ className: classes.indoorTypo }}
                    className={classes.indoornumber}
                    value={`# ${indoorUnitsNumber}`}
                  />
                </Grid>
                {systemsArray.map((system: any, i: number) => {
                  const { name, brand, model, units } = system;
                  const unitsNumber = Object.values(units).length;
                  // outdoor units number not done, currently I'm showing all units number the system has

                  return (
                    <Grid
                      container
                      key={`system-item-${i}`}
                      className={classes.systemcontainer}
                    >
                      <div className={classes.systemRow}>
                        <div className={classes.fieldWrapper}>
                          <InputLabel className={classes.labelStyle}>
                            System name
                        </InputLabel>
                          <TextField
                            value={name}
                            className={classes.systemFields}
                          />
                        </div>
                        <div className={classes.fieldWrapper}>
                          <InputLabel className={classes.labelStyle}>
                            Brand
                        </InputLabel>
                          <TextField
                            value={brand || "not specified"}
                            className={classes.systemFields}
                          />
                        </div>
                      </div>
                      <div className={classes.systemRow}>
                        <div className={classes.fieldWrapper}>
                          <InputLabel className={classes.labelStyle}>
                            Model
                        </InputLabel>
                          <TextField
                            value={model || "not specified"}
                            className={classes.systemFields}
                          />
                        </div>
                        <div className={classes.fieldWrapper}>
                          <InputLabel className={classes.labelStyle}>
                            Outdoor units
                        </InputLabel>
                          <TextField
                            value={unitsNumber}
                            className={classes.systemFields}
                          />
                        </div>
                      </div>
                    </Grid>
                  );
                })}
                <Button
                  variant="contained"
                  className={classes.nextBtn}
                  onClick={goNext}
                >
                  {t`Next`}
                </Button>
              </>
            )}
        </Grid>
      </Grid>
    </div>
  );
};

export default SystemDetection;

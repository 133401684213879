import React from "react";

const SvgUnitSettings = props => (
  <svg width={17} height={17} {...props}>
    <g fill="none" fillRule="evenodd" stroke="#FFF" strokeLinejoin="round">
      <path
        strokeLinecap="round"
        strokeWidth={0.8}
        d="M4.51 11c-.719.69-.639 1.698-.005 2.55.635.852.686 1.802-.004 2.45m4.009-5c-.719.69-.639 1.698-.005 2.55.635.852.686 1.803-.004 2.45m4.009-5c-.718.69-.64 1.698-.005 2.55.635.852.686 1.803-.004 2.45"
      />
      <path
        strokeWidth={0.909}
        d="M14.248 9H2.752C1.785 9 1 8.04 1 6.854V3.093C1 2.489 1.4 2 1.892 2h13.216C15.6 2 16 2.489 16 3.093v3.761C16 8.04 15.215 9 14.248 9z"
      />
      <path strokeLinecap="round" strokeWidth={0.909} d="M3 7.196h11M3 5h11" />
    </g>
  </svg>
);

export default SvgUnitSettings;

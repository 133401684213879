import React from "react";

const SvgClose = props => (
  <svg width={20} height={20} {...props}>
    <defs>
      <path
        id="close_svg__a"
        d="M14.635 2.135c.244-.245.366-.54.365-.885 0-.346-.121-.64-.365-.885A1.206 1.206 0 0 0 13.75 0c-.346 0-.64.121-.885.365L7.5 5.729 2.135.365A1.206 1.206 0 0 0 1.25 0C.904 0 .61.121.365.365A1.2 1.2 0 0 0 0 1.25c0 .346.121.64.365.885L5.729 7.5.365 12.865c-.244.245-.366.54-.365.885 0 .346.121.64.365.885.245.244.54.366.885.365.346 0 .64-.121.885-.365L7.5 9.271l5.365 5.364c.245.244.54.366.885.365.346 0 .64-.121.885-.365.244-.245.366-.54.365-.885 0-.346-.121-.64-.365-.885L9.271 7.5l5.364-5.365z"
      />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(2.5 2.5)">
      <mask id="close_svg__b" fill="#fff">
        <use xlinkHref="#close_svg__a" />
      </mask>
      <use fill="#3E3E3E" fillRule="nonzero" xlinkHref="#close_svg__a" />
      <g fill="#F4F4F6" mask="url(#close_svg__b)">
        <path d="M-1.875-1.875h18.75v18.75h-18.75z" />
      </g>
    </g>
  </svg>
);

export default SvgClose;

import React from "react";
import clsx from "clsx";
import { t } from "ttag";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  AutoMode,
  FanMode,
  Hot,
  DryIcon,
  ColdIcon,
  Fan1Icon,
  Fan2Icon,
  Fan3Icon,
  Fan4Icon,
  SwingVer,
  SwingHor,
  Swing30,
  Swing45,
  Swing60,
  Swing
} from "../../svgComponents";

import modesListStyle from "./modesList.style";
import { useStoreState } from "../../Stores/typedHooks";

const ModesList = (props: any) => {
  const { activeModes = [], action, customClass, modeName } = props;
  const useStyle = makeStyles(modesListStyle);
  const classes = useStyle();
  const { container, modeStyle, activeModeStyle } = classes;

  const types = useStoreState(states => states.typesStore.types);

  const icons: any = {
    operationModes: {
      COOL: <ColdIcon />,
      HEAT: <Hot />,
      AUTO: <AutoMode />,
      DRY: <DryIcon />,
      FAN: <FanMode />
    },
    fanModes: {
      LOW: <Fan1Icon />,
      MEDIUM: <Fan2Icon />,
      HIGH: <Fan3Icon />,
      AUTO: <Typography>{t`Auto`}</Typography>,
      TOP: <Fan4Icon />
    },
    swingModes: {
      vertical: <SwingVer />,
      horizontal: <SwingHor />,
      30: <Swing30 />,
      45: <Swing45 />,
      60: <Swing60 />,
      auto: <Swing />
    }
  };

  const mode = types[modeName];
  if (!mode) {
    return <div>{t`pending...`}</div>;
  }
  return (
    <div className={container}>
      {Object.keys(mode).map((modeKey: any, index: number) => {
        const stateName = mode[modeKey];
        const isSelected = activeModes.indexOf(+modeKey) > -1;
        const modeIcon = icons[modeName][stateName] || (
          <Typography>{stateName}</Typography>
        );
        return (
          <div
            className={clsx(
              modeStyle,
              {
                [activeModeStyle]: isSelected,
                [classes.inactiveText]: !isSelected
              },
              customClass
            )}
            key={index}
            onClick={() => action(modeName, modeKey)}
          >
            {React.cloneElement(modeIcon, {
              opacity: isSelected ? 1 : 0.4
            })}
          </div>
        );
      })}
    </div>
  );
};

export default ModesList;

import { createStyles } from "@material-ui/styles";
import { fade } from "@material-ui/core/styles";

export const newInviteStyle = (theme: any) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      flex: 1
    },
    iconStyle: { fontSize: "22px", color: theme.palette.colors.gray },
    pageContent: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      width: "100%",
      alignItems: "center",
      boxSizing: "border-box",
      background: theme.palette.colors.black,
      overflowY: "auto",
      height: "100%",
      minHeight: "max-content"
    },
    inputFont: {
      color: theme.palette.colors.black,
      fontSize: "18px",
      borderRadius: "6px",
      height: "35px",
      padding: "10px"
    },
    roleSelect: {
      fontSize: "18px",
      width: "100%"
    },
    usernameContainer: {
      color: theme.palette.colors.white,
      border: `1px solid ${fade(theme.palette.colors.white, 0.7)}`,
      borderRadius: "5px",
      boxSizing: "border-box",
      paddingLeft: "5px"
    },
    usernameErrorWrapper: {
      width: "37%"
    },
    personalDetailsSections: {
      display: "flex",
      flexDirection: "column",
      background: theme.gradient.default,
      width: "100%",
      padding: "4%",
      boxSizing: "border-box",
      marginBottom: "6%",
      justifyContent: "space-between",
      alignItems: "baseline"
    },
    permissionsSection: {
      display: "flex",
      flexDirection: "column",
      background: theme.gradient.default,
      width: "100%",
      padding: "4%",
      boxSizing: "border-box",
      marginBottom: "6%",
      justifyContent: "space-between",
      alignItems: "baseline"
    },

    errorStyle: {
      fontSize: "12px",
      marginTop: "5px",
      marginBottom: "5px",
      color: theme.palette.colors.error
    },
    guestSection: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      maxHeight: "300px",
      overflowY: "auto"
    },
    option: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      boxSizing: "border-box",
      "&:last-child div": {
        borderBottomWidth: "0px"
      }
    },
    optionTitleContainer: {
      display: "flex",
      width: "50%",
      justifyContent: "space-between"
    },
    optionSubTitle: {
      fontSize: "16px",
      color: fade(theme.palette.colors.lightWhite, 0.6)
    },
    optionTitle: {
      fontSize: "16px",
      color: fade(theme.palette.colors.lightWhite, 0.6)
    },
    sitesContainer: {
      background: theme.gradient.default,
      width: "100%",
      minHeight: "fit-content !important"
    },
    sitesContainerHeader: {
      padding: "10px 0",
      color: theme.palette.colors.white
    },
    sitesListContainer: {
      height: "90%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center"
    },
    siteFieldsetStyle: {
      border: `1px solid ${fade(theme.palette.colors.lightWhite, 0.3)}`,
      borderRadius: "4px",

      width: "100%",
      margin: "0 5px",
      marginBottom: "20px",
      boxSizing: "border-box"
    },
    fieldsetLabelStyle: {
      color: fade(theme.palette.colors.white, 0.7),
      marginLeft: "20px",
      padding: "0 10px"
    },
    radioIconStyle: {
      color: theme.palette.secondary.main
    },
    siteItem: {
      display: "flex",
      justifyContent: "space-between",
      color: theme.palette.colors.white,
      "& svg": {
        fontSize: "16px",
        color: "white"
      },
      borderTop: `1px solid ${fade(theme.palette.colors.lightWhite, 0.1)}`,
      padding: "4%",
      background: theme.gradient.toolbarBackground
    },
    dialogStyle: {
      display: "flex",
      width: "100%"
    },
    selectIconStyle: {
      color: fade(theme.palette.colors.white, 0.7)
    },
    permissionsListLabel: {
      color: `${theme.palette.colors.white} !important`,
      margin: "0px 10px",
      top: 5,
      zIndex: 5,
      background: theme.gradient.toolbarBackground
    },
    permissionsListFeild: {
      border: `1px solid ${fade(theme.palette.colors.white, 0.7)}`,
      borderRadius: "4px",
      paddingLeft: "4px",
      color: fade(theme.palette.colors.white, 0.7),
      height: "30px"
    },
    permissionsListItem: {
      background: theme.palette.colors.white,
      color: theme.palette.colors.black,
      padding: "3%",
      "& svg": {
        color: theme.palette.colors.white
      }
    },
    dialogPaper: {
      width: "100%",
      maxHeight: "70%"
    },
    permissionsPanelDetails: {
      display: "flex",
      flexDirection: "column",
      height: "250px"
    },
    createInviteButton: {
      padding: "10px",
      width: "90%",
      marginTop: "20px",
      marginBottom: "20px"
    },
    collapseWrapperInnerStyle: {
      height: "100%"
    },

    unitsGroupsWrapper: {
      width: "100%",
      maxHeight: "inherit"
    },
    emailDialogTitle: {
      "& *": {
        fontSize: "25px",
        fontWeight: "bold"
      }
    },
    emailDialogBody: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "20px"
    },
    siteContainerHeader: {
      height: "30px"
    },
    radioButtonsWrapper: {
      flexDirection: "row",
      color: fade(theme.palette.colors.white, 0.5),
      padding: "10px 20px"
    },
    inputField: {
      width: "100%",
      borderRadius: "6px",
      margin: "5px 0",
      borderColor: theme.palette.colors.fieldsBorder,
      "& div": {
        height: "100%"
      },
      "& input": {
        fontFamily: "Roboto",
        fontSize: "14px",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        color: theme.palette.colors.white,
        paddingLeft: "10px"
      },
      "& label": {
        color: `${theme.palette.colors.lightWhite} !important`,
        fontSize: "16px"
      },
      "& fieldset": {
        borderColor: `${theme.palette.colors.fieldsBorder} !important`
      }
    },
    shareIconButton: {
      padding: 0
    },
    shareContainer: {
      display: "flex",
      width: "80%",
      justifyContent: "space-between",
      margin: " 10px auto"
    },
    shareSection: {
      display: "flex",
      flexDirection: "column",
      marginTop: "15px",
      background: theme.gradient.default,
      padding: "10px 0"
    },
    shareHeader: {
      width: "80%",
      margin: "auto",
      color: theme.palette.colors.lightWhite,
      fontWeight: 400
    },
    sectionTitleStyle: {
      width: "100%",
      color: theme.palette.colors.lightWhite,
      fontSize: "16px",
      paddingBottom: "10px"
    },
    checkStyle: {
      width: "23px",
      height: "23px",
      color: fade(theme.palette.colors.lightWhite, 0.6)
    },
    itemContainer: {
      width: "58%",
      flexFlow: "column",
      justifyContent: "center",
      flex: 1
    },
    inputs: {
      "& input": {
        color: fade(theme.palette.colors.lightWhite, 0.6),
        fontSize: "16px",
        paddingLeft: "40px !important"
      }
    },
    emailInput: {  "& input": {
        color: fade(theme.palette.colors.lightWhite, 0.6),
        fontSize: "16px",
        paddingLeft: "24px !important"
      }},
    list: {
      width: "100%",
      "& li": {
        height: "50px",
        margin: "auto",
        marginBottom: "15px",
        border: `1px solid ${fade(theme.palette.colors.lightWhite, 0.3)}`,
        borderRadius: "6px"
      },
      "& input": {
        width: "100%",
        paddingLeft: "5px",
        color: fade(theme.palette.colors.lightWhite, 0.6),
        background: theme.palette.colors.transparent,
        fontSize: "14px",
        fontWeight: "normal"
      },
      "& span": {
        width: "fit-content",
        fontFamily: "Roboto",
        fontSize: "16px",
        color: theme.palette.colors.lightWhite,
        fontWeight: "normal"
      }
    },
    siteContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      border: `1px solid ${fade(theme.palette.colors.lightWhite, 0.3)}`,
      borderRadius: "6px",
      margin: "20px",
      boxSizing: "border-box"
    },
    selectionRow: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      padding: "5px 13px",
      borderBottom: `1px solid ${fade(theme.palette.colors.lightWhite, 0.3)}`,
      boxSizing: "border-box"
    },
    siteNameStyle: {
      color: theme.palette.colors.lightWhite,
      fontSize: "16px",
      fontWeight: 500
    },
    unitNameStyle: {
      color: fade(theme.palette.colors.lightWhite, 0.6),
      fontSize: "16px"
    },
    hidden: {
      display: "none"
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      color: theme.palette.colors.white,
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest
      })
    },
    expandOpen: {
      transform: "rotate(-180deg)"
    },
    noBorder: {
      borderWidth: "0px"
    },
    emailTextField:{marginTop: "10px"},
    itemText: {flex: "unset"}
  });

import { createStyles } from "@material-ui/styles";
import { fade } from "@material-ui/core/styles";

export default (theme: any) =>
  createStyles({
    iconStyle: { fontSize: "22px", color: theme.palette.colors.white },
    usersContainerHeader: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      height: "55px",
      padding: "15px",
      marginBottom: "3%",
      boxShadow: "0 5px 9px 0 rgba(0, 0, 0, 0.45)",
      "& svg": {
        color: theme.palette.colors.white
      }
    },
    usersContainer: {
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      width: "100%",
      boxSizing: "border-box",
      padding: "15px",
      display: "flex",
      flex: 1,
      overflowY: "auto"
    },
    usersListStyle: {
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      width: "99%",
      boxSizing: "border-box",
      overflowY: "auto"
    },

    userItemContainer: {
      justifyContent: "space-between",
      alignItems: "center",
      color: theme.palette.colors.white,
      background: fade(theme.palette.colors.lightWhite, 0.1),
      width: "99%",
      height: "50px",
      borderRadius: "4px",
      padding: "0 5px 0 8px",
      marginTop: "10px"
    },
    typoStyle: {
      fontFamily: "sans-serif",
      fontSize: "16px",
      lineHeight: "16px",
      color: fade(theme.palette.colors.white, 0.4)
    },
    usernameStyle: {
      fontSize: "16px",
      lineHeight: "16px",
      fontFamily: "sans-serif"
    },
    headerTitleStyle: {
      fontFamily: "sans-serif",
      fontSize: "16px",
      color: theme.palette.colors.white,
      background: theme.palette.colors.transparent
    },
    forwardArrowButton: {
      padding: "5px"
      // marginLeft: "auto"
    },
    forwardArrow: {
      color: theme.palette.colors.white,
      fontSize: "13px"
    },
    userAvatarStyle: {
      marginRight: "10px"
    },
    expand: {
      transform: "rotate(0deg)",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest
      })
    },
    expandOpen: {
      transform: "rotate(180deg)"
    },
    flex: {
      display: "flex"
    },
    inviteContainer: {
      position: "relative"
    },
    shareContainer: {
      position: "absolute",
      width: "60%",
      top: 0,
      bottom: 0,
      right: 0,
      backgroundColor: theme.palette.colors.lightPurple,
      display: "flex",
      justifyContent: "space-evenly",
      alignItems: "center"
    },
    emailButton: {
      padding: 0
    },
    closeShareButton: {
      color: theme.palette.colors.error
    }
  });

import React, { useState, useEffect } from "react";
import {
  List,
  ListItem,
  ListItemText,
  MenuItem,
  IconButton,
  Collapse,
  makeStyles
} from "@material-ui/core";
import clsx from "clsx";
import _ from "lodash";
import { unitSelectionStyle } from "./unitSelection.style";
import { ArrowDown } from "../../svgComponents";

const UnitSelection = (props: any) => {
  const [collapse, setCollapse] = useState<boolean>(false);
  const [selectedUnitId, setSelectedUnitId] = useState<any>("");

  const { siteName, units, handleSelectedUnitChange } = props;
  const useStyles = makeStyles(unitSelectionStyle);
  const classes = useStyles();

  useEffect(() => {
    if (_.isEmpty(units)) {
      return;
    }
  }, [units]);

  const handleMenuItemClick = (unitId: any, index: number) => {
    setSelectedUnitId(unitId);
    setCollapse(false);
    handleSelectedUnitChange(unitId);
  };

  const handleCollapse = () => {
    setCollapse(!collapse);
  };

  const unitsArray: any = Object.values(units);
  return (
    <div className={classes.unitSelectionContainer}>
      <List
        aria-label="unit graph"
        classes={{ padding: classes.listHeaderStyle }}
      >
        <ListItem
          aria-haspopup="true"
          aria-controls="units-menu"
          aria-label={siteName}
          onClick={handleCollapse}
          classes={{ root: classes.listItemRoot }}
        >
          <ListItemText
            primary={siteName}
            secondary={
              selectedUnitId
                ? units[selectedUnitId].name
                : "please select a unit"
            }
            classes={{
              primary: classes.listItemTextPrimary,
              secondary: classes.listItemTextSecondary
            }}
          />
          <IconButton>
            <ArrowDown
              className={clsx({
                [classes.expandOpen]: collapse
              })}
            />
          </IconButton>
        </ListItem>
      </List>
      <Collapse
        in={collapse}
        unmountOnExit
        timeout="auto"
        classes={{
          container: classes.unitsListCollapseContainer,
          wrapper: classes.unitsListWrapper
        }}
      >
        {unitsArray.map((oneUnit: any, index: number) => {
          const { id } = oneUnit;
          return (
            <MenuItem
              key={id}
              selected={id === selectedUnitId}
              onClick={() => handleMenuItemClick(id, index)}
            >
              {oneUnit.name}
            </MenuItem>
          );
        })}
      </Collapse>
    </div>
  );
};

export default UnitSelection;

import React from "react";

const SvgAdd = props => (
  <svg width={40} height={40} {...props}>
    <defs>
      <path
        id="Add_svg__a"
        d="M12.44 1.815c.207-.208.31-.459.31-.752 0-.294-.103-.545-.31-.753a1.025 1.025 0 00-.753-.31c-.293 0-.544.103-.752.31l-4.56 4.56L1.815.31A1.025 1.025 0 001.063 0C.769 0 .518.103.31.31.103.518 0 .769 0 1.063c0 .293.103.544.31.752l4.56 4.56-4.56 4.56c-.207.208-.31.459-.31.752 0 .294.103.545.31.753.208.207.459.31.753.31.293 0 .544-.103.752-.31l4.56-4.56 4.56 4.56c.208.207.459.31.752.31.294 0 .545-.103.753-.31.207-.208.31-.459.31-.753 0-.293-.103-.544-.31-.752l-4.56-4.56 4.56-4.56z"
      />
    </defs>
    <g fill="none" fillRule="evenodd" transform="rotate(45 -3.274 29.67)">
      <mask id="Add_svg__b" fill="#fff">
        <use xlinkHref="#Add_svg__a" />
      </mask>
      <use fill="#3E3E3E" fillRule="nonzero" xlinkHref="#Add_svg__a" />
      <g fill="#FDFDFE" mask="url(#Add_svg__b)">
        <path d="M-1.594-1.594h15.938v15.938H-1.594z" />
      </g>
    </g>
  </svg>
);

export default SvgAdd;

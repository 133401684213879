

const productMap = {
  CoolMasterNet: {
    text: "CoolMasterNet",
    value: "283B96"
  },
  CoolMasterNetRUS: {
    text: "CoolMasterNet RUS",
    value: "283B9601"
  },
  CloudBox: {
    text: "CloudBox",
    value: "283B9602"
  },
  CooLinkHub: {
    text: "CooLinkHub",
    value: "283B96C1"
  },
  CooLinkBridge: {
    text: "CooLinkBridge",
    value: "283B96C3"
  },
}

export const findProduct = (serial="") => {
  const subSerial = serial.substring(0, 8);
  let productName = "unknown";
  let installationLink = "https://coolautomation.com/support/#contact"
  let userManualLink = "https://coolautomation.com/support/#contact"

  switch(subSerial){
    case productMap.CoolMasterNetRUS.value:
      productName = productMap.CoolMasterNetRUS.text;
      installationLink = "https://coolautomation.com/wp-content/uploads/sites/2/2019/09/CoolMasterNet-QuickInstallationGuide.pdf";
      userManualLink = "https://coolautomation.com/support#products/coolmasternet";

      break;

    // Not ready yet
    case productMap.CloudBox.value:
      productName = productMap.CloudBox.text;
      break;

    case productMap.CooLinkHub.value:
      productName = productMap.CooLinkHub.text;
      installationLink = "https://coolautomation.com/wp-content/uploads/sites/2/2020/08/CoolPlug-to-CooLInkHub-installation-manual-rev2.6.1.pdf";
      userManualLink = "https://coolautomation.com/support#products/coolplugs-and-coolinkhub";

      break;

    case productMap.CooLinkBridge.value:
      productName = productMap.CooLinkBridge.text;
      installationLink = "https://coolautomation.com/support#products/coolinkbridge";
      userManualLink = "https://coolautomation.com/support#products/coolinkbridge";

      break;


    // Not ready yet
    // case productMap.CoolPlug.value:
    // break;
    default:
      if(subSerial.includes(productMap.CoolMasterNet.value)){
        productName = productMap.CoolMasterNet.text
        installationLink = "https://coolautomation.com/wp-content/uploads/sites/2/2021/01/CoolMasterNET-installation-manual-3.5_.pdf"
        userManualLink = "https://coolautomation.com/support#products/coolmasternet";

      }
      break;
  }

  return {
    product: productName,
    installationLink,
    userManualLink

  };
}

import React from "react";
import { makeStyles, Typography, Link } from "@material-ui/core";
import packageJson from "../../../package.json";
import { Toolbar } from "../../components";
import { ArrowBack } from "../../svgComponents";
import AboutUsStyle from "./AboutUs.style";
import { t } from "ttag";

interface IProps {
  history: any;
}

export const AboutUs: React.FC<IProps> = ({ history }) => {
  const useStyles = makeStyles(AboutUsStyle);
  const classes = useStyles();

  const goBack = () => {
    history.push({ pathname: "/site", menuOpen: true });
  };

  return (
    <div className={classes.secreenContainer}>
      <Toolbar
        title={t`About`}
        leftIconComponent={<ArrowBack />}
        leftAction={goBack}
      />
      <div className={classes.container}>
        <Typography className={classes.headers} >
          {t`About CoolAutomation`}
        </Typography>

        <Typography className={classes.subtitles} >
          {t`CoolAutomation leads the way in connected HVAC solutions, with a
          pioneering approach we call the Internet of Climate (IoC). The
          Internet of Climate is a universal concept that Internet-enabled any
          climate system, simply and seamlessly. Our mission is to connect the
          traditional world of HVAC with the future of IoT, enabling HVAC to
          play a central role in smart buildings and smart homes.`}{" "}
        </Typography>

        <Typography className={classes.headers} >
          {t`Version number`}
        </Typography>

        <Typography className={classes.subtitles} >
          {packageJson ? t`version` + packageJson.version : ""}
        </Typography>

        <Typography className={classes.headers} >
          {t`Terms of use`}
        </Typography>

        <Link href="https://coolautomation.com/terms-of-use/" className={classes.subtitles} >
          https://coolautomation.com/terms-of-use/
        </Link>

        <Typography className={classes.supportHeader} >
          {t`Support`}{" "}
        </Typography>

        <Link className={classes.subtitles} href="https://coolautomation.com/support/">
          https://coolautomation.com/support/
        </Link>

      </div>
    </div>
  );
};

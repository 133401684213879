import React from "react";

const SvgHidePassword = props => (
  <svg width={26} height={18} {...props}>
    <path
      d="M21 0L4 17l1 1L22 1l-1-1zm-8 6c-.768 0-1.536.293-2.121.879A2.988 2.988 0 0010 9l3-3zm3 3a2.99 2.99 0 01-.879 2.121A2.99 2.99 0 0113 12l3-3zm-6.602 6.602A16.11 16.11 0 0013 16c9 0 13-7 13-7s-1.617-2.83-5.108-4.891l-3.994 3.993a4 4 0 01-4.796 4.796l-2.704 2.704zm-4.289-1.71C1.617 11.83 0 9 0 9s4-7 13-7c1.305 0 2.504.147 3.602.398l-2.704 2.704a4 4 0 00-4.796 4.796l-3.993 3.994z"
      fill="#AAA2AA"
      fillRule="evenodd"
      opacity={0.5}
    />
  </svg>
);

export default SvgHidePassword;

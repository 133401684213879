import { thunk, Thunk } from "easy-peasy";
import { Injections } from "./index";
import { IEmailInvitePayload } from "../screens/NewInvite/NewInvite";

interface IInviteUser {
  inviteId: string;
  data: any;
}

export interface IInviteModel {
  emailInvite: Thunk<IInviteModel, IEmailInvitePayload, Injections>;
  getInvite: Thunk<IInviteModel, string, Injections>;
  deleteInvite: Thunk<IInviteModel, string, Injections>;
  acceptInvite: Thunk<IInviteModel, IInviteUser, Injections>;
}

const inviteStore: IInviteModel = {
  emailInvite: thunk(async (actions, payload, { injections }) => {
    const { sdkInvite } = injections;
    const { inviteId, email } = payload;
    return await sdkInvite.sendInviteEmail(inviteId, { email });
  }),
  getInvite: thunk(async (actions, payload, { injections }) => {
    const { sdkInvite } = injections;
    return await sdkInvite.getInviteInfo(payload);
  }),
  deleteInvite: thunk(async (actions, payload, { injections }) => {
    const { sdkInvite } = injections;

    return await sdkInvite.delete(payload);
  }),
  acceptInvite: thunk(async (actions, payload, { injections }) => {
    const { sdkInvite } = injections;
    const { inviteId, data } = payload;

    return await sdkInvite.acceptInvite(inviteId, data);
  })
};
export default inviteStore;

import {
  CircularProgress,
  IconButton,
  makeStyles,
  Typography
} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import clsx from "clsx";
import _ from "lodash";
import moment from "moment-timezone";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import CircularSlider from "react-circular-slider-svg";
import tinycolor2 from "tinycolor2";
import { t } from "ttag";
import { useStoreActions, useStoreState } from "../../Stores/typedHooks";
import {
  Alert,
  ArrowDownControl,
  ArrowUp,
  AutoMode,
  ClosedSensor,
  CO2,
  ColdIcon,
  ControlWrapper,
  DryIcon,
  Fan1Icon,
  Fan2Icon,
  Fan3Icon,
  Fan4Icon,
  FanMode,
  HeatIcon,
  Humidity,
  OpenSensor,
  ScheduleOffIcon,
  SideArrow,
  Swing,
  Swing30,
  Swing45,
  Swing60,
  SwingHor,
  SwingVer,
  SwitcherOffIcon,
  SwitcherOnIcon,
  Temperature,
  TempSensor
} from "../../svgComponents";
import unitViewStyle from "./unitView.style";

interface IObject {
  [key: string]: any;
}

const colorRanges: any = {
  COOL: ["#35a8e0", "#2d2e82"],
  HEAT: ["#f8b133", "#f05146"],
  AUTO: ["#35a8e0", "#f05146"],
  DRY: ["#266101", "#266101"],
  FAN: ["#4f00ed", "#4f00ed"],
  default: ["#ffffff", "#000000"]
};

const icons: any = {
  1: <TempSensor />,
  2: <CO2 />,
  3: "",
  4: "",
  5: <Humidity  />
    };

const unitsSymbols: any = {
  "Temperature": "°",
  "PPM": "\u209A\u209A\u2098" ,
  "RPM": "\u1D63\u209A\u2098",
  "Open/Close": "",
  "Percent": "%"
  };

const hasValue = (value: any) => {
    return !!value || value === 0;
  };

const UnitView: React.FC<any> = (props) => {
  const {
    unit,
    navigateToSchedules,
    selectedSiteId,
    types,
    setActiveSetpoint,
    setActiveOperationMode,
    setActiveOperationStatus,
    operationModesMirror,
    fanModesMirror,
    swingModesMirror,
    setActiveFanMode,
    setActiveSwingMode,
    temperatureScale,
    addMessage,
    operationStatusesMirror,
    isCelsius,
    isSensor,
    timezone,
    updateLocalSensor
  } = props;

  const useStyles = makeStyles(unitViewStyle);
  const classes = useStyles();

  const {
    id: unitId,
    activeSetpoint,
    activeOperationMode,
    activeFanMode,
    activeSwingMode,
    temperatureLimits,
    enableCoolMode,
    enableHeatMode,
    enableAutoMode,
    ambientTemperature,
    activeOperationStatus,
    supportedOperationModes = [],
    supportedFanModes = [],
    supportedSwingModes = [],
    message,
    filter,
    name,
    enableSetpoint,
    enableMode,
    enableOnState,
    enableOnoff,
    readingValue = 0,
    readingValueTimestamp,
    userData = {},
    type,
    isWritable
  } = unit;

  const { operationStatuses, fanModes, operationModes, swingModes, sensorTypes, sensorMeasurementUnits } = types;
  const sensorType = sensorTypes[type] || {};
  const {enableMinMaxSelection, enableNormalModeSelection, measurementUnits, enableMeasurementUnitSelection, valueMax, valueMin} = sensorType;
  const activeMeasurementUnit = enableMeasurementUnitSelection && userData?.measurementUnitsType ? userData?.measurementUnitsType : measurementUnits;
  const sensorUnit = sensorMeasurementUnits[activeMeasurementUnit]?.name;
  const {rangeMax : userMax, rangeMin: userMin, normalStateValue} = userData;
  const value = +readingValue;

  const displayValue = !sensorUnit || type === 130 || type === 7 ? (enableMinMaxSelection ? hasValue(userMax) && hasValue(userMin) ?
(((value - valueMin) * (+userMax - +userMin)) / (valueMax - valueMin)) + +userMin : value :  value).toFixed(1) :
sensorUnit === "Temperature" ? (isCelsius ? value : (Math.round(((value * 9 / 5) + 32) * 10) / 10)) :
sensorUnit === "Open/Close" ?  type === 129 ? +value === 0 ? t`OFF` : t`ON` : (+value === 0 ? t`OPEN` : t`CLOSE`) : value;

  const hasUserValues = hasValue(userMax) && hasValue(userMin);
  const doConvert =  enableMinMaxSelection && hasUserValues;

  const { dateFormat , timeFormat } = useStoreState((state) => state.userStore);
  const {updateSensorValue, _storeUpdateSensor} = useStoreActions((actions) => actions.sensorStore);
  const [clickedIcon, setClickedIcon] = useState<string>("");
  const [startTemp, setStart] = useState<number>(0);
  const [endTemp, setEnd] = useState<number>(48);
  const [startAngle, setStartAngle] = useState<number>(0);
  const [endAngle, setEndAngle] = useState<number>(360);
  const [currentColor, setCurrentColor] = useState<any>("#888");
  const [pendingState, setPendingState] = useState<any>({
    type: "",
    mode: null
  });

  const [timer, setTimer] = useState<any>();
  const [localActiveSetpoint, setLocalActiveSetPoint] = useState<any>(0);
  const [togglePowerButton, setTogglePowerButton] = useState<boolean>(false);

  const powerOffVal = operationStatusesMirror && +operationStatusesMirror.off;
  const powerOnVal = operationStatusesMirror && +operationStatusesMirror.on;
  const isPowerOn = activeOperationStatus === powerOnVal;

  const [loading, setLoading] = useState<boolean>(false);
  const [saveValue, setSaveValue] = useState<number>(value || 0);

  const mainIcons: IObject = {
    operation: {
      COOL: <ColdIcon />,
      HEAT: <HeatIcon />,
      AUTO: <AutoMode />,
      DRY: <DryIcon />,
      FAN: <FanMode />
    },
    fan: {
      LOW: <Fan1Icon />,
      MEDIUM: <Fan2Icon />,
      HIGH: <Fan3Icon />,
      TOP: <Fan4Icon />,
      AUTO: <Typography>{t`Auto`}</Typography>,
      ON: <Typography>{t`On`}</Typography>,
      OFF: <Typography>{t`Off`}</Typography>
    },
    swing: {
      vertical: <SwingVer />,
      horizontal: <SwingHor />,
      30: <Swing30 />,
      45: <Swing45 />,
      60: <Swing60 />,
      auto: <Swing />,
      off: <Typography>{t`Off`}</Typography>,
      on: <Typography>{t`On`}</Typography>
    }
  };

  const enabledMode: IObject = {
    0: enableCoolMode,
    1: enableHeatMode,
    2: enableAutoMode
  };

  useEffect(() => {
    if (
      !unit ||
      !temperatureLimits || activeOperationMode > 2
    ) {
      return;
    }

    const midByType = isCelsius ? 24 : 75;
    const defaultMin = 0;
    const defaultMax = 100;
    const minLimit = Math.min((temperatureLimits[activeOperationMode][0] || defaultMin), activeSetpoint);
    const maxLimit = Math.max((temperatureLimits[activeOperationMode][1] || defaultMax), activeSetpoint);
    if (minLimit === startTemp && maxLimit === endTemp) {
      return;
    }
    if (!enabledMode[activeOperationMode]) {
      setStart(defaultMin);
      setEnd(defaultMax);
      setStartAngle(5);
      setEndAngle(355);
    } else {
      setStart(minLimit);
      setEnd(maxLimit);

      const startAngle = (180 * +minLimit) / midByType;
      const endAngle = (180 * +maxLimit) / midByType;

      setStartAngle(startAngle);
      setEndAngle(endAngle);
    }
  }, [
    activeOperationMode,
    temperatureLimits,
    temperatureScale,
    unit,
    endTemp,
    startTemp,
    unitId,
    operationModesMirror,
    activeSetpoint,
    isCelsius,
    enableCoolMode,
    enableHeatMode,
    enableAutoMode
  ]);

  useEffect(() => {
    setLocalActiveSetPoint(activeSetpoint);
  }, [activeSetpoint]);

  useEffect(() => {
    if (!togglePowerButton) {
      return;
    }

    setTimeout(() => {
      setTogglePowerButton(false);
    }, 5000);

    if (
      pendingState.type === "setpoint" &&
      pendingState.mode === activeSetpoint
    ) {
      setTogglePowerButton(false);
      setPendingState({ type: "", mode: null });
    }

    if (
      pendingState.type === "power" &&
      pendingState.mode === activeOperationStatus
    ) {
      setTogglePowerButton(false);
      setPendingState({ type: "", mode: null });
    }
  }, [
    activeOperationStatus,
    activeSetpoint,
    togglePowerButton,
    pendingState.mode,
    pendingState.type
  ]);

  useEffect(() => {
    if (!localActiveSetpoint || activeSetpoint === localActiveSetpoint) {
      return;
    }
    if (timer) {
      clearTimeout(timer);
    }
    let newTimer: NodeJS.Timeout = setTimeout(() => {
      setTogglePowerButton(true);
      setActiveSetpoint({
        unitId,
        data: localActiveSetpoint
      })
        .catch((err: any) => {
          addMessage({
            message: err.message
          });
        })
        .finally(() => setTogglePowerButton(false));
    }, 1000);
    setTimer(newTimer);
  }, [localActiveSetpoint, addMessage, unitId]);

  const changeTemp = (isAdd: boolean) => {
    if (
      (isAdd && (localActiveSetpoint === endTemp || localActiveSetpoint > endTemp)) ||
      (!isAdd && (localActiveSetpoint === startTemp || localActiveSetpoint < startTemp))
    ) {
      return;
    }

    const updatedSetPoint = isAdd
      ? localActiveSetpoint + 1
      : localActiveSetpoint - 1;
    setLocalActiveSetPoint(updatedSetPoint);

    setPendingState({
      type: "setpoint",
      mode: isAdd ? activeSetpoint + 1 : activeSetpoint - 1
    });
  };

  useEffect(() => {
    const mode = operationModes[activeOperationMode];

    const range = colorRanges[mode]
      ? colorRanges[mode]
      : colorRanges["default"];
    const c1 = range[0];
    const c2 = range[1];
    const absoluteVal =
      ((localActiveSetpoint - startTemp) / (endTemp - startTemp)) * 100;
    const c3 = tinycolor2.mix(c1, c2, absoluteVal);
    setCurrentColor(c3.toHexString());
  }, [
    localActiveSetpoint,
    operationModes,
    activeOperationMode,
    endTemp,
    startTemp
  ]);

  const handleOnOffSensor = () => {
      setLoading(true);
      const updateValueTo = value === 0 ? 1 : 0;
      updateSensorValue({id: unitId, data: {value: updateValueTo}})
      .then(() => {
        updateLocalSensor(unitId, updateValueTo);
     })
      .catch((err: any) => {
        addMessage({ message: err.message });
      }).finally(() => setLoading(false));

    };

  const delayedCall = useCallback(_.debounce((newValue: any) => {
    setLoading(true);
    updateSensorValue({id: unitId, data: {value: newValue}})
    .then(() => {
      updateLocalSensor(unitId, newValue);
    })
    .catch((err: any) => {
      setSaveValue(value);
      addMessage({ message: err.message });
    }).finally(() => setLoading(false));
  }, 1000), []);

  const updateValue = (newValue: any) => {
    setSaveValue(newValue);
    delayedCall(newValue);

  };

  const togglePower = () => {
    const data =
      operationStatuses[activeOperationStatus] === "on"
        ? powerOffVal
        : powerOnVal;

    setTogglePowerButton(true);
    setPendingState({
      type: "power",
      mode: data
    });
    setActiveOperationStatus({ unitId, data }).catch((err: any) => {
      setTogglePowerButton(false);
      addMessage({
        message: err.message
      });
    });
  };

  const timeToString = (timestamp: any) => {
    return moment(timestamp).tz(timezone).format(timeFormat);
  };
  const dateToString = (timestamp: any) => {
    return moment(timestamp).tz(timezone).format(dateFormat);
  };
  const handleClose = () => {
    setClickedIcon("");
  };

  const handleChoose = (name: string, modesMirror: IObject) => {
    const data = modesMirror[name];

    if (clickedIcon === "operation") {
      setActiveOperationMode({
        unitId,
        data
      }).catch((err: any) =>
        addMessage({
          message: err.message
        })
      );
    } else if (clickedIcon === "fan") {
      setActiveFanMode({
        unitId,
        data
      }).catch((err: any) =>
        addMessage({
          message: err.message
        })
      );
    } else if (clickedIcon === "swing") {
      setActiveSwingMode({
        unitId,
        data
      }).catch((err: any) =>
        addMessage({
          message: err.message
        })
      );
    }
    handleClose();
  };

  const handleIconClick = (e: any, name: string) => {
    setClickedIcon(name === clickedIcon ? "" : name);
  };
  const createMenu = (
    activeMode: number,
    modesMirror: IObject,
    supportedArray: Number[],
    type: string
  ) => {
    const icons = mainIcons[type];
    if (!Object.keys(icons)) {
      return <></>;
    }

    return (
      <>
        {Object.keys(icons).map((icon: any, i: number) => {
          if (supportedArray.indexOf(+modesMirror[icon]) < 0) {
            return <React.Fragment key={`mode-${i}`} />;
          }
          return (
            <MenuItem
              key={`mode-${i}`}
              className={
                activeMode === +modesMirror[icon] ? classes.selected : ""
              }
              onClick={() => handleChoose(icon, modesMirror)}
            >
              {React.cloneElement(icons[icon], {
                colorblue: "#35A8E0",
                color1: "#4B1C46",
                color2: "#AAA2AA",
                maincolor: "#AAA2AA",
                opacity: 1
              })}
            </MenuItem>
          );
        })}
      </>
    );
  };

  const currentMode = operationModes[activeOperationMode];
  const noControl = currentMode === "DRY" || currentMode === "FAN";
  const hasError = message && message.replace(/\s/g, "").toUpperCase() !== "OK";

  return (
    <div className={classes.unitView}>
      <div className={classes.firstRowContainer}>
        <div className={classes.nameSection}>

          <Typography>{name}</Typography>
        </div>
        <div className={classes.loaderContainer}>
          {togglePowerButton && (
            <CircularProgress size={40} className={classes.loaderStyle} />
          )}
          {loading && (
            <CircularProgress size={40} className={classes.loaderStyle} />
          )}
        </div>

        <div className={classes.powerContainer}>
        {isSensor ? ((sensorUnit === "Open/Close" && isWritable) ?
          <>
          <IconButton
            className={classes.powerIconStyle}
            onClick={handleOnOffSensor}
          >
            {+readingValue === 0 ? <SwitcherOffIcon className={classes.powerOnButtonClick} /> :
            <SwitcherOnIcon className={classes.powerOffButtonClick} />}
          </IconButton>
          <Typography className={classes.powerTextStyle}>
            {isPowerOn ? t`POWER OFF` : t`POWER ON`}
          </Typography>
          </> : null) :
          <>
          <IconButton
            className={classes.powerIconStyle}
            disabled={enableOnoff || (activeOperationStatus === 2 && enableOnState)}
            onClick={() => togglePower()}
          >
            {isPowerOn && (
              <SwitcherOnIcon className={classes.powerOffButtonClick} />
            )}
            {!isPowerOn && (
              <SwitcherOffIcon className={classes.powerOnButtonClick} />
            )}
          </IconButton>
          <Typography className={classes.powerTextStyle}>
            {isPowerOn ? t`POWER OFF` : t`POWER ON`}
          </Typography>
          </>
        }
        </div>
      </div>

      {!isSensor && (
        <>
          <div className={classes.secondRowContainer}>
            {(hasError || filter) && (
              <div className={classes.alertContainer}>
                <Alert className={classes.alertIcon} />
                <Typography className={classes.alertMessageStyle}>{filter ? t`Filter` : t`Error ` + message}</Typography>
              </div>
            )}
            <div className={classes.controlDiv}>
              <ControlWrapper
                className={clsx(classes.controlWrapperStyle)}
                fill={isPowerOn ? currentColor : "rgba(253,253,254, 0.5)"}
                width={336}
                height={326}
              />
              {!noControl ? (
                <Fragment>
                  <div
                    className={clsx(classes.sliderContainer)}
                  >
                    <CircularSlider
                      size={280}
                      minValue={startTemp}
                      maxValue={endTemp}
                      startAngle={startAngle}
                      endAngle={endAngle}
                      coerceToInt={true}
                      angleType={{
                        direction: "cw",
                        axis: "-y"
                      }}
                      handle1={{
                        value: localActiveSetpoint,
                        onChange: (value: any) => { }
                        // setLocalActiveSetPoint(value)
                      }}
                      arcColor={"#48314A"}
                      arcBackgroundColor={"#48314A"}
                      disabled={enableSetpoint}
                    />
                  </div>
                  <div
                    className={clsx(classes.innerControls)}
                  >
                    <IconButton
                      onClick={() => changeTemp(true)}
                      className={classes.controlArrow}
                      disabled={enableSetpoint}
                    >
                      <ArrowUp />
                    </IconButton>
                    <div>
                      <Typography
                        className={clsx(classes.setPointStyle)}
                      >
                        {localActiveSetpoint ? localActiveSetpoint : activeSetpoint}
                      </Typography>
                    </div>
                    <div className={classes.ampTempContainer}>
                      <Temperature />
                      <Typography className={classes.ampTempStyle}>
                        {Math.round(ambientTemperature || 0)}
                      </Typography>
                    </div>
                    <IconButton
                      onClick={() => changeTemp(false)}
                      className={classes.controlArrow}
                      disabled={enableSetpoint}
                    >
                      <ArrowDownControl />
                    </IconButton>
                  </div>
                </Fragment>
              ) : (
                  <Typography className={classes.modeTitle}>{currentMode}</Typography>
                )}
            </div>
          </div>

          <div className={classes.lastRowContainer}>
            <div
              className={clsx(
                classes.iconContainer, classes.rightSpace,

                !supportedOperationModes.length && classes.completeHide
              )}
            >
              <div
                className={clsx(
                  classes.paper,
                  clickedIcon === "operation"
                    ? classes.showStyle
                    : classes.hideStyle
                )}
              >
                {createMenu(
                  activeOperationMode,
                  operationModesMirror,
                  supportedOperationModes,
                  "operation"
                )}
              </div>
              <IconButton
                className={classes.mainIconsStyle}
                disabled={enableMode}
                onClick={(e: any) => handleIconClick(e, "operation")}
              >
                {mainIcons.operation[operationModes[activeOperationMode]]
                  ? React.cloneElement(
                    mainIcons.operation[operationModes[activeOperationMode]],
                    {
                      opacity: 0.6
                    }
                  )
                  : operationModes[activeOperationMode] || activeOperationMode}
              </IconButton>
            </div>
            {/*fan*/}
            <div
              className={clsx(
                classes.iconContainer, classes.rightSpace,
                !supportedFanModes.length && classes.completeHide
              )}
            >
              <div
                className={clsx(
                  classes.paper,
                  clickedIcon === "fan" ? classes.showStyle : classes.hideStyle
                )}
              >
                {createMenu(
                  activeFanMode,
                  fanModesMirror,
                  supportedFanModes,
                  "fan"
                )}
              </div>
              <IconButton
                className={classes.mainIconsStyle}
                onClick={(e: any) => handleIconClick(e, "fan")}
              >
                {mainIcons.fan[fanModes[activeFanMode]]
                  ? React.cloneElement(mainIcons.fan[fanModes[activeFanMode]], {
                    opacity: 0.6
                  })
                  : fanModes[activeFanMode] || activeFanMode}
              </IconButton>
            </div>
            {/*swing*/}
            <div
              className={clsx(
                classes.iconContainer, classes.rightSpace,
                !supportedSwingModes.length && classes.completeHide
              )}
            >
              <div
                className={clsx(
                  classes.paper,
                  clickedIcon === "swing" ? classes.showStyle : classes.hideStyle
                )}
              >
                {createMenu(
                  activeSwingMode,
                  swingModesMirror,
                  supportedSwingModes,
                  "swing"
                )}
              </div>
              <IconButton
                className={classes.mainIconsStyle}
                onClick={(e: any) => handleIconClick(e, "swing")}
              >
                {mainIcons.swing[swingModes[activeSwingMode]]
                  ? React.cloneElement(
                    mainIcons.swing[swingModes[activeSwingMode]],
                    {
                      opacity: 0.6
                    }
                  )
                  : swingModes[activeSwingMode] || activeSwingMode}
              </IconButton>
            </div>
            <div className={classes.iconContainer}>
              <IconButton
                disableFocusRipple
                disableRipple
                className={classes.mainIconsStyle}
                name="schedule"
                onClick={() => navigateToSchedules(selectedSiteId, unitId)}
              >
                <ScheduleOffIcon />
              </IconButton>
            </div>
          </div>
        </>
      )}
      {isSensor && (
        <>
          <div className={classes.secondRowContainer}>
            <div className={classes.controlDiv}>
              <div className={classes.sensorWrapperStyle} >
                  {type !== 130 &&  <div className={classes.sensorIcon}>
                    { type !== 6 ?
                icons[activeMeasurementUnit] || ""
                : value === 0 ? <OpenSensor  height="70" width="70" className={clsx(classes.iconStyle, classes.modeIcon, classes.smallIcons)}/>
                                : <ClosedSensor height="70" width="70" className={clsx(classes.iconStyle, classes.modeIcon, classes.smallIcons)} />}
                  </div>}
                  {type === 130 && isWritable ? <div className={classes.sensorSliderContainer}>
                      <CircularSlider
                        size={275}
                        minValue={valueMin}
                        maxValue={valueMax}
                        startAngle={0}
                        endAngle={180}
                        coerceToInt={true}
                        angleType={{direction: "cw", axis: "-x"}}
                        handle1={{value: saveValue, onChange: updateValue}}
                        arcColor={"#FFF"}
                        arcBackgroundColor={"#FFF"}
                      />
                      <div className={classes.sensorValuesContainer}>
                      <Typography className={classes.sensorValues}>{hasUserValues ? userMin : valueMin}</Typography>
                      <Typography className={classes.sensorValues}>{hasUserValues ? userMax : valueMax}</Typography>
                      </div>
                      <div className={classes.valueControl}>
                      <Typography className={classes.sensorValue}>{doConvert ? ((((saveValue - valueMin) * (+userMax - +userMin)) / (valueMax - valueMin)) + +userMin).toFixed(1) : saveValue.toFixed(1)}</Typography>
                      <Typography className={classes.measurementUnit}>{unitsSymbols[sensorUnit || ""] || ""}</Typography>
                      </div>

                    </div> :
                    (<>
                      <div className={classes.valueContainer}>
                    <Typography className={clsx(classes.sensorValueText, {[classes.offText]: displayValue === t`OFF`})}>{displayValue}</Typography>
                    <Typography className={clsx(classes.measurementUnitsStyle, {[classes.percentStyle]: sensorUnit === "Percent", [classes.tempUnit]: sensorUnit === "Temperature" })}>{unitsSymbols[sensorUnit || ""] || ""}</Typography>
                    </div>
                    {(sensorUnit === "Open/Close" && enableNormalModeSelection && hasValue(normalStateValue)) && <Typography className={classes.setPointDiscription}>{+normalStateValue === +readingValue ? t`(Normal)` : ""}</Typography>}
                    </>)
                  }
                  <div className={clsx(classes.timeDateStyle, {[classes.addTopMargin]: type === 130})}>
                    <div className={clsx(classes.dataTimeFont, classes.timeStyle)}> {timeToString(readingValueTimestamp)}</div>
                    <div className={classes.dataTimeFont}> {dateToString(readingValueTimestamp)}</div>
                  </div>
              </div>
            </div>
          </div>
          <div className={classes.lastRowContainer}>
          </div>
        </>
      )}
    </div>
  );
};

export default UnitView;

import { createStyles } from "@material-ui/styles";

export default (theme: any) =>
  createStyles({
    screenContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      backgroundColor: theme.palette.colors.blackFontShadow
    },
    gridStyle: {
      width: "100%",
      flexFlow: "column",
      maxWidth: "768px"
    },
    requiredStyle: { color: theme.palette.secondary.main, fontSize: "15px" },
    saveButtonStyle: {
      width: "90%",
      alignSelf: "center",
      marginTop: "41px",
      marginBottom: "20px"
    },
    inputClass: {
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: theme.palette.colors.lightWhite,
      padding: "15px"
    },
    inputFieldStyle: {
      marginTop: 0,
      marginBottom: "15px"
    },
    errorStyle: {
      marginTop: "0px",
      fontSize: "12px",
      paddingLeft: "15px",
      color: `${theme.palette.secondary.main} !important`
    },
    zipfieldtStyle: {
      maxWidth: "50%",
      borderRadius: "6px",
      border: `solid 1px ${theme.palette.colors.fieldsBorder}`,
      marginLeft: "20px"
    },
    fieldsContainer: {
      padding: "32px 5%",
      boxShadow: `0 1px 2px 0 ${theme.palette.colors.fieldsShadow}`,
      backgroundImage: theme.gradient.default
    },
    formStyle: {
      padding: 0,
      margin: "11px 0px",
      display: "flex",
      flexFlow: "column"
    },
    rowContainer: { display: "flex", alignItems: "center" },
    formContainer: { overflow: "auto", maxWidth: "100%" },
    selectStyle: {
      marginTop: 0,
      marginBottom: "15px"

    },
    selectErrorStyle: {
      marginTop: 0,
      marginBottom: "15px",
      "& div": {
        borderColor: `${theme.palette.secondary.main} !important`
      }
    }
  });

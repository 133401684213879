import {
  makeStyles, Typography,
} from "@material-ui/core";
import classNames from "classnames";
import { Form, Formik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { t } from "ttag";
import * as Yup from "yup";
import { useStoreActions, useStoreState } from "../../Stores/typedHooks";
import { Button, FormikField } from "../../widgets";
import { loginStyles } from "./login.style";

interface ILoginProps {
  history: any;
  checkLogin: any;
}

const LoginScreen: React.FC<ILoginProps> = ({ history }) => {
  const appConfig = useStoreState((state) => state.configStore.config);
  const login = useStoreActions((actions) => actions.userStore.login);
  const user = useStoreState((state) => {
    return state.userStore.user;
  });
  const [loginFailedError, setLoginFailedError] = useState<any>("");
  const { isLoggedIn } = user;

  useEffect(() => {
    if (isLoggedIn) {
      history.push("/site");
    }
  }, [history, isLoggedIn]);

  const startLoader = useStoreActions(
    (actions) => actions.loaderStore.startLoader
  );
  const finishLoader = useStoreActions(
    (actions) => actions.loaderStore.finishLoader
  );

  const validationSchema = Yup.object({
    username: Yup.string().required(t`required field`),
    password: Yup.string().required(t`required field`)
  });

  const handleSubmit = async (values: any) => {

    const { username, password } = values;
    startLoader();
    await login({ username, password }).then(({ message }: any) => {
      if (message) {
        setLoginFailedError(message);
      }
    })
      .finally((res: any) => {
        finishLoader();
      });
  };

  const goToDeviceReg = () => {
    history.push(`/device-registration`);
  };
  const forgotPassword = () => {
    history.push("/forgot-password");
  };

  const useStyles = makeStyles(loginStyles);
  const classes = useStyles();
  const { isLogo } = appConfig;
  return (
    <>
      <div className={classes.loginContainer}>
        <div className={classes.logoSection}>
          {isLogo && (
            <Fragment>
              <div
                className={classNames(classes.imageContainer, classes.logo)}
              ></div>
              <div
                className={classNames(
                  classes.imageContainer,
                  classes.titleLogo
                )}
              ></div>
            </Fragment>
          )}
        </div>
        <Formik
          initialValues={{ username: "", password: "" }}
          onSubmit={async (values, { resetForm }) => {

            await handleSubmit(values);
            resetForm();

          }}
          enableReinitialize={true}
          validationSchema={validationSchema}
          render={({ values, setFieldValue, ...formikProps }) => {
            return (
              <Form className={classes.form}>
                <h1 className={classes.welcomeMessage}>
                  Welcome! Please login.
                </h1>
                <FormikField
                  value={values.username}
                  name="username"
                  placeholder={t`Username`}
                  formikProps={formikProps}
                  className={classes.usernameStyle}
                />
                <FormikField
                  value={values.password}

                  name="password"
                  placeholder={t`Password`}
                  formikProps={formikProps}
                  className={classes.passwordStyle}
                  type="password"
                />
                {loginFailedError &&
                  <Typography
                    className={classes.failedLoginMsg}
                  >{loginFailedError}</Typography>
                }
                <Button type="submit" variant="contained">
                  {t`Login`}
                </Button>
                <div className={classes.buttonsGroup}>
                  <Button
                    onClick={forgotPassword}
                  >{t`Forgot Password?`}</Button>
                  <Button onClick={goToDeviceReg}>{t`Register Now`}</Button>
                </div>
              </Form>
            );
          }}
        />
      </div>
    </>
  );
};

export default LoginScreen;

import React from "react";

const TempSensor = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} {...props} viewBox="0 0 80 80">
    <g fill="none" fill-rule="evenodd">
        <g>
            <g>
                <g>
                    <path fill="#FFF" d="M15.273 48.057v-24.13c0-.815-.712-1.527-1.528-1.527-.815 0-1.527.712-1.527 1.527v24.13c-3.46.712-6.109 3.767-6.109 7.434 0 4.176 3.46 7.636 7.636 7.636 4.177 0 7.637-3.46 7.637-7.636 0-3.667-2.65-6.821-6.11-7.434z" transform="translate(-306 -53) translate(306 53) translate(26 4)"/>
                    <path stroke="#FFF" stroke-width="1.8" d="M21.382 44.088V7.636C21.382 3.46 17.922 0 13.745 0 9.57 0 6.11 3.46 6.11 7.636v36.452C2.343 46.634 0 50.91 0 55.491c0 7.533 6.213 13.745 13.745 13.745 7.533 0 13.746-6.212 13.746-13.745 0-4.582-2.343-8.857-6.11-11.403z" transform="translate(-306 -53) translate(306 53) translate(26 4)"/>
                </g>
            </g>
        </g>
    </g>
</svg>);

export default TempSensor;

import { Action, action, Thunk , thunk } from "easy-peasy";
import { Injections } from "./";

interface IDeviceTokenPayload {
  serial: string;
  pin: string;
}

interface IAddDeviecPayload {
  serial: string;
  pin: string;
  name: string;
  siteId: string;
}
interface IUpdateDeviecPayload {
  deviceId: string;
  name: string;
}

interface IGetDaikinDevice {
  deviceId: string;
  lineId: string;
}

export interface IDeviceModel {
  devices: any;
  getDeviceToken: Thunk<IDeviceModel, IDeviceTokenPayload, Injections>;
  addNewDevice: Thunk<IDeviceModel, IAddDeviecPayload, Injections>;
  updateDevice: Thunk<IDeviceModel, IUpdateDeviecPayload, Injections>;
  getDevices: Thunk<IDeviceModel, string, Injections>;
  getDevicesToStore: Thunk<IDeviceModel, string, Injections>;
  deleteDevice: Thunk<IDeviceModel, string, Injections>;
  getDeviceById: Thunk<IDeviceModel, string, Injections>;
  getUserDevices: Thunk<IDeviceModel, void, Injections>;
  getDeviceTree: Thunk<IDeviceModel, string, Injections>;
  refreshSystems: Thunk<IDeviceModel, string, Injections>;
  getDeviceUnits: Thunk<IDeviceModel, string, Injections>;
  getDeviceDaikin: Thunk<IDeviceModel, IGetDaikinDevice, Injections>;
  setDevices: Action<IDeviceModel, any>;
  updateDeviceStatus: Action<IDeviceModel, {deviceId: string, status: boolean}>;
}

const deviceStore: IDeviceModel = {
  devices: {},
  getDeviceToken: thunk((actions, payload, { injections }) => {
    const { serial, pin } = payload;
    const { sdkDevice } = injections;

    return sdkDevice.getDeviceToken({ serial, pin });
  }),
  addNewDevice: thunk((actions, payload, { injections }) => {
    const { serial, pin, name, siteId } = payload;
    const { sdkSite } = injections;
    return sdkSite.createDevice(siteId, { serial, pin, name });
  }),
  updateDevice: thunk((actions, payload, { injections }) => {
    const { deviceId, name } = payload;
    const { sdkDevice } = injections;

    return sdkDevice.update(deviceId, { name });
  }),
  getDevices: thunk((actions, payload, { injections }) => {
    const { sdkSite } = injections;

    return sdkSite.getDevices(payload);
  }),
  getDevicesToStore: thunk(async (actions, payload, { injections }) => {
    const { sdkSite } = injections;

    const devices = await sdkSite.getDevices(payload);
    actions.setDevices(devices);
  }),
  setDevices: action((state, payload) => {
    state.devices = payload;
  }),
  updateDeviceStatus: action((state, payload) => {
    if (!state.devices[payload.deviceId])
    {
      return;
    }
    state.devices[payload.deviceId].isConnected = payload.status;
  }),
  deleteDevice: thunk((actions, payload, { injections }) => {
    const { sdkDevice } = injections;

    return sdkDevice.delete(payload);
  }),
  getDeviceById: thunk((actions, payload, { injections }) => {
    const { sdkDevice } = injections;

    return sdkDevice.getDeviceById(payload);
  }),
  getUserDevices: thunk((actions, payload, { injections }) => {
    const { sdkDevice } = injections;

    return sdkDevice.getDevices();
  }),
  getDeviceTree: thunk((actions, payload, { injections }) => {
    const { sdkDevice } = injections;
    return sdkDevice.fetchTree(payload);
  }),
  refreshSystems: thunk((actions, payload, { injections }) => {
    const { sdkDevice } = injections;
    return sdkDevice.refreshSystems(payload);
  }),
  getDeviceUnits: thunk((actions, payload, { injections }) => {
    const { sdkDevice } = injections;

    return sdkDevice.getUnits(payload);
  }),
  getDeviceDaikin: thunk((actions, payload, { injections }) => {
    const { sdkDevice } = injections;
    const { deviceId: id, lineId } = payload;
    return sdkDevice.getDeviceDaikinIndoorMapping(id, lineId);
  })
};

export default deviceStore;

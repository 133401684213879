import React from "react";

const SvgLogoutCopy = props => (
  <svg width={17} height={17} {...props}>
    <path
      d="M14.36 8.35H7.396m0 0l2.143-2.142M7.396 8.35l2.143 2.144m2.717-6.072V1.535h-9.72v13.676h9.72v-2.93"
      fill="none"
      fillRule="evenodd"
      stroke="#FDFDFE"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.143}
    />
  </svg>
);

export default SvgLogoutCopy;

import React, { useState, useEffect } from "react";
import {
  makeStyles,
  FormControl,
  FormHelperText,
  Collapse,
  Typography,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  DialogActions,
  Divider,
  List,
  ListItem,
  ListItemText,
  Input,
  TextField as MUTextField,
  InputLabel
} from "@material-ui/core";
import { Send, Close, ExpandMore } from "@material-ui/icons";
import { t } from "ttag";
import _ from "lodash";
import {
  EmailIcon,
  FacebookShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  FacebookIcon
} from "react-share";
import { ArrowBack } from "../../svgComponents";
import { Select, Button, Checkbox, DatePicker } from "../../widgets";
import { Toolbar, ConfirmationDialog } from "../../components";
import { newInviteStyle } from "./newInvite.style";
import { useStoreActions, useStoreState } from "../../Stores/typedHooks";
import { validateEmail } from "../../services/customValidators";
import clsx from "clsx";

interface INewInvite {
  classes: any;
  history: any;
  match: any;
}
export interface ICreateNewInvitePayload {
  customerId: string;
  inviteObj: object;
}
export interface IEmailInvitePayload {
  inviteId: string;
  email: any;
}

const permissions = [
  { value: "admin", label: "Admin" },
  { value: "manager", label: "Manager" },
  { value: "guest", label: "Guest" }
];
const labels = {
  firstName: "First Name*",
  lastName: "Last Name*",
  email: "Email Address",
  phone: "Phone Number"
};

const NewInvite: React.FC<INewInvite> = (props) => {
  const [userUnits, setUserUnits] = useState<any>({});
  const [getGroupsFlag, setGroupsFlag] = useState<boolean>(true);
  const [permissionSelected, setPermissionSelected] = useState<any>("");
  const [inviteInfo, setInviteInfo] = useState<any>({
    firstName: "",
    lastName: "",
    email: "",
    role: ""
  });
  const [formErrors, setFormErrors] = useState<any>({
    firstName: "",
    lastName: "",
    email: "",
    role: ""
  });
  const [selectedSites, setSelectedSites] = useState<any>([]);
  const [selectedGroups, setSelectedGroups] = useState<any>({});
  const [selectedUnits, setSelectedUnits] = useState<any>({});
  const [shareEmail, setEmail] = useState<any>();
  const [toGetSites, setToGetSites] = useState<boolean>(true);
  const [createdInvite, setCreatedInvite] = useState<any>({});
  const [emailDialog, setEmailDialog] = useState<boolean>(false);
  const [openedSites, setOpenedSites] = useState<any>(false);
  const [isExpiriedEnabled, enableExpired] = useState<boolean>(false);
  const [expireTimestamp, setExpireTimestamp] = useState<any>(null);
  const [isGusetMissingPermissions, setIsGusetMissingPermissions] = useState<boolean>(false);

  const startLoader = useStoreActions(
    (actions) => actions.loaderStore.startLoader
  );
  const finishLoader = useStoreActions(
    (actions) => actions.loaderStore.finishLoader
  );
  const createNewInvite = useStoreActions(
    (actions) => actions.customerStore.createInvite
  );
  const addMessage = useStoreActions(
    (actions) => actions.messageStore.addMessage
  );
  const user = useStoreState((state) => state.userStore.user);
  const { customer: customerId } = user;
  const emailInvite = useStoreActions(
    (actions) => actions.inviteStore.emailInvite
  );

  const getAllSites = useStoreActions(
    (actions) => actions.siteStore.getUserSites
  );

  const getFullGroups = useStoreActions(
    (actions) => actions.groupStore.getFullGroups
  );

  const getAllUnits = useStoreActions(
    (actions) => actions.unitStore.getUserUnits
  );

  const allSites = useStoreState((state) => state.siteStore.userSites);
  const allGroups = useStoreState((state) => state.groupStore.groups);
  const groupSiteMap = useStoreState(
    (state) => state.groupStore.groupSiteMapping
  );

  const { history } = props;

  useEffect(() => {
    if (!_.isEmpty(allSites)) {
      setToGetSites(false);
      return;
    }
    if (!toGetSites) {
      return;
    }

    getAllSites();
  }, [allSites, getAllSites, toGetSites]);

  useEffect(() => {
    if (!startLoader || !finishLoader || !getAllUnits) {
      return;
    }
    startLoader();
    getAllUnits()
      .then((units: any) => {
        setUserUnits(units);
      })
      .finally(() => finishLoader());
  }, [startLoader, finishLoader, getAllUnits]);

  useEffect(() => {
    if (!_.isEmpty(groupSiteMap)) {
      setGroupsFlag(false);
      return;
    }
    if (!getGroupsFlag) {
      return;
    }
    setGroupsFlag(false);
    getFullGroups();
  }, [groupSiteMap, startLoader, finishLoader, getFullGroups, getGroupsFlag]);

  const goBack = () => {
    history.push(`/users-management`);
  };

  const handleFieldChange = (
    event: React.ChangeEvent<{ value: any }>,
    name: string
  ) => {
    const { value } = event.target;
    setInviteInfo({ ...inviteInfo, [name]: value });

    if (value) {
      setFormErrors({ ...formErrors, [name]: "" });
    }
    if (name === "email") {
      setEmail(value);
    }
  };

  const switchSiteControl = (id: string) => {
    let selSites = [...selectedSites];
    const idIndex = selSites.indexOf(id);
    if (idIndex >= 0) {
      selSites.splice(idIndex, 1);
      delete selectedUnits[id];
      delete selectedGroups[id];

    } else {
      selSites.push(id);
      setSelectedUnits({ ...selectedUnits, [id]: [] });
      setSelectedGroups({ ...selectedGroups, [id]: [] });
    }

    setSelectedSites(selSites);

  };

  const switchGroupControl = (
    id: string,
    siteId: string,
    isDisabled: boolean
  ) => {
    if (isDisabled) {
      return;
    }
    let selGroups = { ...selectedGroups };
    if (_.isEmpty(selGroups[siteId])) {
      selGroups[siteId] = [];
    }
    const idIndex = selGroups[siteId].indexOf(id);
    if (idIndex >= 0) {
      selGroups[siteId].splice(idIndex, 1);

      if (_.isEmpty(selGroups[siteId])) {
        delete selGroups[siteId];
      }
    } else {
      selGroups[siteId].push(id);
    }
    setSelectedGroups(selGroups);
  };

  const switchUnitControl = (
    id: string,
    siteId: string,
    isDisabled: boolean
  ) => {
    if (isDisabled) {
      return;
    }

    let selUnits = { ...selectedUnits };
    if (_.isEmpty(selUnits[siteId])) {
      selUnits[siteId] = [];
    }
    const idIndex = selUnits[siteId].indexOf(id);
    if (idIndex >= 0) {
      selUnits[siteId].splice(idIndex, 1);
      if (_.isEmpty(selUnits[siteId])) {
        delete selUnits[siteId];
      }

    } else {
      selUnits[siteId].push(id);
    }
    setSelectedUnits(selUnits);
  };

  const getAdminPermission = () => {
    return {
      customers: {
        [customerId]: "customerAdmin"
      }
    };
  };

  const getManagerPermission = () => {
    return {
      customers: { [customerId]: "customerManager" }
    };
  };

  const getCustomPermissions = () => {
    let customerPermission: any = {};
    let sitesPermissions: any = {};
    let groupsPermissions: any = {};
    let unitsPermissions: any = {};

    customerPermission[customerId] = "customerGuest";
    for (let x in selectedSites) {
      sitesPermissions[selectedSites[x]] = "siteAdmin";
    }

    const mergedGroups: any = Object.values(selectedGroups).flat();
    for (let x in mergedGroups) {
      groupsPermissions[mergedGroups[x]] = "groupAdmin";
    }

    const mergedUnits: any = Object.values(selectedUnits).flat();
    for (let x in mergedUnits) {
      unitsPermissions[mergedUnits[x]] = "unitAdmin";
    }

    const permissionObject: any = {
      customers: customerPermission,
      sites: sitesPermissions,
      groups: groupsPermissions,
      units: unitsPermissions
    };

    return permissionObject;
  };

  const prepareInvite = () => {
    let permissiosnObj;
    const { role, firstName, lastName, email } = inviteInfo;
    if (role === "admin") {
      permissiosnObj = getAdminPermission();
    } else if (role === "manager") {
      permissiosnObj = getManagerPermission();
    } else {
      permissiosnObj = getCustomPermissions();
    }

    return {
      firstName,
      lastName,
      email,
      permissions: permissiosnObj
    };
  };

  const createInvite = () => {
    setFormErrors({});
    const { firstName, lastName, email, role } = inviteInfo;
    let errors: any = {};
    let hasErrors = false;

    if (isExpiriedEnabled && !expireTimestamp) {
      errors["expireDate"] = "expire date is required";
      hasErrors = true;
    }

    if (!firstName) {
      errors["firstName"] = "reauired field";
      hasErrors = true;
    }
    if (!lastName) {
      errors["lastName"] = "reauired field";
      hasErrors = true;
    }
    if (!role) {
      errors["role"] = "reauired field";
      hasErrors = true;
    }
    if (!!email && validateEmail(email)) {
      errors["email"] = "Wrong email format";
      hasErrors = true;
    }

    // If the user is guest and missing permissions
    if (permissionSelected.label === "Guest" &&
      (
        !selectedSites.length
        && !Object.keys(selectedUnits).length
        && !Object.keys(selectedGroups).length
      )
    ) {
      errors["permissions"] = true;
      hasErrors = true;
    }

    if (hasErrors) {

      if (errors.permissions) {
        delete errors.permissions;
        setIsGusetMissingPermissions(true);
      }

      setFormErrors(errors);
      return;
    }

    startLoader();

    const inviteObj: any = prepareInvite();

    if (isExpiriedEnabled) {
      inviteObj.expiryTimestamp = expireTimestamp + "";
    }

    createNewInvite({ customerId, inviteObj })
      .then((res: any) => {
        setCreatedInvite(res);
      })
      .catch((err: any) => {
        addMessage({
          message: err.message
        });
      })
      .finally(() => {
        finishLoader();
      });
  };

  const useStyles = makeStyles(newInviteStyle);
  const classes = useStyles();

  const handlePermissionChange = (option: any) => {
    setPermissionSelected(option);
    setSelectedSites([]);
    setSelectedGroups({});
    setSelectedUnits({});
    setInviteInfo(option && { ...inviteInfo, role: option.value });
  };

  const sendEmail = () => {
    startLoader();
    emailInvite({ inviteId: createdInvite.id, email: shareEmail })
      .then(() => {
        goBack();
      })
      .finally(() => finishLoader());
  };

  const toggleSite = (siteId: string) => {
    setOpenedSites({ ...openedSites, [siteId]: !openedSites[siteId] });
  };

  const inviteIsCreated = !_.isEmpty(createdInvite);

  const isOptionGuest =
    !inviteIsCreated &&
    !_.isEmpty(permissionSelected) &&
    permissionSelected.value === "guest";

  const generalGroups = groupSiteMap["groups"];

  return (
    <div className={classes.container}>
      <Toolbar
        title={t`Invite a new user`}
        leftAction={() => history.push("/users-management")}
        leftIconComponent={<ArrowBack />}
      />
      <div className={classes.pageContent}>
        <div className={classes.personalDetailsSections}>
          <Typography
            className={classes.sectionTitleStyle}
          >{t`Personal Details`}</Typography>

          <List className={classes.list} disablePadding={true} dense={true}>
            <ListItem>
              <ListItemText
                primary={labels.firstName}
                className={classes.itemText}
              />
              <Grid container className={classes.itemContainer}>
                <Input
                  name="firstName"
                  disableUnderline={true}
                  value={inviteInfo["firstName"]}
                  onChange={(e) => handleFieldChange(e, "firstName")}
                  className={classes.inputs}
                  disabled={inviteIsCreated}
                />
                {!!formErrors["firstName"] && (
                  <FormHelperText error={true} className={classes.errorStyle}>
                    {formErrors["firstName"]}
                  </FormHelperText>
                )}
              </Grid>
            </ListItem>

            <ListItem>
              <ListItemText
                primary={labels.lastName}
                className={classes.itemText}
              />
              <Grid container className={classes.itemContainer}>
                <Input
                  name="lastName"
                  disableUnderline={true}
                  className={classes.inputs}
                  value={inviteInfo["lastName"]}
                  onChange={(e) => handleFieldChange(e, "lastName")}
                  disabled={inviteIsCreated}
                />
                {!!formErrors["lastName"] && (
                  <FormHelperText error={true} className={classes.errorStyle}>
                    {formErrors["lastName"]}
                  </FormHelperText>
                )}
              </Grid>
            </ListItem>

            <ListItem>
              <ListItemText
                primary={labels.email}
                className={classes.itemText}
              />

              <Grid container className={classes.itemContainer}>
                <Input
                  name="email"
                  disableUnderline={true}
                  type="email"
                  className={classes.emailInput}
                  value={inviteInfo["email"]}
                  onChange={(e) => handleFieldChange(e, "email")}
                  disabled={inviteIsCreated}
                />
                {!!formErrors["email"] && (
                  <FormHelperText error={true} className={classes.errorStyle}>
                    {formErrors["email"]}
                  </FormHelperText>
                )}
              </Grid>
            </ListItem>
          </List>
        </div>
        <div className={classes.permissionsSection}>
          <InputLabel className={classes.sectionTitleStyle}>
            <Checkbox
              checked={isExpiriedEnabled}
              value={"User Expires"}
              onChange={() => enableExpired(!isExpiriedEnabled)}
              disabled={inviteIsCreated}
            />
            User expires
          </InputLabel>
          <DatePicker
            onSet={(timestamp: any) => setExpireTimestamp(timestamp)}
            disabled={inviteIsCreated || !isExpiriedEnabled}
          />
          <Typography className={classes.errorStyle}>
            {formErrors["expireDate"]}
          </Typography>
        </div>
        <div className={classes.permissionsSection}>
          <Typography
            className={classes.sectionTitleStyle}
          >{t`Permissions`}</Typography>
          <Divider orientation="horizontal" variant="fullWidth" />
          <FormControl
            required
            className={classes.roleSelect}
            error={!!formErrors["role"]}
            disabled={inviteIsCreated}
          >
            <Select
              disabled={inviteIsCreated}
              value={permissionSelected || { label: "User Role", value: "" }}
              suggestions={permissions}
              handleSelectChange={handlePermissionChange}
              clear={false}
              search={false}
              placeholder={t`Permission`}
            />
            {!permissionSelected && !!formErrors["role"] && (
              <FormHelperText
                className={classes.errorStyle}
              >{formErrors["role"]}</FormHelperText>
            )}
          </FormControl>

          <Collapse
            in={isOptionGuest}
            timeout="auto"
            unmountOnExit
            className={classes.sitesContainer}
            classes={{ wrapper: classes.unitsGroupsWrapper }}
          >
            <Grid className={classes.sitesListContainer}>
              {!_.isEmpty(allSites) &&
                Object.values(allSites).map((site: any) => {
                  const { name: siteName, id: siteId, units: siteUnits } = site;
                  const isSiteSelected = selectedSites.indexOf(siteId) >= 0;
                  const siteGroups = groupSiteMap[siteId];
                  const isSiteOpen = !!openedSites[siteId];
                  return (
                    <div
                      className={classes.siteContainer}
                      key={`site-${siteId}`}
                    >
                      <div
                        className={clsx(
                          classes.selectionRow,
                          !isSiteOpen && classes.noBorder
                        )}
                        onClick={() => toggleSite(siteId)}
                      >
                        <Typography className={classes.siteNameStyle}>
                          {t`Site ` + siteName}
                        </Typography>

                        <ExpandMore
                          className={clsx(
                            classes.expand,
                            isSiteOpen && classes.expandOpen
                          )}
                        />
                      </div>

                      <Collapse
                        in={isSiteOpen}
                        timeout="auto"
                        unmountOnExit
                        className={classes.guestSection}
                        classes={{
                          wrapper: classes.unitsGroupsWrapper,
                          wrapperInner: classes.collapseWrapperInnerStyle
                        }}
                      >
                        <div className={classes.option} key={`all-${siteId}`}>
                          <div
                            className={classes.selectionRow}
                            onClick={() => switchSiteControl(siteId)}
                          >
                            <Typography className={classes.unitNameStyle}>
                              {t`Entire site`}
                            </Typography>
                            <Checkbox
                              checked={isSiteSelected}
                              value={siteId}
                              onChange={() => { }}
                            />
                          </div>
                        </div>
                        {!_.isEmpty(siteGroups) &&
                          !_.isEmpty(allGroups) &&
                          !_.isEmpty(siteGroups) &&
                          siteGroups.map((groupId: any) => {
                            const { name: groupName } = allGroups[groupId];
                            const isSelected =
                              !_.isEmpty(selectedGroups[siteId]) &&
                              selectedGroups[siteId].indexOf(groupId) >= 0;
                            return (
                              <div className={classes.option} key={groupId}>
                                <div
                                  className={classes.selectionRow}
                                  onClick={() =>
                                    switchGroupControl(
                                      groupId,
                                      siteId,
                                      isSiteSelected
                                    )
                                  }
                                >
                                  <Typography className={classes.unitNameStyle}>
                                    {groupName}
                                  </Typography>
                                  <Checkbox
                                    checked={isSelected}
                                    value={groupId}
                                    disabled={isSiteSelected}
                                    onChange={() => { }}
                                  />
                                </div>
                              </div>
                            );
                          })}
                        {!_.isEmpty(siteUnits) &&
                          !_.isEmpty(userUnits) &&
                          siteUnits.map((unitId: any) => {
                            const unit = userUnits[unitId];
                            if (_.isEmpty(unit)) {
                              return null;
                            }
                            const { name: unitName } = unit;
                            const isSelected =
                              !_.isEmpty(selectedUnits[siteId]) &&
                              selectedUnits[siteId].indexOf(unitId) >= 0;
                            return (
                              <div className={classes.option} key={unitId}>
                                <div
                                  className={classes.selectionRow}
                                  onClick={() =>
                                    switchUnitControl(
                                      unitId,
                                      siteId,
                                      isSiteSelected
                                    )
                                  }
                                >
                                  <Typography className={classes.unitNameStyle}>
                                    {unitName}
                                  </Typography>
                                  <Checkbox
                                    checked={isSelected}
                                    value={unitId}
                                    disabled={isSiteSelected}
                                    onChange={() => { }}
                                  />
                                </div>
                              </div>
                            );
                          })}
                      </Collapse>
                    </div>
                  );
                })}

              {!_.isEmpty(allGroups) && !_.isEmpty(generalGroups) && (
                <div
                  className={clsx(
                    classes.siteContainer,
                    !isOptionGuest && classes.hidden
                  )}
                  key={`groups-section`}
                >
                  <div
                    className={clsx(
                      classes.selectionRow,
                      !openedSites["groups"] && classes.noBorder
                    )}
                    onClick={() => toggleSite("groups")}
                  >
                    <Typography className={classes.siteNameStyle}>
                      {t`Groups`}
                    </Typography>

                    <ExpandMore
                      className={clsx(
                        classes.expand,
                        !!openedSites["groups"] && classes.expandOpen
                      )}
                    />
                  </div>

                  <Collapse
                    in={!!openedSites["groups"]}
                    timeout="auto"
                    unmountOnExit
                    className={clsx(classes.guestSection)}
                    classes={{
                      wrapper: classes.unitsGroupsWrapper,
                      wrapperInner: classes.collapseWrapperInnerStyle
                    }}
                  >
                    {generalGroups.map((groupId: any) => {
                      const group = allGroups[groupId];
                      const { name: groupName } = group;
                      const isSelected =
                        !_.isEmpty(selectedGroups["groups"]) &&
                        selectedGroups["groups"].indexOf(groupId) >= 0;
                      return (
                        <div className={classes.option} key={groupId}>
                          <div
                            className={classes.selectionRow}
                            onClick={() =>
                              switchGroupControl(groupId, "groups", false)
                            }
                          >
                            <Typography className={classes.unitNameStyle}>
                              {groupName}
                            </Typography>
                            <Checkbox
                              checked={isSelected}
                              value={groupId}
                              onChange={() => { }}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </Collapse>
                </div>
              )}
            </Grid>
          </Collapse>
        </div>
        {inviteIsCreated && (
          <div className={classes.shareSection}>
            <Typography
              className={classes.shareHeader}
            >{t`Invite created successfully - share the invite link`}</Typography>
            <div className={classes.shareContainer}>
              <IconButton
                className={classes.shareIconButton}
                onClick={() => setEmailDialog(true)}
              >
                <EmailIcon borderRadius={6} />
              </IconButton>
              <FacebookShareButton
                url={createdInvite.inviteUrl || ""}
                onShareWindowClose={() => goBack()}
              >
                <FacebookIcon borderRadius={6} />
              </FacebookShareButton>
              <WhatsappShareButton
                url={createdInvite.inviteUrl || ""}
                onShareWindowClose={() => goBack()}
              >
                <WhatsappIcon borderRadius={6} />
              </WhatsappShareButton>
            </div>
          </div>
        )}
        <ConfirmationDialog
          onCancel={_.noop}
          onConfrim={() => setIsGusetMissingPermissions(false)}
          onClose={() => setIsGusetMissingPermissions(false)}
          title="Missing users permissions"
          text="Please choose site or unit permissions for guest user"
          confrimLabel="OK"
          cancelLabel="Connect with smart thermostat"
          dialogType="normal"
          openDialog={isGusetMissingPermissions}
          hideCancle={true}
        />

        <Button
          variant="contained"
          className={classes.createInviteButton}
          onClick={() => createInvite()}
          disabled={inviteIsCreated}
        >{t`Create Invite`}</Button>

        <Dialog
          open={emailDialog}
          onClose={() => setEmailDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{t`send email to?`}</DialogTitle>
          <DialogContent>
            <Typography>{t`Enter the details of the user you want to invite`}</Typography>
            <MUTextField
              value={shareEmail}
              onChange={(e) => setEmail(e.target.value)}
              label={t`Email`}
              fullWidth
              className={classes.emailTextField}
            />
          </DialogContent>
          <DialogActions>
            <IconButton onClick={() => setEmailDialog(false)} color="primary">
              <Close />
            </IconButton>
            <IconButton onClick={() => sendEmail()} color="secondary" autoFocus>
              <Send />
            </IconButton>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default NewInvite;

import { createStyles, fade } from "@material-ui/core/styles";

export default (theme: any) =>
  createStyles({
    disabledStyle: {
      color: `${fade(theme.palette.colors.white, 0.1)} !important`
    },
    root: {
      boxSizing: "content-box",
      padding: "6px",
      maxWidth: "20px",
      maxHeight: "20px",
      "& svg": {
        fontSize: "20px"
      },
      "&:hover": {
        backgroundColor: "transparent"
      },
      color: theme.palette.colors.iconSelected
    }
  });

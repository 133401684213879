import React from "react";

const SvgSearch = props => (
  <svg width={23} height={23} {...props}>
    <g
      fill="none"
      fillRule="evenodd"
      stroke="#AAA2AA"
      strokeLinecap="round"
      strokeWidth={1.42}
    >
      <circle cx={10.237} cy={10.237} r={9.286} />
      <path d="M17.201 17.201l4.467 4.467" />
    </g>
  </svg>
);

export default SvgSearch;

import { action, Action, Thunk, thunk } from "easy-peasy";

export interface IConfigStore {
  splashScreenShow: boolean;
  config: any;
  theme: any;
  handleSplashShow: Action<IConfigStore>;
  prepareConfig: Thunk<IConfigStore>;
  setConfigFile: Action<IConfigStore, any>;
  prepareTheme: Thunk<IConfigStore>;
  setThemeFile: Action<IConfigStore, any>;
  appId: string
}

const configStore: IConfigStore = {
  appId: "coolAutomationControl",
  splashScreenShow: true,
  config: null,
  theme: null,
  handleSplashShow: action(state => {
    state.splashScreenShow = false;
  }),
  setConfigFile: action((state, payload) => {
    if (!!state.config) {
      return;
    }
    state.config = payload;
  }),
  prepareConfig: thunk(actions => {
    const configUrl = `${process.env.PUBLIC_URL}/assets/${window.location.hostname}/config.json`;
    fetch(configUrl)
      .then(res => res.json())
      .then(res => {
        actions.setConfigFile(res);
      });
  }),
  setThemeFile: action((state, payload) => {
    if (!!state.theme) {
      return;
    }
    state.theme = payload;
  }),
  prepareTheme: thunk(actions => {
    const themeUrl = `${process.env.PUBLIC_URL}/assets/${window.location.hostname}/style.config.json`;
    fetch(themeUrl)
      .then(res => res.json())
      .then(res => {
        actions.setThemeFile(res);
      });
  })
};

export default configStore;

import { Typography, withStyles } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import Select, { components } from "react-select";
import selectMainStyle, {
  filledStyle,
  inlineStyle,
  selectStyles,
  smallFilledStyle
} from "./select.style";

interface SelectProps {
  classes: any;
  suggestions: any;
  value: any;
  handleSelectChange: any;
  disabled?: boolean;
  placeholder?: string;
  className?: any;
  search?: boolean;
  clear?: boolean;
  error?: string;
  helperText?: string;
  selectClass?: any;
  label?: string;
  variant?: "outlined" | "inline" | "filled" | undefined;
  height?: string;
}

const SelectMenu = (props: SelectProps) => {
  const {
    classes,
    suggestions,
    handleSelectChange,
    value,
    disabled = false,
    placeholder = "Search..",
    label,
    className,
    search = true,
    clear = true,
    error,
    helperText,
    selectClass,
    variant = "outlined",
    height = "50px"
  } = props;

  const { Placeholder } = components;
  const mainStyle = selectStyles(height);
  const specificFilledStyle =
    height !== "50px" ? smallFilledStyle : filledStyle;
  let styles =
    variant === "inline"
      ? { ...mainStyle, ...inlineStyle }
      : variant === "filled"
        ? { ...mainStyle, ...specificFilledStyle }
        : mainStyle;

  const CustomValueContainer = ({ children, ...props }: any) => {
    return (
      <components.ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.label}
        </Placeholder>
        {React.Children.map(children, (child) => {
          return child && child.type !== Placeholder ? child : null;
        })}
      </components.ValueContainer>
    );
  };

  return (
    <div className={clsx(classes.selectContainer, className)}>
      <Select
        isDisabled={disabled}
        className={selectClass}
        styles={styles}
        options={suggestions}
        onChange={handleSelectChange}
        isClearable={clear}
        isSearchable={search}
        components={
          label
            ? {
              ValueContainer: CustomValueContainer
            }
            : {}
        }
        placeholder={label ? label : placeholder}
        {...props}
      />
      {!error && helperText && (
        <Typography className={classes.helperStyle}>{helperText}</Typography>
      )}
      {error && <Typography className={classes.errorStyle}>{error}</Typography>}
    </div>
  );
};

export default withStyles(selectMainStyle)(SelectMenu);

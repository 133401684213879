import { createStyles, fade } from "@material-ui/core/styles";

export default (theme: any) =>
  createStyles({
    root: {
      width: 60,
      height: 30,
      padding: 6
    },
    switchBase: {
      left: 6,
      padding: 1,
      color: theme.palette.colors.lightGray,
      "&$checked": {
        left: 0,
        transform: "translateX(26px)",
        color: theme.palette.secondary.main,
        "& + $track": {
          backgroundColor: fade(theme.palette.secondary.main, 0.25),
          border: "none",
          opacity: 1
        }
      },
      "&$focusVisible $thumb": {
        color: theme.palette.secondary.main
      }
    },
    thumb: {
      width: 28,
      height: 28
    },
    track: {
      borderRadius: 50 / 2,
      backgroundColor: fade(theme.palette.colors.lightWhite, 0.25),
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"])
    },
    checked: {},
    focusVisible: {}
  });

import { createStyles, fade } from "@material-ui/core/styles";

export default (theme: any) =>
  createStyles({
    containedStyle: {
      width: "100%",
      backgroundColor: theme.palette.secondary.main,
      fontSize: "18px",
      letterSpacing: "0.34px",
      color: theme.palette.colors.lightWhite,
      "&:hover": {
        letterSpacing: "normal",
        color: theme.palette.colors.white,
        backgroundColor: theme.palette.secondary.dark
      },
      "&:focus": {
        letterSpacing: "normal",
        color: theme.palette.colors.white,
        backgroundColor: theme.palette.secondary.dark,
        "& span:nth-child(2)": {
          "& span": {
            display: "none"
          }
        }
      }
    },
    sharedStyle: {
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      textTransform: "none",
      minHeight: "55px",
      maxHeight: "55px",
      borderRadius: "6px",
      "&:active:focus": {
        backgroundColor: fade(theme.palette.secondary.main, 0.9)
      }
    },
    outlinedStyle: {
      width: "100%",
      boxShadow: `0 2px 3px 0 ${fade(theme.palette.colors.black, 0.1)}`,
      border: `solid 1px ${fade(theme.palette.colors.white, 0.6)}`,
      fontSize: "18px",
      letterSpacing: "normal",
      color: theme.palette.colors.white,
      "&:focus": {
        border: `solid 2px ${fade(theme.palette.colors.white, 0.6)}`,
        "& span:nth-child(2)": {
          "& span": {
            display: "none"
          }
        }
      },
      "&:active:focus": {
        backgroundColor: fade(theme.palette.colors.white, 0.1)
      }
    },
    normalStyle: {
      opacity: 0.6,
      fontSize: "15px",
      fontWeight: "normal",
      letterSpacing: "normal",
      color: theme.palette.colors.white,
      textDecoration: "underline",
      minHeight: "20px",
      "&:active:focus": {
        backgroundColor: fade(theme.palette.colors.white, 0.1)
      }
    },
    disabledStyle: {
      opacity: "0.4",
      color: `${theme.palette.colors.lightWhite} !important`,
      backgroundColor: `${theme.palette.secondary.main} !important`
    }
  });

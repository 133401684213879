import { action, Action, thunk, Thunk } from "easy-peasy";
import _ from "lodash";
import { Injections } from "./index";

interface ICreateSiteModel {
  customerId: string;
  siteData: object;
}

interface IPowerPayload {
  siteId: string;
  state: number;
}

interface IAcceptInvitePayload {
  inviteToken: string;
  password: string;
}

interface ICreateGroup {
  siteId: string;
  data: any;
}

interface IUpdatedGroupNameAndUnitsPayload {
  groupId: string;
  updatedGroup: any;
}

interface IAddGroupPayload {
  addedGroup: any;
}

export interface ISiteModel {
  userSites: any;
  selectedSite: any;
  setSelectedSite: Action<ISiteModel, object>;
  siteInvites: any;
  siteUsers: any;
  siteUnits: any;
  siteIndoorUnits: any;
  siteGroups: any;
  socketMessages: any;
  setSocketMessages: Action<ISiteModel, any>;
  setInvites: Action<ISiteModel>;
  setUserSites: Action<ISiteModel>;
  deleteUserSite: Action<ISiteModel, string>;
  setUsers: Action<ISiteModel>;
  setUnits: Action<ISiteModel, any>;
  setGroups: Action<ISiteModel, any>;
  updateGroup: Action<ISiteModel, any>;
  updateUnit: Action<ISiteModel, any>;
  updateIndoorUnit: Action<ISiteModel, any>;
  addUnitByWebsocket: Action<ISiteModel, any>;
  updateUnitsPower: Action<ISiteModel, any>;
  addGroup: Action<ISiteModel, IAddGroupPayload>;
  updateGroupNameAndUnits: Action<ISiteModel, IUpdatedGroupNameAndUnitsPayload>;
  getSite: Thunk<ISiteModel, string, Injections>;
  updateSite: Thunk<ISiteModel, any, Injections>;
  createNewSite: Thunk<ISiteModel, ICreateSiteModel, Injections>;
  getUserSites: Thunk<ISiteModel, void, Injections>;
  getSiteUsers: Thunk<ISiteModel, any, Injections>;
  getGroups: Thunk<ISiteModel, string, Injections>;
  getUnits: Thunk<ISiteModel, string, Injections>;
  changeSitePowerStatus: Thunk<ISiteModel, IPowerPayload, Injections>;
  getInvites: Thunk<ISiteModel, any, Injections>;
  acceptInvite: Thunk<ISiteModel, IAcceptInvitePayload, Injections>;
  createGroup: Thunk<ISiteModel, ICreateGroup, Injections>;
  openWebsocket: Thunk<ISiteModel, any, Injections>;
  setIndoorUnits: Action<ISiteModel, any>;
  getSiteUnitsForCustomer: Thunk<ISiteModel, string, Injections>;
  getSiteGroupsForCustomer: Thunk<ISiteModel, string, Injections>;
  deleteSite: Thunk<ISiteModel, string, Injections>;
  webSocketUnitUpdate: any;
  setWebSocketUnitUpdate: Action<ISiteModel, any>;
  appVersion: string;
  compareAppVersion: Action<ISiteModel, string>;
  getAllSites: Thunk<ISiteModel, any, Injections>;
}

const siteStore: ISiteModel = {
  userSites: [],
  selectedSite: {},
  siteInvites: [],
  siteUsers: [],
  siteUnits: [],
  siteGroups: [],
  siteIndoorUnits: [],
  socketMessages: {},
  webSocketUnitUpdate: null,
  appVersion: "",
  compareAppVersion: action((state, payload) => {
    if (!payload) {
      return;
    }
    if (!state.appVersion) {
      state.appVersion = payload;
      return;
    }
    if (state.appVersion && state.appVersion !== payload) {
      window.location.reload();
    }

  }),
  setSocketMessages: action((state, payload) => {
    state.socketMessages = payload;
  }),
  setIndoorUnits: action((state, payload) => {
    state.siteIndoorUnits = payload;
  }),
  setSelectedSite: action((state, payload) => {
    state.selectedSite = { ...state.selectedSite, ...payload };
  }),
  setInvites: action((state, payload) => {
    state.siteInvites = payload;
  }),
  setUsers: action((state, payload) => {
    state.siteUsers = payload;
  }),
  setUnits: action((state, payload) => {
    state.siteUnits = payload;
  }),
  setGroups: action((state, payload) => {
    state.siteGroups = payload;
  }),
  setUserSites: action((state, payload) => {
    state.userSites = payload;
  }),
  deleteUserSite: action((state, payload) => {
    const { userSites } = state;
    if (!userSites[payload]) {
      return;
    }
    delete userSites[payload];
    state.userSites = userSites;
  }),
  updateGroup: action((state, payload) => {
    const { groupId, group } = payload;
    if (!groupId || !group) {
      return;
    }

    const groupUnits = state.siteGroups[groupId].units;

    if (!groupUnits || groupUnits.length === 0) {
      return;
    }

    const IDs = Object.keys(state.siteUnits);
    const numOfUnits = IDs.length;

    for (let x = 0; x < numOfUnits; x++) {
      const id = IDs[x];
      if (groupUnits.indexOf(id) >= 0) {
        state.siteUnits[id] = { ...state.siteUnits[id], ...group };
      }
    }
  }),
  updateUnit: action((state, payload) => {
    const { id } = payload;
    if (!state.siteUnits[id]) {
      return;
    }
    state.siteUnits = {
      ...state.siteUnits,
      [id]: { ...state.siteUnits[id], ...payload }
    };
  }),
  updateIndoorUnit: action((state, payload) => {
    const { id } = payload;
    if (!state.siteIndoorUnits[id]) {
      return;
    }

    state.siteIndoorUnits = {
      ...state.siteIndoorUnits,
      [id]: { ...state.siteIndoorUnits[id], ...payload }
    };
  }),
  addUnitByWebsocket: action((state, payload) => {
    const { id } = payload;
    const indoorUnits = state.siteIndoorUnits;

    state.siteIndoorUnits = {
      ...indoorUnits,
      [id]: indoorUnits[id] ? { ...indoorUnits[id], ...payload } : payload
    };
  }),
  updateUnitsPower: action((state, payload) => {
    const unitsIds = Object.keys(state.siteUnits);
    const numOfUnits = unitsIds.length;

    for (let x = 0; x < numOfUnits; x++) {
      const id = unitsIds[x];
      state.siteUnits[id].activeOperationStatus = payload;
    }
  }),
  getGroups: thunk((actions, payload, { injections }) => {
    const { sdkSite } = injections;
    sdkSite.getGroups(payload).then((res: any) => {
      actions.setGroups(res);
    });
  }),
  getSiteGroupsForCustomer: thunk((actions, payload, { injections }) => {
    const { sdkSite } = injections;
    return sdkSite.getGroups(payload);
  }),
  getUnits: thunk((actions, payload, { injections, getStoreState }) => {
    const { sdkSite } = injections;
    sdkSite.getUnits(payload).then((res: any) => {
      actions.setUnits(res);

      const { typesStore: { types } }: any = getStoreState();
      if (_.isEmpty(types)) {
        return;
      }
      const { unitTypes } = types;

      for (const unitId in res) {
        if (res[unitId].type !== unitTypes.indoor) {
          delete res[unitId];
        }
      }
      actions.setIndoorUnits(res);
    });
  }),
  getSiteUnitsForCustomer: thunk((actions, payload, { injections }) => {
    const { sdkSite } = injections;
    return sdkSite.getUnits(payload);
  }),
  getSite: thunk(async (actions, payload, { injections }) => {
    const { sdkSite } = injections;
    return sdkSite.getSiteById(payload);
  }),
  updateSite: thunk(async (actions, payload, { injections }) => {
    const { sdkSite } = injections;
    const {
      id,
      name,
      description,
      country,
      city,
      address,
      postalCode,
      timezone,
      role,
      state
    } = payload;
    return sdkSite.update(id, {
      name,
      description,
      country,
      city,
      address,
      postalCode,
      timezone,
      role,
      state
    });
  }),
  createNewSite: thunk((actions, payload, { injections }) => {
    const { customerId, siteData } = payload;
    const { sdkCustomer } = injections;

    return sdkCustomer.createSite(customerId, siteData);
  }),
  getUserSites: thunk(async (actions, payload, { injections }) => {
    const { sdkSite } = injections;
    return sdkSite.getSites().then((sites: any) => {
      if (_.isEmpty(sites)) {
        return true;
      }
      actions.setUserSites(sites);
      actions.openWebsocket();
    });
  }),
  changeSitePowerStatus: thunk(async (actions, payload, { injections }) => {
    const { sdkSite } = injections;
    const { siteId, state } = payload;
    return sdkSite.changeSitePower(siteId, state);
  }),
  getSiteUsers: thunk(async (actions, payload, { injections }) => {
    const { sdkSite } = injections;
    sdkSite.getUsers(payload).then((users: any) => {
      actions.setUsers(users);
    });
  }),
  getInvites: thunk(async (actions, payload, { injections }) => {
    const { sdkSite } = injections;
    return sdkSite.getInvites(payload);
  }),
  acceptInvite: thunk(async (actions, payload, { injections }) => {
    const { sdkInvite } = injections;
    const { inviteToken, password } = payload;
    return sdkInvite.acceptInvite(inviteToken, password);
  }),
  createGroup: thunk(async (actions, payload, { injections }) => {
    const { sdkSite } = injections;
    const { siteId, data } = payload;
    return sdkSite.createGroup(siteId, data);
  }),
  addGroup: action((state, payload) => {
    const { addedGroup } = payload;

    state.siteGroups = {
      ...state.siteGroups,
      [addedGroup.id]: { ...addedGroup }
    };
  }),
  updateGroupNameAndUnits: action((state, payload) => {
    const { groupId, updatedGroup } = payload;

    state.siteGroups = {
      ...state.siteGroups,
      [groupId]: { ...state.siteGroups[groupId], ...updatedGroup }
    };
  }),
  openWebsocket: thunk(
    (
      actions,
      payload,
      { injections, getState, getStoreState, getStoreActions }
    ) => {
      const { sdkUser } = injections;
      const storeActions: any = getStoreActions();
      const { deviceStore, sensorStore } = storeActions;
      const { updateDeviceStatus } = deviceStore;

      sdkUser.openWebSocket((message: any) => {

        if (message && message.type === "ping") {
          actions.compareAppVersion(message.version);
          return;
        }
        const { name, data } = message;
        if (!data) {
          return;
        }

        switch (name) {
          case "UPDATE_UNIT": {
            const updatedUnit = getState().siteIndoorUnits[data.unitId];

            // If the unit is not found, nothing to update anyway.
            if (!updatedUnit) {
              break;
            }

            if (
              (!_.isNil(data.name) && data.name !== updatedUnit.name) ||
              (!_.isNil(data.operationMode) &&
                data.operationMode !== updatedUnit.activeOperationMode) ||
              (!_.isNil(data.operationStatus) &&
                data.operationStatus !== updatedUnit.activeOperationStatus) ||
              (!_.isNil(data.setpoint) &&
                data.setpoint !== updatedUnit.activeSetpoint) ||
              (!_.isNil(data.fan) && data.fan !== updatedUnit.activeFanMode) ||
              (!_.isNil(data.swing) &&
                data.swing !== updatedUnit.activeSwingMode) ||
              (!_.isNil(data.filter) && data.filter !== updatedUnit.filter)
            ) {
              const mappedUnit = {
                id: data.unitId,
                activeSetpoint: data.setpoint,
                filter: data.filter,
                activeFanMode: data.fan,
                activeSwingMode: data.swing,
                activeOperationMode: data.operationMode,
                activeOperationStatus: data.operationStatus,
                ambientTemperature: data.ambientTemperature,
                message: data.message
              };

              actions.updateIndoorUnit(mappedUnit);
              actions.setWebSocketUnitUpdate(mappedUnit);
            }
            break;
          }
          case "UPDATE_SENSOR":
            let updateSensorData = {} as any;
            updateSensorData.readingValue = message.data.readingValue;
            updateSensorData.readingValueTimestamp = message.data.readingValueTimestamp;
            sensorStore._storeUpdateSensor({
              id: message.data.sensorId,
              data: updateSensorData
            });
            break;
          case "UNIT_RECONNECTED":
          case "UNIT_DISCONNECTED": {
            const isConnected = name === "UNIT_RECONNECTED" ? true : false;
            const siteUnits = getState().siteUnits;
            let unitId;
            if (siteUnits[data.unitId] && siteUnits[data.unitId].type === 2) {
              unitId = data.unitId;
            } else {
              const controlUnit: any = _.filter(
                Object.values(getState().siteIndoorUnits),
                (unit: any) => unit.serviceUnits.includes(data.unitId)
              )[0];

              if (controlUnit) {
                unitId = controlUnit.id;
              }
            }
            if (unitId) {
              actions.updateUnit({ id: unitId, isConnected });
              actions.setSocketMessages({
                item: "unit",
                status: isConnected,
                unitId
              });
            }

            break;
          }

          case "DEVICE_CONNECTED":
          case "DEVICE_DISCONNECTED": {
            const isConnected = name === "UNIT_RECONNECTED" ? true : false;

            updateDeviceStatus({
              deviceId: data.deviceId,
              status: isConnected
            });
            actions.setSocketMessages({
              item: "device",
              status: isConnected,
              deviceId: data.deviceId
            });
            break;
          }
          case "UNIT_ADDED": {
            const { typesStore: { types } }: any = getStoreState();

            if (_.isEmpty(types)) {
              break;
            }

            const { unitTypes: { indoor } } = types;

            data.type === indoor &&
              actions.addUnitByWebsocket({ id: data.unitId, ...data });
            break;
          }
          default:
            break;
        }
      });
    }
  ),
  deleteSite: thunk(async (actions, payload, { injections }) => {
    const { sdkSite } = injections;
    return sdkSite.delete(payload);
  }),
  setWebSocketUnitUpdate: action((state, payload) => {
    state.webSocketUnitUpdate = payload;
  }),
  getAllSites: thunk(async (actions, payload, { injections }) => {
    const { sdkSite } = injections;
    return sdkSite.getSites();
  })
};
export default siteStore;

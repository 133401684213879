import { fade } from "@material-ui/core/styles";
import { createStyles } from "@material-ui/styles";

export default (theme: any) =>
  createStyles({
    wrapperStyle: {
      marginBottom: "10px"
    },
    typoStyle: {
      fontFamily: "Roboto",
      fontSize: "22px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      textAlign: "center",
      color: "#fdfdfe"
    },
    submitButtonStyle: {
      marginTop: "5px",
      marginBottom: "15px",
      height: "55px",
      borderRadius: "6px",
      fontFamily: "Roboto",
      fontSize: "15px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      textAlign: "center",
      color: theme.palette.colors.white,
      width: "100%",
      backgroundColor: theme.palette.secondary.dark,
      boxShadow: `0 2px 3px 0 ${theme.palette.colors.buttonShadow}`,
      "&:hover": {
        background: theme.palette.secondary.dark,
        opacity: 0.9
      }
    },
    errorStyle: {
      fontSize: "12px",
      marginTop: "3px",
      marginBottom: "7px",
      color: `${theme.palette.secondary.main} !important`
    },
    inputsContainer: {
      display: "flex",
      flexFlow: "column nowrap",
      justifyItems: "center",
      alignContent: "center",
      width: "80%",
      marginTop: "10px"
    },
    phoneInput: {
      fontSize: "14px",
      fontFamily: theme.typography.fontFamily,
      fontWeight: "normal",
      "& ::placeholder": {
        color: theme.palette.colors.whiteOpacity7,
        opacity: 1
      },
      "& input": {
        backgroundColor: "transparent",
        border: "none",
        color: theme.palette.colors.white,
        fontSize: "16px",
        fontFamily: "Roboto",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal"
      },
      "& input:invalid + input": {
        backgroundColor: "green !important"
      }
    },
    inputClass: {
      color: "white",
      fontSize: "16px",
      fontFamily: "Roboto",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      padding: "16px 0px",
      paddingLeft: "20px"
    },
    feildContainer: {
      justifyContent: "flex-start",
      alignItems: "center",
      width: "100%",
      marginTop: 0,
      marginBottom: "10px"
    },
    logoTitleStyle: {
      maxHeight: "27px",
      width: "auto"
    },
    logoStyle: {
      width: "auto",
      maxHeight: "60px",
      marginRight: "10px"
    },
    logoSection: {
      marginTop: "20px",
      marginBottom: "10px",
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
      alignSelf: "center",
      width: "80%",
      maxHeight: "60px"
    },
    passwordContainerStyle: {
      marginTop: "10px",
      marginBottom: "10px",
      "& input:focus + fieldset": {
        borderWidth: "1px !important"
      },
      "& div": {
        backgroundColor: "rgba(255,255,255,0.1)",
        borderRadius: "4px"
      },
      "& ::placeholder": {
        color: theme.palette.colors.whiteOpacity7,
        opacity: 1
      }
    },
    screenContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      backgroundImage: theme.gradient.default,
      overflow: "auto"
    },
    container: {
      flexFlow: "column nowrap",
      alignItems: "center",
      maxWidth: "600px"
    },
    phoneInputContainer: {
      backgroundColor: theme.palette.colors.whiteOpacity1,
      paddingTop: "11px",
      paddingBottom: "11px",
      borderRadius: "4px",
      paddingLeft: "20px",
      width: "100%"
    },
    errorContainerStyle: {
      border: `1px solid ${theme.palette.secondary.main}`
    },
    phoneErrorStyle: {
      fontSize: "12px",
      color: theme.palette.secondary.main,
      margin: "8px 14px 0"
    },
    selectContainer: { width: "100%" },
    selectStyle: { marginTop: "10px" },
    textStyle: {
      color: theme.palette.colors.white,
      fontSize: "12px",
      textAlign: "center",
      lineHeight: 1.5,
      opacity: 0.6,
      marginBottom: "20px"
    },
    inputs: {
      "& input": {
        color: fade(theme.palette.colors.lightWhite, 0.6),
        fontSize: "16px",
        paddingLeft: "40px !important"
      }
    },
    itemText: { flex: "unset", color: theme.palette.colors.lightWhite },
    itemContainer: {
      width: "58%",
      flexFlow: "column",
      justifyContent: "center",
      flex: 1
    },
    list: {
      width: "100%",
      "& li": {
        height: "50px",
        margin: "auto",
        border: `1px solid ${fade(theme.palette.colors.lightWhite, 0.3)}`,
        borderRadius: "6px",
        marginTop: "15px"
      },
      "& input": {
        width: "100%",
        paddingLeft: "5px",
        color: fade(theme.palette.colors.lightWhite, 0.6),
        background: theme.palette.colors.transparent,
        fontSize: "14px",
        fontWeight: "normal"
      },
      "& span": {
        width: "fit-content",
        fontFamily: "Roboto",
        fontSize: "16px",
        color: theme.palette.colors.lightWhite,
        fontWeight: "normal"
      }
    },
    phoneFieldText: {
      flex: "0.2 1 auto"
    }
  });

export const tempSelectStyle = {
  indicatorsContainer: (styles: any) => {
    return {
      ...styles,
      "& div": {
        color: "rgba(253, 253, 254, 0.6)"
      },
      "& span": {
        display: "none"
      }
    };
  },
  control: (styles: any, isDisabled: boolean) => ({
    ...styles,
    borderRadius: "6px",
    border: "none",
    backgroundColor: "rgba(255,255,255,0.1)",
    height: "50px"
  }),
  option: (styles: any) => {
    return {
      ...styles,
      color: "#000",
      cursor: "default",
      fontSize: "12px",
      fontWeight: "bold"
    };
  },
  input: (styles: any) => ({
    ...styles,
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#fdfdfe",
    padding: 0,
    paddingLeft: "15px",
    "&:disabled": { backgroundColor: "transparent" }
  }),
  placeholder: (styles: any) => ({ ...styles }),
  singleValue: (styles: any) => ({
    ...styles,
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#fdfdfe",
    padding: 0,
    paddingLeft: "15px"
  })
};

import {
  Button as MuiButton,
  Grid,
  Input,
  InputLabel,
  makeStyles,
  Typography
} from "@material-ui/core";
import clsx from "clsx";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { isIOS } from "react-device-detect";
import { t } from "ttag";
import { ConfirmationDialog, Toolbar } from "../../components";
import {
  isEndStampLaterThanStartStamp,
  minsToTime,
  stringTimeToUTCMins
} from "../../services/timeService";
import { useStoreActions, useStoreState } from "../../Stores/typedHooks";
import { ArrowBack, ArrowDownControl, ArrowUp } from "../../svgComponents";
import { Switch, TimePicker } from "../../widgets";
import { Button, Checkbox, DaysList } from "../../widgets";
import addEditScheduleStyles from "./addEditSchedule.style";
interface IProps {
  history: any;
  match: any;
}

interface ISchedule {
  powerOnTime: number | string;
  powerOffTime: number | string;
  setpoint: number;
  days: string[];
  [key: string]: any;
}

const AddEditSchedule: React.FC<IProps> = ({ history, match }) => {
  const [scheduleDisabled, setScheduleStatus] = useState<boolean>();
  const [powerOnTime, setPowerOnTime] = useState<string>("");
  const [powerOffTime, setPowerOfftime] = useState<string>("");
  const [setpoint, setSetpoint] = useState<number>(0);
  const [days, setDays] = useState<[]>([]);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [setpointEnabled, enableSetpoint] = useState<boolean>(false);
  const [schedule, setSchedule] = useState<any>();
  const [openPicker, setOpenPicker] = useState<string>("");

  const user = useStoreState((state) => state.userStore.user);
  const temperatureSymbol = useStoreState(
    (state) => state.userStore.temperatureSymbol
  );
  const timeFormat = useStoreState((state) => state.userStore.timeFormat);
  const types = useStoreState((state) => state.typesStore.types);
  const { temperatureScale, timeFormat: myTimeFormat } = user;
  const { weekDays = [""], timeFormat: timeFormatTypes } = types;

  const startLoader = useStoreActions(
    (actions) => actions.loaderStore.startLoader
  );
  const finishLoader = useStoreActions(
    (actions) => actions.loaderStore.finishLoader
  );
  const addMessage = useStoreActions(
    (actions) => actions.messageStore.addMessage
  );
  const getSchedule = useStoreActions(
    (actions) => actions.scheduleStore.getSchedule
  );
  const updateSchedule = useStoreActions(
    (actions) => actions.scheduleStore.updateSchedule
  );
  const createObjectSchedules = useStoreActions(
    (actions) => actions.scheduleStore.createObjectSchedules
  );
  const getSiteById = useStoreActions((actions) => actions.siteStore.getSite);
  const getAllUnits = useStoreActions((actions) => actions.unitStore.getUserUnits);
  const getUnit = useStoreActions((actions) => actions.unitStore.getUnitById);
  const getGroup = useStoreActions((actions) => actions.groupStore.getGroup);

  const { params } = match;
  const { unitId, groupId, scheduleId } = params;
  const isGroup = !!groupId;
  const itemId = isGroup ? groupId : unitId;
  const editMode = !!scheduleId;
  const title = editMode ? t`Edit Schedule` : t`Add Schedule`;
  const timeFormatObject = myTimeFormat ? timeFormatTypes[myTimeFormat] : timeFormatTypes[0]; //default 24 hours
  const is12Hours = timeFormatObject.text === "12 hours" ? true : false;

  const useStyles = makeStyles(addEditScheduleStyles);
  const classes = useStyles();

  const passScheduleInfo = (schedule: any) => {
    const { powerOnTime, powerOffTime, setpoint, days = [], isDisabled } = schedule;

    setScheduleStatus(isDisabled);
    setPowerOnTime(hasValue(powerOnTime) ? minsToTime(powerOnTime, timeFormat) : "");
    setPowerOfftime(hasValue(powerOffTime) ? minsToTime(powerOffTime, timeFormat) : "");

    setpoint && setSetpoint(setpoint);
    setpoint && enableSetpoint(true);
    setDays(days as []);
  };

  const hasValue = (value: any) => {
    return !!value || value === 0;
  };

  useEffect(() => {
    temperatureScale === 2 ? setSetpoint(75) : setSetpoint(24);
  }, [temperatureScale]);

  useEffect(() => {
    if (scheduleId) {
      getSchedule(scheduleId)
        .then((response: any) => {
          setSchedule(response);
          passScheduleInfo(response);
        })
        .catch((err: any) =>
          addMessage({
            message: err.message
          })
        );
    }
  }, [addMessage, getSchedule, scheduleId]);

  const checkIfCanGoBack = () => {
    setOpenDialog(true);
  };

  const goBack = () => {
    history.push(
      isGroup ? `/group/${groupId}/schedules` : `/unit/${unitId}/schedules`
    );
  };

  const checkRequiredFields = () => {
    setErrorMsg("");

    if (!powerOnTime && !powerOffTime) {
      setErrorMsg("Add at least start or end hour");
      return false;
    }

    if (days.length === 0) {
      setErrorMsg("Pick one day at least");
      return false;
    }

    if (!(powerOnTime && powerOffTime)) {
      return true;
    }

    if (!isEndStampLaterThanStartStamp(powerOnTime, powerOffTime, is12Hours)) {
      setErrorMsg("End hour must be later than start hour");
      return false;
    }

    return true;
  };

  const editSchedule = () => {
    const {
      powerOnTime: defaultPowerOff,
      powerOffTime: defaultPowerOn,
      setpoint: defaultSetpoint
    } = schedule;

    const startHour =
      powerOnTime === ""
        ? defaultPowerOn === 0
          ? undefined
          : null
        : stringTimeToUTCMins(powerOnTime, is12Hours);

    const endHour =
      powerOffTime === ""
        ? defaultPowerOff === 0
          ? undefined
          : null
        : stringTimeToUTCMins(powerOffTime, is12Hours);

    let setpointValue: any;
    if (setpointEnabled) {
      setpointValue = setpoint;
    } else {
      if (defaultSetpoint) {
        setpointValue = null;
      } else {
        setpointValue = undefined;
      }
    }

    updateSchedule({
      id: scheduleId,
      data: {
        isDisabled: scheduleDisabled,
        name: "New Schedule",
        powerOnTime: startHour,
        powerOffTime: endHour,
        setpoint: setpointValue,
        days
      }
    })
      .then(() => goBack())
      .catch((err: any) => {
        addMessage({
          message: err.message
        });
      });
  };

  const createSchedule = () => {
    const startHour = powerOnTime ? stringTimeToUTCMins(powerOnTime, is12Hours) : undefined;
    const endHour = powerOffTime ? stringTimeToUTCMins(powerOffTime, is12Hours) : undefined;
    const setpointValue = setpointEnabled ? setpoint : undefined;

    createObjectSchedules({
      data: {
        isDisabled: scheduleDisabled,
        days,
        powerOnTime: startHour,
        powerOffTime: endHour,
        setpoint: setpointValue,
        name: "new Schedule"
      },
      objId: itemId,
      objectType: isGroup ? "group" : "unit"
    })
      .then(() => goBack())
      .catch((err: any) => {
        addMessage({
          message: err.message
        });
      });
  };

  const save = () => {
    const allRequiredNotEmpty = checkRequiredFields();

    if (!allRequiredNotEmpty) {
      return;
    }

    if (!scheduleId) {
      createSchedule();
      return;
    }

    editSchedule();
  };

  const decreaseSetpoint = () => {
    setSetpoint(setpoint - 1);
  };

  const increaseSetpoint = () => {
    setSetpoint(setpoint + 1);
  };

  const cancel = () => {
    setOpenDialog(false);
  };

  const discard = () => {
    goBack();
  };

  const addRemoveDay = (selectedDay: string) => {
    let currentDays: any = [...days];

    currentDays.includes(selectedDay)
      ? (currentDays = days.filter((day) => day !== selectedDay))
      : currentDays.push(selectedDay);

    setDays(currentDays);
  };

  const handleSetpoint = () => {
    enableSetpoint(!setpointEnabled);
  };

  const changeScheduleStatus = () => {
    setScheduleStatus(!scheduleDisabled);
  };
  const currentDays: string[] = days;

  const weekDaysArray = Object.keys(weekDays);

  const onClear = () => {
    setOpenPicker("");
    openPicker === "start" ? setPowerOnTime("") : setPowerOfftime("");
  };

  const onSetTime = (time: string) => {
    openPicker === "start" ? setPowerOnTime(time) : setPowerOfftime(time);
    setOpenPicker("");
  };

  const hasTime = (time: any) => {
    return time !== "" && time !== null && time >= 0;
  };

  const onInput = (event: any, powerTime: "start" | "end") => {
    const { target } = event.nativeEvent;

    if (target.defaultValue && target.defaultValue === target.value) {
      powerTime === "start" ? setPowerOnTime("") : setPowerOfftime("");
    }

    function iosClearDefault() {
      target.defaultValue = "";
    }
    window.setTimeout(iosClearDefault, 0);
  };

  const handlePowerOffTime = (event: any) => {
    const {
      target: { value }
    } = event;

    setPowerOfftime(value || "");
  };

  const handlePowerOnTime = (event: any) => {
    const {
      target: { value }
    } = event;

    setPowerOnTime(value || "");
  };

  const hasTimeIOS = (time: number | string) => {
    return time || time === 0;
  };

  return (
    <div className={classes.scheduleInfoContainer}>
      <Toolbar
        title={title}
        leftIconComponent={<ArrowBack />}
        leftAction={checkIfCanGoBack}
      />
      <Grid container className={classes.pageContent}>
        <Grid
          container
          className={clsx(classes.startEndTimeContainer, classes.container)}
        >
          <div className={classes.bodyRow}>
            <Typography className={classes.statusStyle}>
              {t`Status: ${scheduleDisabled ? t`Inactive` : t`Active`}`}
            </Typography>
            <div className={classes.controlSec}>
              <Switch
                checked={!scheduleDisabled}
                disableRipple={true}
                onChange={changeScheduleStatus}
                value={true}
              />
            </div>
          </div>
          <Grid className={classes.startEndTimeContainer}>
            {isIOS ? (
              <>
                <div
                  className={clsx(classes.iosHourButton, {
                    [classes.timeSelected]: !!powerOnTime
                  })}
                >
                  <InputLabel className={classes.iosTimeLabel}>
                    {powerOnTime
                      ? powerOnTime
                      : "START HOUR"}
                  </InputLabel>
                  <Input
                    className={classes.iosTimeInput}
                    disableUnderline
                    type="time"
                    value={powerOnTime}
                    onChange={handlePowerOnTime}
                    onInput={(event: any) => onInput(event, "start")}
                  />
                </div>
                <div
                  className={clsx(classes.iosHourButton, {
                    [classes.timeSelected]: !!powerOffTime
                  })}
                >
                  <InputLabel className={classes.iosTimeLabel}>
                    {powerOffTime ? powerOffTime : "END HOUR"}
                  </InputLabel>
                  <Input
                    className={classes.iosTimeInput}
                    disableUnderline
                    type="time"
                    value={powerOffTime}
                    onChange={handlePowerOffTime}
                    onInput={(event: any) => onInput(event, "end")}
                  />
                </div>
              </>
            ) : (
                <>
                  <MuiButton
                    variant="contained"
                    className={clsx(classes.timeContainer, {
                      [classes.timeSelected]: !!powerOnTime
                    })}
                    onClick={() => setOpenPicker("start")}
                  >
                    {powerOnTime ? powerOnTime : "START HOUR"}
                  </MuiButton>
                  <MuiButton
                    variant="contained"
                    className={clsx(classes.timeContainer, {
                      [classes.timeSelected]: !!powerOffTime
                    })}
                    onClick={() => setOpenPicker("end")}
                  >
                    {powerOffTime ? powerOffTime : "END HOUR"}
                  </MuiButton>
                </>
              )}
          </Grid>
        </Grid>
        <Grid
          container
          className={clsx(classes.startEndTimeContainer, classes.container)}
        >
          <Typography
            className={classes.selectModeStyle}
          >{t`Choose Days`}</Typography>
          <Grid container className={classes.daysContainer} id="days">
            <DaysList
              days={weekDaysArray}
              activeDays={currentDays}
              action={addRemoveDay}
            />
          </Grid>
        </Grid>

        <Grid
          container
          className={clsx(classes.startEndTimeContainer, classes.container)}
        >
          <Typography
            className={classes.selectModeStyle}
          >{t`Select Mode`}</Typography>

          <div className={classes.selectModeContainer}>
            <InputLabel htmlFor="setpoint-input" className={classes.valueTitle}>
              {t`Setpoint`}
              <Checkbox
                onChange={handleSetpoint}
                checked={setpointEnabled}
                className={classes.checkboxStyle}
              />
            </InputLabel>
            <Grid className={classes.setpointContainer}>
              <MuiButton
                disabled={!setpointEnabled}
                onClick={increaseSetpoint}
                className={classes.controlArrowButton}
              >
                <ArrowUp />
              </MuiButton>
              <Typography
                className={clsx(classes.setpointStyle, {
                  [classes.setpointNotSet]: !setpointEnabled
                })}
              >
                {setpointEnabled ? setpoint : temperatureScale === 1 ? "24" : "75"}
                <span className={classes.tempSymbolStyle}>
                  {temperatureSymbol}
                </span>
              </Typography>
              <MuiButton
                disabled={!setpointEnabled}
                onClick={decreaseSetpoint}
                className={classes.controlArrowButton}
              >
                <ArrowDownControl />
              </MuiButton>
            </Grid>
          </div>
        </Grid>
        {errorMsg && (
          <InputLabel
            className={classes.errorLabelStyle}
          >{errorMsg}</InputLabel>
        )}

        <div className={classes.saveButton}>
          <Button type="submit" variant="contained" onClick={save}>
            {t`Save`}
          </Button>
        </div>
        <ConfirmationDialog
          onCancel={cancel}
          onConfrim={discard}
          onClose={cancel}
          title={t`Discard Changes?`}
          text={t`Are you sure you want to discard changes made on this page?`}
          confrimLabel={t`Discard`}
          cancelLabel="No"
          dialogType="normal"
          openDialog={openDialog}
        />
        {!isIOS && (
          <TimePicker
            show={!!openPicker}
            onSet={onSetTime}
            time={openPicker === "start" ? powerOnTime : powerOffTime}
            onDismiss={() => setOpenPicker("")}
            onClear={onClear}
            is12Hours={is12Hours}
          />
        )}
      </Grid>
    </div>
  );
};

export default AddEditSchedule;

import { Input, MenuItem, Select, Slide, Typography, withStyles } from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { Button } from "../../widgets";
import timePickerStyle from "./timePicker.style";

interface IPickerProps {
  classes: any;
  time?: string;
  onSet?: any;
  onDismiss?: any;
  onClear?: any;
  desktop?: boolean;
  show?: boolean;
  is12Hours?: boolean;
}

const TimePicker: React.FC<IPickerProps> = ({
  classes,
  time,
  onDismiss,
  onSet,
  desktop,
  show,
  onClear,
  is12Hours,
  ...props
}) => {
  const [selectedHour, setSelectedHour] = useState<any>(null);
  const [selectedMinutes, setSelectedMinutes] = useState<any>(null);
  const [selectedMidday, setSelectedMidday] = useState<any>();
  const [open, setOpen] = useState<boolean>(false);

  const onHourChange = (value: any) => {
    const maxValue = is12Hours ? 12 : 23;
    const minValue = is12Hours ? 1 : 0;
    if (value > maxValue || value < minValue) {
      return;
    }
    setSelectedHour(value);
  };

  const onMinutesChange = (value: any) => {
    if (value > 59 || value < 0) {
      return;
    }
    setSelectedMinutes(value);
  };

  useEffect(() => {
    if (!show) {
      return;
    }

    setOpen(show);
  }, [show]);

  useEffect(() => {
    if (!time) {
      setSelectedHour(0);
      setSelectedMinutes(0);
      is12Hours ? setSelectedMidday("am") : setSelectedMidday("");
      return;
    }

    const timeArray = time.split(":");
    const minutesAndMidday = timeArray[1];
    let hours = +timeArray[0];
    const minutes = +minutesAndMidday.substr(0, 2);
    let midday = minutesAndMidday.substr(2, 2) || "";
    setSelectedHour(hours);
    setSelectedMinutes(minutes);
    setSelectedMidday(midday);
  }, [time]);

  const setTime = () => {
    const currentSelectedtime = selectedHour.toString().padStart(2, "0") + ":" + selectedMinutes.toString().padStart(2, "0") + selectedMidday;
    onSet(currentSelectedtime);
    setOpen(false);
  };

  const onDismissClick = () => {
    setOpen(false);
    onDismiss && onDismiss();
  };

  const clearTime = () => {
    onClear();
    setOpen(false);
  };
  return (
    <Slide direction="up" in={open} mountOnEnter unmountOnExit>
      <div className={classes.pickerContainer}>
        <div className={classes.actionsContainer}>
          <Button onClick={onDismissClick}>Dismiss</Button>
          <div>
            <Button onClick={clearTime}>Clear</Button>
            <Button onClick={setTime}>Set</Button>
          </div>
        </div>
        <div className={classes.itemsContainer}>
          <div className={classes.pickerWrapper}>
            <KeyboardArrowUp className={classes.pickerIcon} onClick={() => onHourChange((+selectedHour + 1) + "")} />

            <Input
              type="number"
              value={selectedHour}
              disableUnderline
              className={classes.timeInput}
              name={"hours"}
              onChange={(e) => onHourChange(e.target.value)}
            />
            <KeyboardArrowDown className={classes.pickerIcon} onClick={() => onHourChange((+selectedHour - 1) + "")} />
          </div>

          <div className={classes.colonWrapper}>
            <Typography>:</Typography>
          </div>

          <div className={classes.pickerWrapper}>
            <KeyboardArrowUp className={classes.pickerIcon} onClick={() => onMinutesChange((+selectedMinutes + 1) + "")} />
            <Input
              type="number"
              value={selectedMinutes}
              disableUnderline
              className={classes.timeInput}
              name={"mins"}
              onChange={(e) => onMinutesChange(e.target.value)}
            />
            <KeyboardArrowDown className={classes.pickerIcon} onClick={() => onMinutesChange((+selectedMinutes - 1) + "")} />
          </div>
          {is12Hours && (<Select
            labelId="label"
            id="am/pm"
            value={String(selectedMidday)}
            onChange={(e) => setSelectedMidday(e.target.value)}
            className={classes.middayWrapper}
            inputProps={{
              classes: {icon: classes.pickerIcon}}}
          >
              <MenuItem value="am">am</MenuItem>
              <MenuItem value="pm">pm</MenuItem>
          </Select>)}
        </div>
      </div>
    </Slide>
  );
};

export default withStyles(timePickerStyle)(TimePicker);

import React from "react";

const SvgAppTitle = props => (
  <svg width={207} height={32} viewBox={`0 0 207 32`} {...props}>
    <defs>
      <path id="AppTitle_svg__a" d="M0 .449h13.384V32H0z" />
      <path id="AppTitle_svg__c" d="M.098.449h14.258V32H.098z" />
      <path id="AppTitle_svg__e" d="M.485.449h14.257V32H.485z" />
      <path id="AppTitle_svg__g" d="M0 32.551h206.567V1.001H0z" />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(0 -1)">
      <g transform="translate(0 .551)">
        <mask id="AppTitle_svg__b" fill="#fff">
          <use xlinkHref="#AppTitle_svg__a" />
        </mask>
        <path
          fill="#FFF"
          d="M13.384 22.572v1.104c0 3.679-.644 8.325-6.761 8.325C1.103 32 0 28.367 0 23.767V8.222C0 3.392 2.16.449 6.715.449c5.473 0 6.53 3.726 6.53 7.91v1.289H9.108V7.9c0-2.208-.461-3.725-2.438-3.725-1.886 0-2.438 1.425-2.438 3.77V24.09c0 2.437.506 4.185 2.53 4.185 1.977 0 2.437-1.656 2.437-4.185v-1.518h4.186z"
          mask="url(#AppTitle_svg__b)"
        />
      </g>
      <g transform="translate(16 .551)">
        <mask id="AppTitle_svg__d" fill="#fff">
          <use xlinkHref="#AppTitle_svg__c" />
        </mask>
        <path
          fill="#FFF"
          d="M4.33 8.406V24.09c0 2.943 1.058 4.185 2.897 4.185 1.978 0 2.944-1.196 2.944-4.277V8.406c0-2.806-.92-4.231-2.944-4.231-1.932 0-2.897 1.334-2.897 4.23m10.026.369v14.81c0 5.703-2.391 8.417-7.313 8.417C2.168 32 .098 29.103.098 23.63V8.728c0-5.611 2.714-8.28 7.22-8.28 4.6 0 7.038 2.853 7.038 8.326"
          mask="url(#AppTitle_svg__d)"
        />
      </g>
      <g transform="translate(33 .551)">
        <mask id="AppTitle_svg__f" fill="#fff">
          <use xlinkHref="#AppTitle_svg__e" />
        </mask>
        <path
          fill="#FFF"
          d="M4.716 8.406V24.09c0 2.943 1.058 4.185 2.897 4.185 1.978 0 2.944-1.196 2.944-4.277V8.406c0-2.806-.92-4.231-2.944-4.231-1.931 0-2.897 1.334-2.897 4.23m10.026.369v14.81c0 5.703-2.391 8.417-7.313 8.417C2.554 32 .485 29.103.485 23.63V8.728c0-5.611 2.713-8.28 7.22-8.28 4.6 0 7.037 2.853 7.037 8.326"
          mask="url(#AppTitle_svg__f)"
        />
      </g>
      <path
        fill="#FFF"
        d="M51.423 1.414h4.139v27.044h7.727l-.413 3.68H51.423zm32.014 0v24.238c0 2.714 1.012 4.783 3.633 4.783 2.668 0 3.634-1.792 3.634-4.737V1.414h2.3V25.56c0 4.692-2.116 6.946-5.98 6.946-3.817 0-5.84-2.208-5.84-6.854V1.414h2.253zm16.605 2.069h-5.014V1.414h12.188v2.07h-4.92v28.653h-2.254zm10.946 5.106V24.87c0 4.093 1.472 5.61 3.772 5.61 2.437 0 3.77-1.517 3.77-5.794V8.635c0-3.817-1.38-5.52-3.816-5.52-2.53 0-3.726 1.61-3.726 5.474m9.843-.046v16.282c0 5.059-2.024 7.68-6.163 7.68-4.6 0-5.98-3.127-5.98-7.68V8.589c0-4.783 1.978-7.543 6.118-7.543 4.46 0 6.025 3.358 6.025 7.497m18.812 9.797c0-5.888.138-11.683.23-14.811h-.046c-.828 5.06-3.633 19.318-5.473 28.609h-2.3c-1.426-7.865-4.369-23.227-5.105-28.61h-.092c.092 3.543.276 10.535.276 15.778v12.832h-2.208V1.414h3.312c2.024 9.98 4.6 22.95 5.013 26.584h.046c.46-3.45 3.311-17.247 5.29-26.584h3.311v30.724h-2.254V18.34zm22.493-14.857h-5.013V1.414h12.188v2.07h-4.922v28.653h-2.253z"
      />
      <mask id="AppTitle_svg__h" fill="#fff">
        <use xlinkHref="#AppTitle_svg__g" />
      </mask>
      <path
        fill="#FFF"
        d="M171.657 32.137h2.254V1.413h-2.254zm8.693-23.548V24.87c0 4.093 1.472 5.61 3.772 5.61 2.437 0 3.77-1.517 3.77-5.794V8.635c0-3.817-1.38-5.52-3.816-5.52-2.53 0-3.726 1.61-3.726 5.474m9.843-.046v16.282c0 5.059-2.024 7.68-6.163 7.68-4.6 0-5.98-3.127-5.98-7.68V8.589c0-4.783 1.978-7.543 6.118-7.543 4.46 0 6.025 3.358 6.025 7.497m4.232 23.595V1.414h2.805l6.026 21.02c.689 2.483 1.057 4.276 1.24 5.473h.093c-.138-4.37-.23-8.693-.23-13.661V1.414h2.208v30.724h-2.714l-6.715-23.916c-.46-1.564-.643-2.714-.782-3.496h-.046c.185 3.68.323 9.106.323 14.672v12.74h-2.208zM73.18 1.414h-3.267l-5.105 30.724h2.254s4.277-26.26 4.37-28.514h.091c.276 2.529 4.6 28.514 4.6 28.514h2.391L73.18 1.414zm79.889 0h-3.266l-5.105 30.724h2.254s4.277-26.26 4.369-28.514h.092c.276 2.529 4.599 28.514 4.599 28.514h2.392l-5.335-30.724z"
        mask="url(#AppTitle_svg__h)"
      />
    </g>
  </svg>
);

export default SvgAppTitle;

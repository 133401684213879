import React, { useState, useEffect, useCallback } from "react";
import clsx from "clsx";
import { t } from "ttag";
import _ from "lodash";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import {
  Grid,
  Input,
  List,
  ListItem,
  ListItemText,
  FormHelperText,
  makeStyles,
  InputLabel,
  Typography
} from "@material-ui/core";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Toolbar } from "../../components";
import { Avatar, ArrowBack } from "../../svgComponents";
import { FormikField } from "../../widgets";
import { formValidtion } from "../../services/emailValidationService";
import { useStoreState, useStoreActions } from "../../Stores/typedHooks";
import styles from "./profile.style";
import { ClickableRow, Button, Select } from "../../widgets";

interface ProfileProps {
  history: any;
}

const labels = {
  firstName: t`First Name`,
  lastName: t`Last Name`,
  email: t`Email Address`,
  phone: t`Phone Number`,
  username: t`Username`
};

interface IUserInfo {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  username?: string;
  [key: string]: any;
}

interface IObject {
  [key: string]: any;
}

const ProfileScreen: React.FC<ProfileProps> = ({ history }) => {
  const [temperatureScaleOptions, setTemperatureScaleOptions] = useState<
    [object]
  >([{}]);

  const [timeFormatOptions, setTimeFormatOptions] = useState<
    [object]
    >([{}]);

  const [dateFormatOptions, setDateFormatOptions] = useState<
    [object]
    >([{}]);

  const types = useStoreState(state => state.typesStore.types);
  const displayFlags = useStoreState((state) => state.userStore.displayFlags);

  const { temperatureScale: tempOptions, languages} = types;
  const allLanguages = Object.keys(languages).map((language: any) => ({
    value: language,
    label: types.languages[language]
  }));
  const [temperatureScale, setTemperatureScale] = useState<any>({
    value: "",
    label: "Select temperature scale"
  });
  const [timeFormat, setTimeFormat] = useState<any>({value: 0, label: types.timeFormat[0].text});
  const [dateFormat, setDateFormat] = useState<any>({value: 0, label: types.dateFormat[0].text});

  const user = useStoreState(state => state.userStore.user);


  const [language, setLanguage] = useState<any>({
    value: user ? user.language : 'en',
    label: user ? languages[user.language] : languages['en']
  });

  const [firstNameErrorMsg, setFirstNameErrorMsg] = useState<string>("");
  const [lastNameErrorMsg, setLastNameErrorMsg] = useState<string>("");
  const [phoneErrorMsg, setPhoneMessageError] = useState<string>("");
  const [usernameErrorMsg, setUsernameMessageError] = useState<string>("");
  const [isDialogOpen, openDialog] = useState<boolean>(false);
  const [passwordVisibility, setPasswordVisibility] = useState<IObject>({
    currentPasswordVisibility: false,
    newPasswordVisibility: false,
    confirmPasswordVisibility: false
  });
  const [emailErrorMsg, setEmailMessageError] = useState<string>("");
  const [userInfo, setUserInfo] = useState<IUserInfo>({});


  const startLoader = useStoreActions(
    actions => actions.loaderStore.startLoader
  );
  const finishLoader = useStoreActions(
    actions => actions.loaderStore.finishLoader
  );
  const addMessage = useStoreActions(
    actions => actions.messageStore.addMessage
  );
  const changePassword = useStoreActions(
    actions => actions.userStore.changePassword
  );
  const updateUser = useStoreActions(actions => actions.userStore.updateUser);

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const passUserInfo = useCallback(() => {
    const currentUser = Object.assign({}, user);

    let { phone = "", temperatureScale = 1, timeFormat, dateFormat } = currentUser;
    const firstChar = phone.charAt(0);
    const first2Char = firstChar + phone.charAt(1);

    if (firstChar !== "+") {
      phone = "+" + phone;
    }

    if (first2Char === "00") {
      phone.substring(2);
      phone = "+" + phone;
    }

    currentUser.phone = phone;

    setTemperatureScale({
      value: temperatureScale,
      label: tempOptions[temperatureScale]
    });
    setUserInfo(currentUser);


    setDateFormat({value: dateFormat, label: _.find(types.dateFormat, item => item.value === dateFormat).text})
    setTimeFormat({value: timeFormat, label: _.find(types.timeFormat, item => item.value === timeFormat).text})
  }, [tempOptions, user]);

  useEffect(() => {
    passUserInfo();
  }, [passUserInfo, types, user]);

  useEffect(() => {
    const dropdownkeys = Object.keys(tempOptions);
    const dropdownOptions: [object] = [{}];
    dropdownOptions.shift();

    dropdownkeys.map(key => {
      if (tempOptions[key] !== "unknown") {
        dropdownOptions.push({ value: +key, label: tempOptions[key] });
      }
      return key;
    });

    setTemperatureScaleOptions(dropdownOptions);

    const timeOptions = types.timeFormat.map((item: any)=> {return {value: item.value, label: item.text}} )
    const dateOptions = types.dateFormat.map((item: any)=> {return {value: item.value, label: item.text}} )
    setDateFormatOptions(dateOptions)
    setTimeFormatOptions(timeOptions)


  }, [tempOptions]);

  const validationSchema = Yup.object({
    oldPassword: Yup.string().required(t`required field`),
    password: Yup.string()
      .required(t`required field`)
      .min(8, t`Password is too short - should be 8 chars minimum.`)
      .matches(/[A-Z]/, t`password must include capital letters`)
      .matches(/[a-z]/, t`password must include lower case letters`)
      .matches(/[0-9]/, t`password must include numbers`),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], t`Passwords must match`)
      .required(t`Password confirm is required`)
  });

  const handleInputsChange = (event: any) => {
    const {
      target: { value, name }
    } = event;

    setUserInfo({ ...userInfo, [name]: value });
  };
  const handlePhoneChange = (value: string) => {
    setUserInfo({ ...userInfo, phone: value });
  };

  const setValidationErrors = () => {
    const { phone = "", email = "" } = userInfo;
    const validationPassed = formValidtion(phone, email);
    const { pass, errorType, errorMsg } = validationPassed;

    setPhoneMessageError("");
    setEmailMessageError("");

    if (pass === true) {
      return true;
    }

    errorType === "phone"
      ? setPhoneMessageError(errorMsg)
      : setEmailMessageError(errorMsg);

    return false;
  };

  const pickShortProps = (data: any) => {
    return _.pick(data, [
      "firstName",
      "lastName",
      "username",
      "email",
      "password",
      "permissions",
      "username",
      "language",
      "timeFormat",
      "dateFormat"
    ]);
  };

  const save = () => {
    if (!setValidationErrors()) {
      return;
    }

    const { phone = "", firstName = "", lastName = "", username } = userInfo;

    setFirstNameErrorMsg("");
    setLastNameErrorMsg("");

    if (!firstName) {
      setFirstNameErrorMsg("required field");
      return;
    }

    if (!lastName) {
      setLastNameErrorMsg("required field");
      return;
    }

    if (!username) {
      setUsernameMessageError("required field");
      return;
    } else if (username.length < 6) {
      setUsernameMessageError(
        "your username should include at least 6 characters"
      );
      return;
    } else if (username.length > 50) {
      setUsernameMessageError(
        "your username can include maximum 50 characters"
      );
      return;
    }

    const newPhone = phone.replace(/[+]/g, "");

    const updatedUser = {
      ...pickShortProps(userInfo),
      temperatureScale: temperatureScale.value,
      phone: newPhone,
      language: language.value,
      timeFormat: timeFormat.value,
      dateFormat: dateFormat.value,
    };
    startLoader();

    updateUser(updatedUser)
      .catch((err: any) => {
        addMessage({
          message: err.message
        });
      })
      .finally(() => {
        finishLoader();
      });
  };

  const handleDialog = () => {
    openDialog(!isDialogOpen);
    setPasswordVisibility({
      currentPasswordVisibility: false,
      newPasswordVisibility: false,
      confirmPasswordVisibility: false
    });
  };

  const handlePasswordSubmit = (values: any) => {
    const { password, oldPassword } = values;
    startLoader();

    changePassword({ oldPassword, newPassword: password })
      .then(() => {
        handleDialog();
      })
      .catch((err: any) => {
        addMessage({
          message: err.message
        });
      })
      .finally(() => finishLoader());
  };

  const goBack = () => {
    history.push({ pathname: "/site", menuOpen: true });
  };

  const handleTempSelectChange = (selectedOption: any) => {
    setTemperatureScale(selectedOption);
  };
  const handleLanguageSelect = (lan: any) => {
    setLanguage(lan);
  };
  const { firstName, lastName, email, phone = "", username } = userInfo;

  return (
    <div className={classes.screenContainer}>
      <Grid container className={classes.grid}>
        <Toolbar
          title={t`My Profile`}
          leftIconComponent={<ArrowBack />}
          leftAction={goBack}
        />
        <div
          style={{
            flex: 1,
            width: "100%",
            display: "flex",
            flexFlow: "column nowrap",
            alignItems: "center",
            overflow: "auto"
          }}
        >
          <div>
            <Avatar className={classes.avatar} />
          </div>
          {!isDialogOpen ? (
            <>
              <List className={classes.list} disablePadding={true} dense={true}>
                <ListItem>
                  <ListItemText primary={labels.username} />
                  <Grid container className={classes.itemContainer}>
                    <Input
                      name="username"
                      disableUnderline={true}
                      value={username || ""}
                      onChange={handleInputsChange}
                      className={classes.inputs}
                    />
                  </Grid>
                </ListItem>
                {usernameErrorMsg && (
                  <FormHelperText
                    error={true}
                    className={classes.usernameErrorStyle}
                  >
                    {usernameErrorMsg}
                  </FormHelperText>
                )}
                <ListItem>
                  <ListItemText primary={labels.firstName} />
                  <Grid container className={classes.itemContainer}>
                    <Input
                      name="firstName"
                      disableUnderline={true}
                      value={firstName || ""}
                      onChange={handleInputsChange}
                      className={classes.inputs}
                    />
                    {firstNameErrorMsg && (
                      <FormHelperText
                        error={true}
                        className={classes.errorStyle}
                      >
                        {firstNameErrorMsg}
                      </FormHelperText>
                    )}
                  </Grid>
                </ListItem>
                <ListItem>
                  <ListItemText primary={labels.lastName} />
                  <Grid container className={classes.itemContainer}>
                    <Input
                      name="lastName"
                      disableUnderline={true}
                      className={classes.inputs}
                      value={lastName || ""}
                      onChange={handleInputsChange}
                    />
                    {lastNameErrorMsg && (
                      <FormHelperText
                        error={true}
                        className={classes.errorStyle}
                      >
                        {lastNameErrorMsg}
                      </FormHelperText>
                    )}
                  </Grid>
                </ListItem>
                <ListItem>
                  <ListItemText primary={labels.phone} />
                  <Grid container className={classes.itemContainer}>
                    <PhoneInput
                      placeholder="Enter phone number"
                      value={phone || ""}
                      onChange={handlePhoneChange}
                      className={classes.phoneInput}
                      limitMaxLength={true}
                    />
                    {phoneErrorMsg && (
                      <FormHelperText
                        error={true}
                        className={classes.errorStyle}
                      >
                        {phoneErrorMsg}
                      </FormHelperText>
                    )}
                  </Grid>
                </ListItem>
                {
                  displayFlags.enableLanguageSelection && (
                    <div className={classes.selectContainer}>
                      <Select
                        value={language || "en"}
                        suggestions={allLanguages}
                        handleSelectChange={handleLanguageSelect}
                        clear={false}
                        search={false}
                        label={t`Language`}
                      />
                    </div>
                  )

                }

                <ListItem>
                  <ListItemText primary={labels.email} />

                  <Grid container className={classes.itemContainer}>
                    <Input
                      name="email"
                      disableUnderline={true}
                      type="email"
                      className={clsx(classes.inputs)}
                      value={email || ""}
                      onChange={handleInputsChange}
                    />
                    {emailErrorMsg && (
                      <FormHelperText
                        error={true}
                        className={classes.errorStyle}
                      >
                        {emailErrorMsg}
                      </FormHelperText>
                    )}
                  </Grid>
                </ListItem>
                <div className={classes.selectContainer}>
                  <Select
                    value={temperatureScale || ""}
                    suggestions={temperatureScaleOptions}
                    handleSelectChange={handleTempSelectChange}
                    clear={false}
                    search={false}
                    label={t`Temperature Scale`}
                  />
                </div>
                <div className={classes.selectContainer}>
                  <Select
                    value={timeFormat}
                    suggestions={timeFormatOptions}
                    handleSelectChange={(value: any)=>{
                      setTimeFormat(value)
                    }}
                    clear={false}
                    search={false}
                    label={t`Time Format`}
                  />
                </div>
                <div className={classes.selectContainer}>
                  <Select
                    value={dateFormat}
                    suggestions={dateFormatOptions}
                    handleSelectChange={(value: any)=>setDateFormat(value)}
                    clear={false}
                    search={false}
                    label={t`Date Format`}
                  />
                </div>
                <ClickableRow
                  key="reset-password-feild"
                  title="Change password"
                  handleClick={handleDialog}
                  titleClassName={classes.resetPasswordTitleStyle}
                />
              </List>

              <div className={classes.saveButtonContainer}>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.saveButtonStyle}
                  onClick={save}
                >
                  {t`Save`}
                </Button>
              </div>
            </>
          ) : (
              <>
                <Typography className={classes.resetPasswordDialogMessage}>
                  {t` Your password should include at least 8 characters, 1 number, 1
                  capital letter, 1 special characters`}
                </Typography>
                <Formik
                  initialValues={
                    isDialogOpen
                      ? {
                        oldPassword: "",
                        password: "",
                        confirmPassword: ""
                      }
                      : ""
                  }
                  onSubmit={values => handlePasswordSubmit(values)}
                  enableReinitialize={true}
                  validationSchema={validationSchema}
                  render={({ values, setFieldValue, ...formikProps }) => {
                    return (
                      <Form className={classes.form}>
                        <div className={classes.fieldsContainer}>
                          <InputLabel
                            className={classes.labelStyle}
                          >{t`Current Password`}</InputLabel>
                          <FormikField
                            name="oldPassword"
                            placeholder={t`Current Password`}
                            formikProps={formikProps}
                            className={classes.inputFieldStyle}
                            type={
                              passwordVisibility.currentPasswordVisibility
                                ? "text"
                                : "password"
                            }
                            disableUnderline={true}
                          />
                          <InputLabel
                            className={classes.labelStyle}
                          >{t`New Password`}</InputLabel>
                          <FormikField
                            name="password"
                            placeholder={t`Password (8+ characters)`}
                            formikProps={formikProps}
                            className={classes.inputFieldStyle}
                            type={
                              passwordVisibility.newPasswordVisibility
                                ? "text"
                                : "password"
                            }
                            disableUnderline={true}
                            inputClass={classes.inputClass}
                            errorStyle={classes.errorStyle}
                            labelClass={{
                              shrink: true,
                              className: classes.inputFeildLabel
                            }}
                          />
                          <InputLabel
                            className={classes.labelStyle}
                          >{t`Repeat Password`}</InputLabel>
                          <FormikField
                            name="confirmPassword"
                            placeholder={t`Confirm Password`}
                            formikProps={formikProps}
                            className={classes.inputFieldStyle}
                            type={
                              passwordVisibility.confirmPasswordVisibility
                                ? "text"
                                : "password"
                            }
                            disableUnderline
                          />
                        </div>
                        <div className={classes.saveButtonContainer}>
                          <Button
                            type="submit"
                            variant="contained"
                            className={classes.saveButtonStyle}
                          >
                            {t`Save`}
                          </Button>
                        </div>
                      </Form>
                    );
                  }}
                />
              </>
            )}
        </div>
      </Grid>
    </div>
  );
};

export default ProfileScreen;

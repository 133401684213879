import {
  AppBar,
  Button,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  makeStyles,
  Tab,
  Tabs
} from "@material-ui/core/";
import { Close } from "@material-ui/icons";
import clsx from "clsx";
import _ from "lodash";
import "magic.css/dist/magic.min.css";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Swipeable } from "react-swipeable";
import { t } from "ttag";
import { PowerIcon, SearchIcon } from "../../svgComponents";
import { ControlItem } from "../../widgets/";
import { selectionMenuStyles } from "./selectionMenu.style";

const SiteUnitSelectionMenu = (props: any) => {
  const {
    sites,
    setSelectedSite,
    selectItem,
    units,
    groups,
    selectedSite,
    changeSitePower,
    groupSiteMapping,
    loggedUser,
    siteSensors,
    isCelsius,
    updateLocalSensor
  } = props;

  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [tabs, setTabs] = useState<any>(null);
  const [items, setItems] = useState<any>([]);
  const [filteredItems, setFilteredItems] = useState<any>([]);
  const [searchValue, setSearchValue] = useState<any>("");
  const [slideAnim, setSlideAnim] = useState<any>("");
  const [sortedArr, setSortedArr] = useState<any>([]);
  const [resort, setResort] = useState<boolean>(true);
  const [propsUnits, setPropsUnits] = useState<any>(units);
  const [groupsTabAdded, setGroupsTabAdded] = useState<boolean>(false);

  const { language } = loggedUser;
  const useStyles = makeStyles(selectionMenuStyles);
  const classes: { [index: string]: any } = useStyles();
  const { groups: groupsOfGroups } = groupSiteMapping;
  const sitesArr: any = Object.values(sites);

  const slide = (direction: "right" | "left") => {
    const classesNames: any = {
      left: "slideLeftReturn",
      right: "slideRightReturn"
    };
    setSlideAnim(classesNames[direction]);
  };

  useEffect(() => {
    if (slideAnim === "") {
      return;
    }
    setTimeout(() => setSlideAnim(""), 600);
  }, [slideAnim]);

  useEffect(() => {
    if (selectedSite === (sitesArr && sitesArr[selectedTab])) {
      return;
    }

    if (selectedSite === "groups" && groupsTabAdded) {
      setSelectedTab(sitesArr.length);
      return;
    }

    let selectedSiteIndex = sitesArr.findIndex(
      (site: any) => site.id === selectedSite
    );

    if (selectedTab === selectedSiteIndex) {
      return;
    }

    if (selectedTab > selectedSiteIndex) {
      slide("left");
    } else {
      slide("right");
    }

    setPropsUnits({});
    setSelectedTab(selectedSiteIndex >= 0 ? selectedSiteIndex : 0);
    setResort(true);
    setSortedArr([]);
  }, [selectedSite, selectedTab, sitesArr, groupsTabAdded]);

  useEffect(() => {
    setPropsUnits(units);
  }, [units]);

  useEffect(() => {
    if (sortedArr && sortedArr.length) {
      let newArr: any = [];
      for (let el in sortedArr) {
        const newEl = { ...sortedArr[el], ...units[sortedArr[el].id] };
        newArr.push(newEl);
      }
      setSortedArr(newArr);
    }
  }, [units]);

  useEffect(() => {
    if (_.isEmpty(propsUnits)) {
      setSortedArr([]);
      return;
    }
    if (!resort) {
      return;
    }
    const recentUnits: any =
      localStorage.getItem("recents") &&
      JSON.parse(localStorage.getItem("recents") as string);
    const siteRecentUnits = recentUnits && recentUnits[selectedSite];
    let sortedUnits: any = { ...units };

    if (!_.isEmpty(propsUnits) && siteRecentUnits && siteRecentUnits.length) {
      if (siteRecentUnits[2] && units[siteRecentUnits[2]]) {
        delete sortedUnits[siteRecentUnits[2]];
        sortedUnits = {
          [siteRecentUnits[2]]: units[siteRecentUnits[2]],
          ...sortedUnits
        };
      }

      if (siteRecentUnits[1] && units[siteRecentUnits[1]]) {
        delete sortedUnits[siteRecentUnits[1]];
        sortedUnits = {
          [siteRecentUnits[1]]: units[siteRecentUnits[1]],
          ...sortedUnits
        };
      }

      if (siteRecentUnits[0] && units[siteRecentUnits[0]]) {
        delete sortedUnits[siteRecentUnits[0]];
        sortedUnits = {
          [siteRecentUnits[0]]: units[siteRecentUnits[0]],
          ...sortedUnits
        };
      }
    }

    const sortedArray = Object.values(sortedUnits);
    setSortedArr(sortedArray);
    setItems([]);
    setResort(false);
  }, [units, resort, propsUnits, selectedSite]);

  useEffect(() => {
    if (!sortedArr && selectedSite !== "groups") {
      return;
    }

    if (_.isEmpty(units) && selectedSite !== "groups") {
      setItems([]);
      return;
    }

    let displayElements: any = [];
    if (selectedSite !== "groups") {
      const allUnits =
        sortedArr.length && sortedArr[0].site === selectedSite
          ? sortedArr.map((unit: any) => {
            const { id: unitId, schedules, isVisible } = unit;
            let unitHasSchedules = schedules && schedules.length > 0;

            if (!isVisible) {
              return null;
            }

            return (
              <ControlItem
                item={unit}
                key={`control_item_${unitId}`}
                isGroup={false}
                isSensor={false}
                siteId={selectedSite}
                selectItem={selectItem}
                hasSchedules={unitHasSchedules}
                isCelsius={isCelsius}
                updateLocalSensor={updateLocalSensor}
              />
            );
          })
          : [];
      const allSensors =
        Object.values(siteSensors).map((sensor: any) => {
          return (
            <ControlItem
              item={sensor}
              key={sensor.id}
              isGroup={false}
              isSensor={true}
              siteId={selectedSite}
              selectItem={selectItem}
              hasSchedules={false}
              isCelsius={isCelsius}
              updateLocalSensor={updateLocalSensor}
            />
          );
        });
      const selectedSiteIndex = sitesArr.findIndex(
        (site: any) => site.id === selectedSite
      );

      const site = sitesArr && sitesArr[selectedSiteIndex];
      const siteUnits = (site && site.units) || [];
      if (!siteUnits.length) {
        return;
      }

      const groupsElements = Object.values(groups).map((group: any) => {

        const { id, units, sensors, schedules } = group;
        let groupHasSchedules = schedules && schedules.length > 0;

        if (_.isEmpty(units) && _.isEmpty(sensors)) {
          return null;
        }
        let isGroupBelongToSite = true;
        const groupUnitsIds = Object.keys(units);

        for (let i = 0; i < groupUnitsIds.length; i++) {
          if (!siteUnits.includes(groupUnitsIds[i])) {
            isGroupBelongToSite = false;
            break;
          }
        }
        if (isGroupBelongToSite) {
          Object.values(sensors).map((sensor: any) => {
            if (sensor.site !== selectedSite) {
              isGroupBelongToSite = false;
            }
          });
        }

        if (!isGroupBelongToSite) {
          return null;
        }

        return (
          <ControlItem
            item={group}
            key={group.id}
            isGroup={true}
            isSensor={false}
            siteId={selectedSite}
            selectItem={selectItem}
            hasSchedules={groupHasSchedules}
            isCelsius={isCelsius}
            updateLocalSensor={updateLocalSensor}
          />
        );
      });

      displayElements = groupsElements.concat(allUnits, allSensors);
    } else {
      const groupsElements = Object.values(groups).map((group: any) => {
        const { id, schedules } = group;
        let groupHasSchedules = schedules && schedules.length > 0;

        if (
          !groupsOfGroups ||
          (groupsOfGroups && !groupsOfGroups.includes(id))
        ) {
          return null;
        }
        return (
          <ControlItem
            item={group}
            key={group.id}
            isGroup={true}
            isSensor={false}
            siteId={selectedSite}
            selectItem={selectItem}
            hasSchedules={groupHasSchedules}
            isCelsius={isCelsius}
            updateLocalSensor={updateLocalSensor}
          />
        );
      });
      displayElements = groupsElements;
    }

    setItems(displayElements);
    if (searchValue) {
      searchItem();
    } else {
      setFilteredItems(displayElements);
    }
  }, [
    units,
    selectedSite,
    siteSensors,
    selectItem,
    sortedArr,
    groups,
    groupsOfGroups
  ]);

  useEffect(() => {
    if (!!tabs && !_.isEmpty(groupsOfGroups) && !groupsTabAdded) {
      tabs.push(<Tab label={t`Groups`} key={`groups-tab`} />);
      setTabs(tabs);
      setGroupsTabAdded(true);
      return;
    }

    if (!!tabs && _.isEmpty(groupsOfGroups) && groupsTabAdded) {
      tabs.pop();
      setTabs(tabs);
      setGroupsTabAdded(false);

      if (selectedSite === "groups") {
        setSelectedTab(0);
        selectItem(sitesArr[0].id, "", false, false, false);
      }
      return;
    }

    if (!sitesArr || sitesArr.length === 0 || !!tabs) {
      return;
    }
    const tabsEl = [];

    const numOfSites = sitesArr.length;

    for (let x = 0; x < numOfSites; x++) {
      const site: any = sitesArr[x];
      tabsEl.push(<Tab label={site.name.length > 25 ? site.name.substr(0, 25) + "..." : site.name} key={`site-tab-${site.id}`} />);
    }

    setTabs(tabsEl);
  }, [sitesArr, tabs, groupsOfGroups, groupsTabAdded, selectItem]);

  const selectTab = (ev: any, newIndex: number) => {
    setSearchValue("");

    if (newIndex === selectedTab) {
      return;
    }
    setSortedArr([]);
    if (!sitesArr[newIndex]) {
      setSelectedSite("groups");
      return;
    }
    if (selectedSite === sitesArr[newIndex].id || !!slideAnim) {
      return;
    }
    setSelectedSite(sitesArr[newIndex].id);
  };

  const handleNext = () => {
    if (selectedTab === 0) {
      return;
    }
    slide("right");
    const newTabIndex = selectedTab - 1;
    setSelectedSite(sitesArr[newTabIndex].id);
  };

  const handlePrev = () => {
    if (selectedTab === tabs.length - 1) {
      return;
    }
    slide("right");
    const newTabIndex = selectedTab + 1;
    setSelectedSite(sitesArr[newTabIndex].id);
  };
  const swipeableConfig = {
    onSwipedLeft: () => handlePrev(),
    onSwipedRight: () => handleNext(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  };
  const searchItem = useCallback(() => {
    const listOfFilteredItems = Object.values(items).filter((item: any) => {
      if (!item) {
        return null;
      }
      const searchingValue = searchValue.toLowerCase();
      const {
        props: {
          item: { name }
        }
      } = item;

      if (name.toLowerCase().indexOf(searchingValue) > -1) {
        return item;
      }
      return null;
    });

    setFilteredItems(listOfFilteredItems);
  }, [searchValue, items]);

  const changeSearchValue = (event: any) => {
    const {
      target: { value }
    } = event;
    setSearchValue(value);
  };

  useEffect(() => {
    searchItem();
  }, [searchValue, searchItem]);

  return (
    <Grid container className={classes.container}>
      <AppBar position="static" className={classes.appBarStyle}>
        <Tabs
          value={selectedTab}
          onChange={selectTab}
          variant="scrollable"
          scrollButtons="on"
          aria-label="scrollable tabs"
          classes={{
            root: classes.tabStyle,
            scrollButtons: classes.scrollIcon,
            indicator: classes.indicatorStyle
          }}
        >
          {tabs}
        </Tabs>
      </AppBar>
      <div className={classes.selectionMenuHeader}>
        <div className={classes.searchBox}>
          <Input
            placeholder="Search.."
            value={searchValue}
            onChange={changeSearchValue}
            disableUnderline={true}
            classes={{
              root: classes.inputRoot
            }}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
            endAdornment={
              searchValue && (
                <IconButton
                  onClick={() => setSearchValue("")}
                  className={classes.closeIconStyle}
                >
                  <Close />
                </IconButton>
              )
            }
          />
        </div>

        <Button
          className={classes.powerOffButton}
          onClick={() => changeSitePower(false, selectedSite)}
        >
          <PowerIcon />
          <span className={language === "en" ? classes.powerOffButtonText : classes.powerOffPT}>{t`ALL OFF`}</span>
        </Button>
      </div>

      <Swipeable
        {...swipeableConfig}
        className={clsx(classes.tabContainer, "magictime", classes[slideAnim])}
      >
        <div className={classes.unitsWrapper}>{filteredItems}</div>
      </Swipeable>
    </Grid>
  );
};

export default SiteUnitSelectionMenu;

import { Thunk, thunk, Action, action, computed, Computed } from "easy-peasy";
import { Injections } from "./";

interface ISchedulePayload {
  id: string;
  data: object;
}

interface IGetSchedulePayload {
  id: string;
  objectType: "unit" | "group";
}

interface ICreateSchedulePayload {
  objId: string;
  objectType: "unit" | "group";
  data: any;
}

export interface IScheduleModel {
  mappedSiteScheduls: Computed<IScheduleModel>;
  schedules: any;
  requestedCustomerSchedules: boolean;
  setCustomerSchedulesFlag: Action<IScheduleModel, boolean>;
  getSiteSchedules: Thunk<IScheduleModel, string, Injections>;
  deleteSchedule: Thunk<IScheduleModel, string, Injections>;
  addSchedule: Thunk<IScheduleModel, ISchedulePayload, Injections>;
  updateSchedule: Thunk<IScheduleModel, ISchedulePayload, Injections>;
  getSchedule: Thunk<IScheduleModel, string, Injections>;
  setSchedules: Action<IScheduleModel, any>;
  getMappedSiteSchedules: Thunk<IScheduleModel, string, Injections>;
  addNewSchedule: Action<IScheduleModel, any>;
  updateSchedulesList: Action<IScheduleModel, any>;
  removeSchedule: Action<IScheduleModel, any>;
  addGroupSchedule: Thunk<IScheduleModel, ISchedulePayload, Injections>;
  getCustomerSchedules: Thunk<IScheduleModel, string, Injections>;
  getMappedCustomerSchedules: Thunk<IScheduleModel, string, Injections>;
  getObjectSchedules: Thunk<IScheduleModel, IGetSchedulePayload, Injections>,
  createObjectSchedules: Thunk<IScheduleModel, ICreateSchedulePayload, Injections>
}

const scheduleStore: IScheduleModel = {
  schedules: [],
  requestedCustomerSchedules: false,
  setCustomerSchedulesFlag: action((state, payload) => {
    state.requestedCustomerSchedules = payload;
  }),
  setSchedules: action((state, payload) => {
    state.schedules = { ...state.schedules, ...payload };
  }),
  getSiteSchedules: thunk((actions, payload, { injections }) => {
    const { sdkSite } = injections;
    return sdkSite.getSchedules(payload);
  }),
  getMappedSiteSchedules: thunk((actions, payload, { injections }) => {
    actions.getSiteSchedules(payload).then((scheduls: any) => {
      actions.setSchedules(scheduls);
    });
  }),
  getMappedCustomerSchedules: thunk((actions, payload, { injections }) => {
    actions.getCustomerSchedules(payload).then((scheduls: any) => {
      actions.setSchedules(scheduls);
      actions.setCustomerSchedulesFlag(true);
    });
  }),
  deleteSchedule: thunk((actions, payload, { injections }) => {
    const { sdkScheduler } = injections;

    return sdkScheduler.delete(payload);
  }),
  removeSchedule: action((state, payload) => {
    const { schedules: newScedules } = state;
    delete newScedules[payload];

    state.schedules = newScedules;
  }),
  addSchedule: thunk((actions, payload, { injections }) => {
    const { id, data } = payload;
    const { sdkSite } = injections;

    return sdkSite.createSchedule(id, data);
  }),
  addNewSchedule: action((state, payload) => {
    const { createdSchedule } = payload;
    const { id } = createdSchedule;

    state.schedules = { ...state.schedules, [id]: createdSchedule };
  }),

  updateSchedule: thunk((actions, payload, { injections }) => {
    const { id, data } = payload;
    const { sdkScheduler } = injections;

    return sdkScheduler.update(id, data);
  }),
  updateSchedulesList: action((state, payload) => {
    const { id } = payload;
    const { schedules } = state;

    if (!schedules[id]) {
      return;
    }

    schedules[id] = payload;
    state.schedules = schedules;
  }),
  getSchedule: thunk((actions, payload, { injections }) => {
    const { sdkScheduler } = injections;

    return sdkScheduler.getScheduleById(payload);
  }),
  mappedSiteScheduls: computed(state => {
    const { schedules } = state;
    if (!schedules) {
      return [];
    }
    let schedulesMapper: any = {};

    Object.values(schedules).map((schedule: any, index: any) => {
      const { id } = schedule;
      schedule.units.map(
        (unitId: any) =>
          (schedulesMapper = {
            ...schedulesMapper,
            [unitId]: { ...schedulesMapper[unitId], ...{ [id]: id } }
          })
      );
      schedule.groups.map(
        (groupId: any) =>
          (schedulesMapper = {
            ...schedulesMapper,
            [groupId]: { ...schedulesMapper[groupId], ...{ [id]: id } }
          })
      );
      return schedulesMapper;
    });
    return schedulesMapper;
  }),
  addGroupSchedule: thunk((actions, payload, { injections }) => {
    const { id, data } = payload;
    const { sdkCustomer } = injections;

    return sdkCustomer.createSchedule(id, data);
  }),
  getCustomerSchedules: thunk((actions, payload, { injections }) => {
    const { sdkCustomer } = injections;

    return sdkCustomer.getSchedules(payload);
  }),
  getObjectSchedules: thunk((actions, payload, { injections }) => {
    const { sdkScheduler } = injections;
    const { id, objectType } = payload;
    return sdkScheduler.getObjectScheduleById(objectType, id);
  }),
  createObjectSchedules: thunk((actions, payload, { injections }) => {
    const { sdkScheduler } = injections;
    const { objId, data, objectType } = payload;
    return sdkScheduler.createObjectSchedule(objectType, objId, data);
  })
};

export default scheduleStore;

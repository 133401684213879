import { fade } from "@material-ui/core/styles";
import { createStyles } from "@material-ui/styles";

export default (theme: any) =>
  createStyles({
    paper: {
      padding: "10px 0px",
      borderRadius: "31px",
      width: "62px",
      boxShadow: `0 2px 4px 0 ${fade(theme.palette.colors.black, 0.5)}`,
      backgroundColor: theme.palette.colors.lightWhite,
      display: "flex",
      flexFlow: "column",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: "10px",
      "& li": {
        display: "flex",
        justifyContent: "center",
        borderRadius: "25px",
        padding: 0,
        width: "50px",
        height: "50px"
      }
    },
    selected: { background: fade(theme.palette.colors.iconSelected, 0.3) },
    mainIconsStyle: {
      padding: "11px",
      background: theme.palette.colors.mainIconSelected,
      width: "62px",
      height: "62px",
      color: fade(theme.palette.colors.lightWhite, 0.6),
      "&:hover": {
        background: theme.palette.colors.modesIconPressed
      }
    },

    iconContainer: { objectFit: "contain", zIndex: 9999 },
    unitView: {
      width: "100%",
      height: "100%",
      background: theme.gradient.unitView,
      display: "flex",
      flexFlow: "column",
      justifyContent: "space-between",
      alignItems: "center",
      borderTopRightRadius: "25px",
      borderTopLeftRadius: "25px",
      padding: 0,
      paddingTop: "40px",
      paddingBottom: "20px",
      minHeight: "500px",
      overflow: "auto",
      maxHeight: "100%",
      boxSizing: "border-box"
    },
    firstRowContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      justifyItems: "center",
      alignItems: "center",
      alignContent: "center",
      boxSizing: "border-box",
      padding: "0 20px"
    },
    nameStyle: {
      color: theme.palette.colors.white,
      fontFamily: "Roboto",
      fontSize: "22px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      maxWidth: "30%",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      display: "inline-block"
    },
    powerContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    },
    powerIconStyle: { padding: 0, zIndex: 100 },
    powerTextStyle: {
      marginTop: "8px",
      color: "white",
      height: "16px",
      opacity: 0.5,
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal"
    },
    powerOnButtonClick: {
      "&:active": {
        transition: "all 1s",
        "& circle": {
          fill: "#fff",
          opacity: 1
        },
        "& path": {
          stroke: "#f05146"
        }
      }
    },
    powerOffButtonClick: {
      "&:active": {
        transition: "all 1s",
        "& circle": {
          fill: "#fff"
        },
        "& path": {
          fill: "#000"
        }
      }
    },
    secondRowContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexFlow: "column nowrap",
      paddingBottom: "20px"
    },
    iconStyle: {
      fontSize: "17.5px"
    },
    modeIcon: {
      margin: "0 5px"
    },
    smallIcons: {
      transform: "scale(0.5)",
      margin: 0
    },
    timeDateStyle: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      marginTop: 20
    },
    sensorWrapperStyle: {
      height: "100%",
      width: "100%",
      backgroundColor: "rgba(255, 255, 255, 0.1)",
      borderRadius: "50%",
      position: "absolute",
      display: "flex",
      alignItems: "center",
      // justifyContent: "space-evenly",
      flexFlow: "column nowrap"
    },
    lastRowContainer: {
      display: "flex",
      height: "62px",
      alignItems: "flex-end",
      justifyContent: "center",
      width: "100%"
    },
    menuIconsStyle: {
      "& path": {
        stroke: theme.palette.colors.lightWhite,
        opacity: 0.4
      }
    },
    hiddenStyle: {
      width: "100%",
      height: "80px",
      visibility: "hidden"
    },
    controlDiv: {
      maxHeight: "326px",
      maxWidth: "336px",
      padding: 0,
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "326px",
      minWidth: "336px",
      "@media (max-width: 328px)": {
        minWidth: "312px"
      }
    },
    sliderContainer: {
      marginTop: "-20px",
      maxWidth: "280px",
      maxHeight: "280px",
      zIndex: 1
    },
    innerControls: {
      width: "100%",
      position: "absolute",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",
      marginTop: "-3%",
      alignItems: "center"
    },
    controlArrow: {
      zIndex: 100,
      width: "60px",
      height: "60px",
      margin: "-10px 0",

      "&:active": {
        "& path": {
          stroke: `${theme.palette.secondary.main} !important`
        },
        transition: "all 1s"
      }
    },
    setPointStyle: {
      fontSize: "65px",
      color: theme.palette.colors.white
    },
    ampTempStyle: {
      fontSize: "24px",
      color: theme.palette.colors.white
    },
    ampTempContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%"
    },
    controlWrapperStyle: {
      position: "absolute",
      "@media (max-width: 328px)": {
        transform: "scale(0.93)"
      }
    },
    modeTitle: {
      fontSize: "50px",
      color: theme.palette.colors.white,
      fontWeight: "bold",
      position: "absolute"
    },
    hideStyle: {
      display: "none"
    },
    completeHide: {
      display: "none"
    },
    showStyle: { visibility: "visible" },
    tempSvgStyle: { "& path": { transform: "scale(0.7)" } },
    smallRingStyle: { marginTop: "-36px", marginLeft: "-15px" },
    opacityStyle: { opacity: 0.4 },
    loaderContainer: {
      width: "40px",
      height: "40px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    loaderStyle: { color: fade(theme.palette.colors.white, 0.7) },
    alertMessageStyle: {
      fontSize: "16px",
      color: theme.palette.secondary.main
    },
    alertContainer: {
      display: "flex",
      flexDirection: "column",
      justifyItems: "center",
      alignContent: "center",
      alignItems: "center",
      marginBottom: "15px"
    },
    nameSection: {
      justifyContent: "space-between",
      alignItems: "center",
      fontSize: "22px",
      fontWeight: "bold",
      width: "34%",
      overflow: "hidden",
      display: "-webkit-box",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
      maxHeight: "60px",
      "& p": {
        whiteSpace: "unset",
        textOverflow: "unset",
        color: theme.palette.colors.white,
        fontFamily: "Roboto",
        fontSize: "22px"
      }
    },
    smallFont: { fontSize: "40px" },
    addMargin: { marginTop: "-4.5%" },
    alertIcon: { marginBottom: "6px" },
    rightSpace: { marginRight: "10px" },
    sensorSliderContainer: {
      marginTop: "11px",
      maxWidth: "320px",
      maxHeight: "152px",
      zIndex: 1,
      position: "relative"
    },
    sensorValues: {
      fontFamily: "Roboto",
      fontSize: 18,
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: "#ffffff"
    },
    sensorValue: {
      fontFamily: "Roboto",
      fontSize: 60,
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: "#ffffff"
    },
    sensorValuesContainer: {
      position: "absolute",
      top: "120px", display: "flex",
      flexFlow: "row nowrap",
      justifyContent: "space-between",
      width: "200px",
      right: "38px"
    },
    sideArrowStyle: {
      height: 40
    },
    oppositeSideArrowStyle: {
      height: 40,
      transform: "rotate(180deg)"
    },
    iconBtn: {
      background: "transparent"
    },
    valueControl: {
      position: "absolute",
      top: "135px",
      width: "270px",
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    disabledIcon: {
      opacity: 0.5
    },
    sensorValueText: {
      fontFamily: "Roboto",
      fontSize: 80,
      fontWeight: "normal",
      lineHeight: "80px",
      letterSpacing: "normal",
      color: "#ffffff"
    },
    valueContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: 94,
      position: "relative"
    },
    tempUnit: {
      top: -6
    },
    setPointDiscription: {
      fontSize: "20px",
      color: "#fff",
      // height: 65,
      lineHeight: "normal"
    },
    dataTimeFont: {
      height: 20,
      fontFamily: "Roboto",
      fontSize: 18,
      fontWeight: "normal",
      lineHeight: 1.11,
      letterSpacing: "normal",
      color: "#ffffff"
    },
    timeStyle: {
      marginRight: 20
    },
    sensorValueStyle: {
      fontFamily: "Roboto",
      fontSize: 80,
      fontWeight: "normal",
      lineHeight: "80px",
      letterSpacing: "normal",
      color: "#ffffff"
    },
    offText: {
      color: fade("#fff", 0.4)
    },
    addTopMargin: {
      marginTop: 75
    },
    measurementUnitsStyle: {
      fontFamily: "Roboto",
      fontSize: 40,
      fontWeight: "normal",
      letterSpacing: "normal",
      textAlign: "center",
      color: " #ffffff",
      height: 80,
      lineHeight: "80px",
      position: "absolute",
      right: -5,
      width: 0,
      bottom: 0
    },
    sensorIcon: {
      height: 80,
      width: 80,
      marginBottom: 17,
      marginTop: 25,
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    measurementUnit: {
      fontFamily: "Roboto",
      fontSize: 40,
      fontWeight: "normal",
      letterSpacing: "normal",
      textAlign: "center",
      color: " #ffffff",
      height: 80,
      lineHeight: "80px"
    },
    percentStyle: {
      bottom: "unset",
      height: 48
    }
  });

import { fade } from "@material-ui/core/styles/colorManipulator";
import { createStyles } from "@material-ui/styles";

export default (theme: any) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      minHeight: "max-content",
      flex: 1,
      backgroundColor: theme.palette.colors.blackFontShadow
    },
    pageContent: {
      display: "flex",
      flexFlow: "column nowrap",
      width: "100%",
      flex: 1,
      overflowY: "scroll",
      "-webkit-overflow-scrolling": "touch"
    },
    section: {
      background: theme.gradient.default,
      width: "100%",
      marginBottom: "10px",
      boxSizing: "border-box"
    },
    nameSection: {
      minHeight: "max-content",
      paddingTop: "20px",
      paddingBottom: "6px",
      display: "flex",
      justifyContent: "center",
      background: theme.gradient.default,
      width: "100%"
    },
    moreInfo: {
      minHeight: "max-content",
      // paddingTop: "20px",
      paddingBottom: "6px",
      display: "flex",
      justifyContent: "center",
      background: theme.gradient.default,
      width: "100%"
    },
    controlSection: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      minHeight: "max-content"
    },
    controlSectionHead: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "15px 20px",
      boxSizing: "border-box",
      backgroundColor: fade(theme.palette.colors.lightWhite, 0.1),
      height: "55px"
    },
    controlSectionTitle: {
      color: theme.palette.colors.white,
      fontSize: "18px"
    },
    controlSectionBody: {
      display: "flex",
      flexWrap: "wrap",
      padding: "30px 20px",
      boxSizing: "border-box"
    },
    saveButton: {
      margin: "15px auto",
      width: "95%",
      "&:disabled": {
        opacity: "35%"
      }
    },
    textStyle: { width: "90%" },
    unitListVisibility: {
      background: theme.gradient.default,
      display: "flex",
      padding: "0 20px 23px 20px",
      justifyContent: "space-between",
      color: theme.palette.colors.white
    }
  });

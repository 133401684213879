import React from "react";

const SvgMyProfileWhite = props => (
  <svg width={17} height={17} {...props}>
    <defs>
      <path id="my-profile-white_svg__a" d="M.55.922h7.312v7.312H.55z" />
      <path id="my-profile-white_svg__c" d="M0 .925h14.41V8H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(4 .078)">
        <mask id="my-profile-white_svg__b" fill="#fff">
          <use xlinkHref="#my-profile-white_svg__a" />
        </mask>
        <path
          fill="#FDFDFE"
          d="M4.206 1.883a2.698 2.698 0 0 0-2.695 2.694 2.698 2.698 0 0 0 2.695 2.696 2.698 2.698 0 0 0 2.695-2.696 2.698 2.698 0 0 0-2.695-2.694m0 6.35A3.66 3.66 0 0 1 .55 4.578 3.66 3.66 0 0 1 4.206.922a3.66 3.66 0 0 1 3.656 3.655 3.66 3.66 0 0 1-3.656 3.657"
          mask="url(#my-profile-white_svg__b)"
        />
      </g>
      <g transform="translate(1 8.078)">
        <mask id="my-profile-white_svg__d" fill="#fff">
          <use xlinkHref="#my-profile-white_svg__c" />
        </mask>
        <path
          fill="#FDFDFE"
          d="M7.206 1.886c-2.385 0-5.57 1.443-5.927 2.219-.102.26-.176.558-.209.863-.044.318-.078.622-.104.928-.005.673.465 1.142 1.042 1.143h10.397a1.05 1.05 0 0 0 1.046-1.048l-.007-.093a13.623 13.623 0 0 0-.102-.908 3.509 3.509 0 0 0-.219-.911c-.346-.75-3.532-2.193-5.917-2.193M12.406 8h-10.4A2.011 2.011 0 0 1 .01 5.816c.027-.324.063-.645.108-.967.041-.375.135-.756.278-1.12C.99 2.432 4.677.925 7.206.925c2.53 0 6.216 1.507 6.8 2.779.154.392.248.775.29 1.165.044.303.08.625.105.95l.008.083c.003 1.196-.897 2.097-2.004 2.098"
          mask="url(#my-profile-white_svg__d)"
        />
      </g>
    </g>
  </svg>
);

export default SvgMyProfileWhite;

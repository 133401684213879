import { createStyles } from "@material-ui/core/styles";

const aboutUsStyle = (theme: any) =>
  createStyles({
    screenContainer: {
      backgroundImage: theme.gradient.default,
      display: "flex",
      flexFlow: "column nowrap",
      width: "100%"
    },
    container: {
      height: "100%",
      padding: "24px 21px",
      overflowY: "auto",
      boxSizing: "border-box"
    },

    text: {
      fontSize: "18px",
      color: theme.palette.colors.white,
      marginBottom: "24px",
      lineHeight: "normal",
      textAlign: "center"
    },

    nestText: {
      fontSize: "18px",
      color: theme.palette.colors.white,
      marginBottom: "24px",
      marginTop: "20px",
      lineHeight: "normal",
      textAlign: "center"
    }
  });

export default aboutUsStyle;

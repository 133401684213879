import { createStyles, fade } from "@material-ui/core";

export const groupAddEditStyles = (theme: any) =>
  createStyles({
    container: {
      display: "flex",
      width: "100%",
      flex: 1,
      flexFlow: "column nowrap",
      background: theme.palette.colors.blackFontShadow
    },
    sitesContainer: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      width: "100%"
    },
    unitsWrapper: {
      padding: "30px 0"
    },
    unitRowStyle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      borderBottom: `1px solid ${theme.palette.colors.black}`,
      padding: "0 5px"
    },
    titleStyle: {
      fontSize: "25px",
      fontWeight: "bold"
    },
    expand: {
      padding: "9px",
      color: theme.palette.colors.lightWhite,
      transform: "rotate(0deg)",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest
      })
    },
    expandOpen: {
      padding: "9px",
      color: theme.palette.colors.lightWhite,
      transform: "rotate(-180deg)",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest
      })
    },
    siteContainer: {
      display: "flex",
      flexDirection: "column",
      borderRadius: "6px",
      marginTop: "10px",
      marginBottom: "5px",
      maxHeight: "309px",
      width: "90%"
    },
    siteHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: fade(theme.palette.colors.lightWhite, 0.2),
      padding: "17px 10px",
      paddingRight: "0px",
      borderTopLeftRadius: "6px",
      borderTopRightRadius: "6px",
      height: "55px",
      boxSizing: "border-box"
    },
    closedSite: {
      borderBottomLeftRadius: "6px",
      borderBottomRightRadius: "6px"
    },
    siteNameStyle: {
      color: theme.palette.colors.white,
      fontSize: "18px",
      fontWeight: 500
    },
    unitContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      height: "50px",
      borderBottom: `1px solid ${fade(theme.palette.colors.lightWhite, 0.2)}`,
      backgroundColor: fade(theme.palette.colors.lightWhite, 0.1),
      padding: "0 13px",
      "&:last-child": {
        borderWidth: "0px",
        borderBottomLeftRadius: "6px",
        borderBottomRightRadius: "6px"
      }
    },
    unitNameStyle: {
      color: fade(theme.palette.colors.lightWhite, 0.6),
      fontSize: "16px",
      width: "90%",
      overflow: "hidden",
      display: "-webkit-box",
      "-webkit-line-clamp": 1,
      "-webkit-box-orient": "vertical"
    },
    collapseWrapper: {
      height: "100%",
      overflowY: "auto"
    },
    nameSection: {
      minHeight: "105px",
      paddingTop: "22px",
      display: "flex",
      justifyContent: "center",
      background: theme.gradient.default,
      width: "100%",
      marginBottom: "9px"
    },
    textStyle: { marginTop: "9px" },
    labelStyle: {
      fontSize: "18px",
      fontWeight: 500,
      lineHeight: "normal",
      color: theme.palette.colors.lightWhite,
      width: "90%"
    },
    mainLabelStyle: {
      fontSize: "18px",
      fontWeight: 500,
      lineHeight: "normal",
      color: theme.palette.colors.white,
      width: "90%"
    },
    selectUnitsSection: {
      background: theme.gradient.default,
      flex: 1,
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      paddingTop: "24px",
      paddingBottom: "20px",
      justifyContent: "space-between"
    },
    buttonStyle: { width: "90%", marginTop: "20px" },
    noUnitsStyle: {
      color: theme.palette.colors.white,
      fontSize: "18px",
      fontWeight: 500,
      textAlign: "center"
    },
    expandMoreStyle: { fontSize: "22px" },
    errorText: {
      color: "#f44336",
      fontSize: 14
    }
  });

import React from "react";

const SvgAbout = props => (
  <svg width={19} height={19} {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#FFF"
        d="M8.316 7.88a.873.873 0 111.745 0v5.673a.871.871 0 11-1.745 0V7.88zm.873-2.183a.872.872 0 11.1.002h-.001v-.002z"
      />
      <path
        stroke="#FFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.3}
        d="M17.693 9.19A8.504 8.504 0 119.19.686a8.503 8.503 0 018.504 8.503z"
      />
    </g>
  </svg>
);

export default SvgAbout;

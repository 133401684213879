import React from "react";

const SvgArrowDown = props => (
  <svg width={40} height={40} {...props}>
    <defs>
      <path
        id="arrow-down_svg__a"
        d="M6.118 6.867a.98.98 0 0 1-.696-.285L.52 1.681A.985.985 0 0 1 1.911.288l4.206 4.216L10.324.298a.98.98 0 0 1 1.382 1.383L6.804 6.582a.986.986 0 0 1-.686.285z"
      />
    </defs>
    <g
      fill="none"
      fillRule="evenodd"
      opacity={0.6}
      transform="matrix(-1 0 0 1 26 17)"
    >
      <mask id="arrow-down_svg__b" fill="#fff">
        <use xlinkHref="#arrow-down_svg__a" />
      </mask>
      <use fill="#000" fillRule="nonzero" xlinkHref="#arrow-down_svg__a" />
      <g fill="#FFF" mask="url(#arrow-down_svg__b)">
        <path d="M0-.114h12v7.5H0z" />
      </g>
    </g>
  </svg>
);

export default SvgArrowDown;

import { Grid, makeStyles } from "@material-ui/core";
import _ from "lodash";
import React from "react";
import { t } from "ttag";
import { ClickableRow } from "../../widgets/ClickableRow";
import userManagementElementStyles from "./userManagementElement.style";

interface UserElementProps {
  history?: any;
  users: any;
  loggedUser: any;
  invites: any;
  loggedUserId: string;
  customerId: string;
}

const UserManagementElement: React.FC<UserElementProps> = (props) => {
  const { history, users, invites, loggedUserId, customerId } = props;
  const usersList = users && Object.values(users);

  const useStyles = makeStyles(userManagementElementStyles);
  const classes = useStyles();

  const goToEditUser = (id: string) => {
    history.push(`/users-management/customer/${customerId}/user/${id}`);
  };

  const goToInvite = (inviteToken: string) => {
    history.push(`/view-invite/${inviteToken}`);
  };

  return (
    <Grid className={classes.usersContainer}>
      <div className={classes.usersListStyle}>
        {usersList &&
          usersList.map((user: any, i: number) => {
            const { permissions = {}, id, firstName, lastName, isExpired } = user;
            const fullRole: any =
              permissions.customers && Object.values(permissions.customers) || [];
            const role =
              (fullRole[0] &&
                fullRole[0].replace("customer", "").toLowerCase()) ||
              "";
            if (id === loggedUserId) { return; }

            return (
              <ClickableRow
                key={`user-${id}`}
                hasUserAvatar={true}
                title={`${firstName} ${lastName}`}
                subTitle={role}
                contentText={isExpired ? t`expired` : ""}
                handleClick={() => goToEditUser(id)}
              />
            );
          })}

        {invites &&
          Object.values(invites).map((invite: any, i: number) => {
            const {
              id,
              firstName,
              lastName,
              permissions = {},
              inviteUrl,
              isExpired
            } = invite;
            const fullRole: any =
              permissions.customers && Object.values(permissions.customers) || [];
            const role =
              (fullRole[0] &&
                fullRole[0].replace("customer", "").toLowerCase()) ||
              "";

            const inviteSplit = inviteUrl.split("/");
            const inviteToken: any = _.last(inviteSplit);
            return (
              <ClickableRow
                key={`invite -- ${id}`}
                hasUserAvatar={true}
                title={`${firstName} ${lastName}`}
                subTitle={role}
                contentText={isExpired ? `expired` : t`pending`}
                handleClick={() => goToInvite(inviteToken)}
              />
            );
          })}
      </div>
    </Grid>
  );
};

export default UserManagementElement;

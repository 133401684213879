import { fade } from "@material-ui/core";
import { createStyles } from "@material-ui/styles";

export const registerNewDeviceStyle = (theme: any) =>
  createStyles({
    screenContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      backgroundImage: theme.gradient.default,
      overflow: "auto",
      flexFlow: "column nowrap"
    },
    container: {
      display: "flex",
      flexDirection: "column",
      overflow: "auto",
      width: "100%",
      flex: 1,
      maxWidth: "600px",
      maxHeight: "fit-content"
    },
    pageContent: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      flex: 1,
      padding: "0 25px",
      boxSizing: "border-box"
    },
    header: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "212px"
    },
    pageTitle: {
      fontFamily: "Roboto",
      fontSize: "22px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      textAlign: "center",
      color: theme.palette.colors.lightWhite
    },
    deviceImgStyle: {
      alignSelf: "center",
      maxWidth: "202px",
      maxHeight: "114px",
      position: "absolute"
    },
    QRWrapper: {
      width: "100%"
    },
    formsWrapper: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      alignItems: "center",
      height: "100%",
      minHeight: "fit-content"
    },
    errorStyle: {
      fontSize: "12px",
      marginTop: "3px",
      color: `${theme.palette.secondary.main} !important`
    },
    wrapperStyle2: {
      marginTop: 0,
      marginBottom: "15px",
      fontSize: "30px"
    },
    wrapperStyle: {
      marginTop: 0,
      marginBottom: "10px",
      fontSize: "30px"
    },
    inputStyle: {
      fontFamily: "Roboto",
      fontSize: "15px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: theme.palette.colors.whiteOpacity7
    },
    nextButton: {
      height: "55px",
      borderRadius: "6px",
      fontFamily: "Roboto",
      fontSize: "15px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      textAlign: "center",
      color: theme.palette.colors.white,
      width: "100%",
      backgroundColor: theme.palette.secondary.dark,
      boxShadow: `0 2px 3px 0 ${theme.palette.colors.buttonShadow}`,
      "&:hover": {
        background: theme.palette.secondary.dark,
        opacity: 0.9
      }
    },
    inputLabel: {
      fontSize: "18px"
    },
    hintIcon: {
      fontSize: "22px",
      color: theme.palette.colors.iconSelected
    },
    QRForm: {
      padding: "0 14px",
      display: "flex",
      width: "100%",
      minHeight: "80px",
      justifyContent: "space-between",
      alignItems: "center",
      boxSizing: "border-box",
      cursor: "pointer",
      borderRadius: "6px",
      border: `solid 1px ${theme.palette.colors.fieldsBorder}`,
      marginBottom: "15px"
    },
    removeMargin: {
      marginBottom: 0
    },
    QRImgStyle: {
      maxHeight: "55px",
      marginRight: "14px"
    },
    qrTypoStyle: {
      marginTop: "15px",
      marginBottom: "15px",
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: theme.palette.colors.lightWhite,
      textAlign: "center"
    },
    qrReaderStyle: { width: "100%" },
    qrDetailsContainer: {
      display: "inline-flex",
      alignItems: "center"
    },
    deviceImageShadow: {
      position: "absolute",
      width: "206px",
      height: "206px",
      opacity: 0.19,
      WebkitFilter: "blur(36px)",
      filter: "blur(36px)",
      backgroundColor: theme.palette.colors.lightWhite
    },
    deviceImageContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      height: "206px",
      marginTop: "-10px",
      marginBottom: "-10px",
      position: "relative"
    },
    logoTitleStyle: {
      maxHeight: "27px",
      width: "auto"
    },
    logoStyle: {
      width: "auto",
      maxHeight: "60px",
      marginRight: "10px"
    },
    logoSection: {
      margin: "20px 0",
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
      alignSelf: "center",
      width: "80%",
      maxHeight: "60px",
      minHeight: "60px"
    },
    sitesContainer: {
      margin: "15px 0",
      backgroundColor: theme.palette.colors.transparentBackGround,
      justifyContent: "space-between",
      alignItems: "center",
      borderRadius: "4px",
      "& div:before": {
        border: "none !important"
      }
    },
    sitesSelectionMenu: {
      width: "100%",
      color: fade(theme.palette.colors.white, 0.7),

      padding: "10px",
      "& svg": {
        color: theme.palette.colors.white
      }
    },

    hide: {
      display: "none"
    },
    manualFormWrapper: {
      width: "100%",
      "& svg": {
        color: theme.palette.colors.white
      }
    },
    optionStyle: {
      backgroundColor: `${fade(theme.palette.primary.main, 0.5)} !important`
    },
    addDeviceContainer: {
      borderRadius: "6px",
      backgroundColor: fade(theme.palette.colors.lightWhite, 0.1),
      padding: "20px 13px",
      marginBottom: "20px"
    },
    addMargin: {
      marginTop: "27px"
    },
    textStyle: {
      fontSize: "18px",
      fontWeight: 600,
      lineHeight: "normal",
      color: theme.palette.colors.white,
      marginBottom: "21px"
    },
    selectWrapperStyle: { marginBottom: "32px" },
    widthStyle: { width: "210px" },
    buttonStyle: { margin: "20px 0" },
    backBtn: { marginBottom: "10px"}
  });

import coolRemoteSDK, {
  Customer as sdkCustomer,
  Device as sdkDevice,
  Ecobee as sdkEcobee,
  Group as sdkGroup,
  Invite as sdkInvite,
  Nest as sdkNest,
  Scheduler as sdkScheduler,
  Sensor as sdkSensor,
  Site as sdkSite,
  System as sdkSystem,
  Unit as sdkUnit,
  User as sdkUser,
  xhr as sdkXhr
} from "coolremote-sdk";
import { Action, action, createStore } from "easy-peasy";
import _ from "lodash";
import configStore, { IConfigStore } from "./configStore";
import customerStore, { ICustomerModel } from "./customerStore";
import deviceStore, { IDeviceModel } from "./deviceStore";
import ecobeeStore, { IEcobeeModel } from "./ecobeeStore";
import groupStore, { IGroupModel } from "./groupStore";
import inviteStore, { IInviteModel } from "./inviteStore";
import loaderStore, { ILoaderModel } from "./loaderStore";
import messageStore, { IMessagesModel } from "./messagesStore";
import nestStore, { INestModel } from "./nestStore";
import scheduleStore, { IScheduleModel } from "./scheduleStore";
import { ISensorsModel, sensorStore  } from "./sensorStore";
import siteStore, { ISiteModel } from "./siteStore";
import systemStore, { ISystemModel } from "./systemStore";
import typesStore, { ITypesModel } from "./typesStore";
import unitStore, { IUnitModel } from "./unitStore";
import userStore, { IUserModel } from "./userStore";

export interface IStore {
  loaderStore: ILoaderModel;
  userStore: IUserModel;
  configStore: IConfigStore;
  siteStore: ISiteModel;
  sensorStore: ISensorsModel;
  messageStore: IMessagesModel;
  typesStore: ITypesModel;
  deviceStore: IDeviceModel;
  groupStore: IGroupModel;
  unitStore: IUnitModel;
  inviteStore: IInviteModel;
  scheduleStore: IScheduleModel;
  systemStore: ISystemModel;
  customerStore: ICustomerModel;
  ecobeeStore: IEcobeeModel;
  nestStore: INestModel;
  reset: Action<IStore, any>;
}
export interface Injections {
  coolRemoteSDK: typeof coolRemoteSDK;
  sdkUser: typeof sdkUser;
  sdkXhr: typeof sdkXhr;
  sdkDevice: typeof sdkDevice;
  sdkCustomer: typeof sdkCustomer;
  sdkSite: typeof sdkSite;
  sdkSensor: typeof sdkSensor;
  sdkInvite: typeof sdkInvite;
  sdkGroup: typeof sdkGroup;
  sdkUnit: typeof sdkUnit;
  sdkScheduler: typeof sdkScheduler;
  sdkSystem: typeof sdkSystem;
  sdkEcobee: typeof sdkEcobee;
  sdkNest: typeof sdkNest;
}

let initialState: any = {};

const storeObj: IStore = {
  loaderStore,
  userStore,
  configStore,
  messageStore,
  typesStore,
  deviceStore,
  siteStore,
  sensorStore,
  groupStore,
  unitStore,
  inviteStore,
  scheduleStore,
  systemStore,
  customerStore,
  ecobeeStore,
  nestStore,
  reset: action((state, payload) => {
    if (_.isEmpty(initialState)) {
      return;
    }

    state.siteStore = initialState.siteStore;
    state.deviceStore = initialState.deviceStore;
    state.groupStore = initialState.groupStore;
    state.unitStore = initialState.unitStore;
    state.inviteStore = initialState.inviteStore;
    state.scheduleStore = initialState.scheduleStore;
    state.systemStore = initialState.systemStore;
    state.customerStore = initialState.customerStore;
  })
};

const store = createStore(storeObj, {
  injections: {
    coolRemoteSDK,
    sdkUser,
    sdkXhr,
    sdkDevice,
    sdkCustomer,
    sdkSite,
    sdkSensor,
    sdkInvite,
    sdkGroup,
    sdkUnit,
    sdkScheduler,
    sdkSystem,
    sdkEcobee,
    sdkNest
  }
});

initialState = store.getState();

export default store;

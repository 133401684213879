import React, { useEffect, useState } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { t } from "ttag";
import { useStoreActions, useStoreState } from "../../Stores/typedHooks";
import { Toolbar } from "../../components";
import { ArrowBack } from "../../svgComponents";
import { Button } from "../../widgets";
import styles from "./connectSmartThermostat.style";
interface IProps {
  history: any;
}

export const ConnectSmartThermostat: React.FC<IProps> = ({ history }) => {
  const [windowObjectReference, setRefrence] = useState<any>(null);
  const [disableConnectButton, setButtonDisabled] = useState<boolean>(false);

  const { startLoader, finishLoader } = useStoreActions(
    actions => actions.loaderStore
  );
  const addMessage = useStoreActions(
    actions => actions.messageStore.addMessage
  );
  const connectEcobeeAcountURL = useStoreActions(
    actions => actions.ecobeeStore.connectEcobeeAcountURL
  );
  const isConnected = useStoreActions(
    actions => actions.ecobeeStore.isConnected
  );
  const connectNestAcountURL = useStoreActions(
    actions => actions.nestStore.connectNestAcountURL
  );
  const isNestConnected = useStoreActions(
    actions => actions.nestStore.isConnected
  );
  const user = useStoreState(state => state.userStore.user);
  const setUser = useStoreActions(actions => actions.userStore.setUser);
  const { ecobeeStatus, ecobeeToken } = user;

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const goBack = () => {
    history.push({ pathname: "/site", menuOpen: true });
  };

  const connectToNest = () => {
    setButtonDisabled(true);
    connectNestAcountURL()
      .then((e: any) => {
        const options = `toolbar=no, menubar=no, top=auto, left=auto`;
        const ref = window.open(
          e.url,
          "connect Nest with coolautomation",
          options
        );
        setRefrence(ref);
      })
      .catch((error: any) => {
        addMessage({
          message: error.message
        });
        setButtonDisabled(false);
      });
  };

  const connectToEcobee = () => {
    setButtonDisabled(true);
    connectEcobeeAcountURL()
      .then((e: any) => {
        const options = `toolbar=no, menubar=no, top=auto, left=auto`;
        const ref = window.open(
          e.url,
          "connect ecobee with coolautomation",
          options
        );
        setRefrence(ref);
      })
      .catch((error: any) => {
        addMessage({
          message: error.message
        });
        setButtonDisabled(false);
      });
  };




  useEffect(() => {
    if (!windowObjectReference) {
      return;
    }
    const interval = window.setInterval(() => {
      const {
        location: { pathname }
      } = history;

      if (
        !pathname.includes("smart-thermostat/connect") &&
        !windowObjectReference.closed
      ) {
        window.clearInterval(interval);
        windowObjectReference.close();
        return;
      }

      if (windowObjectReference.closed) {
        window.clearInterval(interval);

        startLoader();

        if(!pathname.includes("nest")){
        isConnected()
          .then((status: any) => {
            if (status.ecobeeStatus === "CONNECTED") {
              setUser({ ...user, ecobeeStatus: "CONNECTED" });
              addMessage({
                message:
                  "You will now be routed to ecobee registration page to link your ecobee account with CoolRemote account.",
                textNotCentered: true,
                onClick: () => history.push("/smart-thermostat/ecobee")
              });
              return;
            }
            setButtonDisabled(false);
          })
          .catch(() => {
            setButtonDisabled(false);
          })
          .finally(() => finishLoader());
        }

        if(pathname.includes("nest")) {
          isNestConnected()
            .then((status: any) => {
              if (status.nestStatus === "CONNECTED") {
                setUser({...user, nestStatus: "CONNECTED"});
                addMessage({
                  message:
                    "You will now be routed to Nest registration page to link your Nest account with CoolRemote account.",
                  textNotCentered: true,
                  onClick: () => history.push("/smart-thermostat/nest")
                });
                return;
              }
              setButtonDisabled(false);
            })
            .catch(() => {
              setButtonDisabled(false);
            })
            .finally(() => finishLoader())
        }
      }
    }, 500);
  }, [addMessage, finishLoader, history, isConnected, isNestConnected, setUser, startLoader, windowObjectReference]);

  const {location: { pathname }} = history;

  console.log( 'pathname', pathname);
  return (
    <div className={classes.screenContainer}>
      <Toolbar
        title={t`Smart Thermostat`}
        leftIconComponent={<ArrowBack />}
        leftAction={goBack}
      />
      <div className={classes.container}>
        {!pathname.includes("nest") &&
        <Typography className={classes.text}>
          {t`Connect with Ecobee thermostat`}
        </Typography>
        }
        {!pathname.includes("nest") &&
          <Button
          variant="contained"
          onClick={connectToEcobee}
          disabled={disableConnectButton}
          >
        {t`Connect Account`}
          </Button>
          }
        {pathname.includes("nest") &&

          <Typography className={classes.nestText}>
            {t`Connect with Nest thermostat`}
          </Typography>
        }
        {pathname.includes("nest") &&
          <Button
            variant="contained"
            onClick={connectToNest}
            disabled={disableConnectButton}
          >
            {t`Connect Account`}
          </Button>
        }

      </div>
    </div>
  );
};

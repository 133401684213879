import { createStyles } from "@material-ui/styles";
import assetsService from "../../services/assetsService";

export const loginStyles = (theme: any) =>
  createStyles({
    welcomeMessage: {
      color: theme.palette.colors.white,
      fontSize: "19px",
      fontWeight: 500,
      textAlign: "center",
      marginBottom: "29px",
      marginTop: "25px"
    },
    loginContainer: {
      display: "flex",
      flexFlow: "column nowrap",
      backgroundColor: theme.palette.colors.loginContainer,
      width: "100%"
    },

    logoSection: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      justifyContent: "space-evenly",
      alignItems: "center",
      background: theme.gradient.minor,
      borderBottomRightRadius: "20px",
      borderBottomLeftRadius: "20px"
    },
    loginSection: {
      display: "flex",
      marginTop: "0"
    },
    imageContainer: {
      width: "50%",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "50% 50%"
    },
    logo: {
      width: "65%",
      height: "50%",
      backgroundImage: `url(${assetsService.getResourcePath("logo.png")})`
    },
    titleLogo: {
      width: "50%",
      height: "10%",

      backgroundImage: `url(${assetsService.getResourcePath("logoTitle.png")})`
    },
    form: {
      margin: "0 7%",
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch"
    },
    passwordStyle: {
      marginTop: 0,
      marginBottom: "14px"
    },
    usernameStyle: { marginTop: 0, marginBottom: "20px" },
    inputClass: {
      color: theme.palette.colors.white,
      fontSize: "17px",
      paddingLeft: "7px",
      borderRadius: "4px",
      height: "30px"
    },
    errorStyle: {
      marginTop: "0px",
      fontSize: "12px",
      backgroundColor: theme.palette.colors.loginContainer
    },
    linkStyle: {
      color: theme.palette.colors.menuHeader,
      cursor: "pointer",
      textDecoration: "underline",
      marginBottom: "0px"
    },
    linkStyleLabel: {
      fontSize: "13px"
    },
    inputRootHeight: {
      minHeight: "85%"
    },
    buttonsGroup: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: "25px",
      marginBottom: "34px"
    },
    errorHeplerText: { marginTop: "5px", marginLeft: "15px" },
    failedLoginMsg: {
      color: 'rgb(240, 83, 71)',
      margin: '0 2px 20px 2px'
    }
  });

import React from "react";

const SvgTemperature = props => (
  <svg width={11} height={19} {...props}>
    <path
      d="M6 13.16V2.73a2.18 2.18 0 00-4.36 0v10.43a3.26 3.26 0 002.18 5.69A3.26 3.26 0 006 13.16zM7.73 2.64h2.72m-2.72 3.2h2.72m-2.72 3.2h2.72"
      fill="none"
      fillRule="evenodd"
      stroke="#FFF"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.09}
    />
  </svg>
);

export default SvgTemperature;

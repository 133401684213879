import {
  Collapse,
  Divider,
  IconButton,
  InputAdornment,
  InputLabel,
  makeStyles,
  TextField
} from "@material-ui/core";
import { Check } from "@material-ui/icons";
import clsx from "clsx";
import { Form, Formik } from "formik";
import _ from "lodash";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { t } from "ttag";
import * as Yup from "yup";
import { Toolbar } from "../../components";
import hexToAscii from "../../services/hexToAscii";
import { useStoreActions, useStoreState } from "../../Stores/typedHooks";
import { ArrowBack, Delete, DisconnectIcon, Edit } from "../../svgComponents";
import { Button, FormikField, Select } from "../../widgets";
import styles, { dropDownStyle } from "./addSystem.styles";

const lineOptions = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
  { label: "6", value: 6 },
  { label: "7", value: 7 },
  { label: "8", value: 8 }
];

const AddEditSystem = (props: any) => {
  const [formVals, setFormVals] = useState<any>(null);
  const [deviceUnits, setDeviceUnits] = useState<any>({});
  const [deviceSystems, setDeviceSystems] = useState<any>({});
  const [lineSystems, setLineSystems] = useState<any>({});
  const [selectedUnit, setSelectedUnit] = useState<any>(null);
  const [editedUnitName, setEditedUnitName] = useState<any>("");
  const [internalIds, setInternalIds] = useState<any>({});
  const [isDaikin, setIsDaikin] = useState<boolean>(false);

  const { history, match } = props;
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const {
    params: { systemId, deviceId }
  } = match;
  const isAdd = !systemId;

  const unitTypes = useStoreState((state) => state.typesStore.unitTypes);
  const getSystem = useStoreActions((action) => action.systemStore.getSystem);
  const socketMessages = useStoreState((state) => state.siteStore.socketMessages);
  const getDeviceSystems = useStoreActions(
    (action) => action.systemStore.getDeviceSystems
  );
  const getDeviceUnits = useStoreActions(
    (action) => action.deviceStore.getDeviceUnits
  );
  const createSystem = useStoreActions(
    (action) => action.systemStore.createSystem
  );

  const startLoader = useStoreActions((action) => action.loaderStore.startLoader);
  const finishLoader = useStoreActions(
    (action) => action.loaderStore.finishLoader
  );
  const addMessage = useStoreActions((action) => action.messageStore.addMessage);
  const updateUnit = useStoreActions((actions) => actions.unitStore.updateUnit);
  const updateSystem = useStoreActions(
    (actions) => actions.systemStore.updateSystem
  );
  const updateUnitSystem = useStoreActions(
    (actions) => actions.unitStore.updateUnitSystem
  );
  const getInternalIds = useStoreActions(
    (action) => action.deviceStore.getDeviceDaikin
  );
  const deleteUnit = useStoreActions((action) => action.unitStore.deleteUnit);

  useEffect(() => {
    if (_.isEmpty(socketMessages)) {
      return;
    }

    const { item, status, unitId } = socketMessages;

    if (item === "device") {
      return;
    }

    if (item === "unit" && !_.isEmpty(deviceUnits)) {
      if (!deviceUnits[unitId]) {
        return;
      }

      if (status && !deviceUnits[unitId].isConnected) {
        setDeviceUnits({
          ...deviceUnits,
          [unitId]: { ...deviceUnits[unitId], isConnected: true }
        });
        return;
      }

      if (!status && deviceUnits[unitId].isConnected) {
        setDeviceUnits({
          ...deviceUnits,
          [unitId]: { ...deviceUnits[unitId], isConnected: false }
        });
      }
    }
  }, [deviceUnits, socketMessages]);

  const goBack = useCallback(() => {
    history.push(`/site-management`);
  }, [history]);

  useEffect(() => {
    startLoader();
    if (!systemId) {
      setFormVals({
        name: "",
        brand: "",
        model: "",
        line: lineOptions[0]
      });
      finishLoader();
      return;
    }

    let systemLine: any = null;

    getSystem(systemId)
      .then((system: any) => {
        const { name, line, brand, model } = system;
        systemLine = line;
        setFormVals({
          name,
          brand,
          model,
          line
        });
        return getDeviceUnits(deviceId);
      })
      .then((units: any) => {
        setDeviceUnits(units);
        return getDeviceSystems(deviceId);
      })
      .then((deviceSys: any) => {
        setDeviceSystems(deviceSys);
        const sysArr = deviceSys && Object.values(deviceSys);
        const lineSys: any = {};
        for (let sys in sysArr) {
          const currentSys: any = sysArr[sys];
          if (currentSys.line === systemLine) {
            lineSys[currentSys.id] = {
              label: currentSys.name,
              value: currentSys.id
            };
            if (currentSys.brand === "DAIKIN_D3NET") {
              setIsDaikin(true);
            }
          }
        }

        setLineSystems([
          { label: "None", value: null },
          ...Object.values(lineSys)
        ]);
      })
      .catch((error: any) => {
        addMessage({ message: error.message });
        goBack();
      })
      .finally(() => finishLoader());
  }, [
    systemId,
    getSystem,
    addMessage,
    startLoader,
    finishLoader,
    getDeviceSystems,
    deviceId,
    getDeviceUnits,
    goBack
  ]);

  useEffect(() => {
    if (
      formVals &&
      deviceId &&
      formVals.line &&
      formVals.brand &&
      formVals.brand === "DAIKIN_D3NET"
    ) {
      const lineNumber = formVals.line;
      getInternalIds({
        deviceId: deviceId as string,
        lineId: lineNumber as string
      }).then((result: any) => {
        let lineInternals: any = {};
        for (let proIdIdx in result) {
          const internalId = result[proIdIdx];
          lineInternals[internalId.proId] = {
            value: internalId.proId,
            label: `${hexToAscii(internalId.proId)}/${internalId.airNet}`
          };
        }
        setInternalIds(lineInternals);
      });
    }
  }, [formVals, getInternalIds, deviceId]);

  const updateSystemUnits = (
    newSystem: string,
    unitId: string,
    oldSystem: string
  ) => {
    startLoader();
    updateUnitSystem({ unitId, newSystem, oldSystem })
      .then()
      .catch((error: any) => {
        addMessage({ message: error.message });
      })
      .finally(() => finishLoader());
  };

  const handleUnitSystemChange = (systemId: string, unitId: string) => {
    const updatedDeviceUnits = {
      ...deviceUnits,
      [unitId]: { ...deviceUnits[unitId], system: systemId }
    };
    setDeviceUnits(updatedDeviceUnits);
  };
  const handleSystemSelect = (
    newSystem: any,
    unitId: string,
    oldSystem: string
  ) => {
    const { value: newSystemId } = newSystem;
    handleUnitSystemChange(newSystemId, unitId);
    updateSystemUnits(newSystemId, unitId, oldSystem);
  };

  const validationSchema = Yup.object({
    name: Yup.string().required(t`required field`),
    brand: Yup.string(),
    model: Yup.string(),
    line: Yup.object().required(t`required field`)
  });
  const updateSystemValidationSchema = Yup.object({
    name: Yup.string().required(t`required field`),
    brand: Yup.string(),
    model: Yup.string()
  });

  const createNewSystem = (values: any) => {
    startLoader();
    const data = {
      name: values.name,
      brand: values.brand ? values.brand.value : "",
      model: values.model,
      line: values.line.value
    };
    createSystem({ deviceId, data })
      .then(() => {
        goBack();
      })
      .catch((error: any) => {
        addMessage({ message: error.message });
      })
      .finally(() => finishLoader());
  };

  const onSubmitAction = (values: any) => {
    if (isAdd) {
      createNewSystem(values);
      return;
    }

    editSystem(values);
  };

  const editSystem = (values: any) => {
    startLoader();
    updateSystem({
      systemId,
      data: {
        name: values.name,
        brand: values.brand,
        model: values.model,
        line: values.line
      }
    })
      .catch((err: any) => {
        addMessage({
          message: err.message
        });
      })
      .finally(() => {
        finishLoader();
      });
  };

  const saveUnit = (unitId: string, unit: any) => {
    setSelectedUnit(null);

    if (!unit.name) {
      return;
    }
    startLoader();
    updateUnit({ unitId, data: { ...unit } })
      .then(() => {
        setDeviceUnits({
          ...deviceUnits,
          [unitId]: { ...deviceUnits[unitId], ...unit }
        });
      })
      .catch((err: any) => {
        addMessage({
          message: err.message
        });
      })
      .finally(() => finishLoader());
  };

  const handleUnitNameChange = (event: any, id: any) => {
    const name = event.target.value;

    const updatedDeviceUnits = {
      ...deviceUnits,
      [id]: { ...deviceUnits[id], name }
    };
    setEditedUnitName(name);
    setDeviceUnits(updatedDeviceUnits);
  };

  const deleteSelectedUnit = (unitId: string) => {
    startLoader();
    deleteUnit(unitId)
      .then(() => {
        delete deviceUnits[unitId];
        setDeviceUnits(deviceUnits);
      })
      .catch((err: any) =>
        addMessage({
          message: err.message
        })
      )
      .finally(() => finishLoader());
  };

  return (
    <div className={classes.screenContainer}>
      <div className={classes.container}>
        <Toolbar
          leftIconComponent={<ArrowBack />}
          leftAction={() => goBack()}
          title={isAdd ? t`Add System` : t`System Details`}
        />
        <div className={classes.content}>
          <Formik
            initialValues={formVals}
            onSubmit={onSubmitAction}
            enableReinitialize={true}
            validationSchema={
              isAdd ? validationSchema : updateSystemValidationSchema
            }
            render={({ values, setFieldValue, ...formikProps }) => {
              return (
                <Form className={classes.formStyle}>
                  <div className={classes.fieldsContainer}>
                    <FormikField
                      name="name"
                      label={t`System Name`}
                      formikProps={formikProps}
                      className={classes.inputFieldStyle}
                      disableUnderline={true}
                      inputClass={classes.inputClass}
                      errorStyle={classes.errorStyle}
                      showErrorOutside={true}
                      containerClass={classes.fieldContainer}
                      labelClass={classes.systemLabelClass}
                    />

                    <FormikField
                      name="brand"
                      label={t`Brand`}
                      formikProps={formikProps}
                      className={classes.inputFieldStyle}
                      disableUnderline={true}
                      inputClass={classes.inputClass}
                      errorStyle={classes.errorStyle}
                      showErrorOutside={true}
                      containerClass={classes.fieldContainer}
                      labelClass={classes.systemLabelClass}
                    />
                    <FormikField
                      name="model"
                      label={t`Model`}
                      formikProps={formikProps}
                      className={classes.inputFieldStyle}
                      disableUnderline={true}
                      inputClass={classes.inputClass}
                      errorStyle={classes.errorStyle}
                      showErrorOutside={true}
                      containerClass={classes.fieldContainer}
                      labelClass={classes.systemLabelClass}
                    />
                    <Select
                      className={!isAdd ? classes.disabled : ""}
                      disabled={!isAdd}
                      label={t`CA Device Line`}
                      suggestions={lineOptions}
                      value={{
                        label:
                          values.line &&
                          (isAdd ? values.line.label : values.line),
                        value:
                          values.line &&
                          (isAdd ? values.line.value : values.line)
                      }}
                      handleSelectChange={(val: any) => {
                        setFieldValue("line", val);
                      }}
                      search={false}
                      clear={false}
                    />
                  </div>
                  <Button
                    type="submit"
                    variant="contained"
                    className={classes.saveButtonStyle}
                  >
                    {isAdd ? `Create System` : `Update`}
                  </Button>

                  <div
                    className={clsx(classes.systemUnitsSection, {
                      [classes.hide]: isAdd
                    })}
                  >
                    <Collapse
                      in={true}
                      timeout="auto"
                      className={classes.unitsContainer}
                      unmountOnExit
                    >
                      {Object.values(deviceUnits).map(
                        (unit: any, index: number) => {
                          const {
                            id: unitId,
                            name: unitName,
                            type: unitType,
                            system: unitSystem,
                            proId,
                            isConnected,
                            line,
                            privateId
                          } = unit;

                          const unitSystemOption = deviceSystems[unitSystem];

                          let unitSystemOptionValue = {
                            label: unitSystemOption && unitSystemOption.name,
                            value: unitSystemOption && unitSystemOption.id
                          };

                          if (!unitSystem) {
                            unitSystemOptionValue = {
                              label: "None",
                              value: ""
                            };
                          }

                          const isEditable = selectedUnit === unitId;
                          if (+unit.line !== +formVals.line) {
                            return <Fragment key={`${index}-unused`} />;
                          }
                          return (
                            <div
                              key={`unit-${unitId}`}
                              className={clsx(classes.unitContainerStyle, {
                                [classes.highlighted]: !isConnected
                              })}
                            >
                              {!isConnected && (
                                <IconButton
                                  className={classes.deleteIconStyle}
                                  onClick={() => deleteSelectedUnit(unitId)}
                                >
                                  <Delete />
                                </IconButton>
                              )}

                              <div className={classes.rowStyle}>
                                <InputLabel className={classes.labelStyle}>
                                  Unit Name
                                </InputLabel>
                                <TextField
                                  value={unitName}
                                  onChange={(e) =>
                                    handleUnitNameChange(e, unitId)
                                  }
                                  disabled={selectedUnit !== unitId}
                                  inputProps={{
                                    className: classes.textFieldInputStyle
                                  }}
                                  className={classes.textFieldsStyle}
                                  InputProps={{
                                    disableUnderline: true,
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        {!isEditable ? (
                                          <>
                                            <span className={classes.address}>
                                              {privateId && line ? `L${line}.${privateId}` : ""}
                                            </span>
                                            <Edit
                                              className={classes.iconPadding}
                                              onClick={() =>
                                                setSelectedUnit(unitId)
                                              }
                                            />
                                          </>
                                        ) : (
                                            <>
                                              <span className={classes.address}>
                                                {privateId && line ? `L${line}.${privateId}` : ""}
                                              </span>

                                              <Check
                                                className={clsx(
                                                  classes.iconPadding,
                                                  classes.opacityStyle,
                                                  classes.iconColor
                                                )}
                                                onClick={() =>
                                                  saveUnit(unitId, {
                                                    name: editedUnitName
                                                  })
                                                }
                                              />
                                            </>
                                          )}
                                      </InputAdornment>
                                    ),
                                    startAdornment: !isConnected && (
                                      <InputAdornment position="start">
                                        <DisconnectIcon />
                                      </InputAdornment>
                                    )
                                  }}
                                />
                              </div>
                              <Divider className={classes.dividerStyle} />
                              <div className={classes.rowStyle}>
                                <InputLabel className={classes.labelStyle}>
                                  System
                                </InputLabel>
                                <Select
                                  variant="inline"
                                  className={classes.selectContainer}
                                  placeholder={t`System`}
                                  disabled={isAdd}
                                  suggestions={lineSystems}
                                  value={unitSystemOptionValue}
                                  handleSelectChange={(value: any) =>
                                    handleSystemSelect(
                                      value,
                                      unitId,
                                      unitSystem
                                    )
                                  }
                                  selectClass={dropDownStyle}
                                  search={false}
                                  clear={false}
                                />
                              </div>
                              <Divider className={classes.dividerStyle} />
                              {isDaikin && (
                                <>
                                  <div className={classes.rowStyle}>
                                    <InputLabel className={classes.labelStyle}>
                                      Unit ID
                                    </InputLabel>
                                    <Select
                                      disabled={isAdd}
                                      placeholder={t`Internal ID`}
                                      suggestions={[
                                        { value: null, label: t`Not assinged` },
                                        ...Object.values(internalIds)
                                      ]}
                                      value={
                                        proId
                                          ? internalIds[proId]
                                          : {
                                            value: null,
                                            label: t`Not assinged`
                                          }
                                      }
                                      handleSelectChange={(value: any) =>
                                        saveUnit(unitId, { proId: value.value })
                                      }
                                      selectClass={dropDownStyle}
                                      search={false}
                                      clear={false}
                                    />
                                  </div>
                                  <Divider className={classes.dividerStyle} />{" "}
                                </>
                              )}
                              <div className={classes.rowStyle}>
                                <InputLabel className={classes.labelStyle}>
                                  Unit Type
                                </InputLabel>
                                <TextField
                                  value={unitTypes[unitType]}
                                  disabled={true}
                                  inputProps={{
                                    className: clsx(
                                      classes.textFieldInputStyle,
                                      classes.opacityStyle
                                    )
                                  }}
                                  className={classes.textFieldsStyle}
                                  InputProps={{ disableUnderline: true }}
                                />
                              </div>
                            </div>
                          );
                        }
                      )}
                    </Collapse>
                  </div>
                </Form>
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AddEditSystem;

import { createStyles } from "@material-ui/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";

export default (theme: any) =>
  createStyles({
    container: {
      display: "flex",
      width: "100%",
      alignItems: "center",
      flexWrap: "wrap"
    },
    modeStyle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "80px",
      height: "60px",
      borderRadius: "6px",
      backgroundColor: fade(theme.palette.colors.white, 0.1),
      margin: "9px 9px",
      "& *": {
        color: theme.palette.colors.white,
        fontSize: "20px"
      }
    },
    activeModeStyle: {
      backgroundColor: theme.palette.secondary.main
    },
    inactiveText: {
      "& p": { opacity: "0.4" }
    }
  });

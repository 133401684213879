import React from "react";

const SvgGroupManagement = props => (
  <svg width={21} height={16} {...props}>
    <defs>
      <path id="group-management_svg__a" d="M0 .126h19.47v7.95H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#FDFDFE"
        d="M6.91.715a3.59 3.59 0 100 7.178 3.59 3.59 0 000-7.178m0 1.004a2.588 2.588 0 012.586 2.585A2.589 2.589 0 016.91 6.891a2.59 2.59 0 01-2.585-2.587A2.589 2.589 0 016.91 1.72"
      />
      <g transform="translate(.808 6.81)">
        <mask id="group-management_svg__b" fill="#fff">
          <use xlinkHref="#group-management_svg__a" />
        </mask>
        <path
          fill="#FDFDFE"
          d="M18.171 4.637h-5.41v.002c-.027-.09-.049-.184-.082-.273-.27-.622-1.556-1.405-3.049-1.943l.003.001c.867-.568 2.679-1.293 4.114-1.293 1.813 0 4.232 1.158 4.57 1.669.07.195.114.398.135.606l.002.019.003.019c.034.259.063.521.083.8l.003.028a.373.373 0 01-.372.365zm-6.62 2.436H1.48a.56.56 0 01-.552-.566v-.006l.003-.047c.024-.3.056-.606.1-.939.025-.248.08-.492.162-.726.358-.57 3.137-1.984 5.322-1.984 2.191 0 4.98 1.423 5.319 1.978.083.237.138.483.163.734l.001.019.004.019c.04.296.072.598.096.916l.003.041a.558.558 0 01-.55.56zm7.913-2.928a13.234 13.234 0 00-.09-.848 3.728 3.728 0 00-.21-.916c-.401-.924-3.342-2.255-5.417-2.255-1.863 0-4.418 1.073-5.212 1.961-.693-.177-1.392-.287-2.02-.287C4.154 1.8.805 3.316.35 4.366a4.214 4.214 0 00-.243 1.037 19.64 19.64 0 00-.102.966L0 6.464v.043c0 .867.661 1.569 1.479 1.57H11.55c.816-.001 1.479-.703 1.479-1.57v-.043l-.008-.09c-.018-.246-.049-.49-.078-.733h5.227c.719 0 1.3-.618 1.3-1.378v-.04l-.007-.078z"
          mask="url(#group-management_svg__b)"
        />
      </g>
      <path
        fill="#FDFDFE"
        d="M12.115 3.468a2.661 2.661 0 105.323-.002 2.661 2.661 0 00-5.323.002zm2.662-1.744a1.746 1.746 0 010 3.489 1.747 1.747 0 01-1.745-1.745c0-.962.783-1.744 1.745-1.744"
      />
    </g>
  </svg>
);

export default SvgGroupManagement;

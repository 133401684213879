import { createStyles } from "@material-ui/styles";
import { fade } from "@material-ui/core";

export default (theme: any) =>
  createStyles({
    resetPasswordResultContainer: {
      boxSizing: "border-box",
      borderRadius: "4px",
      padding: "15px",
      backgroundColor: fade(theme.palette.colors.iconSelected, 0.1),
      margin: "15px"
    },
    resetPasswordResultMessage: {
      color: theme.palette.colors.white,
      fontSize: "16px",
      textAlign: "center",
      margin: "15px 0"
    }
  });

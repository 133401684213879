import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Typography,
  IconButton,
  Collapse,
  Divider
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import _ from "lodash";
import { t } from "ttag";
import { useStoreActions, useStoreState } from "../../Stores/typedHooks";
import { Toolbar } from "../../components";
import { ArrowBack } from "../../svgComponents";
import { Button, Select } from "../../widgets";
import styles from "./ecobeeSmartThermostat.style";

interface IProps {
  history: any;
}

export const EcobeeSmartThermostat: React.FC<IProps> = ({ history }) => {
  const [open, openCollapse] = useState<boolean>(true);
  const [dataFetched, setDataFetched] = useState<boolean>(false);
  const [assignedUnits, setAssignedUnits] = useState<any>({});
  const [units, setUnits] = useState<any>({});
  const [ecobeeDevices, setEcobeeDevices] = useState<any>([]);
  const [selectedOption, setSelectedOption] = useState<any>("");
  const [selectOptions, setSelectOptions] = useState<any>({
    "": { value: "", label: "None", unitInternalId: "" }
  });

  const addMessage = useStoreActions(
    actions => actions.messageStore.addMessage
  );
  const getUserUnits = useStoreActions(
    actions => actions.unitStore.getUserUnits
  );
  const findEcobeeDevices = useStoreActions(
    actions => actions.ecobeeStore.findEcobeeDevices
  );
  const disconnectEcobeeAcount = useStoreActions(
    actions => actions.ecobeeStore.disconnectEcobeeAcount
  );
  const updateUnit = useStoreActions(actions => actions.unitStore.updateUnit);
  const user = useStoreState(state => state.userStore.user);
  const setUser = useStoreActions(actions => actions.userStore.setUser);
  const { ecobeeStatus } = user;

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const goBack = () => {
    history.push({ pathname: "/site", menuOpen: true });
  };

  const handleUnitSelect = (selectedOption: any, index: number) => {
    if (selectedOption.value === "" && !ecobeeDevices[index].unit) {
      return;
    }

    updateUnit({
      unitId: selectedOption.unitInternalId
        ? selectedOption.value
        : selectOptions[ecobeeDevices[index].unit].value,
      data: {
        ecobeeThermostat: selectedOption.unitInternalId
          ? ecobeeDevices[index].identifier
          : ""
      }
    })
      .then(() => {
        if (
          assignedUnits[selectedOption.unitInternalId] ||
          assignedUnits[selectedOption.unitInternalId] === 0
        ) {
          ecobeeDevices[assignedUnits[selectedOption.unitInternalId]] = {
            ...ecobeeDevices[assignedUnits[selectedOption.unitInternalId]],
            unit: ""
          };
        }

        ecobeeDevices[index] = {
          ...ecobeeDevices[index],
          unit: selectedOption.unitInternalId
        };

        assignedUnits[selectedOption.unitInternalId] = index;

        setAssignedUnits({ ...assignedUnits });
        setEcobeeDevices([...ecobeeDevices]);
      })
      .catch((error: any) =>
        addMessage({
          message: error.message
        })
      );
  };

  useEffect(() => {
    if (dataFetched || ecobeeStatus !== "CONNECTED") {
      return;
    }

    setDataFetched(true);
    Promise.all([getUserUnits(), findEcobeeDevices()]).then((response: any) => {
      const userUnits = response[0];
      const ecobeeDevices = response[1];

      setUnits(userUnits);
      setEcobeeDevices(ecobeeDevices);

      ecobeeDevices.map((ecobee: any, index: number) => {
        if (!ecobee.unit) {
          return;
        }
        assignedUnits[ecobee.unit] = index;
      });

      Object.values(userUnits).map((unit: any) => {
        selectOptions[unit.internalId] = {
          value: unit.id,
          label: unit.name,
          unitInternalId: unit.internalId
        };
      });
      setAssignedUnits({ ...assignedUnits });
      setSelectOptions({ ...selectOptions });
    });
  }, [
    assignedUnits,
    dataFetched,
    ecobeeDevices,
    ecobeeStatus,
    findEcobeeDevices,
    getUserUnits,
    selectOptions,
    units
  ]);

  const disconnect = () => {
    disconnectEcobeeAcount()
      .then(() => setUser({ ...user, ecobeeStatus: "DISCONNECTED" }))
      .catch((error: any) =>
        addMessage({
          message: error.message
        })
      );
  };

  if (ecobeeStatus !== "CONNECTED") {
    history.push("/smart-thermostat/connect");
  }

  return (
    <div className={classes.screenContainer}>
      <Toolbar
        title="ecobee"
        leftIconComponent={<ArrowBack />}
        leftAction={goBack}
      />
      <div className={classes.container}>
        <div className={classes.devicesContainer}>
          <div className={classes.titleRow} onClick={() => openCollapse(!open)}>
            <Typography
              className={classes.title}
            >{t`Linked ecobee devices`}</Typography>
            <IconButton className={open ? classes.expandOpen : classes.expand}>
              <ExpandMore className={classes.expandMoreStyle} />
            </IconButton>
          </div>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Divider className={classes.divider} />
            {ecobeeDevices.length !== 0 &&
              ecobeeDevices.map((ecobee: any, index: number) => (
                <div
                  key={`ecobee-device${ecobee._id}`}
                  className={classes.deviceRow}
                >
                  <Typography className={classes.deviceName}>
                    {ecobee.name}
                  </Typography>
                  <Select
                    value={
                      ecobee.unit
                        ? selectOptions[ecobee.unit]
                        : { value: "", label: "None" }
                    }
                    variant="filled"
                    suggestions={Object.values(selectOptions)}
                    handleSelectChange={(option: any) =>
                      handleUnitSelect(option, index)
                    }
                    clear={false}
                    placeholder={t`Unit`}
                    className={classes.selectStyle}
                    height="30px"
                  />
                </div>
              ))}
            <div className={classes.buttonContainer}>
              <Button
                variant="contained"
                onClick={disconnect}
              >{t`Disconnect`}</Button>
            </div>
          </Collapse>
        </div>
      </div>
    </div>
  );
};

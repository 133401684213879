import { createStyles } from "@material-ui/styles";

export const splashScreenStyle = (theme: any) =>
  createStyles({
    splashScreenWrapper: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",
      alignItems: "center",
      zIndex: 999,
      width: "100%",
      height: "100vh",
      backgroundColor: theme.palette.colors.loginContainer,
      backgroundImage: theme.gradient.minor
    },
    splashScreenItem: {
      maxWidth: "60%",
      maxHeight: "45%"
    },
    fadeout: {
      opacity: 0,
      "-webkit-animation": "fadeout 0.3s",
      "-moz-animation": "fadeout 0.3s",
      "-ms-animation": "fadeout 0.3s",
      "-o-animation": "fadeout 0.3s",
      animation: "fadeout 0.3s"
    },

    "@keyframes fadeout": {
      from: {
        opacity: 1
      },
      to: {
        opacity: 0
      }
    }
  });

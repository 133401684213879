import React from "react";
import clsx from "clsx";
import { t } from "ttag";
import { makeStyles, Typography } from "@material-ui/core";
import daysListStyles from "./daysList.style";

const DaysList = (props: any) => {
  const { days, activeDays, action } = props;
  const useStyles = makeStyles(daysListStyles);
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {days.map((day: any, index: number) => {
        return (
          <div
            className={clsx(classes.dayStyle, {
              [classes.activeDayStyle]: activeDays.indexOf(day) > -1
            })}
            key={index}
            onClick={() => action(day)}
          >
            <Typography>{day.charAt(0)}</Typography>
          </div>
        );
      })}
    </div>
  );
};

export default DaysList

import {
  Divider,
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Typography
} from "@material-ui/core";
import { ArrowForwardIos, Refresh } from "@material-ui/icons";
import React, { useState } from "react";
import { t } from "ttag";
import { ConfirmationDialog } from "../";
import { useStoreActions } from "../../Stores/typedHooks";
import {
  About,
  Avatar,
  CloseIcon,
  GroupManagement,
  LogoutIcon,
  ManagementIcon,
  ProfileIcon,
  SiteIcon,
  StatisticsIcon,
  Thermostat,
  UnitSettings
} from "../../svgComponents";
import styles from "./userMenu.style";

const UserMenu: React.FC<any> = ({
  history,
  container,
  open,
  opened,
  userInfo,
  logoutCb,
  selectedSiteId,
  openStatisticsDialog
}) => {
  const [isConfirmDialogOpen, setDialogOpen] = useState<boolean>(false);
  const startLoader = useStoreActions((action) => action.loaderStore.startLoader);

  const { ecobeeStatus, nestStatus } = userInfo;
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const cancel = () => {
    setDialogOpen(false);
  };

  const logout = () => {
    logoutCb();
    history.push("/");
  };

  const menuOptions = [
    {
      src: <ProfileIcon className={classes.menuIconsStyle} />,
      text: t`My Profile`,
      path: "/profile",
      role: "all",
      action() {
        history.push(this.path);
      }
    },
    {
      src: <ManagementIcon className={classes.menuIconsStyle} />,
      text: t`User Management`,
      path: userInfo.customer ? `/users-management` : `/site`,
      role: ["Admin"],
      action() {
        history.push(this.path);
      }
    },
    {
      src: <SiteIcon className={classes.menuIconsStyle} />,
      text: t`Site/Device Management`,
      path: `/site-management`,
      role: ["Admin", "Manager"],
      action() {
        history.push(this.path);
      }
    },
    {
      src: <UnitSettings className={classes.menuIconsStyle} />,
      text: t`Unit Settings`,
      path: `/unit-settings`,
      role: ["Admin", "Manager"],
      action() {
        history.push(this.path);
      }
    },
    {
      src: <GroupManagement className={classes.menuIconsStyle} />,
      text: t`Group Management`,
      path: userInfo.customer ? "/group-management" : "/site",
      role: ["Admin", "Manager"],
      action() {
        history.push(this.path);
      }
    },
    {
      src: <Thermostat className={classes.menuIconsStyle} />,
      text: t`Smart Thermostat Ecobee`,
      path: ecobeeStatus === "CONNECTED"
        ? "/smart-thermostat/ecobee"
        : "/smart-thermostat/connect_ecobee",
      role: "all",
      action() {
        history.push(this.path);
      }
    },
    {
      src: <Thermostat className={classes.menuIconsStyle} />,
      text: t`Smart Thermostat Nest`,
      path: nestStatus === "CONNECTED"
        ? "/smart-thermostat/nest"
        : "/smart-thermostat/connect_nest",
      role: "all",
      action() {
        history.push(this.path);
      }
    },
    // {
    //   src: <StatisticsIcon className={classes.menuIconsStyle} />,
    //   text: t`Statistics`,
    //   path: "/statistics",
    //   role: "all",
    //   action: () => openStatisticsDialog()
    // },
    {
      src: <Refresh className={classes.menuIconsStyle} />,
      text: t`Refresh App`,
      path: "",
      role: "all",
      action: () => {
        startLoader();
        window.location.reload();
      }
    },
    {
      src: <About className={classes.menuIconsStyle} />,
      text: t`About`,
      path: "/about-us",
      role: "all",
      action() {
        history.push(this.path);
      }
    },
    {
      src: <LogoutIcon className={classes.menuIconsStyle} />,
      text: t`Logout`,
      path: "/",
      role: "all",
      action() {
        setDialogOpen(true);
      }
    }
  ];

  const getUserRole = (userCustomerRole: string) => {
    if (userCustomerRole === "Global Admin") {
      return "Global Admin";
    }
    if (userCustomerRole.includes("Admin")) {
      return "Admin";
    } else if (userCustomerRole.includes("Manager")) {
      return "Manager";
    } else {
      return "Guest";
    }
  };

  const {
    firstName,
    lastName,
    permissions: { customers = "" } = {},
    customer
  } = userInfo;
  const userCustomerRole = !customer ? "Global Admin" : customers[customer];
  const role = getUserRole(userCustomerRole);

  return (
    <>
      <Drawer
        transitionDuration={500}
        anchor="right"
        container={container}
        variant="temporary"
        open={open}
        color="secondary"
        onClose={opened}
        classes={{ paper: classes.drawerPaper, root: classes.root }}
        className={classes.drawerStyle}
        ModalProps={{
          keepMounted: true // Better open performance on mobile.
        }}
      >
        <div className={classes.menuBody}>
          <Grid container className={classes.menuHeader}>
            <CloseIcon className={classes.closeButton} onClick={opened} />
            <Avatar alt="Avatar" className={classes.bigAvatar} />
            <Typography className={classes.usernameStyle}>
              {firstName && firstName}
              {lastName && " " + lastName}
            </Typography>
            <Typography className={classes.roleStyle}>{role}</Typography>
          </Grid>
          <List component="nav">
            <Divider className={classes.divider} />
            {menuOptions.map((menuOption, index) => {
              const { text, src, role: showOnRoles = "" } = menuOption;

              if (
                role === "Global Admin" ||
                showOnRoles === "all" ||
                showOnRoles.includes(role)
              ) {
                return (
                  <div key={index}>
                    <ListItem
                      button
                      onClick={(event) => menuOption.action()}
                      className={classes.listItemStyle}
                    >
                      <div className={classes.iconTextContainer}>
                        {src}
                        <ListItemText
                          primary={text}
                          classes={{ primary: classes.listItemText }}
                        />
                      </div>
                      <ArrowForwardIos className={classes.arrowIconStyle} />
                    </ListItem>
                    <Divider className={classes.divider} />
                  </div>
                );
              }
              return null;
            })}
          </List>
        </div>
        <ConfirmationDialog
          onCancel={cancel}
          onConfrim={logout}
          onClose={cancel}
          title="Log out"
          text="Are you sure?"
          confrimLabel="Logout"
          cancelLabel="No"
          dialogType="normal"
          openDialog={isConfirmDialogOpen}
        />
      </Drawer>
    </>
  );
};

export default UserMenu;

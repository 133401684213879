import React from "react";

const SvgCheck = props => (
  <svg width={16} height={11} {...props}>
    <path
      fill="#FDFDFE"
      fillRule="evenodd"
      d="M15.81.254a.65.65 0 00-.913 0L5.752 9.338l-4.02-3.964a.65.65 0 00-.914 0 .636.636 0 000 .905l4.48 4.415a.656.656 0 00.914 0L15.81 1.16a.635.635 0 000-.905c-.253-.25.252.25 0 0z"
    />
  </svg>
);

export default SvgCheck;

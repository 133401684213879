import React from "react";

const SvgSiteWhite = props => (
  <svg width={17} height={17} {...props}>
    <defs>
      <path id="site-white_svg__a" d="M0 .547h6.008V10.5H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#FDFDFE"
        d="M7.023 1c-.58 0-1.05.47-1.05 1.05v13.903h8.938c.58 0 1.05-.469 1.05-1.05V2.05A1.051 1.051 0 0 0 14.91 1H7.023zm7.875 1.063l.013 12.828H7.035L7.023 2.063h7.875"
      />
      <g transform="translate(1 5.454)">
        <mask id="site-white_svg__b" fill="#fff">
          <use xlinkHref="#site-white_svg__a" />
        </mask>
        <path
          fill="#FDFDFE"
          d="M6.008.547H1.033C.463.547 0 1.009 0 1.58v7.887c0 .57.462 1.033 1.033 1.033h4.975V.547zM4.962 1.593v7.864l-3.915.01-.014-7.874h3.929z"
          mask="url(#site-white_svg__b)"
        />
      </g>
      <path
        fill="#FDFDFE"
        d="M8.846 5.984h1.484V4.5H8.846zm2.758 0h1.484V4.5h-1.484zM8.846 8.803h1.484V7.32H8.846zm2.758 0h1.484V7.32h-1.484z"
      />
    </g>
  </svg>
);

export default SvgSiteWhite;

import { createStyles } from "@material-ui/styles";

export default (theme: any) =>
  createStyles({
    appBarStyle: {
      height: "45px",
      boxShadow: "0px 5px 9px 0 rgba(0, 0, 0, 0.2)",
      background: theme.gradient.default,
      color: theme.palette.colors.white,
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    iosPadding: {
      paddingTop: "20px",
      height: "65px !important"
    },
    buttonText: {
      textDecoration: "underline",
      color: theme.palette.colors.gray,
      textTransform: "none",
      fontWeight: "bold"
    },
    titleStyle: {
      color: theme.palette.colors.white,
      fontSize: "18px"
    },
    leftActionStyle: {
      display: "flex",
      flex: 1,
      justifyContent: "flex-start"
    },
    titleWrapper: {
      display: "flex",
      flex: 4,
      justifyContent: "center",
      fontSize: 18,
      fontWeight: "bold"
    },
    rightActionStyle: {
      display: "flex",
      flex: 1,
      justifyContent: "flex-end"
    },
    rightButtonStyle: { padding: 0 },
    addPadding: { padding: "9px 10px" }
  });

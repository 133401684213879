import {
  makeStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Popover,
  TextField,
  Typography,
  Collapse
} from "@material-ui/core";
import { Delete, Email, ExpandMore, Send, Share } from "@material-ui/icons";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton
} from "react-share";
import { t } from "ttag";
import { ConfirmationDialog } from "../../components/";
import { validateEmail } from "../../services/customValidators";
import { useStoreActions } from "../../Stores/typedHooks";
import { invitationsStyle } from "./invitations.style";

interface IInvitationsProps {
  classes: any;
  customerId: any;
  startLoader: any;
  addMessage: any;
  finishLoader: any;
  emailInvite: any;
  history: any;
}

const Invitations: React.FC<IInvitationsProps> = props => {
  const [customerInvites, setCustomerInvites] = useState<any>({});
  const [deleteDialog, setDeleteDialog] = useState<boolean>(false);
  const [selectedInviteId, setSelectedInviteId] = useState<string>("");
  const [emailDialog, setEmailDialog] = useState<boolean>(false);
  const [inviteEmail, setInviteEmail] = useState<string>("");
  const [inviteEmailError, setInviteEmailError] = useState<string>("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [inviteLink, setInviteLink] = useState<string>("");
  const [collapse, setCollapse] = useState<boolean>();

  const deleteInvite = useStoreActions(
    actions => actions.inviteStore.deleteInvite
  );

  const getCustomerInvites = useStoreActions(
    actions => actions.customerStore.getCustomerInvites
  );

  const {
    startLoader,
    finishLoader,
    emailInvite,
    addMessage,
    customerId
  } = props;

  const useStyles = makeStyles(invitationsStyle);
  const classes = useStyles();

  useEffect(() => {
    startLoader();
    getCustomerInvites(customerId)
      .then((invites: any) => {
        setCustomerInvites(invites);
      })
      .catch((err: any) => {
        addMessage({
          message: err.message
        });
      })
      .finally(finishLoader());
  }, [customerId, getCustomerInvites, startLoader, finishLoader, addMessage]);

  const changeDeleteDialogState = (
    open: boolean,
    selectedInviteId: string = ""
  ) => {
    setDeleteDialog(open);
    setSelectedInviteId(selectedInviteId);
  };

  const changeEmailDialogState = (
    open: boolean,
    selectedInviteId: string = ""
  ) => {
    setEmailDialog(open);
    setSelectedInviteId(selectedInviteId);
    setInviteEmailError("");
    setInviteEmail("");
  };

  const confirmDeletion = () => {
    startLoader();
    deleteInvite(selectedInviteId).then(() => {
      const updatedInvitesList: any = customerInvites;
      delete updatedInvitesList[selectedInviteId];
      setCustomerInvites(updatedInvitesList);
      changeDeleteDialogState(false);
    });

    finishLoader();
  };

  const inviteEmailOnChange = (e: any) => {
    const { value } = e.target;
    const inviteEmailError = validateEmail(value);
    setInviteEmail(value);
    setInviteEmailError(inviteEmailError);
  };

  const sendInvite = () => {
    startLoader();

    if (!!inviteEmailError) {
      return;
    }
    const currentInvite = customerInvites[selectedInviteId];
    const { inviteUrl } = currentInvite;
    emailInvite({ inviteUrl, email: inviteEmail })
      .then(() => {
        addMessage({
          message: `the invite has been sent to the email ${inviteEmail}`
        });
        setInviteEmailError("");
        setInviteEmail("");
        setEmailDialog(false);
      })
      .catch((error: any) => {
        addMessage({ message: error.message });
      })
      .finally(() => {
        finishLoader();
      });
  };

  const openMenu = (event: any, inviteUrl: string) => {
    setAnchorEl(event.currentTarget);
    setInviteLink(inviteUrl);
  };

  const closeMenu = () => {
    setAnchorEl(null);
    setInviteLink("inviteUrl");
  };

  const socialMediaMessage = t`you have been invited to use cool remote, please click on the URL to accept your invite `;

  const handleCollapse = () => {
    setCollapse(!collapse);
  };

  return (
    <Grid className={classes.container} onClick={handleCollapse}>
      <Grid container className={classes.invitationsHeader}>
        <Typography className={classes.headerTitle}>{t`Invitations (` +
          Object.keys(customerInvites).length
          + `)`}</Typography>
        <ExpandMore onClick={handleCollapse}></ExpandMore>
      </Grid>
      <Collapse
        in={collapse}
        timeout="auto"
        unmountOnExit
        className={classes.invitaionsListStyle}
      >
        {!_.isEmpty(customerInvites) &&
          Object.values(customerInvites).map((invite: any) => {
            const { id, username, inviteUrl } = invite;
            return (
              <div key={id} className={classes.inviteRow}>
                <div className={classes.usernameCol}>
                  <Typography className={classes.usernameStyle}>
                    {username}
                  </Typography>
                </div>
                {/* <div className={roleCol}>
                  <Typography className={roleStyle}>{role}</Typography>
                </div> */}
                <div className={classes.iconCol}>
                  <Share
                    className={classes.shareIconStyle}
                    onClick={e => openMenu(e, inviteUrl)}
                  />
                </div>
                <div className={classes.iconCol}>
                  <Email
                    className={classes.emailIconStyle}
                    onClick={() => changeEmailDialogState(true, id)}
                  />
                </div>
                <div className={classes.iconCol}>
                  <Delete
                    className={classes.deleteIconStyle}
                    onClick={() => changeDeleteDialogState(true, id)}
                  />
                </div>
              </div>
            );
          })}
      </Collapse>
      <ConfirmationDialog
        onCancel={() => changeDeleteDialogState(false)}
        onConfrim={() => confirmDeletion()}
        title={"Delete Confirmation"}
        text={"Are you sure you want to delete the invitation?"}
        openDialog={deleteDialog}
        cancelLabel={"No"}
        confrimLabel={"Delete"}
        onClose={() => changeDeleteDialogState(false)}
        dialogType="error"
      />

      <Dialog onClose={() => changeEmailDialogState(false)} open={emailDialog}>
        <DialogTitle
          className={classes.emailDialogTitle}
        >{t`Send invitation via email`}</DialogTitle>
        <DialogContent className={classes.emailDialogBody}>
          <TextField
            label={t`Email`}
            inputProps={{
              className: classes.inputFont
            }}
            InputLabelProps={{
              className: classes.inputFont
            }}
            type={"email"}
            value={inviteEmail}
            onChange={inviteEmailOnChange}
            error={!!inviteEmailError}
            helperText={!!inviteEmailError && inviteEmailError}
            FormHelperTextProps={{
              className: classes.errorStyle
            }}
          />
          <IconButton onClick={sendInvite}>
            <Send />
          </IconButton>
        </DialogContent>
      </Dialog>

      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <div className={classes.shareIcons}>
          <div className={classes.socialIcon}>
            <FacebookShareButton url={inviteLink} quote={socialMediaMessage}>
              <FacebookIcon size={35} round />
            </FacebookShareButton>
          </div>
          <div className={classes.socialIcon}>
            <WhatsappShareButton url={inviteLink} title={socialMediaMessage}>
              <WhatsappIcon size={35} round />
            </WhatsappShareButton>
          </div>
          <div className={classes.socialIcon}>
            <TwitterShareButton url={inviteLink} title={socialMediaMessage}>
              <TwitterIcon size={35} round />
            </TwitterShareButton>
          </div>
          <div className={classes.socialIcon}>
            <TelegramShareButton url={inviteLink} title={socialMediaMessage}>
              <TelegramIcon size={35} round />
            </TelegramShareButton>
          </div>

          <div className={classes.socialIcon}>
            <EmailShareButton
              url={inviteLink}
              body={socialMediaMessage}
              subject={t`invite to use cool remote`}
              openWindow
            >
              <EmailIcon size={35} round />
            </EmailShareButton>
          </div>
        </div>
      </Popover>
    </Grid>
  );
};

export default Invitations;

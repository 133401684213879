import React from "react";

const SvgCalendar = props => (
  <svg width={18} height={20} {...props}>
    <g fill="none" fillRule="evenodd" opacity={0.6}>
      <rect
        width={16}
        height={16}
        x={1}
        y={3}
        stroke="#FFF"
        strokeWidth={1.4}
        rx={2}
      />
      <path
        d="M12.5 1.5v3m-7-3v3"
        stroke="#FFF"
        strokeLinecap="round"
        strokeWidth={1.4}
      />
      <path
        fill="#FFF"
        d="M4 10h2v2H4zm0 4h2v2H4zm4-4h2v2H8zm0 4h2v2H8zm4-4h2v2h-2zm0 4h2v2h-2z"
      />
      <path
        stroke="#FFF"
        strokeLinecap="square"
        strokeWidth={1.4}
        d="M1.5 7.5h15"
      />
    </g>
  </svg>
);

export default SvgCalendar;

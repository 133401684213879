export const validateUsername = (username: string) => {
  if (!username) {
    return "this field is required";
  } else if (!/^[0-9a-zA-Z_.-@]+$/.test(username)) {
    return "Invalid Username";
  } else if (username.length < 5 || username.length > 50) {
    return "username should be from 5 to 50 characters";
  } else {
    return "";
  }
};

export const validateEmail = (email: string) => {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(String(email).toLowerCase()) ? "" : "wrong email format";
};

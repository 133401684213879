import { createStyles } from "@material-ui/styles";
import { fade } from "@material-ui/core";

export default (theme: any) =>
  createStyles({
    permissionsSection: {
      display: "flex",
      flexDirection: "column",
      background: theme.gradient.default,
      width: "100%",
      boxSizing: "border-box",
      marginBottom: "6%",
      justifyContent: "space-between",
      alignItems: "baseline"
    },
    roleSelect: {
      fontSize: "18px",
      width: "100%"
    },
    errorStyle: {
      fontSize: "12px",
      marginTop: "5px",
      marginBottom: "5px",
      color: theme.palette.colors.error
    },
    guestSection: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      maxHeight: "300px",
      overflowY: "auto"
    },
    option: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      boxSizing: "border-box",
      "&:last-child div": {
        borderBottomWidth: "0px"
      }
    },
    optionTitleContainer: {
      display: "flex",
      width: "50%",
      justifyContent: "space-between"
    },
    optionSubTitle: {
      fontSize: "16px",
      color: fade(theme.palette.colors.lightWhite, 0.6)
    },
    optionTitle: {
      fontSize: "16px",
      color: fade(theme.palette.colors.lightWhite, 0.6)
    },
    sitesContainer: {
      background: theme.gradient.default,
      width: "100%",
      minHeight: "fit-content !important",
      marginTop: "15px"
    },
    sitesContainerHeader: {
      padding: "10px 0",
      color: theme.palette.colors.white
    },
    sitesListContainer: {
      height: "90%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center"
    },
    siteFieldsetStyle: {
      border: `1px solid ${fade(theme.palette.colors.lightWhite, 0.3)}`,
      borderRadius: "4px",

      width: "100%",
      margin: "0 5px",
      marginBottom: "20px",
      boxSizing: "border-box"
    },

    collapseWrapperInnerStyle: {
      height: "100%"
    },

    unitsGroupsWrapper: {
      width: "100%",
      maxHeight: "inherit"
    },

    sectionTitleStyle: {
      width: "100%",
      color: theme.palette.colors.lightWhite,
      fontSize: "16px",
      marginTop: "15px"
    },

    list: {
      width: "100%",
      "& li": {
        height: "50px",
        margin: "auto",
        marginBottom: "15px",
        border: `1px solid ${fade(theme.palette.colors.lightWhite, 0.3)}`,
        borderRadius: "6px"
      },
      "& input": {
        width: "100%",
        paddingLeft: "5px",
        color: fade(theme.palette.colors.lightWhite, 0.6),
        background: theme.palette.colors.transparent,
        fontSize: "14px",
        fontWeight: "normal"
      },
      "& span": {
        width: "fit-content",
        fontFamily: "Roboto",
        fontSize: "16px",
        color: theme.palette.colors.lightWhite,
        fontWeight: "normal"
      }
    },
    siteContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      border: `1px solid ${fade(theme.palette.colors.lightWhite, 0.3)}`,
      borderRadius: "6px",
      margin: "10px",
      boxSizing: "border-box"
    },
    selectionRow: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      padding: "5px 13px",
      borderBottom: `1px solid ${fade(theme.palette.colors.lightWhite, 0.3)}`,
      boxSizing: "border-box",
      backgroundColor: fade(theme.palette.colors.white, 0.1)
    },
    siteNameStyle: {
      color: theme.palette.colors.lightWhite,
      fontSize: "16px",
      fontWeight: 500
    },
    unitNameStyle: {
      color: fade(theme.palette.colors.lightWhite, 0.6),
      fontSize: "16px"
    },
    hidden: {
      display: "none"
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      color: theme.palette.colors.white,
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest
      })
    },
    expandOpen: {
      transform: "rotate(-180deg)"
    },
    noBorder: {
      borderWidth: "0px"
    }
  });

import { createStyles } from "@material-ui/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";

export default (theme: any) =>
  createStyles({
    screenContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      background: theme.palette.colors.blackFontShadow
    },
    pageContainer: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      width: "100%",
      maxWidth: "768px"
    },
    pageContent: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      padding: "10px 5px",
      margin: "20px 0",
      overflowY: "auto"
    },
    lineContainer: {
      backgroundImage: theme.gradient.default,
      padding: "15px",
      margin: "10px 0",
      borderRadius: "4px"
    },
    collapseHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    normalText: {
      color: theme.palette.colors.white,
      fontSize: "16px"
    },
    labelText: {
      color: theme.palette.colors.lightWhite,
      fontSize: "13px",
      width: "25%"
    },
    unitContainer: {
      display: "flex",
      flexDirection: "column",
      margin: "30px 0",
      padding: "10px",
      border: `1px solid ${theme.palette.colors.white}`,
      borderRadius: "4px",
      backgroundColor: theme.palette.colors.whiteOpacity1
    },
    detailsRow: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      margin: "10px 0"
    },
    unitNameStyle: {
      "& *": {
        color: theme.palette.colors.white,
        borderColor: fade(theme.palette.colors.lightBorder, 0.3)
      }
    },
    nextBtn: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.colors.white,

      "&:active": {
        backgroundColor: "transparent",
        border: `1px solid ${theme.palette.secondary.main}`
      }
    },
    iconStyle: {
      fontSize: "20px"
    },
    cancelIcon: {
      fill: theme.palette.colors.error,
      marginLeft: "10px"
    }
  });

import { createStyles } from "@material-ui/core/styles";

const aboutUsStyle = (theme: any) =>
  createStyles({
    secreenContainer: {
      backgroundImage: theme.gradient.default,
      color: theme.palette.colors.white,
      display: "flex",
      flexDirection: "column",
      width: "100%"
    },
    container: {
      height: "100%",
      padding: "53px 17px 0px 26px",
      overflowY: "scroll",
      boxSizing: "border-box"
    },

    headers: {
      fontSize: "18px"
    },
    supportHeader: {
      fontSize: '18px',
      marginTop: "30px"
    },
    subtitles: {
      marginBottom: "36px",
      fontSize: "16px",
      opacity: "60%",
      color: theme.palette.colors.white
    }
  });

export default aboutUsStyle;

import { createStyles, fade } from "@material-ui/core/styles";

export default (theme: any) =>
  createStyles({
    filledRoot: {},
    filledInputRoot: {
      borderRadius: "6px",
      backgroundColor: `${fade(theme.palette.colors.white, 0.1)} !important`,
      "& input": {
        minHeight: "50px",
        height: "50px",
        padding: 0,
        paddingLeft: "15px",
        paddingRight: "15px",
        flex: 1
      },
      fontSize: "16px",
      color: theme.palette.colors.lightWhite,
      "& ::placeholder": {
        color: theme.palette.colors.lightWhite,
        opacity: 0.5,
        fontSize: "16px",
        lineHieght: "normal",
        fontWeight: "normal"
      }
    },
    filledLabelRoot: {
      color: fade(theme.palette.colors.lightWhite, 0.5),
      "&$shrink": {
        transform: "translate(15px, 2px) scale(0.75)",
        color: theme.palette.colors.lightWhite
      }
    },
    standardLabelRoot: {
      paddingLeft: "15px",
      color: fade(theme.palette.colors.lightWhite, 0.5),
      "&$shrink": {
        transform: "translate(5px, 10px) scale(0.75)",
        color: theme.palette.colors.lightWhite
      }
    },
    outlinedLabelRoot: {
      color: fade(theme.palette.colors.lightWhite, 0.5)
    },
    shrink: {
      color: theme.palette.colors.lightWhite
    },
    focused: { color: theme.palette.colors.lightWhite },
    disabled: { color: fade(theme.palette.colors.lightWhite, 0.5) },
    formControl: {
      "&$focused": { color: theme.palette.colors.lightWhite },
      "&$disabled": {
        color: fade(theme.palette.colors.lightWhite, 0.5)
      }
    },
    outlinedRoot: {
      borderRadius: "6px"
    },
    outlinedInputRoot: {
      "& input": {
        height: "50px",
        padding: 0,
        paddingLeft: "15px",
        paddingRight: "15px"
      },
      fontSize: "16px",
      color: theme.palette.colors.lightWhite,
      "& input:hover + fieldset": {
        "&$notchedOutline": {
          borderColor: fade(theme.palette.colors.lightWhite, 0.3)
        }
      },
      "& input:focus + fieldset": {
        "&$notchedOutline": {
          borderColor: fade(theme.palette.colors.lightWhite, 0.3)
        }
      },
      "& input:disabled + fieldset": {
        "&$notchedOutline": {
          borderColor: fade(theme.palette.colors.lightWhite, 0.3)
        }
      },
      "& ::placeholder": {
        color: theme.palette.colors.lightWhite,
        opacity: 0.5,
        fontSize: "16px",
        lineHieght: "normal",
        fontWeight: "normal"
      }
    },
    notchedOutline: {
      borderColor: fade(theme.palette.colors.lightWhite, 0.3)
    },
    disabledInput: {
      color: fade(theme.palette.colors.lightWhite, 0.5)
    },
    underline: {
      "&:after": { borderColor: fade(theme.palette.colors.lightWhite, 0.3) },
      "&:before": { borderColor: fade(theme.palette.colors.lightWhite, 0.3) }
    },
    errorHeplerText: { marginTop: "5px", marginLeft: "15px" },
    formControlNoLabel: {
      "&$focused": { color: theme.palette.colors.lightWhite },
      "&$disabled": {
        color: fade(theme.palette.colors.lightWhite, 0.5)
      }
    }
  });

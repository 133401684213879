import {
  Button,
  ButtonGroup,
  Collapse,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Typography
} from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { ExpandMore } from "@material-ui/icons";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import * as brandsLogos from "../../brands";
import { Toolbar } from "../../components";
import { ConfirmationDialog } from "../../components/";
import { useStoreActions, useStoreState } from "../../Stores/typedHooks";
import {
  AddIcon,
  Alert,
  ArrowBack,
  ColdMode,
  Delete,
  DisconnectIcon,
  Edit,
  HotMode,
  OverflowMenu,
  SmallPlus,
  SystemOff
} from "../../svgComponents";
import styles, { paperStyle } from "./site.style";

interface SiteProps {
  history: any;
  match: any;
}
interface ISelectedItemToDeleteType {
  itemName: string;
  itemId: string;
}

const Site: React.FC<SiteProps> = ({
  history,
  match: {
    params: { siteId: urlSiteId }
  }
}) => {
  const [refreshData, toggleRefreshData] = useState<boolean>(false);
  const [selectedSite, setSelectedSite] = useState<string>(urlSiteId);
  const [openSite, setOpenSite] = useState<boolean>(false);
  const [devices, setDevices] = useState<any>({});
  const [systems, setSystems] = useState<any>({});
  const [units, setUnits] = useState<any>({});
  const [selectedSystem, setSelectedSystem] = useState<any>(null);
  const [selectedDevice, setSelectedDevice] = useState<any>(null);
  const [
    anchorElDevice,
    setAnchorElDevice
  ] = React.useState<null | HTMLElement>(null);
  const [anchorElSite, setAnchorElSite] = React.useState<null | HTMLElement>(
    null
  );
  const [
    anchorElSystem,
    setAnchorElSystem
  ] = React.useState<null | HTMLElement>(null);

  const [deleteDialog, setDeleteDialog] = useState<boolean>(false);
  const [selectedItemToDelete, setSelectedItemToDelete] = useState<
    ISelectedItemToDeleteType
  >({
    itemName: "",
    itemId: ""
  });

  const user = useStoreState((state) => state.userStore.user);
  const sites = useStoreState((state) => state.siteStore.userSites);
  const types = useStoreState((state) => state.typesStore.types);
  const brandsMap = useStoreState((state) => state.typesStore.brandsMap);
  const socketMessages = useStoreState((state) => state.siteStore.socketMessages);
  const operationModesMirror = useStoreState(
    (state) => state.typesStore.operationModesMirror
  );

  const getSites = useStoreActions((state) => state.siteStore.getUserSites);
  const deleteSelectedSite = useStoreActions(
    (state) => state.siteStore.deleteUserSite
  );
  const startLoader = useStoreActions(
    (actions) => actions.loaderStore.startLoader
  );
  const finishLoader = useStoreActions(
    (actions) => actions.loaderStore.finishLoader
  );
  const addMessage = useStoreActions(
    (actions) => actions.messageStore.addMessage
  );
  const getDevices = useStoreActions(
    (actions) => actions.deviceStore.getUserDevices
  );
  const getSystems = useStoreActions((actions) => actions.systemStore.getSystems);
  const deleteDevice = useStoreActions(
    (actions) => actions.deviceStore.deleteDevice
  );
  const getUnits = useStoreActions((actions) => actions.unitStore.getUserUnits);
  const updateSystemOperationMode = useStoreActions(
    (actions) => actions.systemStore.updateSystemOperationMode
  );
  const deleteSystem = useStoreActions(
    (actions) => actions.systemStore.deleteSystem
  );
  const deleteSite = useStoreActions((actions) => actions.siteStore.deleteSite);

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const openDeviceMenu = Boolean(anchorElDevice);
  const openSiteMenu = Boolean(anchorElSite);
  const openSystemMenu = Boolean(anchorElSystem);
  let sitesArray = Object.values(sites);
  const unitsArray = Object.values(units);
  const { unitTypes } = types;
  const { customer } = user;

  useEffect(() => {
    if (_.isEmpty(socketMessages)) {
      return;
    }

    const { item, status, unitId, deviceId } = socketMessages;

    if (item === "device" && !_.isEmpty(devices)) {
      if (!devices[deviceId]) {
        return;
      }

      if (status && !devices[deviceId].isConnected) {
        setDevices({
          ...devices,
          [deviceId]: { ...devices[deviceId], isConnected: true }
        });
        return;
      }

      if (!status && devices[deviceId].isConnected) {
        setDevices({
          ...devices,
          [deviceId]: { ...devices[deviceId], isConnected: false }
        });
      }

      return;
    }

    if (item === "unit" && !_.isEmpty(units)) {
      if (!units[unitId]) {
        return;
      }

      if (status && !units[unitId].isConnected) {
        setUnits({
          ...units,
          [unitId]: { ...units[unitId], isConnected: true }
        });
        return;
      }

      if (!status && units[unitId].isConnected) {
        setUnits({
          ...units,
          [unitId]: { ...units[unitId], isConnected: false }
        });
      }
    }
  }, [devices, socketMessages, units]);

  useEffect(() => {
    startLoader();
    getSites().finally(finishLoader());
  }, [finishLoader, getSites, startLoader]);

  useEffect(() => {
    startLoader();
    Promise.all([getDevices(), getSystems(), getUnits()])
      .then((res: any) => {
        setDevices(res[0]);
        setSystems(res[1]);
        setUnits(res[2]);
      })
      .then(() => finishLoader());
  }, [
    finishLoader,
    getDevices,
    getSystems,
    getUnits,
    startLoader,
    refreshData
  ]);

  const goBack = () => {
    history.push({ pathname: "/site", menuOpen: true });
  };

  const handleCollapse = (siteId: string) => {

    if (selectedSite === siteId && openSite === true) {
      setOpenSite(false);
      setSelectedSite(siteId);
      return;

    }
    if (selectedSite === siteId && openSite === false) {

      setOpenSite(true);
      return;

    }
    setOpenSite(true);
    setSelectedSite(siteId);
  };

  const deleteSelectedDevice = (deviceId: string) => {
    handleClose();
    startLoader();

    deleteDevice(deviceId)
      .then(() => {
        delete devices[deviceId];
        setDevices(devices);
      })
      .catch((err: any) => {
        addMessage({
          message: err.message
        });
      })
      .finally(() => {
        finishLoader();
      });
  };

  const changeSystemOperationMode = (systemId: string, state: number) => {
    updateSystemOperationMode({ systemId, state }).catch((err: any) =>
      addMessage({
        message: err.message
      })
    );
  };

  const siteMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    siteId: string
  ) => {
    setSelectedSite(siteId);
    setAnchorElSite(event.currentTarget);
  };

  const deviceMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    deviceId: string
  ) => {
    if (selectedDevice !== deviceId) {
      setSelectedDevice(deviceId);
    }
    setAnchorElDevice(event.currentTarget);
  };

  const systemMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    systemId: string
  ) => {
    if (selectedSystem !== systemId) {
      setSelectedSystem(systemId);
    }
    setAnchorElSystem(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElDevice(null);
    setAnchorElSite(null);
    setAnchorElSystem(null);
  };

  const moveToSiteInfoPage = (siteId: string) => {
    history.push(`/site/${siteId}`);
  };

  const addNewDevice = (siteId: string) => {
    const customerId = user.customer;
    history.push(`/device-registration/${siteId}/user-selection/${customerId}`);
  };
  const addNewSystem = (deviceId: string) => {
    history.push(`/site-management/${deviceId}/add-edit-system/`);
  };

  const editSystem = (deviceId: string, systemId: string) => {
    handleClose();
    history.push(`/site-management/${deviceId}/add-edit-system/${systemId}`);
  };
  const removeSystem = (systemId: string) => {
    handleClose();

    startLoader();
    toggleRefreshData(!refreshData);
    deleteSystem(systemId)
      .catch((err: any) =>
        addMessage({
          message: err.message
        })
      )
      .finally(() => finishLoader());
  };
  const removeSite = (siteId: string) => {
    startLoader();
    deleteSite(siteId)
      .then(() => {
        deleteSelectedSite(siteId);
      })
      .catch((err: any) =>
        addMessage({
          message: err.message
        })
      )
      .finally(() => finishLoader());
  };
  const changeDeleteDialogState = (...theArgs: any) => {
    handleClose();
    setDeleteDialog(theArgs[0]);
    setSelectedItemToDelete(theArgs[1]);
  };

  const removeItemSelector = () => {
    changeDeleteDialogState(false);
    const { itemName, itemId } = selectedItemToDelete;

    if (itemName === "site") {
      removeSite(itemId);
      return;
    } else if (itemName === "system") {
      removeSystem(itemId);
      return;
    }
    deleteSelectedDevice(itemId);
  };

  const goToAddSitePAge = () => {
    history.push(`/site-management/customer/${customer}/add-site`);
  };

  const brandsLogosObj: any = brandsLogos;

  return (
    <div className={classes.screenContainer}>
      <div className={classes.gridStyle}>
        <Toolbar
          title={t`Site Management`}
          leftIconComponent={<ArrowBack />}
          leftAction={goBack}
          rightIconComponent={customer ? <AddIcon /> : undefined}
          rightAction={customer ? goToAddSitePAge : undefined}
        />
        <div className={classes.sitesContainer}>
          <List className={classes.listStyle}>
            {sitesArray.map((site: any, i: number) => {
              let {
                id: siteId,
                name,
                address,
                city,
                state = "",
                country,
                postalCode = "",
                devices: siteDevices
              } = site;

              if (site.country !== "US") {
                state = ""
              }

              return (
                <ListItem
                  key={`site-item-${siteId}`}
                  className={classes.listItemStyle}
                >
                  <div className={classes.siteNameContainer}>
                    <ListItemText
                      primary={name}
                      classes={{ primary: classes.listItemTextStyle }}
                    />
                    <div className={classes.iconHolderStyle}>
                      <IconButton color="primary"
                        id={`site-overflow-menu-${siteId}`}
                        component="span"
                        onClick={(e: any) => siteMenuClick(e, siteId)}
                        className={classes.iconBtn}>
                        <OverflowMenu />
                      </IconButton>
                      <Menu
                        id="site-menu"
                        anchorEl={anchorElSite}
                        keepMounted
                        open={selectedSite === siteId && !_.isNull(anchorElSite)}
                        onClose={handleClose}
                        className={classes.menuStyle}
                        PaperProps={{
                          style: paperStyle
                        }}
                      >
                        <MenuItem
                          onClick={() => addNewDevice(siteId)}
                          className={classes.menuItemStyle}
                        >
                          <SmallPlus
                            id={`add-device-${siteId}`}
                            className={classes.plusIconStyle}
                          />
                          {t`Add Device`}
                        </MenuItem>
                        <MenuItem
                          onClick={() => moveToSiteInfoPage(siteId)}
                          className={classes.menuItemStyle}
                        >
                          <Edit
                            id={`site-${siteId}`}
                            className={classes.editIcon}
                          />
                          {t`Edit Site`}
                        </MenuItem>
                        <MenuItem
                          key={`site-menu-delete-${siteId}`}
                          onClick={() =>
                            changeDeleteDialogState(true, {
                              itemName: "site",
                              itemId: siteId
                            })
                          }
                          className={classes.menuItemStyle}
                        >
                          <Delete
                            id={`delete-site-${siteId}`}
                            className={classes.deleteIconStyle}
                          />
                          {t`Delete Site`}
                        </MenuItem>
                      </Menu>
                      <IconButton
                        id={`site-button-arrow-down-${siteId}`}
                        onClick={() => handleCollapse(siteId)}
                        className={
                          selectedSite === siteId && openSite
                            ? classes.expandOpen
                            : classes.expand
                        }
                      >
                        <ExpandMore id={`site-overflow-menu-${siteId}`} />
                      </IconButton>
                    </div>
                  </div>
                  <Collapse
                    in={selectedSite === siteId && openSite}
                    timeout="auto"
                    unmountOnExit
                    className={classes.collapseStyle}
                  >
                    <div className={classes.siteFirstRow}>
                      <Typography
                        className={classes.typoStyle}
                        display="inline"
                      >
                        {`${address}, ${city}, ${state}${state &&
                          ", "}${country}${postalCode && ", "}${postalCode}`}
                      </Typography>
                    </div>
                    {siteDevices.map(
                      (deviceId: string, deviceIndex: number) => {
                        const currentDevice = devices[deviceId];
                        if (!currentDevice) {
                          return null;
                        }
                        const { name, serviceSystems: deviceSystems } = currentDevice;
                        const notConnectedUnits = unitsArray.filter(
                          (unit: any) =>
                            unit.device === deviceId && !unit.system
                        ).length;
                        const deviceIndoorUnitsArray = unitsArray.filter(
                          (unit: any) =>
                            unit.device === deviceId &&
                            unit.type === unitTypes.indoor
                        );
                        const deviceIndoorUnits = deviceIndoorUnitsArray.length;
                        const deviceOutdoorunitsArray = unitsArray.filter(
                          (unit: any) =>
                            unit.device === deviceId &&
                            unit.type === unitTypes.outdoor
                        );
                        const deviceOutdoorunits =
                          deviceOutdoorunitsArray.length;
                        const disconnectedOutdoorunits = deviceOutdoorunitsArray.filter(
                          (unit: any) => !unit.isConnected
                        ).length;
                        const disconnectedIndoorunits = deviceIndoorUnitsArray.filter(
                          (unit: any) => !unit.isConnected
                        ).length;

                        const isDeviceDisconnected = !currentDevice.isConnected;

                        return (
                          <div
                            key={`device-item-${siteId}-${deviceId}`}
                            className={classes.deviceCard}
                          >
                            <div
                              className={clsx(
                                classes.deviceInfoFontStyle,
                                classes.deviceNameContainerStyle
                              )}
                            >
                              <Typography className={classes.deviceTypoStyle}>
                                CoolMasterNet {name}
                              </Typography>
                              {isDeviceDisconnected && (
                                <DisconnectIcon
                                  className={
                                    classes.disconnectedDeviceIconStyle
                                  }
                                />
                              )}

                              <Grid>
                                <IconButton color="primary" aria-label="upload picture" component="span" id="device-overflow-menu"
                                  className={classes.menuIconStyle}
                                  onClick={(e: any) =>
                                    deviceMenuClick(e, deviceId)
                                  }
                                  disableTouchRipple={true}>
                                  <OverflowMenu />
                                </IconButton>
                                <Menu
                                  id="device-menu"
                                  anchorEl={anchorElDevice}
                                  keepMounted
                                  open={
                                    openDeviceMenu &&
                                    selectedDevice === deviceId
                                  }
                                  onClose={handleClose}
                                  PaperProps={{
                                    style: paperStyle
                                  }}
                                  className={classes.menuStyle}
                                >
                                  <MenuItem
                                    onClick={() => addNewSystem(deviceId)}
                                    className={classes.menuItemStyle}
                                  >
                                    <SmallPlus
                                      id={`system-${deviceId}`}
                                      className={classes.plusIconStyle}
                                    />
                                    Add System
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() =>
                                      changeDeleteDialogState(true, {
                                        itemName: "device",
                                        itemId: deviceId
                                      })
                                    }
                                    className={classes.menuItemStyle}
                                  >
                                    <Delete
                                      id={`delete-device-${deviceId}`}
                                      className={classes.deleteIconStyle}
                                    />
                                    Delete
                                  </MenuItem>
                                </Menu>
                              </Grid>
                            </div>
                            <div className={classes.deviceInfoFontStyle}>
                              <Typography className={classes.deviceTypoStyle}>
                                {deviceOutdoorunits} {t`Outdoor units`}
                                {disconnectedOutdoorunits !== 0 && (
                                  <span className={classes.redText}>
                                    {" "}
                                    {disconnectedOutdoorunits} {t`Disconnected`}
                                  </span>
                                )}
                              </Typography>
                            </div>
                            <div className={classes.deviceInfoFontStyle}>
                              <Typography className={classes.deviceTypoStyle}>
                                {deviceIndoorUnits} {t`Indoor units`}
                                {disconnectedIndoorunits !== 0 && (
                                  <span className={classes.redText}>
                                    {" "}
                                    {disconnectedIndoorunits} {t`Disconnected`}
                                  </span>
                                )}
                              </Typography>
                            </div>
                            {notConnectedUnits !== 0 && (
                              <div
                                className={clsx(
                                  classes.deviceInfoFontStyle,
                                  classes.warningContainerStyle
                                )}
                              >
                                <Typography className={classes.deviceTypoStyle}>
                                  {notConnectedUnits} {t`NOT Assigned`}
                                </Typography>
                                <Alert className={classes.alertPadding} />
                              </div>
                            )}
                            {/*system card*/}
                            <div className={classes.systemsContainer}>
                              {deviceSystems &&
                                deviceSystems.map(
                                  (systemId: string, systemIndex: number) => {
                                    const currentSystem = systems[systemId];
                                    if (!currentSystem) {
                                      return null;
                                    }

                                    const {
                                      name,
                                      brand,
                                      id: currentSystemId
                                    } = currentSystem;

                                    const brandName = brandsMap[brand];
                                    const brandLogo = brandName
                                      ? brandsLogosObj[
                                      brandName
                                        .toUpperCase()
                                        .replace(" ", "_")
                                      ]
                                      : !!brand
                                        ? brandsLogosObj[brand] ||
                                        brandsLogosObj[brand.toUpperCase()]
                                        : "";
                                    const systemIndoorUnits = unitsArray.filter(
                                      (unit: any) =>
                                        unit.system === systemId &&
                                        unit.type === unitTypes.indoor
                                    ).length;
                                    const systemOutdoorunits = unitsArray.filter(
                                      (unit: any) =>
                                        unit.system === systemId &&
                                        unit.type === unitTypes.outdoor
                                    ).length;
                                    const isSystemDisconnected =
                                      unitsArray.filter(
                                        (unit: any) =>
                                          unit.system === systemId &&
                                          !unit.isConnected
                                      ).length > 0
                                        ? true
                                        : false;

                                    return (
                                      <div
                                        key={`system-item-${deviceId}-${currentSystemId}`}
                                        className={classes.systemCard}
                                      >
                                        <Grid
                                          container
                                          className={
                                            classes.systemInfoContainer
                                          }
                                        >
                                          <div
                                            className={
                                              classes.systemNameContainer
                                            }
                                          >
                                            <Typography
                                              className={
                                                classes.systemNameStyle
                                              }
                                            >
                                              {name}
                                            </Typography>
                                            {isSystemDisconnected && (
                                              <DisconnectIcon
                                                className={
                                                  classes.disconnectedIconStyle
                                                }
                                              />
                                            )}
                                          </div>
                                          <div
                                            id={`system-menu-container-${systemId}`}
                                          >
                                            <IconButton color="primary" aria-label="upload picture" component="span" onClick={(e: any) =>
                                              systemMenuClick(e, systemId)
                                            }
                                              className={
                                                classes.systemMenuIconStyle
                                              }
                                              disableTouchRipple={true}>
                                              <OverflowMenu />
                                            </IconButton>
                                            <OverflowMenu
                                              onClick={(e: any) =>
                                                systemMenuClick(e, systemId)
                                              }
                                              className={
                                                classes.systemMenuIconStyle
                                              }
                                            />

                                            <Menu
                                              id={`menu-system-${selectedSystem}`}
                                              key={`menu-${systemId}`}
                                              anchorEl={anchorElSystem}
                                              keepMounted
                                              open={
                                                openSystemMenu &&
                                                systemId === selectedSystem
                                              }
                                              onClose={handleClose}
                                              PaperProps={{
                                                style: paperStyle
                                              }}
                                            >
                                              <MenuItem
                                                onClick={() =>
                                                  editSystem(
                                                    deviceId,
                                                    currentSystemId
                                                  )
                                                }
                                                className={
                                                  classes.menuItemStyle
                                                }
                                              >
                                                <Edit
                                                  id={`system-${currentSystemId}`}
                                                  className={classes.editIcon}
                                                />
                                                {t`Edit System`}
                                              </MenuItem>
                                              <MenuItem
                                                onClick={() =>
                                                  changeDeleteDialogState(
                                                    true,
                                                    {
                                                      itemName: "system",
                                                      itemId: currentSystemId
                                                    }
                                                  )
                                                }
                                                className={
                                                  classes.menuItemStyle
                                                }
                                              >
                                                <Delete
                                                  id={`delete-system-${systemId}`}
                                                  className={
                                                    classes.deleteIconStyle
                                                  }
                                                />
                                                {t`Delete System`}
                                              </MenuItem>
                                            </Menu>
                                          </div>
                                        </Grid>
                                        {brand && (
                                          <img
                                            src={brandLogo}
                                            className={classes.brandImageStyle}
                                            alt="brand"
                                          />
                                        )}
                                        <Typography
                                          className={classes.systemTypoStyle}
                                        >
                                          {systemOutdoorunits} {t`Outdoor`}{" "}
                                          {systemIndoorUnits} {t`Indoor`}
                                        </Typography>
                                        <div
                                          className={
                                            classes.systemControlsContainer
                                          }
                                        >
                                          <div
                                            className={
                                              classes.systemControlSecondRow
                                            }
                                          >
                                            <ButtonGroup
                                              size="small"
                                              className={
                                                classes.groupButtonStyle
                                              }
                                            >
                                              <Button
                                                className={
                                                  classes.coldModeButtonStyle
                                                }
                                                onClick={() =>
                                                  changeSystemOperationMode(
                                                    systemId,
                                                    operationModesMirror.COOL
                                                  )
                                                }
                                              >
                                                <ColdMode
                                                  className={
                                                    classes.modesIconStyle
                                                  }
                                                />
                                              </Button>
                                              <Button
                                                className={
                                                  classes.heatModeButtonStyle
                                                }
                                                onClick={() =>
                                                  changeSystemOperationMode(
                                                    systemId,
                                                    operationModesMirror.HEAT
                                                  )
                                                }
                                              >
                                                <HotMode
                                                  className={
                                                    classes.modesIconStyle
                                                  }
                                                />
                                              </Button>
                                            </ButtonGroup>

                                            <SystemOff
                                              onClick={() => { }}
                                              className={
                                                classes.systemPowerButton
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                            </div>
                          </div>
                        );
                      }
                    )}
                  </Collapse>
                </ListItem>
              );
            })}
          </List>
        </div>
        <ConfirmationDialog
          onCancel={() => changeDeleteDialogState(false)}
          onConfrim={() => removeItemSelector()}
          title={"Delete Confirmation"}
          text={"Are you sure you want to delete ?"}
          openDialog={deleteDialog}
          cancelLabel={"No"}
          confrimLabel={"Delete"}
          onClose={() => changeDeleteDialogState(false)}
          dialogType="error"
        />
      </div>
    </div>
  );
};

export default Site;

import React from "react";
import { Field } from "formik";
import { TextField } from "../TextField";

interface IFormikFieldProps {
  formikProps: any;
  name: string;
  inputClass?: any;
  className?: string;
  placeholder?: string;
  label?: string;
  type?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  value?: any;
  isFeildArray?: boolean;
  labelClass?: any;
  disableUnderline?: boolean;
  errorStyle?: string;
  classes?: any;
  inputRoot?: any;
  showErrorOutside?: boolean;
  containerClass?: any;
  variant?: any;
  iconComponent?: any;
  wrapperErrorClass?: any;
  autoComplete?: string;
  endAdornment?: any;
  margin?: "normal" | "none" | "dense" | undefined;
}

const FormikField: React.SFC<IFormikFieldProps> = ({
  formikProps,
  name,
  inputClass,
  className,
  labelClass,
  errorStyle,
  placeholder,
  label,
  type = "text",
  disabled = false,
  fullWidth = true,
  value = null,
  isFeildArray = false,
  disableUnderline = false,
  inputRoot,
  showErrorOutside = false,
  containerClass,
  variant = "outlined",
  iconComponent,
  wrapperErrorClass,
  endAdornment,
  margin,

  ...props
}) => {
  const getFieldValue = (form: any) =>
    isFeildArray ? value : form.values[name];
  return (
    <Field
      validateOnBlur
      validateOnChange
      name={name}
      render={(fieldProps: any) => {
        const { form } = fieldProps;
        const error = form.errors[name];
        const hasError = !!error;
        const isTouched = !!form.touched[name];

        return (
          <TextField
            variant={variant}
            name={name}
            label={label}
            placeholder={placeholder}
            margin={margin}
            value={getFieldValue(form) || ""}
            onChange={formikProps.handleChange}
            onBlur={formikProps.handleBlur}
            type={type}
            disabled={disabled}
            error={hasError && isTouched && String(error)}
            className={className}
            endAdornment={endAdornment}
            disableUnderline={disableUnderline}
            {...props}
          />
        );
      }}
    />
  );
};

export default FormikField;

import React from "react";

const SvgAppLogo = props => (
  <svg width={270} height={262} viewBox={`0 0 270 262`} {...props}>
    <defs>
      <linearGradient
        id="app-logo_svg__O"
        x1="-14.601%"
        x2="695.988%"
        y1="49.891%"
        y2="49.891%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__a"
        x1="-568.16%"
        x2="111.666%"
        y1="49.891%"
        y2="49.891%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__c"
        x1="-626.202%"
        x2="121.558%"
        y1="50.045%"
        y2="50.045%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__d"
        x1="-683.47%"
        x2="133.47%"
        y1="50%"
        y2="50%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__e"
        x1="-759.588%"
        x2="151.443%"
        y1="50%"
        y2="50%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__f"
        x1="-845.929%"
        x2="175.769%"
        y1="49.969%"
        y2="49.969%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__g"
        x1="-924.814%"
        x2="205.175%"
        y1="50%"
        y2="50%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__h"
        x1="-980.145%"
        x2="236.999%"
        y1="50.027%"
        y2="50.027%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__i"
        x1="-1000.836%"
        x2="268.658%"
        y1="49.975%"
        y2="49.975%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__j"
        x1="-989.338%"
        x2="298.102%"
        y1="50.022%"
        y2="50.022%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__k"
        x1="-952.524%"
        x2="324.599%"
        y1="50%"
        y2="50%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__l"
        x1="-903.222%"
        x2="349.312%"
        y1="50.017%"
        y2="50.017%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__m"
        x1="-856.436%"
        x2="375.763%"
        y1="50%"
        y2="50%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__n"
        x1="-828.255%"
        x2="410.649%"
        y1="50.013%"
        y2="50.013%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__o"
        x1="-829.202%"
        x2="461.887%"
        y1="50%"
        y2="50%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__p"
        x1="-869.129%"
        x2="540.734%"
        y1="49.99%"
        y2="49.99%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__q"
        x1="-963.99%"
        x2="666.547%"
        y1="50.01%"
        y2="50.01%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__r"
        x1="-1146.159%"
        x2="877.778%"
        y1="49.926%"
        y2="49.926%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__s"
        x1="-1496.704%"
        x2="1267.997%"
        y1="50.018%"
        y2="50.018%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__t"
        x1="-2265.978%"
        x2="2124.793%"
        y1="50%"
        y2="50%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__u"
        x1="-3329.247%"
        x2="3526.022%"
        y1="50%"
        y2="50%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__v"
        x1="-1715.986%"
        x2="2115.385%"
        y1="50%"
        y2="50%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__w"
        x1="-1148.249%"
        x2="1643.082%"
        y1="50%"
        y2="50%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__x"
        x1="-869.493%"
        x2="1440.435%"
        y1="50.012%"
        y2="50.012%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__y"
        x1="-709.663%"
        x2="1357.588%"
        y1="50%"
        y2="50%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__z"
        x1="-607.514%"
        x2="1339.768%"
        y1="50.016%"
        y2="50.016%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__A"
        x1="-534.501%"
        x2="1358.432%"
        y1="49.982%"
        y2="49.982%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__B"
        x1="-473.86%"
        x2="1390.292%"
        y1="50.021%"
        y2="50.021%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__C"
        x1="-414.539%"
        x2="1410.132%"
        y1="50%"
        y2="50%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__D"
        x1="-356.389%"
        x2="1414.556%"
        y1="50.025%"
        y2="50.025%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__E"
        x1="-295.737%"
        x2="1382%"
        y1="49.973%"
        y2="49.973%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__F"
        x1="-239.921%"
        x2="1331.148%"
        y1="50.084%"
        y2="50.084%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__G"
        x1="-190.265%"
        x2="1266.636%"
        y1="49.971%"
        y2="49.971%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__H"
        x1="-147.207%"
        x2="1191.6%"
        y1="50.122%"
        y2="50.122%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__I"
        x1="-111.073%"
        x2="1110.268%"
        y1="50%"
        y2="50%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__J"
        x1="-81.698%"
        x2="1027.453%"
        y1="50%"
        y2="50%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__K"
        x1="-58.499%"
        x2="946.768%"
        y1="49.962%"
        y2="49.962%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__L"
        x1="-41.055%"
        x2="872.85%"
        y1="50%"
        y2="50%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__M"
        x1="-28.359%"
        x2="804.809%"
        y1="50%"
        y2="50%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__N"
        x1="-19.736%"
        x2="744.7%"
        y1="50%"
        y2="50%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__b"
        x1="-588.313%"
        x2="114.598%"
        y1="50%"
        y2="50%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__P"
        x1="-12.384%"
        x2="657.864%"
        y1="49.939%"
        y2="49.939%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__Q"
        x1="-12.047%"
        x2="625.876%"
        y1="50.245%"
        y2="50.245%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__R"
        x1="-13.855%"
        x2="602.126%"
        y1="50.03%"
        y2="50.03%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__S"
        x1="-18.018%"
        x2="585.942%"
        y1="50%"
        y2="50%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__T"
        x1="-24.613%"
        x2="577.522%"
        y1="50%"
        y2="50%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__U"
        x1="-33.907%"
        x2="576.062%"
        y1="50%"
        y2="50%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__V"
        x1="-46.308%"
        x2="581.315%"
        y1="50%"
        y2="50%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__W"
        x1="-62.503%"
        x2="593.538%"
        y1="49.934%"
        y2="49.934%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__X"
        x1="-84.695%"
        x2="619.302%"
        y1="49.963%"
        y2="49.963%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__Y"
        x1="-116.957%"
        x2="666.454%"
        y1="49.952%"
        y2="49.952%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__Z"
        x1="-164.601%"
        x2="741.769%"
        y1="49.976%"
        y2="49.976%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__aa"
        x1="-237.251%"
        x2="858.179%"
        y1="50%"
        y2="50%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__ab"
        x1="-346.475%"
        x2="1023.99%"
        y1="50%"
        y2="50%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__ac"
        x1="-508.77%"
        x2="1249.476%"
        y1="49.984%"
        y2="49.984%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__ad"
        x1="-740.614%"
        x2="1534.69%"
        y1="50%"
        y2="50%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__ae"
        x1="-1052.516%"
        x2="1863.952%"
        y1="49.979%"
        y2="49.979%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__af"
        x1="-1454.902%"
        x2="2221.799%"
        y1="50.111%"
        y2="50.111%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__ag"
        x1="-1987.246%"
        x2="2632.609%"
        y1="49.977%"
        y2="49.977%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__ah"
        x1="-2816.35%"
        x2="3243.916%"
        y1="50%"
        y2="50%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__ai"
        x1="-3763.593%"
        x2="3772.34%"
        y1="50.18%"
        y2="50.18%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__aj"
        x1="-2804.992%"
        x2="2499.002%"
        y1="49.979%"
        y2="49.979%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__ak"
        x1="-2191.369%"
        x2="1739.211%"
        y1="50%"
        y2="50%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__al"
        x1="-1759.811%"
        x2="1247.453%"
        y1="50.018%"
        y2="50.018%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__am"
        x1="-1441.938%"
        x2="915.828%"
        y1="49.983%"
        y2="49.983%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__an"
        x1="-1206.243%"
        x2="688.942%"
        y1="49.984%"
        y2="49.984%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__ao"
        x1="-1032.237%"
        x2="531.894%"
        y1="49.985%"
        y2="49.985%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__ap"
        x1="-903.574%"
        x2="421.322%"
        y1="50%"
        y2="50%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__aq"
        x1="-808.117%"
        x2="341.847%"
        y1="50%"
        y2="50%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__ar"
        x1="-739.512%"
        x2="284.484%"
        y1="50%"
        y2="50%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__as"
        x1="-689.6%"
        x2="241.659%"
        y1="50%"
        y2="50%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__at"
        x1="-650.458%"
        x2="208.297%"
        y1="50%"
        y2="50%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__au"
        x1="-617.423%"
        x2="181.7%"
        y1="50%"
        y2="50%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__av"
        x1="-590.549%"
        x2="160.735%"
        y1="49.982%"
        y2="49.982%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__aw"
        x1="-570.247%"
        x2="144.484%"
        y1="50%"
        y2="50%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__ax"
        x1="-555.728%"
        x2="132.017%"
        y1="50%"
        y2="50%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__ay"
        x1="-547.78%"
        x2="122.891%"
        y1="50%"
        y2="50%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__az"
        x1="-545.617%"
        x2="116.556%"
        y1="49.965%"
        y2="49.965%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__aA"
        x1="-549.066%"
        x2="112.832%"
        y1="50%"
        y2="50%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
      <linearGradient
        id="app-logo_svg__aB"
        x1="-557.98%"
        x2="111.424%"
        y1="50%"
        y2="50%"
      >
        <stop offset="0%" stopColor="#F7B132" />
        <stop offset="37%" stopColor="#BC2026" />
        <stop offset="63%" stopColor="#2F2F81" />
        <stop offset="99%" stopColor="#38A7DF" />
      </linearGradient>
    </defs>
    <g fill="none">
      <path
        fill="url(#app-logo_svg__a)"
        d="M269 124c-.079 1.333-.14 2.667-.236 4l-40.633-1.194c-.061-.941-.087-1.874-.131-2.806h41z"
      />
      <path
        fill="url(#app-logo_svg__b)"
        d="M269 134.065c-.22 1.314-.467 2.612-.714 3.935l-39.207-4.17c0-.95-.07-1.89-.079-2.83l40 3.065z"
      />
      <path
        fill="url(#app-logo_svg__c)"
        d="M266 145.05a120.826 120.826 0 01-1.154 3.95L229 141.994V139l37 6.05z"
      />
      <path
        fill="url(#app-logo_svg__d)"
        d="M263 155.27a231.162 231.162 0 01-1.551 3.73L229 150c0-1.006.078-2.012.13-3l33.87 8.27z"
      />
      <path
        fill="url(#app-logo_svg__e)"
        d="M259 164.596L257.157 168 228 157.908l.39-2.908z"
      />
      <path
        fill="url(#app-logo_svg__f)"
        d="M253 172.746L251.044 176 226 164.862l.727-2.862z"
      />
      <path
        fill="url(#app-logo_svg__g)"
        d="M248 181.836a171.818 171.818 0 00-2.083 3.164L223 172.776c.399-.923.78-1.854 1.152-2.776L248 181.836z"
      />
      <path
        fill="url(#app-logo_svg__h)"
        d="M243 189.91c-.711 1.033-1.44 2.057-2.134 3.09L220 179.582c.536-.86 1.036-1.721 1.546-2.582L243 189.91z"
      />
      <path
        fill="url(#app-logo_svg__i)"
        d="M237 198.865c-.753 1.033-1.472 2.093-2.208 3.135L215 186.423c.63-.81 1.28-1.604 1.892-2.423L237 198.865z"
      />
      <path
        fill="url(#app-logo_svg__j)"
        d="M231 206.92c-.8 1.015-1.564 2.048-2.346 3.08L209 192.127c.746-.703 1.51-1.406 2.24-2.127L231 206.92z"
      />
      <path
        fill="url(#app-logo_svg__k)"
        d="M225 214.045c-.829.974-1.622 1.973-2.442 2.955L203 196.723c.829-.577 1.675-1.137 2.503-1.723L225 214.045z"
      />
      <path
        fill="url(#app-logo_svg__l)"
        d="M219 222.013L216.467 225 197 200.397c.864-.448 1.729-.931 2.654-1.397L219 222.013z"
      />
      <path
        fill="url(#app-logo_svg__m)"
        d="M212 230.141L209.173 233 189 204.063l2.845-1.063z"
      />
      <path
        fill="url(#app-logo_svg__n)"
        d="M204 237.31l-3.12 2.69L181 205.82l2.887-.82z"
      />
      <path
        fill="url(#app-logo_svg__o)"
        d="M196 244.65l-3.404 2.35L174 208.653l2.825-.653z"
      />
      <path
        fill="url(#app-logo_svg__p)"
        d="M187 250.052c-1.203.664-2.433 1.302-3.662 1.948L167 209.559c.885-.192 1.77-.393 2.742-.559L187 250.052z"
      />
      <path
        fill="url(#app-logo_svg__q)"
        d="M177 254.518c-1.27.5-2.548 1-3.835 1.482L160 210.544c.87-.193 1.74-.369 2.652-.544L177 254.518z"
      />
      <path
        fill="url(#app-logo_svg__r)"
        d="M167 258.054c-1.36.321-2.72.651-4.089.946L153 212.53c.889-.192 1.778-.357 2.667-.53L167 258.054z"
      />
      <path
        fill="url(#app-logo_svg__s)"
        d="M156 260.533a124.93 124.93 0 01-4.068.467L146 213.494a66.115 66.115 0 012.602-.494L156 260.533z"
      />
      <path
        fill="url(#app-logo_svg__t)"
        d="M145 261l-3.884-.009L139 214.44l2.554-.44z"
      />
      <path
        fill="url(#app-logo_svg__u)"
        d="M135 261c-1.342-.14-2.675-.287-4-.461l1.265-44.165c.911-.148 1.815-.26 2.718-.374l.017 45z"
      />
      <path
        fill="url(#app-logo_svg__v)"
        d="M123.828 259l-3.828-.924 4.282-41.786L127 216z"
      />
      <path
        fill="url(#app-logo_svg__w)"
        d="M113.835 256L110 254.706l7.102-37.558L120 217z"
      />
      <path
        fill="url(#app-logo_svg__x)"
        d="M103.635 253L100 251.351 109.052 218l2.948.017z"
      />
      <path
        fill="url(#app-logo_svg__y)"
        d="M94.586 248L91 246.087 101.877 217l3.123.27z"
      />
      <path
        fill="url(#app-logo_svg__z)"
        d="M85.199 243A168.217 168.217 0 0082 240.904L93.058 216a88.33 88.33 0 002.942.589L85.199 243z"
      />
      <path
        fill="url(#app-logo_svg__A)"
        d="M77.153 237L74 234.72 86.007 213c.989.336 1.995.636 2.993.955L77.153 237z"
      />
      <path
        fill="url(#app-logo_svg__B)"
        d="M68.956 231L66 228.642 78.175 210 81 211.32z"
      />
      <path
        fill="url(#app-logo_svg__C)"
        d="M60.773 225A213.726 213.726 0 0058 222.534L70.424 206A139.78 139.78 0 0073 207.685L60.773 225z"
      />
      <path
        fill="url(#app-logo_svg__D)"
        d="M53.764 218L51 215.525 64.564 201 67 202.967z"
      />
      <path
        fill="url(#app-logo_svg__E)"
        d="M46.675 212L44 209.412 58.808 196 61 198.227z"
      />
      <path
        fill="url(#app-logo_svg__F)"
        d="M39.484 205c-.825-.952-1.641-1.923-2.484-2.866L53.075 189c.63.844 1.277 1.68 1.925 2.524L39.484 205z"
      />
      <path
        fill="url(#app-logo_svg__G)"
        d="M33.284 197L31 194.044 48.367 182 50 184.657z"
      />
      <path
        fill="url(#app-logo_svg__H)"
        d="M27.16 189L25 185.98 44.642 175c.45.899.882 1.823 1.358 2.721L27.16 189z"
      />
      <path
        fill="url(#app-logo_svg__I)"
        d="M21.983 182L20 178.658 41.943 168 43 170.98z"
      />
      <path
        fill="url(#app-logo_svg__J)"
        d="M17.766 173L16 169.526 40.252 160l.748 3.035z"
      />
      <path
        fill="url(#app-logo_svg__K)"
        d="M12.598 164L11 160.328 38.567 152l.433 3.125z"
      />
      <path
        fill="url(#app-logo_svg__L)"
        d="M8.369 154L7 150.331 37.849 144l.151 2.995z"
      />
      <path
        fill="url(#app-logo_svg__M)"
        d="M5.093 145L4 141.234 38 137l-.116 2.941z"
      />
      <path
        fill="url(#app-logo_svg__N)"
        d="M2.79 135L2 130.874 39 129l-.346 3.074z"
      />
      <path
        fill="url(#app-logo_svg__O)"
        d="M1.452 124L1 120l39 1.12-.548 2.845z"
      />
      <path
        fill="url(#app-logo_svg__P)"
        d="M.106 113.948A84.134 84.134 0 010 110l42 4.331c-.247.853-.468 1.782-.733 2.669L.106 113.948z"
      />
      <path
        fill="url(#app-logo_svg__Q)"
        d="M0 103.19c.07-1.39.167-2.79.264-4.19L44 107.308c-.29.898-.581 1.795-.88 2.692L0 103.19z"
      />
      <path
        fill="url(#app-logo_svg__R)"
        d="M1 92.183c.209-1.391.417-2.792.652-4.183L46 100.388c-.322.897-.67 1.741-1.017 2.612L1 92.183z"
      />
      <path
        fill="url(#app-logo_svg__S)"
        d="M3 81.097L4.046 77 49 93.486 47.85 96z"
      />
      <path
        fill="url(#app-logo_svg__T)"
        d="M6 70.982L7.442 67 52 87.571 50.749 90z"
      />
      <path
        fill="url(#app-logo_svg__U)"
        d="M10 60.697A119.81 119.81 0 0111.84 57L56 80.737c-.449.76-.88 1.52-1.382 2.263L10 60.697z"
      />
      <path
        fill="url(#app-logo_svg__V)"
        d="M15 51.574L17.206 48 60 75.778 58.503 78z"
      />
      <path
        fill="url(#app-logo_svg__W)"
        d="M21 42.315c.823-1.12 1.69-2.213 2.549-3.315L64 69.857c-.522.717-1.044 1.443-1.593 2.143L21 42.315z"
      />
      <path
        fill="url(#app-logo_svg__X)"
        d="M28 33.894c.972-.985 1.97-1.944 2.986-2.894L68 63.831A55.496 55.496 0 0166.436 66L28 33.894z"
      />
      <path
        fill="url(#app-logo_svg__Y)"
        d="M36 26.448c1.106-.865 2.256-1.635 3.38-2.448L72 57.742c-.53.762-1.026 1.54-1.575 2.258L36 26.448z"
      />
      <path
        fill="url(#app-logo_svg__Z)"
        d="M45 19.979c1.234-.666 2.442-1.349 3.676-1.979L76 51.61c-.529.823-1.084 1.602-1.622 2.39L45 19.979z"
      />
      <path
        fill="url(#app-logo_svg__aa)"
        d="M55 15.53L58.896 14 81 45.588 79.213 48z"
      />
      <path
        fill="url(#app-logo_svg__ab)"
        d="M66 12.138L69.818 11 86 39.646 84.057 42z"
      />
      <path
        fill="url(#app-logo_svg__ac)"
        d="M76 8.87L79.892 8 92 32.891 89.714 35z"
      />
      <path
        fill="url(#app-logo_svg__ad)"
        d="M86 6.716c1.25-.218 2.475-.48 3.7-.716L98 28.183c-.857.612-1.713 1.188-2.501 1.817L86 6.716z"
      />
      <path
        fill="url(#app-logo_svg__ae)"
        d="M95 4.62c1.308-.198 2.598-.405 3.888-.62L105 23.64c-.988.447-1.958.904-2.937 1.36L95 4.62z"
      />
      <path
        fill="url(#app-logo_svg__af)"
        d="M105 3.597c1.283-.205 2.574-.383 3.866-.597L113 22.108c-1.061.295-2.113.625-3.165.892L105 3.597z"
      />
      <path
        fill="url(#app-logo_svg__ag)"
        d="M115 1.463c1.217-.14 2.435-.306 3.643-.463L121 19.502c-1.026.157-2.052.314-3.078.498L115 1.463z"
      />
      <path
        fill="url(#app-logo_svg__ah)"
        d="M125 .29l4.002-.29.998 18.85-3.413.15z"
      />
      <path
        fill="url(#app-logo_svg__ai)"
        d="M134 .146L138 0l-.596 19c-1.125-.086-2.25-.146-3.376-.206L134 .146z"
      />
      <path
        fill="url(#app-logo_svg__aj)"
        d="M144.456 0L148 .071 145.92 21l-2.92-.562z"
      />
      <path
        fill="url(#app-logo_svg__ak)"
        d="M154.297 1l3.703.276L153.935 23 151 22.139z"
      />
      <path
        fill="url(#app-logo_svg__al)"
        d="M164.34 1l3.66.492L161.76 25c-.918-.388-1.835-.794-2.76-1.165L164.34 1z"
      />
      <path
        fill="url(#app-logo_svg__am)"
        d="M174.166 3l3.834.75L168.734 29 166 27.585z"
      />
      <path
        fill="url(#app-logo_svg__an)"
        d="M184.192 5L188 6.077 175.595 33 173 31.393z"
      />
      <path
        fill="url(#app-logo_svg__ao)"
        d="M193.317 8L197 9.435 181.411 38 179 36.21z"
      />
      <path
        fill="url(#app-logo_svg__ap)"
        d="M202.474 12L206 13.778 187.234 43 185 41.062z"
      />
      <path
        fill="url(#app-logo_svg__aq)"
        d="M211.675 17c1.109.688 2.217 1.394 3.325 2.117L193.087 48A90.114 90.114 0 01191 45.989L211.675 17z"
      />
      <path
        fill="url(#app-logo_svg__ar)"
        d="M220.878 23L224 25.406 198.969 53 197 50.949z"
      />
      <path
        fill="url(#app-logo_svg__as)"
        d="M229.09 30l2.91 2.704L203.858 59 202 56.86z"
      />
      <path
        fill="url(#app-logo_svg__at)"
        d="M236.262 37L239 39.976 207.698 65 206 62.702z"
      />
      <path
        fill="url(#app-logo_svg__au)"
        d="M243.534 45L246 48.165 212.483 71 211 68.611z"
      />
      <path
        fill="url(#app-logo_svg__av)"
        d="M249.82 53l2.18 3.386L216.308 77 215 74.524z"
      />
      <path
        fill="url(#app-logo_svg__aw)"
        d="M255.129 62L257 65.649 219.146 84 218 81.413z"
      />
      <path
        fill="url(#app-logo_svg__ax)"
        d="M259.425 72L261 75.775 220.99 91l-.99-2.62z"
      />
      <path
        fill="url(#app-logo_svg__ay)"
        d="M263.772 82L265 85.907 223.822 98c-.283-.884-.566-1.768-.822-2.652L263.772 82z"
      />
      <path
        fill="url(#app-logo_svg__az)"
        d="M266.128 92l.872 4.066L225.646 105c-.227-.9-.428-1.853-.646-2.77L266.128 92z"
      />
      <path
        fill="url(#app-logo_svg__aA)"
        d="M268.512 103l.488 3.86-41.53 5.14-.47-2.633z"
      />
      <path
        fill="url(#app-logo_svg__aB)"
        d="M269.85 113l.15 3.974L228.3 119l-.3-2.75z"
      />
    </g>
  </svg>
);

export default SvgAppLogo;

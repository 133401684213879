import React from "react";

const SvgMenu = props => (
  <svg width={20} height={18} {...props}>
    <g fill="none" fillRule="evenodd">
      <path fill="#35A8E0" d="M0 1h12.364H0z" />
      <path
        stroke="#FFF"
        strokeLinecap="round"
        strokeWidth={2}
        d="M12.364 1H.001"
      />
      <path fill="#35A8E0" d="M0 8.918h19.59H0z" />
      <path
        stroke="#FFF"
        strokeLinecap="round"
        strokeWidth={2}
        d="M19.588 8.918H0"
      />
      <path fill="#35A8E0" d="M7.086 17.453h12.503H7.086z" />
      <path
        stroke="#FFF"
        strokeLinecap="round"
        strokeWidth={2}
        d="M19.588 17.452H7.085"
      />
    </g>
  </svg>
);

export default SvgMenu;

import { createStyles } from "@material-ui/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";

export default (theme: any) =>
  createStyles({
    screenContainer: {
      display: "flex",
      width: "100%",
      background: theme.gradient.default,
      flexDirection: "column",
      flex: 1
    },
    pageContent: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      width: "100%",
      height: "100%",
      overflowY: "auto"
    },
    expand: {
      color: theme.palette.colors.lightWhite,
      transform: "rotate(0deg)",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest
      })
    },
    expandOpen: {
      color: theme.palette.colors.lightWhite,
      transform: "rotate(-180deg)",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest
      })
    },
    siteContainer: {
      display: "flex",
      flexDirection: "column",
      borderRadius: "6px",
      margin: "15px 20px",
      maxHeight: "70%"
    },
    siteHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: fade(theme.palette.colors.lightWhite, 0.2),
      padding: "17px 10px",
      borderTopLeftRadius: "6px",
      borderTopRightRadius: "6px",
      height: "55px",
      boxSizing: "border-box"
    },
    closedSite: {
      borderBottomLeftRadius: "6px",
      borderBottomRightRadius: "6px"
    },
    siteNameStyle: {
      color: theme.palette.colors.white,
      fontSize: "18px",
      fontWeight: 500,
      marginLeft: "12px"
    },
    unitContainer: {
      display: "flex",
      alignItems: "center",
      height: "50px",
      borderBottom: `1px solid ${fade(theme.palette.colors.lightWhite, 0.2)}`,
      backgroundColor: fade(theme.palette.colors.lightWhite, 0.1),
      padding: "0 13px",
      justifyContent: "space-between",
      "&:last-child": {
        borderWidth: "0px",
        borderBottomLeftRadius: "6px",
        borderBottomRightRadius: "6px"
      }
    },
    noUnitsStyle: {
      color: theme.palette.colors.white,
      fontSize: "18px",
      fontWeight: 500,
      textAlign: "center"
    },
    unitNameStyle: {
      color: fade(theme.palette.colors.lightWhite, 0.6),
      fontSize: "16px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "90%"
    },
    collapseWrapper: {
      height: "100%",
      overflowY: "auto"
    },
    deleteButton: {
      minHeight: "34px",
      lineHeight: "11px",
      fontSize: "11px",
      width: "25%",
      padding: "0px"
    },
    unitNameContainer: {
      height: "100%",
      width: "70%",
      display: "flex",
      alignItems: "center"
    }
  });

import { action, Action, Thunk, thunk, computed, Computed } from "easy-peasy";
import { Injections } from "./";
export interface ITypesModel {
  types: any;
  powerOnVal: number;
  powerOffVal: number;
  unitTypes: Computed<ITypesModel>;
  getTypes: Thunk<ITypesModel, void, Injections>;
  setTypes: Action<ITypesModel, any>;
  setPowerVal: Action<ITypesModel, any>;
  operationModesMirror: Computed<ITypesModel>;
  fanModesMirror: Computed<ITypesModel>;
  swingModesMirror: Computed<ITypesModel>;
  operationStatusesMirror: Computed<ITypesModel>;
  temperatureScaleMirror: Computed<ITypesModel>;
  brandsMap: Computed<ITypesModel>;
}
const typesStore: ITypesModel = {
  types: {},
  powerOffVal: 0,
  powerOnVal: 0,
  unitTypes: computed(state => {
    const { types } = state;
    const { unitTypes } = types;

    if (!unitTypes) {
      return null;
    }

    const keys = Object.keys(unitTypes);
    const object: { [index: string]: any } = {};

    const unitType = keys.reduce((allTypes, key) => {
      const name = unitTypes[key];
      allTypes[name as string] = key;

      return allTypes;
    }, object);

    return unitType;
  }),
  operationModesMirror: computed(state => {
    const { types } = state;
    const { operationModes } = types;

    if (!operationModes) {
      return null;
    }

    const keys = Object.keys(operationModes);
    const object: { [index: string]: any } = {};

    const modes = keys.reduce((allTypes, key) => {
      const name = operationModes[key];
      allTypes[name as string] = key;

      return allTypes;
    }, object);

    return modes;
  }),
  operationStatusesMirror: computed(state => {
    const { types } = state;
    const { operationStatuses } = types;

    if (!operationStatuses) {
      return null;
    }

    const keys = Object.keys(operationStatuses);
    const object: { [index: string]: any } = {};

    const modes = keys.reduce((allTypes, key) => {
      const name = operationStatuses[key];
      allTypes[name as string] = key;

      return allTypes;
    }, object);

    return modes;
  }),
  fanModesMirror: computed(state => {
    const { types } = state;
    const { fanModes } = types;

    if (!fanModes) {
      return null;
    }

    const keys = Object.keys(fanModes);
    const object: { [index: string]: any } = {};

    const modes = keys.reduce((allTypes, key) => {
      const name = fanModes[key];
      allTypes[name as string] = key;

      return allTypes;
    }, object);

    return modes;
  }),
  swingModesMirror: computed(state => {
    const { types } = state;
    const { swingModes } = types;

    if (!swingModes) {
      return null;
    }

    const keys = Object.keys(swingModes);
    const object: { [index: string]: any } = {};

    const modes = keys.reduce((allTypes, key) => {
      const name = swingModes[key];
      allTypes[name as string] = key;

      return allTypes;
    }, object);

    return modes;
  }),
  brandsMap: computed(state => {
    let brandsObj: any = {};
    if (!state || !state.types || !state.types.hvacBrands) {
      return {};
    }

    const brandsArr = state.types.hvacBrands;

    for (let brandIndex in brandsArr) {
      const brand: any = brandsArr[brandIndex];
      brandsObj[brand.type] = brand.name;
    }
    return brandsObj;
  }),
  getTypes: thunk((actions, payload, { injections }) => {
    const { coolRemoteSDK } = injections;
    coolRemoteSDK.Services.getTypes().then((res: any) => {
      actions.setTypes(res);
      const powerTypes = res.operationStatuses;
      const powerVals = Object.keys(powerTypes);
      const numOfStates = powerVals.length;
      let powerOnVal, powerOffVal;

      for (let x = 0; x < numOfStates; x++) {
        const key = powerVals[x];
        const state = powerTypes[key];
        if (state === "on") {
          powerOnVal = +key;
        }
        if (state === "off") {
          powerOffVal = +key;
        }
      }
      actions.setPowerVal({ powerOnVal, powerOffVal });
    });
  }),
  setTypes: action((state, payload) => {
    state.types = payload;
  }),
  setPowerVal: action((state, payload) => {
    state.powerOffVal = payload.powerOffVal;
    state.powerOnVal = payload.powerOnVal;
  }),
  temperatureScaleMirror: computed(state => {
    const { types } = state;
    const { temperatureScale } = types;

    if (!temperatureScale) {
      return null;
    }

    const keys = Object.keys(temperatureScale);
    const object: { [index: string]: any } = {};

    const temperatureScaleType = keys.reduce((allTypes, key) => {
      const name = temperatureScale[key];
      allTypes[name as string] = key;

      return allTypes;
    }, object);

    return temperatureScaleType;
  })
};
export default typesStore;

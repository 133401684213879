import React from "react";

const OpenSensor = props => (
<svg xmlns="http://www.w3.org/2000/svg"  width={props.width} height={props.height} viewBox="0 0 20 20">
    <g fill="none" fill-rule="evenodd">
        <g stroke="#FFF">
            <g>
                <g transform="translate(-702 -239) translate(702 239) translate(1 5.2)">
                    <circle cx="3.897" cy="4.332" r="2.031"/>
                    <circle cx="14.022" cy="4.332" r="2.031"/>
                    <path stroke-linecap="round" d="M0 4.334L1.366 4.334M16.634 4.311L18 4.311M5.906 2.894L10.73 0"/>
                </g>
            </g>
        </g>
    </g>
</svg>


);

export default OpenSensor;

import { Divider, makeStyles, TextField, Typography } from "@material-ui/core";
import _ from "lodash";
import moment from "moment-timezone";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import { ConfirmationDialog, Toolbar } from "../../components";
import { minsToTime } from "../../services/timeService";
import { useStoreActions, useStoreState } from "../../Stores/typedHooks";
import { AddIcon, ArrowBack, Delete, Edit } from "../../svgComponents";
import { DaysList, FormikField, Loader, Switch } from "../../widgets";
import styles from "./scheduleList.style";

interface IProps {
  history: any;
  match: any;
}
const ScheduleList: React.FC<IProps> = ({ history, match }) => {
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(
    false
  );
  const [selectedItem, setSelectedItem] = useState<string>("");
  const [currentItem, setCurrentItem] = useState<any>({});
  const [itemSchedules, setItemSchedules] = useState<any>({});
  const [dataIsLoading, setDataIsLoading] = useState<boolean>(true);
  const [schedulesInitialized, setSchedulesInitialized] = useState<boolean>(false);

  const types = useStoreState((state) => state.typesStore.types);
  const temperatureSymbol = useStoreState(
    (state) => state.userStore.temperatureSymbol
  );
  const timeFormat = useStoreState((state) => state.userStore.timeFormat);
  const getUnit = useStoreActions((actions) => actions.unitStore.getUnitById);
  const getGroup = useStoreActions((actions) => actions.groupStore.getGroup);
  const { updateSchedule, getObjectSchedules } = useStoreActions(
    (actions) => actions.scheduleStore
  );

  const { weekDays } = types;
  const { params } = match;
  const { unitId, groupId } = params;
  const isGroup = !!groupId;

  const itemId = isGroup ? groupId : unitId;

  const startLoader = useStoreActions(
    (actions) => actions.loaderStore.startLoader
  );
  const finishLoader = useStoreActions(
    (actions) => actions.loaderStore.finishLoader
  );
  const addMessage = useStoreActions(
    (actions) => actions.messageStore.addMessage
  );
  const deleteSchedule = useStoreActions(
    (actions) => actions.scheduleStore.deleteSchedule
  );
  const removeScheduleFromList = useStoreActions(
    (actions) => actions.scheduleStore.removeSchedule
  );

  useEffect(() => {
    if (!schedulesInitialized) {
      return;
    }
    setDataIsLoading(false);
  }, [schedulesInitialized]);

  useEffect(() => {
    getObjectSchedules({ objectType: isGroup ? "group" : "unit", id: itemId })
      .then((schedules: any) => {
        setItemSchedules(schedules);
        setSchedulesInitialized(true);
      })
      .catch((error: any) => {
        addMessage({ message: error.message });
      });
  }, [getObjectSchedules, addMessage, isGroup, itemId]);

  const goBack = () => {
    history.push("/site");
  };

  useEffect(() => {
    if (!_.isEmpty(currentItem)) {
      return;
    }

    isGroup
      ? getGroup(groupId)
        .then((group: any) => {
          setCurrentItem(group);
        })
      : getUnit(unitId)
        .then((unit: any) => {
          setCurrentItem(unit);
        })
        .catch((err: any) => {
          addMessage({ message: err.message });
          history.push("/");
        });
  }, [
    addMessage,
    currentItem,
    getGroup,
    getUnit,
    groupId,
    history,
    isGroup,
    unitId
  ]);

  const addSchedule = () => {
    history.push(
      isGroup ? `/group/${groupId}/schedule` : `/unit/${unitId}/schedule`
    );
  };

  const editSchedule = (scheduleId: string) => {
    history.push(
      isGroup
        ? `/group/${groupId}/schedule/${scheduleId}`
        : `/unit/${unitId}/schedule/${scheduleId}`
    );
  };

  const deleteSelectedSchedule = (scheduleId: string) => {
    // startLoader();
    // setIsConfirmDialogOpen(false);
    startLoader();
    deleteSchedule(scheduleId)
      .then(() => {
        removeScheduleFromList(scheduleId);
        let schedules = { ...itemSchedules };
        delete schedules[scheduleId];
        setItemSchedules(schedules);
      })
      .catch((err: any) =>
        addMessage({
          message: err.message
        })
      )
      .finally(() => {
        finishLoader();
      });
  };

  const confrimScheduleDelete = (itemId: string) => {
    setIsConfirmDialogOpen(true);
    setSelectedItem(itemId);
  };

  const cancel = () => {
    setIsConfirmDialogOpen(false);
    setSelectedItem("");
  };

  const changeScheduleActiveState = (e: any, id: string) => {
    const isDisabled = !e.target.checked;
    updateSchedule({ id, data: { isDisabled } }).then(() => {
      setItemSchedules({
        ...itemSchedules,
        [id]: { ...itemSchedules[id], isDisabled }
      });
    });
  };
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  return dataIsLoading ? (
    <Loader showLoader={true} spinnerColor="#fff" spinnerName="BounceLoader" />
  ) : (
      <div className={classes.screenContainer}>
        <div className={classes.gridStyle}>
          <Toolbar
            title={t`Schedules`}
            leftIconComponent={<ArrowBack />}
            leftAction={goBack}
            rightAction={addSchedule}
            rightIconComponent={<AddIcon />}
          />
          <div className={classes.schedulesContainer}>
            {Object.values(itemSchedules).length === 0 ? (
              <Typography
                className={classes.noSchedulesStyle}
              >{t`There are no schedules to show`}</Typography>
            ) : (
                Object.values(itemSchedules).map((schedule: any, i: number) => {
                  const {
                    powerOnTime,
                    powerOffTime,
                    setpoint,
                    id: scheduleId,
                    days,
                    isDisabled
                  } = schedule;
                  const startHour = powerOnTime || powerOnTime === 0 ? minsToTime(powerOnTime, timeFormat) : "";
                  const endHour =
                    powerOffTime || powerOffTime === 0
                      ? minsToTime(powerOffTime, timeFormat)
                      : "";

                  return (
                    <div
                      className={classes.scheduleContainer}
                      key={`schedule-${scheduleId}`}
                    >
                      <div className={classes.scheduleHeader}>
                        <div className={classes.scheduleTitle}>
                          <Typography className={classes.longNamestyle}>
                            {currentItem.name}
                          </Typography>
                        </div>
                        <div className={classes.scheduleControl}>
                          <Edit onClick={() => editSchedule(scheduleId)} />
                          <Delete
                            onClick={() => deleteSelectedSchedule(scheduleId)}
                          />
                        </div>
                      </div>
                      <div className={classes.scheduleBody}>
                        <div className={classes.rowContainer}>
                          <div className={classes.bodyRow}>
                            <div className={classes.timeSection}>
                              <TextField
                                variant={"outlined"}
                                name={"Start Time"}
                                label={t`Start Time`}
                                placeholder={t`None`}
                                value={powerOnTime || powerOnTime === 0
                                  ? startHour
                                  : t`None`}
                                className={classes.inputClass}
                                disabled={true}
                              />
                              <TextField
                                variant={"outlined"}
                                name={"End Time"}
                                label={t`End Time`}
                                placeholder={t`None`}
                                value={powerOffTime || powerOffTime === 0
                                  ? endHour
                                  : t`None`}
                                className={classes.inputClass}
                                disabled={true}
                              />
                            </div>
                          </div>

                          {!!setpoint && (
                            <div className={classes.setpointContainer}>
                              <div className={classes.setPointSection}>
                                <Typography className={classes.setPointStyle}>
                                  {setpoint}
                                </Typography>
                                <Typography className={classes.tempSymbolStyle}>
                                  {temperatureSymbol}
                                </Typography>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className={classes.bodyRow}>
                          <DaysList
                            days={Object.keys(weekDays)}
                            activeDays={days}
                            action={() => { }}
                          />
                        </div>
                        <div className={classes.bodyRow}>
                          <Typography className={classes.scheduleStatusStyle}>
                            {t`Status: ${isDisabled ? t`Inactive` : t`Active`}`}
                          </Typography>
                          <div className={classes.controlSec}>
                            <Switch
                              checked={!isDisabled}
                              disableRipple={true}
                              onChange={(e: any) =>
                                changeScheduleActiveState(e, scheduleId)
                              }
                              value={true}
                            />
                          </div>
                        </div>
                        <Divider className={classes.dividerStyle} />
                      </div>
                    </div>
                  );
                })
              )}
          </div>
          {/*<ConfirmationDialog
            onCancel={cancel}
            onConfrim={() => deleteSelectedSchedule(selectedItem)}
            onClose={cancel}
            title="Delete"
            text="Are you sure you want to delete this item?"
            confrimLabel="Delete"
            cancelLabel="No"
            dialogType="error"
            openDialog={isConfirmDialogOpen}
          />*/}
        </div>
      </div>
    );
};

export default ScheduleList;

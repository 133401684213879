import React from "react";
import { Button, withStyles } from "@material-ui/core";
import clsx from "clsx";
import buttonStyle from "./button.style";

interface IButtonProps {
  classes?: any;
  children?: any;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
  variant?: "outlined" | "contained" | undefined;
  type?: "button" | "reset" | "submit" | undefined;
}

const CustomButton: React.FC<IButtonProps> = ({
  classes,
  children,
  onClick,
  className,
  disabled,
  variant,
  type
}) => {
  return (
    <Button
      type={type}
      variant={variant}
      onClick={onClick}
      disabled={disabled}
      className={className}
      classes={{
        root: clsx(classes.sharedStyle, variant ? "" : classes.normalStyle),
        outlined: classes.outlinedStyle,
        contained: classes.containedStyle,
        disabled: classes.disabledStyle
      }}
    >
      {children}
    </Button>
  );
};

export default withStyles(buttonStyle)(CustomButton);

import React from "react";

const SvgShowPassword = props => (
  <svg width={26} height={14} {...props}>
    <g fill="#AAA2AA" fillRule="evenodd" opacity={0.5}>
      <path d="M16.069 7.033a3.056 3.056 0 11-6.112 0 3.056 3.056 0 016.111 0" />
      <path d="M12.97 10.981a3.99 3.99 0 11.001-7.978 3.99 3.99 0 010 7.978M0 6.981c1.394 2.403 3.617 4.357 6.352 5.583 7.156 3.207 15.915.707 19.563-5.583-1.394-2.404-3.617-4.358-6.351-5.584C12.408-1.81 3.649.69 0 6.981z" />
    </g>
  </svg>
);

export default SvgShowPassword;

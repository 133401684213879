import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useStoreState } from "../../Stores/typedHooks";
import { TermsOfUseAgreement } from "../../screens";

interface IComponentProps {
  exact?: boolean;
  path: string;
  history?: any;
  component: any;
}

const PrivateComponent = ({
  component: Component,
  ...props
}: IComponentProps) => {
  const user = useStoreState(state => state.userStore.user);

  const { isLoggedIn, isAcceptedTOU } = user;

  return (
    <Route
      exact={true}
      {...props}
      render={innerProps => {
        return isLoggedIn ? (
          isAcceptedTOU ? (
            <Component {...innerProps} />
          ) : (
            <TermsOfUseAgreement {...innerProps} />
          )
        ) : (
          <Redirect to="/" />
        );
      }}
    />
  );
};
export default PrivateComponent;

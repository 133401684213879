import React, { useState, useEffect, Fragment } from "react";
import { t, ngettext, msgid } from "ttag";
import _ from "lodash";
import {
  IconButton,
  Typography,
  CircularProgress,
  makeStyles
} from "@material-ui/core";
import CircularSlider from "react-circular-slider-svg";
import tinycolor2 from "tinycolor2";
import clsx from "clsx";
import {
  ScheduleOffIcon,
  SwitcherOffIcon,
  SwitcherOnIcon,
  ControlWrapper,
  ArrowDownControl,
  ArrowUp,
  AutoMode,
  DryIcon,
  ColdMode,
  HotMode,
  ModeFanIcon
} from "../../svgComponents";
import groupControllerStyles from "./groupController.style";

interface IObject {
  [key: string]: any;
}

const colorRanges: any = {
  COOL: ["#35a8e0", "#2d2e82"],
  HEAT: ["#f8b133", "#f05146"],
  AUTO: ["#35a8e0", "#f05146"],
  DRY: ["#266101", "#266101"],
  FAN: ["#4f00ed", "#4f00ed"],
  default: ["#ffffff", "#000000"]
};

const GroupController: React.FC<any> = props => {
  const {
    group,
    navigateToSchedules,
    selectedSiteId,
    types,
    changeGroupPower,
    changeGroupSetPoint,
    temperatureScale: userTempScale,
    addMessage,
    operationStatusesMirror,
    isCelsius
  } = props;

  const useStyles = makeStyles(groupControllerStyles);
  const classes = useStyles();

  const { id: groupId, units: groupUnits } = group;

  const tempLimits: any = [0, 100];
  const [currentColor, setCurrentColor] = useState<any>("#888");
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
  const [updateObj, setUpdateObj] = useState<any>(null);
  const [pendingState, setPendingState] = useState<any>({
    type: "",
    mode: null
  });
  const [groupStatus, setGroupStatus] = useState<any>({
    power: false,
    setPoint: null,
    mode: null
  });
  const [timer, setTimer] = useState<any>();
  const [groupTimer, setGroupTimer] = useState<any>();
  const [localActiveSetpoint, setLocalActiveSetPoint] = useState<any>(0);
  const [togglePowerButton, setTogglePowerButton] = useState<boolean>(false);
  const [startTemp, setStart] = useState<number>(0);
  const [endTemp, setEnd] = useState<number>(48);
  const [startAngle, setStartAngle] = useState<number>(0);
  const [endAngle, setEndAngle] = useState<number>(360);

  const modeIcons: IObject = {
    COOL: <ColdMode className={clsx(classes.iconStyle, classes.modeIcon)} />,
    HEAT: <HotMode className={clsx(classes.iconStyle, classes.modeIcon)} />,
    AUTO: (
      <AutoMode
        className={clsx(
          classes.iconStyle,
          classes.modeIcon,
          classes.smallIcons
        )}
      />
    ),
    DRY: (
      <DryIcon
        className={clsx(
          classes.iconStyle,
          classes.modeIcon,
          classes.smallIcons
        )}
      />
    ),
    FAN: (
      <ModeFanIcon
        className={clsx(
          classes.iconStyle,
          classes.modeIcon,
          classes.smallIcons
        )}
      />
    )
  };

  const { operationModes, temperatureScale } = types;
  const powerOnVal = operationStatusesMirror && +operationStatusesMirror.on;

  const isTempInC = temperatureScale[userTempScale] === "celsius";

  useEffect(() => {
    if (!operationStatusesMirror || _.isEmpty(operationStatusesMirror)) {
      return;
    }

    let power: boolean = false,
      setPoint: any = null,
      mode: any = null,
      first: boolean = true;
    const units = { ...group.units };

    if (_.isEmpty(units)) {
      return;
    }

    for (let x in units) {
      const unit = units[x];
      if (!unit) {
        continue;
      }

      if (first) {
        power = unit.activeOperationStatus === powerOnVal;
        setPoint = unit.activeSetpoint;
        mode = unit.activeOperationMode;
        first = false;
        continue;
      }
      if (!power && unit.activeOperationStatus === powerOnVal) {
        power = true;
      }
      if (!isNaN(setPoint) && setPoint !== unit.activeSetpoint) {
        setPoint = null;
      }
      if (!isNaN(mode) && mode !== unit.activeOperationMode) {
        mode = null;
      }
    }

    setUpdateObj({ power, setPoint, mode });
  }, [group.units, operationStatusesMirror, powerOnVal]);

  useEffect(() => {
    if (!updateObj) {
      return;
    }
    if (isFirstLoad || !groupTimer) {
      setLocalActiveSetPoint(updateObj.setPoint || "--");
      setGroupStatus(updateObj);
      setUpdateObj(null);
      setIsFirstLoad(false);
      return;
    }
  }, [isFirstLoad, groupTimer, updateObj]);

  useEffect(() => {
    if (!togglePowerButton || pendingState.type === "") {
      return;
    }

    setTimeout(() => {
      setTogglePowerButton(false);
    }, 5000);

    if (
      pendingState.type === "setpoint" &&
      pendingState.mode === groupStatus.setPoint
    ) {
      setTogglePowerButton(false);
      setPendingState({ type: "", mode: null });
    }

    if (
      pendingState.type === "power" &&
      pendingState.mode === groupStatus.power
    ) {
      setTogglePowerButton(false);
      setPendingState({ type: "", mode: null });
    }
  }, [groupStatus, togglePowerButton, pendingState]);

  useEffect(() => {
    if (
      !localActiveSetpoint ||
      groupStatus.setPoint === localActiveSetpoint ||
      isNaN(localActiveSetpoint)
    ) {
      return;
    }
    if (timer) {
      clearTimeout(timer);
    }
    let newTimer: NodeJS.Timeout = setTimeout(() => {
      setTogglePowerButton(true);
      changeGroupSetPoint({
        groupId,
        setPoint: localActiveSetpoint
      })
        .catch((err: any) => {
          addMessage({
            message: err.message
          });
        })
        .finally(() => setTogglePowerButton(false));
    }, 1000);
    setTimer(newTimer);
  }, [
    localActiveSetpoint,
    addMessage,
    groupId,
    changeGroupSetPoint,
    groupStatus
  ]);

  const changeTemp = (isAdd: boolean) => {
    if (
      (isAdd && localActiveSetpoint === tempLimits[1]) ||
      (!isAdd && localActiveSetpoint === tempLimits[0])
    ) {
      return;
    }

    const emptyTemp = !localActiveSetpoint || isNaN(localActiveSetpoint);

    const startTemp = !emptyTemp ? localActiveSetpoint : isTempInC ? 24 : 75;
    const updatedSetPoint = emptyTemp
      ? startTemp
      : isAdd
        ? startTemp + 1
        : startTemp - 1;
    setLocalActiveSetPoint(updatedSetPoint);

    setPendingState({
      type: "setpoint",
      mode: updatedSetPoint
    });

    if (!isFirstLoad && !groupTimer) {
      let newTimer: NodeJS.Timeout = setTimeout(() => {
        clearTimeout(groupTimer);
        setGroupTimer(null);
      }, 10000);
      setGroupTimer(newTimer);
    }
  };

  useEffect(() => {
    const mode = groupStatus.mode;
    if (
      _.isEmpty(groupUnits) || (!mode && mode !== 0) || mode > 2 || (!localActiveSetpoint && localActiveSetpoint !== 0)
    ) {
      return;
    }
    const minTempPerType = isCelsius ? 0 : 32;
    const maxTempPerType = isCelsius ? 48 : 118.4;
    const midByType = isCelsius ? 24 : 75;
    const units: any = Object.values(groupUnits)

    const groupLimits = units[0].temperatureLimits

    const minLimit = Math.min((groupLimits[mode][0] || minTempPerType), localActiveSetpoint);
    const maxLimit = Math.max((groupLimits[mode][1] || maxTempPerType), localActiveSetpoint);
    if (minLimit === startTemp && maxLimit === endTemp) {
      return;
    }

    setStart(minLimit);
    setEnd(maxLimit);

    const startAngle = (180 * +minLimit) / midByType;
    const endAngle = (180 * +maxLimit) / midByType;

    setStartAngle(startAngle);
    setEndAngle(endAngle);
  }, [
    groupStatus,
    groupUnits,
    endTemp,
    startTemp,
    localActiveSetpoint,
    isCelsius
  ]);

  useEffect(() => {
    const mode =
      groupStatus.mode === null ? "default" : operationModes[groupStatus.mode];

    const range = colorRanges[mode]
      ? colorRanges[mode]
      : colorRanges["default"];
    const c1 = range[0];
    const c2 = range[1];
    const absoluteVal =
      ((localActiveSetpoint - tempLimits[0]) /
        (tempLimits[1] - tempLimits[0])) *
      100;
    const c3 = tinycolor2.mix(c1, c2, absoluteVal);
    setCurrentColor(c3.toHexString());
  }, [localActiveSetpoint, operationModes, groupStatus, tempLimits]);

  const togglePower = () => {
    setTogglePowerButton(true);
    setPendingState({
      type: "power",
      mode: !groupStatus.power
    });
    changeGroupPower(!groupStatus.power, groupId);
  };

  const currentMode =
    groupStatus.mode === null ? null : operationModes[groupStatus.mode];
  const noControl = currentMode === "DRY" || currentMode === "FAN";
  const isPowerOn = groupStatus.power;
  return (
    <div className={classes.unitView}>
      <div className={classes.firstRowContainer}>
        <div className={classes.nameSection}>

          <Typography> {group.name}</Typography>
        </div>

        <div className={classes.loaderContainer}>
          {togglePowerButton && (
            <CircularProgress size={40} className={classes.loaderStyle} />
          )}
        </div>

        <div className={classes.powerContainer}>
          <IconButton
            className={classes.powerIconStyle}
            onClick={() => togglePower()}
          >
            {isPowerOn && (
              <SwitcherOnIcon className={classes.powerOffButtonClick} />
            )}
            {!isPowerOn && (
              <SwitcherOffIcon className={classes.powerOnButtonClick} />
            )}
          </IconButton>
          <Typography className={classes.powerTextStyle}>
            {isPowerOn ? t`POWER OFF` : t`POWER ON`}
          </Typography>
        </div>
      </div>

      <div className={classes.secondRowContainer}>
        <div className={classes.controlDiv} >
          <ControlWrapper
            className={clsx(classes.controlWrapperStyle)}
            fill={isPowerOn ? currentColor : "rgba(253,253,254, 0.5)"}
            width={336}
            height={326}
          />
          {!noControl ? (
            <Fragment>
              <div
                className={clsx(classes.sliderContainer)}
              >
                <CircularSlider
                  size={280}
                  minValue={startTemp}
                  maxValue={endTemp}
                  startAngle={startAngle}
                  endAngle={endAngle}
                  coerceToInt={true}
                  angleType={{
                    direction: "cw",
                    axis: "-y"
                  }}
                  handle1={{
                    value:
                      localActiveSetpoint === null || isNaN(localActiveSetpoint)
                        ? 0
                        : localActiveSetpoint,
                    onChange: () => { }
                  }}
                  arcColor={"#48314A"}
                  arcBackgroundColor={"#48314A"}
                />
              </div>
              <div
                className={clsx(classes.innerControls)}
              >
                <IconButton
                  onClick={() => changeTemp(true)}
                  className={classes.controlArrow}
                >
                  <ArrowUp />
                </IconButton>
                <div>
                  <Typography
                    className={clsx(classes.setPointStyle)}
                  >
                    {!isNaN(localActiveSetpoint) ? localActiveSetpoint : "--"}
                  </Typography>
                </div>
                <div className={classes.ampTempContainer}>
                  {modeIcons[operationModes[groupStatus.mode]]}
                </div>
                <IconButton
                  onClick={() => changeTemp(false)}
                  className={classes.controlArrow}
                >
                  <ArrowDownControl />
                </IconButton>
              </div>
            </Fragment>
          ) : (
              <Typography className={classes.modeTitle}>{currentMode}</Typography>
            )}
        </div>
      </div>

      <div className={classes.lastRowContainer}>
        <div className={classes.iconContainer}>
          <IconButton
            disableFocusRipple
            disableRipple
            className={classes.mainIconsStyle}
            name="schedule"
            onClick={() => navigateToSchedules(selectedSiteId, groupId)}
          >
            <ScheduleOffIcon />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default GroupController;

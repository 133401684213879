import React from "react";

const SvgThermostat = props => (
  <svg width={17} height={17} {...props}>
    <g
      fill="none"
      fillRule="evenodd"
      stroke="#FFF"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M9.168 11.336v-8.55C9.168 1.8 8.421 1 7.5 1c-.921 0-1.668.8-1.668 1.787v8.55c-.772.738-1.037 1.911-.667 2.947.37 1.035 1.3 1.718 2.335 1.716 1.035.002 1.964-.68 2.335-1.716.37-1.036.105-2.209-.667-2.948z" />
      <path strokeWidth={1.14} d="M11 10h2m-2-3h2m-2-3h2" />
    </g>
  </svg>
);

export default SvgThermostat;

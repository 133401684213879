import { createStyles } from "@material-ui/styles";
import { fade } from "@material-ui/core/styles";

export default (theme: any) =>
  createStyles({
    screenContainer: {
      backgroundImage: theme.gradient.default,
      width: "100%",
      display: "flex",
      justifyContent: "center"
    },
    container: {
      width: "100%",
      maxWidth: "768px",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center"
    },
    sectionTitleStyle: {
      width: "100%",
      color: theme.palette.colors.lightWhite,
      fontSize: "16px",
      paddingBottom: "10px"
    },
    formContainer: { overflow: "auto", padding: "0px 20px" },
    wrapperStyle: {
      marginBottom: "15px",
      height: "50px",
      borderRadius: "6px",
      "& fieldset": {
        borderColor: `${fade(theme.palette.colors.lightWhite, 0.3)} !important`
      },
      "& *": {
        fontFamily: "Roboto",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        letterSpacing: "normal"
      }
    },
    inputClass: {
      fontSize: "16px",
      color: theme.palette.colors.lightWhite,
      lineHeight: "normal",
      "&:disabled": { color: fade(theme.palette.colors.lightWhite, 0.5) }
    },

    labelClass: {
      // fontSize: "12px",
      lineHeight: "normal",
      color: `${fade(theme.palette.colors.lightWhite, 0.5)} !important`
    },
    errorStyle: {
      fontSize: "12px",
      marginTop: "3px",
      color: `${theme.palette.secondary.main} !important`
    },
    submitButtonStyle: {
      marginTop: "15px",
      marginBottom: "15px"
    },
    deleteIconStyle: {
      paddingRight: "12px",
      "& path": {
        fill: theme.palette.colors.white
      }
    },
    shareIconButton: {
      padding: 0
    },
    shareContainer: {
      display: "flex",
      width: "80%",
      justifyContent: "space-between",
      margin: "auto"
    },
    shareSection: {
      display: "flex",
      flexDirection: "column",
      marginTop: "15px"
    },
    shareHeader: {
      width: "80%",
      margin: "auto",
      color: theme.palette.colors.lightWhite,
      fontWeight: 400,
      marginBottom: "5px"
    },
    emailTextField: { marginTop: "10px" },
    labelDisabled: { opacity: 0.6 }
  });

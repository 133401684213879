import { createStyles } from "@material-ui/styles";
import { fade } from "@material-ui/core/styles";

export default (theme: any) =>
  createStyles({
    drawerStyle: {
      left: "auto !important",
      right: "auto !important"
    },
    root: {
      width: "100%",
      left: "auto",
      right: "auto",
      margin: "auto",
      "@media (min-width: 769px)": {
        maxWidth: "375px",
        maxHeight: "812px",
        "& div:first-of-type:first-child": {
          maxWidth: "375px",
          maxHeight: "812px"
        }
      },
      "@media (min-height: 1025px)": {
        maxWidth: "375px",
        maxHeight: "812px",
        "& div:first-of-type:first-child": {
          maxWidth: "375px",
          maxHeight: "812px"
        }
      },
      "& div:first-of-type:first-child": {
        width: "100%",
        left: "auto",
        right: "auto",
        margin: "auto"
      }
    },
    drawerPaper: {
      width: "85%",
      background: theme.gradient.menu,
      maxWidth: "500px",
      position: "absolute",
      flexDirection: "row",
      margin: "auto",
      top: "50%",
      bottom: "50%"
    },
    bigAvatar: {
      transform: "scale(0.8727)",
      marginTop: "14px",
      marginBottom: "5px"
    },
    menuHeader: {
      flexFlow: "column",
      alignItems: "center",
      paddingTop: "14px",
      marginBottom: "33px !important"
    },
    divider: {
      opacity: 0.2,
      background: theme.palette.colors.lightWhite
    },
    listItemText: {
      opacity: 0.7,
      fontFamily: "Roboto",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      fontSize: "16px",
      color: theme.palette.colors.lightWhite
    },

    closeButton: { alignSelf: "flex-start" },
    menuBody: {
      paddingLeft: "27px",
      height: "94%",
      maxHeight: "850px",
      margin: 0
    },
    usernameStyle: {
      fontFamily: "Roboto",
      fontSize: "20px",
      textAlign: "right",
      color: theme.palette.colors.white,
      lineHeight: "normal"
    },
    roleStyle: {
      fontFamily: "Roboto",
      fontSize: "14px",
      color: fade(theme.palette.colors.white, 0.7),
      lineHeight: "normal"
    },
    listItemStyle: {
      display: "flex",
      justifyContent: "space-between",
      justifyItems: "center",
      paddingLeft: "3px",
      paddingRight: "14px",
      height: "54px"
    },
    iconTextContainer: {
      display: "inline-flex",
      justifyItems: "center",
      justifyContent: "center"
    },
    menuIconsStyle: {
      minWidth: "17px",
      minHeight: "17px",
      alignSelf: "center",
      paddingRight: "11px",
      fontSize: "20px",
      fill: fade(theme.palette.colors.lightWhite, 0.8)
    },
    arrowIconStyle: {
      fontSize: "16px",
      opacity: 0.6,
      color: theme.palette.colors.lightWhite
    }
  });

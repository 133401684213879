import { action, Action } from "easy-peasy";

export interface IMessagesModel {
  message: any;
  addMessage: Action<IMessagesModel, any>;
  hideMessage: Action<IMessagesModel>;
}

const messageStore: IMessagesModel = {
  message: {
    message: ""
  },

  addMessage: action((state, payload) => {
    state.message = { ...payload };
  }),
  hideMessage: action(state => {
    state.message.message = "";
  })
};

export default messageStore;

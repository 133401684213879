import React from "react";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { t } from "ttag";
import { Button } from "../../widgets";
import resetPasswordResultStyle from "./resetPasswordResult.style";

const ResetPasswordResult: any = (props: any) => {
  const { backToLogin } = props;
  const useStyles = makeStyles(resetPasswordResultStyle);
  const classes = useStyles();

  return (
    <Grid classes={{ root: classes.resetPasswordResultContainer }}>
      <Typography
        className={classes.resetPasswordResultMessage}
      >{t`Your password has been changed`}</Typography>
      <Button variant="contained" onClick={backToLogin}>{t`LOGIN`}</Button>
    </Grid>
  );
};

export default ResetPasswordResult;

import React from "react";
import { SiteUnitSelectionMenu } from "../../components";

const HomeFooter = (props: any) => {
  const {
    selectedSiteId,
    sites,
    selectItem,
    classes,
    changeSitePower,
    setSelectedSite,
    units,
    groups,
    unitTypes,
    startLoader,
    finishLoader,
    groupSiteMapping,
    loggedUser,
    siteSensors,
    isCelsius,
    updateLocalSensor
  } = props;
  const { selectionMenuContainer, slideWrapper } = classes;

  return (
    <div className={selectionMenuContainer}>
      <div className={slideWrapper}>
        <SiteUnitSelectionMenu
          changeSitePower={changeSitePower}
          sites={sites}
          selectItem={selectItem}
          selectedSite={selectedSiteId}
          setSelectedSite={setSelectedSite}
          units={units}
          groups={groups}
          unitTypes={unitTypes}
          startLoader={startLoader}
          finishLoader={finishLoader}
          groupSiteMapping={groupSiteMapping}
          loggedUser={loggedUser}
          siteSensors={siteSensors}
          isCelsius={isCelsius}
          updateLocalSensor={updateLocalSensor}
        />
      </div>
    </div>
  );
};

export default HomeFooter;

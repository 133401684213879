import { Grid, makeStyles, Typography } from "@material-ui/core/";
import { AccessTime, ReportProblemOutlined } from "@material-ui/icons/";
import clsx from "clsx";
import React, { Fragment, useEffect, useState } from "react";
import { PulseLoader } from "react-spinners";
import { t } from "ttag";
import { useStoreActions, useStoreState } from "../../Stores/typedHooks";
import {
  AutoMode,
  ClosedSensor,
  CO2,
  ColdMode,
  DryIcon,
  HotMode,
  Humidity,
  ModeFanIcon,
  OpenSensor,
  TempSensor
} from "../../svgComponents";

import { Switch } from "../../widgets";
import { controlItemStyles } from "./controlItem.style";

interface IObject {
  [key: string]: any;
}

const icons: any = {
    1: <TempSensor style={{height: 24}}/>,
    2: <CO2 />,
    3: "",
    4: "",
    5: <Humidity style={{width: 22}} />
    };

const unitsSymbols: any = {
  "Temperature": "°",
  "PPM": "\u209A\u209A\u2098" ,
  "RPM": "\u1D63\u209A\u2098",
  "Open/Close": "",
  "Percent": "%"
  };

const hasValue = (value: any) => {
    return !!value || value === 0;
  };

const ControlItem = (props: any) => {
  const [togglePowerButton, setTogglePowerButton] = useState<boolean>(false);
  const [targetPowerVal, setTargetPowerVal] = useState<any>(null);
  const [groupStatus, setGroupStatus] = useState<any>({
    power: false,
    setPoint: null,
    mode: null
  });
  let spinnerTimer: number = 0;

  const types = useStoreState((state) => state.typesStore);
  const devices = useStoreState((state) => state.deviceStore.devices);
  const {updateSensorValue, _storeUpdateSensor} = useStoreActions((actions) => actions.sensorStore);

  const groupPowerControl = useStoreActions(
    (state) => state.groupStore.changePowerState
  );
  const unitPowerControl = useStoreActions(
    (state) => state.unitStore.changePowerState
  );
  const addMessage = useStoreActions((actions) => actions.messageStore.addMessage);
  const { item, isGroup, siteId, selectItem, hasSchedules, isSensor, isCelsius, updateLocalSensor } = props;

  const {
    types: { operationModes, operationStatuses, sensorTypes, sensorMeasurementUnits },
    operationStatusesMirror: { on: powerOnVal, off: powerOffVal }
  } = types;

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!isGroup) {
      return;
    }

    let power: boolean = false,
      setPoint: any = null,
      mode: any = null,
      first: boolean = true;
    const { units } = item;

    for (let x in units) {
      const unit = units[x];

      if (!unit) {
        continue;
      }
      if (first) {
        power = unit.activeOperationStatus === +powerOnVal;
        setPoint = unit.activeSetpoint;
        mode = unit.activeOperationMode;
        first = false;
        continue;
      }
      if (!unit) {
        continue;
      }
      if (!power && unit.activeOperationStatus === +powerOnVal) {
        power = true;
      }
      if (+x !== 0 && !isNaN(setPoint) && setPoint !== unit.activeSetpoint) {
        setPoint = null;
      }
      if (+x !== 0 && !isNaN(mode) && mode !== unit.activeOperationMode) {
        mode = null;
      }
    }
    setGroupStatus({ power, setPoint, mode });
  }, [isGroup, item, powerOnVal]);

  const useStyles = makeStyles(controlItemStyles);
  const classes = useStyles();

  const modeIcons: IObject = {
    COOL: <ColdMode className={clsx(classes.iconStyle, classes.modeIcon)} />,
    HEAT: <HotMode className={clsx(classes.iconStyle, classes.modeIcon)} />,
    AUTO: <AutoMode className={clsx(classes.iconStyle, classes.modeIcon, classes.smallIcons)} />,
    DRY: <DryIcon className={clsx(classes.iconStyle, classes.modeIcon, classes.smallIcons)} />,
    FAN: <ModeFanIcon className={clsx(classes.iconStyle, classes.modeIcon, classes.smallIcons)} />
  };

  const {
    id: itemId,
    activeOperationMode,
    activeOperationStatus: itemPowerStatus,
    activeSetpoint,
    ambientTemperature,
    name,
    message,
    filter,
    isConnected,
    device,
    type,
    readingValue = 0,
    userData = {}
  } = item;

  const sensorType = sensorTypes[type] || {};
  const {enableMinMaxSelection, measurementUnits, enableMeasurementUnitSelection, valueMax, valueMin} = sensorType;
  const activeMeasurementUnit = enableMeasurementUnitSelection && userData?.measurementUnitsType ? userData?.measurementUnitsType : measurementUnits;
  const sensorUnit = sensorMeasurementUnits[activeMeasurementUnit]?.name;
  const {rangeMax : userMax, rangeMin: userMin} = userData;
  const value = +readingValue;

  const displayValue = !sensorUnit || type === 130 || type === 7 ? (enableMinMaxSelection ? hasValue(userMax) && hasValue(userMin) ?
(((value - valueMin) * (+userMax - +userMin)) / (valueMax - valueMin)) + +userMin : value :  value).toFixed(1) :
sensorUnit === "Temperature" ? (isCelsius ? value : (Math.round(((value * 9 / 5) + 32) * 10) / 10)) :
sensorUnit === "Open/Close" ?  type === 129 ? +value === 0 ? t`OFF` : t`ON` : (+value === 0 ? t`OPEN` : t`CLOSE`) : value;

  const deviceIsConnected = devices[device] && devices[device].isConnected;

  const isItemOn = +itemPowerStatus === +powerOnVal;
  const hasError = message && message.replace(/\s/g, "").toUpperCase() !== "OK";

  const toggleUnitPower = (event: any) => {
    event.stopPropagation();
    event.preventDefault();

    const changeStatusTo: number =
      operationStatuses[itemPowerStatus] === "on" ? +powerOffVal : +powerOnVal;

    setTargetPowerVal(changeStatusTo);
    setTogglePowerButton(true);
    spinnerTimer = window.setTimeout(() => {
      setTogglePowerButton(false);
    }, 5000);

    unitPowerControl({ unitId: itemId, state: +changeStatusTo })
      .then(() => {
        let recentsCopy: any = localStorage.getItem("recents")
          ? JSON.parse(localStorage.getItem("recents") as string)
          : {};

        if (recentsCopy && recentsCopy[siteId]) {
          if (recentsCopy[siteId].indexOf(itemId) > -1) {
            recentsCopy[siteId].splice(recentsCopy[siteId].indexOf(itemId), 1);
          } else {
            if (recentsCopy[siteId].length > 2) {
              recentsCopy[siteId].pop();
            }
          }
          recentsCopy[siteId].unshift(itemId);
        } else {
          recentsCopy[siteId] = [itemId];
        }
        localStorage.setItem("recents", JSON.stringify(recentsCopy));
      })
      .catch((error: any) => {
        addMessage({ message: error.message });
      });
  };

  useEffect(() => {
    if (!togglePowerButton) {
      return;
    }
    if (
      itemPowerStatus === targetPowerVal ||
      groupStatus.power === targetPowerVal
    ) {
      setTogglePowerButton(false);
      clearTimeout(spinnerTimer);
    }
  }, [
    togglePowerButton,
    targetPowerVal,
    itemPowerStatus,
    spinnerTimer,
    groupStatus
  ]);

  const switchClick = (event: any) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const handleOnOff = (event: any) => {
    event.stopPropagation();
    event.preventDefault();

    setLoading(true);
    const updateValueTo = +value === 0 ? 1 : 0;
    updateSensorValue({id: itemId, data: {value: updateValueTo}})
    .then(() => {
      updateLocalSensor(itemId, updateValueTo);
    })
    .catch((err: any) => {
      addMessage({ message: err.message });
    }).finally(() => setLoading(false));

  };

  const changeGroupPowerState = (event: any, powerOn: boolean) => {
    event.preventDefault();
    event.stopPropagation();
    const state = powerOn ? +powerOnVal : +powerOffVal;

    setTargetPowerVal(powerOn);
    setTogglePowerButton(true);
    spinnerTimer = window.setTimeout(() => {
      setTogglePowerButton(false);
    }, 5000);

    groupPowerControl({ groupId: itemId, state })
      .catch((error: any) => {
        addMessage({ message: error.message });
      })
      .finally(() => {
        selectItem(siteId, itemId, isGroup, false, false);
      });
  };

  const {
    power: groupPower,
    setPoint: groupSetPoint,
    mode: groupMode
  } = groupStatus;

  return (
    <div
      className={clsx(classes.unitStyle, isGroup && classes.groupStyle, {
        [classes.removedStyle]: !isSensor && (!isConnected || !deviceIsConnected) && !isGroup
      })}
      onClick={() =>
        (isSensor ? selectItem(siteId, itemId, isGroup, isSensor, true) : !isConnected || !deviceIsConnected) && !isGroup ? {} : selectItem(siteId, itemId, isGroup, isSensor, true)
      }
    >
      {isGroup ? (
        <Fragment>
          <div className={classes.locSec}>
            <Typography noWrap>{name}</Typography>
          </div>

          <div className={clsx(classes.tempSec)}>
            <div className={classes.modeIconPlace}>
              {groupMode !== null && modeIcons[operationModes[groupMode]]}
            </div>
            <Typography className={classes.tempText}>
              {groupSetPoint !== null ? `${groupSetPoint}°` : ""}
            </Typography>
          </div>

          <Grid container className={classes.powerAlertsSchedulesContainer}>
            {!togglePowerButton && (
              <div className={classes.notesSec}>
                {hasSchedules && <AccessTime className={classes.noteIcon} />}
              </div>
            )}

            {togglePowerButton && (
              <PulseLoader size={5} color="white" margin="3px" />
            )}
            <Switch
              checked={groupPower}
              disableRipple={true}
              onClick={(e: any) => switchClick(e)}
              switchChange={(e: any) => changeGroupPowerState(e, !groupPower)}
              value="checkedA"
            />
          </Grid>
        </Fragment>
      ) : (
          <Fragment>
            <div className={classes.locSec}>
              <Typography noWrap>{name}</Typography>
            </div>

            {!isSensor && (
              <>
                <div className={clsx(classes.tempSec, !isItemOn && classes.offStyle)}>
                  <div className={clsx(classes.iconDiv, !isItemOn && classes.notVisibile)}>
                    {modeIcons[operationModes[activeOperationMode]]}
                  </div>
                  <div className={classes.tempInfo}>
                    <Typography className={classes.tempText}>
                      {isItemOn ? `${activeSetpoint}°` : t`OFF`}
                    </Typography>
                    <Typography className={classes.roomTemp}>
                      {t`Now: ${ambientTemperature}°`}
                    </Typography>
                  </div>
                </div>

                <Grid container className={classes.powerAlertsSchedulesContainer}>
                  {!togglePowerButton && (
                    <div className={classes.notesSec}>
                      {hasSchedules && <AccessTime className={classes.noteIcon} />}
                      {(hasError || filter) && (
                        <ReportProblemOutlined className={classes.noteIcon} />
                      )}
                    </div>
                  )}

                  {togglePowerButton && (
                    <PulseLoader size={5} color="white" margin="3px" />
                  )}
                  <Switch
                    checked={isItemOn}
                    disableRipple={true}
                    onClick={(e: any) => switchClick(e)}
                    switchChange={(e: any) => toggleUnitPower(e)}
                    value="checkedA"
                    disabled={!isConnected || !deviceIsConnected}
                  />
                </Grid>
              </>
            )}
            { isSensor && (
              <>
                <div className={classes.tempSec}>
                  <div className={classes.iconDiv}>
                    { type !== 6 ?
                      icons[activeMeasurementUnit] || ""
                      : value === 0 ? <OpenSensor  height="30" width="30" className={clsx(classes.iconStyle, classes.modeIcon, classes.smallIcons)}/>
                                      : <ClosedSensor height="30" width="30" className={clsx(classes.iconStyle, classes.modeIcon, classes.smallIcons)} />}
                  </div>
                  <Typography className={clsx(classes.tempInfo , classes.tempTextSensor, {[classes.offText] : t`OFF` === displayValue})}>
                    {displayValue}{unitsSymbols[sensorUnit || ""] || ""}
                  </Typography>
                </div>
                <Grid container className={classes.onOffSensor}>
                {type === 129 && <>
                <div>
                {loading && (
                  <PulseLoader size={5} color="white" margin="3px" />
                )}
                </div>
                <Switch
                  checked={value === 1}
                  disableRipple={true}
                  onClick={(e: any) => switchClick(e)}
                  switchChange={(e: any) => handleOnOff(e)}
                  value="checkedA"
                />
                </>}
                </Grid>
              </>
            )}
          </Fragment>
        )}
    </div>
  );
};

export default ControlItem;

import React, { useState, useEffect } from "react";
import { t } from "ttag";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import { useStoreActions, useStoreState } from "../../Stores/typedHooks";
import { AppLogo, AppTitle } from "../../svgComponents";
import { FormikField, FormikPhoneInput, Select, Button } from "../../widgets";
import createUserFormStyles from "./createUserForm.style";

interface IProps {
  classes?: any;
  history: any;
}

const CreateUserForm: React.FC<IProps> = ({ history }) => {
  const [temperatureScaleOptions, setTemperatureScaleOptions] = useState<
    [object]
  >([{}]);

  const types = useStoreState(state => state.typesStore.types);
  const temperatureScaleMirror = useStoreState(
    state => state.typesStore.temperatureScaleMirror
  );
  const { temperatureScale: tempOptions } = types;

  const [temperatureScale, setTemperatureScale] = useState<any>({
    value: temperatureScaleMirror.celsius,
    label: "celsius"
  });

  const startLoader = useStoreActions(
    actions => actions.loaderStore.startLoader
  );
  const finishLoader = useStoreActions(
    actions => actions.loaderStore.finishLoader
  );
  const addMessage = useStoreActions(
    actions => actions.messageStore.addMessage
  );
  const createUserUsingDeviceToken = useStoreActions(
    actions => actions.userStore.createUserUsingDeviceToken
  );

  const useStyles = makeStyles(createUserFormStyles);
  const classes = useStyles();

  const validationSchema = Yup.object({
    username: Yup.string()
      .required(t`required field`)
      .min(6, t`Username is too short- should be 6 chars minimum.`),
    password: Yup.string()
      .required(t`required field`)
      .min(8, t`Password is too short - should be 8 chars minimum.`)
      .matches(/[A-Z]/, t`password must include capital letters`)
      .matches(/[a-z]/, t`password must include lower case letters`)
      .matches(/[0-9]/, t`password must include numbers`),
    confrimPassword: Yup.string()
      .required(t`Confirm password is required`)
      .oneOf([Yup.ref("password"), null], t`Passwords must match`),
    email: Yup.string().test(
      "email validation",
      t`email must be a valid email`,
      function (value) {
        if (!value) {
          return true;
        }

        const emailSchema = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailSchema.test(String(value).toLowerCase())) {
          return true;
        }
        return false;
      }
    ),
    phone: Yup.string()
      .required(t`required field`)
      .min(11, t`Phone number should be 10 chars minimum.`)
  });

  useEffect(() => {
    const dropdownkeys = Object.keys(tempOptions);
    const dropdownOptions: [object] = [{}];
    dropdownOptions.shift();

    dropdownkeys.map(key => {
      if (tempOptions[key] !== "unknown") {
        dropdownOptions.push({ value: +key, label: tempOptions[key] });
      }
      return key;
    });

    setTemperatureScaleOptions(dropdownOptions);
  }, [tempOptions]);

  const handleFormdSubmit = (values: any) => {
    const token = localStorage.getItem("regToken") || "";
    const { firstName, lastName, username } = values

    const { phone } = values;
    const newPhone = phone.replace(/[+]/g, "");

    const userData = {
      ...values,
      phone: newPhone,
      confrimPassword: undefined,
      temperatureScale: temperatureScale.value,
      token
    };

    startLoader();
    console.log("called")
    createUserUsingDeviceToken({
      name: firstName || lastName ? firstName + " " + lastName : username,
      token,
      userData
    })
      .then((customer: any) => {
        history.push(
          `/device-registration/customer/${customer.id}/new-site`

        );
      })
      .catch((err: any) =>
        addMessage({
          type: "error",
          message: err.message
        })
      )
      .finally(() => finishLoader());
  };

  const goBack = () => {
    history.push("/");
  };

  const handleTempSelectChange = (selectedOption: any) => {
    setTemperatureScale(selectedOption);
  };

  return (
    <div className={classes.screenContainer}>
      <Grid container className={classes.container}>
        <div className={classes.logoSection} onClick={goBack}>
          <AppLogo className={classes.logoStyle} />
          <AppTitle className={classes.logoTitleStyle} />
        </div>
        <Typography className={classes.typoStyle}>{t`Personal Details`}</Typography>
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            username: "",
            email: "",
            password: "",
            confrimPassword: "",
            phone: ""
          }}
          onSubmit={values => handleFormdSubmit(values)}
          enableReinitialize={true}
          validationSchema={validationSchema}
          render={({ values, setFieldValue, ...formikProps }) => {
            return (
              <Form className={classes.inputsContainer}>
                <FormikField
                  name="username"
                  variant="filled"
                  disableUnderline
                  placeholder={t`Username`}
                  formikProps={formikProps}
                  className={classes.wrapperStyle}
                />
                <FormikField
                  name="firstName"
                  variant="filled"
                  disableUnderline
                  placeholder={t`First Name`}
                  formikProps={formikProps}
                  className={classes.wrapperStyle}
                />
                <FormikField
                  name="lastName"
                  variant="filled"
                  disableUnderline
                  placeholder={t`Last Name`}
                  formikProps={formikProps}
                  className={classes.wrapperStyle}
                />
                <FormikField
                  name="email"
                  variant="filled"
                  disableUnderline
                  placeholder={t`Email`}
                  formikProps={formikProps}
                  className={classes.wrapperStyle}
                />
                <FormikPhoneInput
                  name="phone"
                  formikProps={formikProps}
                  className={classes.feildContainer}
                  inputClass={classes.phoneInput}
                  inputContainerClass={classes.phoneInputContainer}
                  errorContainerStyle={classes.errorContainerStyle}
                  errorStyle={classes.phoneErrorStyle}
                />
                <Select
                  value={temperatureScale || ""}
                  variant="filled"
                  suggestions={temperatureScaleOptions}
                  handleSelectChange={handleTempSelectChange}
                  clear={false}
                  placeholder={t`Temperature Scale`}
                  className={classes.wrapperStyle}
                />
                <FormikField
                  name="password"
                  variant="filled"
                  disableUnderline
                  type="password"
                  placeholder={t`Password`}
                  formikProps={formikProps}
                  className={classes.passwordWrapperStyle}
                />
                <FormikField
                  name="confrimPassword"
                  variant="filled"
                  disableUnderline
                  type="password"
                  placeholder={t`Confrim Password`}
                  formikProps={formikProps}
                  className={classes.wrapperStyle}
                />
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.submitButtonStyle}
                >
                  {t`REGISTER`}
                </Button>
              </Form>
            );
          }}
        />
      </Grid>
    </div>
  );
};

export default CreateUserForm;

import { createStyles, fade } from "@material-ui/core/styles";

export const controlItemStyles = (theme: any) =>
  createStyles({
    unitStyle: {
      width: "90%",
      height: "60px",
      margin: "5px",
      borderRadius: "6px",
      padding: "3px 9px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      background: fade(theme.palette.colors.white, 0.1),
      color: theme.palette.colors.white
    },
    groupStyle: {
      background: fade(theme.palette.colors.white, 0.25)
    },
    controlSec: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      height: "100%"
    },
    iconDiv: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flex: 1
    },
    tempInfo: {
      display: "flex",
      flexDirection: "column",
      width: "70%",
      justifyContent: "flex-start",
      marginLeft: "5px"
    },

    roomTemp: {
      fontSize: "14px",
      fontWeight: 300,
      color: "#fff"
    },
    tempSec: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      fontSize: "26px",
      height: "100%",
      width: "25%",
      flex: 1
    },
    tempText: {
      fontSize: "33px",
      lineHeight: "unset"
    },
    tempTextSensor: {
    fontSize: "26px",
    fontWeight: 300,
    fontFamily: "RobotoLight",
    lineHeight: "normal",
    letterSpacing: "normal",
    marginLeft: 5
  },
    locSec: {
      paddingLeft: "10px",
      justifyContent: "space-between",
      alignItems: "center",
      fontSize: "16px",
      fontWeight: "bold",
      width: "25%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-line-clamp": 3,
      "-webkit-box-orient": "vertical",
      maxHeight: "60px"
    },
    notesSec: {
      display: "flex",
      height: "100%",
      width: "42%",
      justifyContent: "space-between",
      alignItems: "center"
    },
    iconStyle: {
      fontSize: "17.5px"
    },
    modeIcon: {
      margin: "0 5px"
    },
    sensorIconContainer: {
      // marginRight: "6px",
      display: "flex",
      justifyContent: "center",
      width: 28,
      maxWidth: 28,
      marginRight: 5,
      minWidth: 28,
      alignItems: "center"
    },
    powerOnIcon: {
      color: theme.palette.colors.black
    },
    powerOffIcon: {
      color: theme.palette.colors.white
    },
    itemOff: {
      "& *": {
        color: theme.palette.colors.fontGreyColor
      }
    },
    nameStyle: {
      fontSize: "20px",
      textOverflow: "ellipsis",
      overflow: "hidden",
      display: "inline-block"
    },
    iconButton: {
      backgroundColor: theme.palette.colors.lightGray,
      minWidth: "40px",
      height: "40px",
      margin: "0 5px",
      "& *": {
        color: theme.palette.colors.mainScreenColorDark
      }
    },
    noteIcon: {
      fontSize: "20px"
    },

    powerAlertsSchedulesContainer: {
      alignItems: "center",
      width: "34%",
      justifyContent: "space-between",
      height: "100%"
    },
    customizedSwitch: {
      color: theme.palette.colors.darkGray
    },
    switchTrack: {
      backgroundColor: theme.palette.colors.darkGray
    },
    smallIcons: {
      transform: "scale(0.5)",
      margin: "-10px",
      marginRight: "-4px"
    },
    offStyle: {
      color: fade(theme.palette.colors.lightWhite, 0.6)
    },
    notVisibile: {
      visibility: "hidden"
    },
    modeIconPlace: {
      minWidth: "30px"
    },
    removedStyle: {
      opacity: 0.5
    },
    onOffSensor: {
    display: "flex",
    alignItems: "center",
    width: "34%",
    justifyContent: "space-between",
    height: "100%"
  },
    offText: {
  color: fade("#fff", 0.4)
}
  });

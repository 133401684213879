import React from 'react';

function SvgRefresh(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 25 25" {...props}>
      <path
        d="M22.639 12.175L25 7.429l-2.68.911C20.692 4.53 16.814 2 12.5 2 8.01 2 3.987 4.735 2.487 8.803l2.004.681c1.2-3.255 4.42-5.443 8.009-5.443 3.414 0 6.472 1.991 7.798 4.986l-2.604.884 4.945 2.264zM0 16.98l2.679-.911c1.629 3.81 5.506 6.34 9.821 6.34 4.49 0 8.513-2.735 10.013-6.802l-2.004-.682c-1.201 3.255-4.42 5.443-8.009 5.443-3.414 0-6.472-1.991-7.796-4.986l2.602-.884-4.945-2.264L0 16.98z"
        fill="#AAA2AA"
      />
    </svg>
  );
}

export default SvgRefresh;

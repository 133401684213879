import * as React from "react";

function SvgSideArrow(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 15 37" {...props}>
      <path
        d="M.5.818l13.838 17.841L.5 36.5"
        stroke="#FFF"
        strokeWidth={1.459}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default SvgSideArrow;

import "./index.css";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import locale from "./services/localeService";

locale.init();

ReactDOM.render(<App />, document.getElementById("root"));

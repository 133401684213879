import React from "react";

const SvgDelete = props => (
  <svg id={props.id} width="20" height="22" viewBox="0 0 20 22" {...props}>
    <defs>
      <path id={`delete_svg__a-${props.id}`} d="M0 .29h20.09v1.791H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(0 4.216)">
        <mask id={`delete_svg__b-${props.id}`} fill="#fff">
          <use xlinkHref={`#delete_svg__a-${props.id}`} />
        </mask>
        <path
          fill="#AAA2AA"
          d="M19.194 2.081H.895a.895.895 0 0 1 0-1.79h18.3a.896.896 0 1 1 0 1.79"
          mask={`url(#delete_svg__b-${props.id})`}
        />
      </g>
      <path
        fill="#AAA2AA"
        d="M6.959 4.506h6.562V2.523a.733.733 0 0 0-.733-.732H7.691a.733.733 0 0 0-.732.732v1.983zm8.353 1.79H5.168V2.524A2.526 2.526 0 0 1 7.691 0h5.097a2.527 2.527 0 0 1 2.524 2.523v3.774zM15.36 21.057H4.94a2.72 2.72 0 0 1-2.716-2.716V8.615a.895.895 0 1 1 1.79 0v9.726c0 .51.415.926.927.926H15.36c.51 0 .925-.416.925-.926V8.615a.896.896 0 1 1 1.792 0v9.726a2.72 2.72 0 0 1-2.717 2.716"
      />
      <path
        fill="#AAA2AA"
        d="M8.167 17.073a.896.896 0 0 1-.896-.896v-5.363a.896.896 0 1 1 1.792 0v5.363a.896.896 0 0 1-.896.896M12.133 17.073a.896.896 0 0 1-.896-.896v-5.363a.896.896 0 1 1 1.792 0v5.363a.896.896 0 0 1-.896.896"
      />
    </g>
  </svg>
);

export default SvgDelete;

import { AppBar, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { UserMenu } from "../";
import { useStoreState } from "../../Stores/typedHooks";
import { MenuIcon, Refresh } from "../../svgComponents";
import styles from "./header.style";

const Header: React.FC<any> = ({
  history,
  userInfo,
  logoutCb,
  selectedSiteId,
  openStatisticsDialog,
  siteTemp
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const tempSymbol = useStoreState((state) => state.userStore.temperatureSymbol);

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  useEffect(() => {
    const { location } = history;
    const { menuOpen } = location;

    if (!menuOpen) {
      return;
    }
    setOpen(menuOpen);
  }, [history]);

  const handleUserMenu = () => {
    setOpen(!open);
  };

  return (
    <AppBar position="static" className={classes.header}>
      {siteTemp === "loading" && (
        <Refresh className={classes.refreshStyle} />
      )}
      {siteTemp !== "loading" && (
        <Typography className={classes.tempText}>
          {siteTemp && siteTemp.toFixed(0)}
          <span className={classes.tempSymbolStyle}>
            {siteTemp && tempSymbol}
          </span>
        </Typography>
      )}
      <MenuIcon className={classes.menuIcon} onClick={handleUserMenu} />
      <UserMenu
        open={open}
        userInfo={userInfo}
        history={history}
        logoutCb={logoutCb}
        opened={handleUserMenu}
        selectedSiteId={selectedSiteId}
        openStatisticsDialog={openStatisticsDialog}
      />
    </AppBar>
  );
};

export default Header;

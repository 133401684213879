import React, { useState, useEffect } from "react";
import { Grid, makeStyles, Input } from "@material-ui/core";
import { Toolbar, UserManagementElement } from "../../components";
import { useStoreState, useStoreActions } from "../../Stores/typedHooks";
import { SearchIcon, ArrowBack, AddIcon } from "../../svgComponents";
import usersManagementPageStyles from "./usersManagementPage.style";
import { t } from "ttag"

interface IUsersManagementProps {
  history: any;
  match: any;
}

const UsersManagementPage: React.FC<IUsersManagementProps> = props => {
  const [filteredUsersList, setFilteredUsersList] = useState<any>({});
  const [customerUsers, setCustomerUsers] = useState<any>({});
  const [customerInvites, setCustomerInvites] = useState<any>({});
  const [filteredInvites, setFilteredInvites] = useState<any>({});

  const startLoader = useStoreActions(
    actions => actions.loaderStore.startLoader
  );
  const finishLoader = useStoreActions(
    actions => actions.loaderStore.finishLoader
  );
  const addMessage = useStoreActions(
    actions => actions.messageStore.addMessage
  );

  const getCutomerUsers = useStoreActions(
    actions => actions.customerStore.getCustomerUsers
  );

  const loggedUser = useStoreState(state => state.userStore.user);

  const getCustomerInvites = useStoreActions(
    actions => actions.customerStore.getCustomerInvites
  );

  const { customer: customerId, id: loggedUserId } = loggedUser;

  const { history } = props;
  const useStyles = makeStyles(usersManagementPageStyles);
  const classes = useStyles();

  useEffect(() => {
    if (!customerId) {
      return;
    }
    startLoader();
    getCutomerUsers(customerId)
      .then((customerUsers: any) => {
        setFilteredUsersList(customerUsers);
        setCustomerUsers(customerUsers);
      })
      .finally(finishLoader());
  }, [finishLoader, startLoader, customerId, getCutomerUsers]);

  useEffect(() => {
    if (!customerId) {
      return;
    }
    startLoader();
    getCustomerInvites(customerId)
      .then((invites: any) => {
        setFilteredInvites(invites);
        setCustomerInvites(invites);
      })
      .catch((err: any) => {
        addMessage({
          message: err.message
        });
      })
      .finally(finishLoader());
  }, [
    addMessage,
    customerId,
    finishLoader,
    getCustomerInvites,
    getCutomerUsers,
    startLoader
  ]);

  const goBack = () => {
    const { history } = props;
    history.push({ pathname: "/site", menuOpen: true });
  };

  const goToNewInvite = () => {
    const { history } = props;
    history.push(`/new-invite`);
  };

  const searchUser = (event: any) => {
    const filteredUsers = Object.values(customerUsers).filter((user: any) => {
      const searchingValue = event.target.value.toLowerCase();
      const { firstName, lastName } = user;
      const userFullName = `${firstName} ${lastName}`;
      if (userFullName.toLowerCase().indexOf(searchingValue) > -1) {
        return user;
      }
      return null;
    });

    const filteredInvitesList = Object.values(customerInvites).filter(
      (invite: any) => {
        const searchingValue = event.target.value.toLowerCase();
        const { firstName, lastName } = invite;
        const userFullName = `${firstName} ${lastName}`;
        if (userFullName.toLowerCase().indexOf(searchingValue) > -1) {
          return invite;
        }
        return null;
      }
    );

    setFilteredUsersList(filteredUsers);
    setFilteredInvites(filteredInvitesList);
  };

  return (
    <div className={classes.screenContainer}>
      <Grid container className={classes.root}>
        <Toolbar
          title={t`Users Management`}
          leftIconComponent={<ArrowBack />}
          leftAction={goBack}
          rightIconComponent={<AddIcon />}
          rightAction={goToNewInvite}
        />
        <div className={classes.searchBox}>
          <SearchIcon className={classes.searchIconStyle} />

          <Input
            placeholder="Search User"
            onKeyUp={searchUser}
            disableUnderline={true}
            classes={{
              root: classes.inputRoot
            }}
          />
        </div>
        <UserManagementElement
          loggedUserId={loggedUserId}
          users={filteredUsersList}
          loggedUser={loggedUser}
          invites={filteredInvites}
          history={history}
          customerId={customerId}
        />
      </Grid>
    </div>
  );
};

export default UsersManagementPage;

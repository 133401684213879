import React from "react";
import { t } from "ttag";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles
} from "@material-ui/core";
import clsx from "clsx";
import { Button } from "../../widgets";
import confirmationDialogStyle from "./confirmationDialog.style";

interface ToolbarProps {
  onCancel: any;
  onConfrim: any;
  onClose: any;
  title: string;
  text: string;
  confrimLabel: string;
  cancelLabel: string;
  openDialog: boolean;
  iconComponent?: any;
  dialogType?: string;
  hideCancle?: boolean;
}

const ConfirmationDialog = (props: ToolbarProps) => {
  const {
    onCancel,
    onConfrim,
    title,
    text,
    openDialog,
    cancelLabel,
    confrimLabel,
    onClose,
    dialogType = "info",
    hideCancle = false
  } = props;

  const useStyles = makeStyles(confirmationDialogStyle);
  const classes: { [index: string]: any } = useStyles();

  return (
    <Dialog
      open={openDialog}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
      classes={{ paper: classes.dialogStyle }}
    >
      <DialogTitle
        disableTypography
        id="responsive-dialog-title"
        className={classes.dialogTitle}
      >
        {title}
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContentStyle }}>
        <DialogContentText
          className={classes.contentStyle}
        >{text}</DialogContentText>
      </DialogContent>
      <DialogActions
        className={classes.dialogActionStyle}
        classes={{ spacing: classes.actionsSpacing }}
      >
        {!hideCancle &&
          <Button
            onClick={onCancel}
            className={clsx(classes.cancelButtonStyle, classes[dialogType])}
            variant="contained"
          >
            {cancelLabel}
          </Button>
        }

        <Button
          onClick={onConfrim}
          variant="outlined"
          className={classes.confirmButtonStyle}
        >
          {confrimLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;

import { createStyles } from "@material-ui/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";

export default (theme: any) =>
  createStyles({
    screenContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      background: theme.gradient.schedule,
    },
    gridStyle: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      width: "100%",
      maxWidth: "768px",
    },
    iconStyle: { fontSize: "22px", color: theme.palette.colors.white },
    fieldContainerStyle: { width: "80%", overflow: "auto" },
    typographyMargin: { marginBottom: "-10px" },
    testclass: {
      width: "50px",
      minWidth: "60px",
    },
    schedulesContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      flex: 1,
      padding: "0 20px",
      boxSizing: "border-box",
      overflowY: "auto",
    },
    scheduleHeader: {
      display: "flex",
      justifyContent: "space-between",
      backgroundColor: fade(theme.palette.colors.lightWhite, 0.2),
      width: "100%",
      height: "50px",
      borderRadius: "6px",
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      padding: "10px",
      boxSizing: "border-box",
    },
    scheduleTitle: {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      flex: 5,
      "& *": {
        fontSize: "18px",
        fontWeight: "500",
        color: theme.palette.colors.white,
        marginRight: "7px",
      },
    },
    scheduleControl: {
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      width: "20%",
    },
    scheduleBody: {
      display: "flex",
      flex: 1,
      height: "235px",
      flexDirection: "column",
      backgroundColor: fade(theme.palette.colors.lightWhite, 0.1),
      width: "100%",
      borderRadius: "6px",
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      padding: "10px",
      paddingBottom: "25px",
      boxSizing: "border-box",
    },
    scheduleContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      margin: "12px 0",
      minHeight: "max-content",
    },
    bodyRow: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flex: 1,
      height: "100%",
    },
    timeSection: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      height: "100%",
      justifyContent: "space-between",
      margin: "10px 10px 15px 0",
    },
    setPointSection: {
      display: "flex",
      height: "100%",
      justifyContent: "center",
      alignItems: "center",
      paddingRight: "15px",
    },
    setPointStyle: {
      color: theme.palette.colors.white,
      fontSize: "50px",
    },
    timeStyle: {
      color: theme.palette.colors.white,
      fontSize: "16px",
    },
    tempSymbolStyle: {
      color: theme.palette.colors.white,
      fontSize: "30px",
    },
    dividerStyle: {
      backgroundColor: fade(theme.palette.colors.lightWhite, 0.1),
    },
    controlSec: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      height: "100%",
    },
    scheduleStatusStyle: {
      color: theme.palette.colors.white,
      fontSize: "18px",
    },
    noSchedulesStyle: {
      fontSize: "30px",
      color: theme.palette.colors.white,
      marginTop: "20px",
    },
    longNamestyle: {
      maxWidth: "11ch",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden !important",
      display: "inline-block",
    },
    rowContainer: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      minHeight: "110px",
    },
    setpointContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },

    inputWrapperStyle: {
      marginTop: 0,
      marginBottom: "10px",
      fontSize: "30px",
      "& *": { borderRadius: "6px" },
      "&  div": {
        border: "1px solid rgba(253, 253, 254, 0.3)",
      },
      "& input:focus + fieldset": {
        borderWidth: "1px !important",
      },
      "& input": {
        color: "white",
        fontSize: "16px",
        fontFamily: "Roboto",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        padding: "16px 0px",
        paddingLeft: "20px",
      },
      "& label": {
        color: "white",
      },
    },
    inputClass: {
      "& input": {
        color: theme.palette.colors.lightWhite,

        height: "50px",
        padding: 0,
        paddingLeft: "15px",
        paddingRight: "15px",
      },
      fontSize: "16px",
      color: theme.palette.colors.lightWhite,
      "& input:hover + fieldset": {
        "&$notchedOutline": {
          borderColor: fade(theme.palette.colors.lightWhite, 0.3),
        },
      },
      "& input:focus + fieldset": {
        "&$notchedOutline": {
          borderColor: fade(theme.palette.colors.lightWhite, 0.3),
        },
      },
      "& input:disabled + fieldset": {
        borderColor: `${theme.palette.colors.lightWhite} !important`,
        "&$notchedOutline": {
          borderColor: fade(theme.palette.colors.lightWhite, 0.3),
        },
      },
      "& ::placeholder": {
        color: theme.palette.colors.lightWhite,
        opacity: 0.5,
        fontSize: "16px",
        lineHieght: "normal",
        fontWeight: "normal",
      },
      "& label": {
        color: `${theme.palette.colors.lightWhite} !important`,
      },
    },
  });

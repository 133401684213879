import React from "react";

const SvgAvatar = props => (
  <svg width={110} height={110} {...props}>
    <g fill="#AAA2AA" fillRule="evenodd">
      <circle cx={55} cy={55} r={55} fillOpacity={0.2} />
      <path
        d="M82.065 68.915a16.91 16.91 0 011.054 4.317c.192 1.327.34 2.662.447 3.997l.034.375v.182a6.494 6.494 0 01-6.494 6.499H32.89a6.493 6.493 0 01-6.49-6.499v-.182l.026-.392c.114-1.335.263-2.67.446-3.997a16.978 16.978 0 011.063-4.3c2-4.35 16.697-10.628 27.065-10.628 10.368 0 25.066 6.278 27.065 10.628M55 53.41c-7.46 0-13.507-6.043-13.507-13.502 0-7.46 6.048-13.507 13.507-13.507 7.46 0 13.507 6.047 13.507 13.507S62.459 53.409 55 53.409"
        opacity={0.3}
      />
    </g>
  </svg>
);

export default SvgAvatar;

import React from "react";
import clsx from "clsx";
import { t } from "ttag";
import {
  IconButton,
  Grid,
  Typography,
  Divider,
  InputLabel,
  makeStyles
} from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import styles from "./schedule.style";

interface ScheduleProps {
  startHour: string;
  endHour: string;
  setpoint: number;
  temperatureSymbol: string;
  activatedDays?: any;
  deleteAction: any;
  editAction: any;
  itemId: string;
  days: string[];
}

const Schedule = (props: ScheduleProps) => {
  const {
    startHour,
    endHour,
    setpoint,
    temperatureSymbol,
    editAction,
    deleteAction,
    itemId,
    activatedDays,
    days
  } = props;

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container justify="flex-end" alignItems="center" id="actions">
        <IconButton onClick={() => editAction(itemId)}>
          <Edit className={classes.iconStyle} />
        </IconButton>
        <IconButton onClick={() => deleteAction(itemId)}>
          <Delete className={classes.iconStyle} />
        </IconButton>
      </Grid>
      <Grid container className={classes.detailsContainer} id="details">
        <div>
          <Typography className={classes.typoStyle}>
            {t`start:  `}
            {startHour || "not set"}
          </Typography>
          <Typography className={classes.typoStyle}>
            {t`end:  `}
            {endHour || "not set"}
          </Typography>
        </div>
        <Typography className={classes.setPointStyle}>
          {setpoint ? `${setpoint} ${temperatureSymbol}` : ""}
        </Typography>
      </Grid>
      <Divider className={classes.dividerStyle} />
      <Grid container className={classes.daysContainer} id="days">
        {days.map(day => {
          const isDayHighlited = activatedDays
            ? activatedDays.includes(day)
            : false;

          return (
            <InputLabel
              key={`${day}-label-`}
              className={clsx(classes.dayLabelStyle, {
                [classes.highlited]: isDayHighlited
              })}
            >
              {day.substring(0, 3)}
            </InputLabel>
          );
        })}
      </Grid>
    </div>
  );
};

export default Schedule

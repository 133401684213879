import { Action, action, Thunk, thunk } from "easy-peasy";
import _ from "lodash";
import { Injections } from "./";

export interface IGroupModel {
  groups: any;
  groupsCalled: boolean;
  groupUnitsMapping: any;
  groupSiteMapping: any;
  setGroups: Action<IGroupModel, any>;
  deleteCurrentGroup: Action<IGroupModel, string>;
  setGroupsCalled: Action<IGroupModel, boolean>;
  setGroupSiteMapping: Action<IGroupModel, any>;
  changePowerState: Thunk<IGroupModel, IPowerPayload, Injections>;
  updateGroup: Thunk<IGroupModel, IUpdateGroup, Injections>;
  getGroup: Thunk<IGroupModel, any, Injections>;
  changeSetPoint: Thunk<IGroupModel, ISetPointPayload, Injections>;
  getGroupUnits: Thunk<IGroupModel, string, Injections>;
  deleteGroup: Thunk<IGroupModel, string, Injections>;
  getFullGroups: Thunk<IGroupModel, void, Injections>;
  setGroupUnitsMap: Action<IGroupModel, any>;
  updateGroupUnit: Action<IGroupModel, any>;
}

interface IPowerPayload {
  groupId: string;
  state: number;
}
interface IUpdateGroup {
  groupId: string;
  data: any;
}
interface ISetPointPayload {
  groupId: string;
  setPoint: number;
}

const groupStore: IGroupModel = {
  groups: {},
  groupsCalled: false,
  groupUnitsMapping: {},
  groupSiteMapping: {},
  changePowerState: thunk((actions, payload, { injections }) => {
    const { sdkGroup } = injections;
    const { groupId, state } = payload;

    return sdkGroup.setActiveOperationStatus(groupId, {
      operationStatus: state
    });
  }),
  updateGroup: thunk(async (actions, payload, { injections }) => {
    const { sdkGroup } = injections;
    const { groupId, data } = payload;
    return await sdkGroup.update(groupId, data);
  }),
  getGroup: thunk(async (actions, payload, { injections }) => {
    const { sdkGroup } = injections;
    return await sdkGroup.getGroupById(payload);
  }),
  changeSetPoint: thunk((actions, payload, { injections }) => {
    const { sdkGroup } = injections;
    const { groupId, setPoint } = payload;

    return sdkGroup.setActiveSetpoint(groupId, setPoint);
  }),
  getGroupUnits: thunk((actions, payload, { injections }) => {
    const { sdkGroup } = injections;
    return sdkGroup.getUnits(payload);
  }),
  deleteGroup: thunk((actions, payload, { injections }) => {
    const { sdkGroup } = injections;

    return sdkGroup.delete(payload);
  }),
  setGroups: action((state, groups) => {
    state.groups = groups;
  }),
  deleteCurrentGroup: action((state, id) => {
    const { groups } = state;
    delete groups[id];
    state.groups = groups;
  }),
  setGroupsCalled: action((state, status) => {
    state.groupsCalled = status;
  }),
  getFullGroups: thunk(async (actions, payload, { injections }) => {
    const { sdkGroup } = injections;
    let unitsMap: any = {};
    let sitesMap: any = {};
    sdkGroup.getFullGroups().then((groups: any) => {
      for (let g in groups) {
        let groupSite = "groups";
        const group = groups[g];
        const { id: groupId, units, sensors } = group;
        let firstUnit = true;
        for (let u in units) {
          const unit = units[u];
          unitsMap[unit.id] = groupId;
          if (firstUnit) {
            groupSite = unit.site;
            firstUnit = false;
            continue;
          }
          if (!firstUnit && groupSite !== unit.site) {
            groupSite = "groups";
          }
        }
        if (groupSite !== "groups"){
          for (let s in sensors){
            const sensor = sensors[s];
            if (sensor.site !== groupSite){
              groupSite = "groups";
            }
          }
        }
        if (!sitesMap[groupSite]) {
          sitesMap[groupSite] = [];
        }
        sitesMap[groupSite].push(g);
      }
      actions.setGroups(groups);
      actions.setGroupUnitsMap(unitsMap);
      actions.setGroupSiteMapping(sitesMap);
      actions.setGroupsCalled(true);
    });
  }),
  setGroupSiteMapping: action((state, payload) => {
    state.groupSiteMapping = payload;
  }),
  setGroupUnitsMap: action((state, payload) => {
    state.groupUnitsMapping = payload;
  }),
  updateGroupUnit: action((state, payload) => {
    const { groupId, unit } = payload;
    const { groups } = state;
    if (_.isEmpty(groups) || !groups[groupId]) {
      return;
    }

    state.groups[groupId].units[unit.id] = {
      ...state.groups[groupId].units[unit.id],
      ...unit
    };
  })
};

export default groupStore;

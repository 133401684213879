import { createStyles } from "@material-ui/styles";

export default (theme: any) =>
  createStyles({
    pageContentContainer: {
      display: "flex",
      flexFlow: "column nowrap",
      backgroundImage: theme.gradient.main,
      padding: "20px"
    },
    textContainer: {
      boxShadow: "5px 5px 12px 2px #9a9696 inset",
      borderRadius: "4px",
      boxSizing: "border-box",
      backgroundColor: theme.palette.colors.white,
      maxHeight: "75%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignContent: "flex-start",
      width: "100%",
      overflowY: "scroll",
      color: theme.palette.colors.black,
      textAlign: "justify",
      padding: "10px",
      marginBottom: "20px",
      "& h6": {
        fontSize: "16px",
        fontWeight: "bold",
        textDecoration: "underline"
      },
      "& p": {
        fontSize: "12px",
        textAlign: "justify"
      },
    },
    buttonStyle: {
      "&:disabled": {
        backgroundColor: theme.palette.colors.lightGray
      }
    },
    checkboxLabelStyle: {
      marginTop: "20px",
      "& span": {
        fontSize: "14px",
        fontWeight: "bold"
      }
    },
    logoTitleStyle: {
      maxHeight: "27px",
      width: "auto"
    },
    logoStyle: {
      width: "auto",
      maxHeight: "60px",
      marginRight: "10px"
    },
    logoSection: {
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
      alignSelf: "center",
      width: "80%",
      maxHeight: "60px",
      marginBottom: "20px"
    }
  });

import { createStyles } from "@material-ui/styles";
import { fade } from "@material-ui/core";

export default (theme: any) =>
  createStyles({
    screenContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      backgroundColor: theme.palette.colors.blackFontShadow,
    },
    gridStyle: {
      width: "100%",
      maxWidth: "768px",
      display: "flex",
      flexDirection: "column",
    },
    listStyle: {
      paddingTop: "7px",
      paddingBottom: "0",
      overflow: "auto",
      width: "100%",
      height: "100%",
    },
    listItemStyle: {
      width: "100%",
      display: "flex",
      flexFlow: "column",
      padding: "0 20px",
      boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.05)",
      background: theme.gradient.default,
      marginBottom: "7px",
    },
    listItemTextStyle: {
      opacity: 0.89,
      fontFamily: "Roboto",
      fontSize: "18px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: theme.palette.colors.lightWhite,
    },
    typoStyle: {
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: theme.palette.colors.lightWhite,
    },
    expand: {
      color: theme.palette.colors.lightWhite,
      opacity: 0.6,
      marginRight: "-15px",
      transform: "rotate(0deg)",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
      padding: 0
    },
    expandOpen: {
      marginRight: "-15px",
      color: theme.palette.colors.lightWhite,
      opacity: 0.6,
      transform: "rotate(180deg)",
      padding: 0

    },
    siteNameContainer: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "55px",
    },
    collapseStyle: {
      width: "100%",
      marginBottom: "10px",
    },
    deviceCard: {
      width: "100%",
      background: theme.palette.colors.transparentBackGround,
      display: "flex",
      flexFlow: "column",
      borderRadius: "6px",
      marginBottom: "20px",
    },
    deviceInfoFontStyle: {
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 3.13,
      letterSpacing: "normal",
      color: theme.palette.colors.lightWhite,
      width: "100%",
      borderBottom: `1px solid ${theme.palette.colors.grayBorder}`,
      display: "flex",
      flexFlow: "row nowrap",
      justifyContent: "space-between",
      alignItems: "center",
      height: "50px",
    },
    deviceNameContainerStyle: {
      backgroundColor: fade(theme.palette.colors.lightWhite, 0.2),
      borderRadius: "6px 6px 0 0",
      border: "none",
    },
    warningContainerStyle: {
      color: theme.palette.secondary.main,
      border: "none",
      justifyContent: "flex-start",
    },
    alertPadding: { marginLeft: "7px" },
    systemsContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",
      alignItems: "center",
      minHeight: "120px",
      padding: "10px 0",
    },
    systemCard: {
      minHeight: "100px",
      borderRadius: "6px",
      background: theme.palette.colors.lightWhite,
      width: "85%",
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "flex-start !important",
      justifyContent: "space-between",
      padding: "12px",
      margin: "10px 0",
    },
    systemNameStyle: {
      fontFamily: "Roboto",
      fontSize: "18px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: theme.palette.colors.blackFontShadow,
      marginBottom: "10px",
      alignSelf: "flex-start",
    },
    systemTypoStyle: {
      opacity: 0.89,
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.43,
      letterSpacing: "normal",
      color: theme.palette.colors.blackFontShadow,
      display: "inline-flex",
      alignItems: "center",
      alignSelf: "flex-start",
    },
    brandImageStyle: {
      alignSelf: "flex-start",
      marginBottom: "10px",
      maxWidth: "81px",
      WebkitFilter: "grayscale(100%)" /* Safari 6.0 - 9.0 */,
      filter: "grayscale(100%)",
    },
    systemControlsContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      justifyContent: "space-between",
      alignSelf: "stretch",
    },
    systemControlFirstRow: {
      background: "black",
    },
    systemControlSecondRow: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-end",
      flex: 1,
      width: "100%",
    },
    menuStyle: {
      "& ul": {
        padding: "2px",
      },
    },
    menuItemStyle: {
      padding: "7px",
      fontFamily: "Arial",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      textAlign: "center",
      color: theme.palette.colors.lightBlack,
      minHeight: "unset",
    },
    deleteIconStyle: {
      marginRight: "10px",
    },
    editIcon: {
      fontSize: "17px",
      margin: "0 10px 0 2px",
      width: "18px",
      height: "19px",
    },
    plusIconStyle: {
      margin: "0 12px 0 1px",
      "& path": {
        fill: "rgb(170, 162, 170)",
      },
      "& use": {
        fill: "rgb(170, 162, 170)",
      },
    },
    deviceTypoStyle: { paddingLeft: "15px" },
    systemInfoContainer: {
      width: "100%",
      alignItems: "flex-start",
      justifyContent: "space-between",
      flexFlow: "row nowrap",
    },
    menuIconStyle: {
      paddingRight: "15px", marginLeft: "10px",
      '&:hover': {
        backgroundColor: 'inherit'
      }
    },
    sitesContainer: {
      display: "contents",
      overflow: "auto",
    },
    systemMenuIconStyle: {
      // padding: "3px 0",
      "& g": {
        fill: theme.palette.colors.darkGray,
      },
      '&:hover': {
        backgroundColor: 'inherit'
      }
    },
    coldModeButtonStyle: {
      border: "none",
      borderRight: `1px solid ${theme.palette.colors
        .buttonsGrayBorder} !important`,
      borderTopLeftRadius: "20px !important",
      borderBottomLeftRadius: "20px !important",
      height: "35px",
    },
    heatModeButtonStyle: {
      border: "none",
      borderTopRightRadius: "20px !important",
      borderBottomRightRadius: "20px !important",
      height: "35px",
    },
    modesIconStyle: { transform: "scale(1.2)" },
    groupButtonStyle: {
      display: "flex",
      border: `1px solid ${theme.palette.colors.buttonsGrayBorder}`,
      borderRadius: "20px",
      flexFlow: "row !important",
    },
    iconHolderStyle: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "45px",
      height: "40px",
    },
    siteFirstRow: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "15px",
    },
    addSystemBox: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "6px",
      border: `1px solid ${theme.palette.secondary.main}`,
      padding: "4px 12px",
      width: "85%",
      margin: "5px 0",
      "&:active": {
        backgroundColor: theme.palette.secondary.main,
        borderColor: theme.palette.colors.white,
        "& *": {
          color: theme.palette.colors.white,
        },
      },
    },
    removeChevron: {
      '& div[class$="IndicatorsContainer"]': {
        display: "none",
      },
    },
    redText: { color: theme.palette.secondary.main },
    disconnectedIconStyle: { padding: "0 5px", minWidth: "45px" },
    systemNameContainer: { display: "flex", width: "75%" },
    disconnectedDeviceIconStyle: { minWidth: "30px" },
    systemPowerButton: {
      "& :clicked": {
        backgroundColor: "red",
      },
    },
    iconBtn: {
      // "& .MuiTouchRipple-root span":{
      //   backgroundColor: 'inherit!important',
      //   // opacity: .3,
      // },
    }
  });

export const paperStyle = {
  marginTop: "43px",
  marginLeft: "-25px",
  borderRadius: "6px",
  boxShadow: `rgba(0, 0, 0, 0.46) 0px 6px 17px 3px`,
  backgroundColor: "#FFF",
  padding: "5px",
};

import { FormHelperText, Grid, Input, List, ListItem, ListItemText, makeStyles, Typography } from "@material-ui/core";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import "react-phone-number-input/style.css";

import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { t } from "ttag";
import * as Yup from "yup";
import { useStoreActions, useStoreState } from "../../Stores/typedHooks";
import { AppLogo, AppTitle } from "../../svgComponents";
import { Button, Select } from "../../widgets";
import acceptInviteStyle from "./inviteAccept.style";

interface IProps {
  history: any;
  match: any;
}

const CreateUserForm: React.FC<IProps> = ({ history, match }) => {
  const { params: { inviteToken = "" } = {} } = match;

  const types = useStoreState((state) => state.typesStore.types);

  const { temperatureScale: tempOptions } = types;

  const [temperatureScaleOptions, setTemperatureScaleOptions] = useState<any>([
    {}
  ]);


  const [timeFormatOptions, setTimeFormatOptions] = useState<
    [object]
    >([{}]);

  const [dateFormatOptions, setDateFormatOptions] = useState<
    [object]
    >([{}]);

  const [timeFormat, setTimeFormat] = useState<any>({value: 0, label: types.timeFormat[0].text});
  const [dateFormat, setDateFormat] = useState<any>({value: 0, label: types.dateFormat[0].text});

  const [temperatureScale, setTemperatureScale] = useState<any>({});
  const [invite, setInvite] = useState<any>({});
  const [inviteId, setInviteId] = useState<any>({});

  const startLoader = useStoreActions(
    (actions) => actions.loaderStore.startLoader
  );
  const finishLoader = useStoreActions(
    (actions) => actions.loaderStore.finishLoader
  );
  const addMessage = useStoreActions(
    (actions) => actions.messageStore.addMessage
  );
  const getInvite = useStoreActions((actions) => actions.inviteStore.getInvite);
  const acceptInvite = useStoreActions(
    (actions) => actions.inviteStore.acceptInvite
  );
  const checkUsernameAvailability = useStoreActions(
    (actions) => actions.userStore.checkUsernameAvailability
  );

  const useStyles = makeStyles(acceptInviteStyle);
  const classes = useStyles();

  const validationSchema = Yup.object({
    username: Yup.string()

      .min(6, t`Username is too short- should be 6 chars minimum.`)
      .required(t`required field`),
    password: Yup.string()

      .min(8, t`Password is too short - should be 8 chars minimum.`)
      .matches(/[A-Z]/, t`password must include capital letters`)
      .matches(/[a-z]/, t`password must include lower case letters`)
      .matches(/[0-9]/, t`password must include numbers`)
      .required(t`required field`),
    confrimPassword: Yup.string()

      .oneOf([Yup.ref("password"), null], t`Passwords must match`)
      .required(t`Confirm password is required`),
    email: Yup.string().test(
      "email validation",
      t`email must be a valid email`,
      function(value) {
        if (!value) {
          return true;
        }

        const emailSchema = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (emailSchema.test(String(value).toLowerCase())) {
          return true;
        }
        return false;
      }
    ),
    phone: Yup.string()
      .required(t`required field`)
      .test(
        "phone validation",
        t`Phone number should be 10 chars minimum.`,
        function(value) {
          if (!value) {
            return true;
          }

          let newValue = value.replace(/[^a-zA-Z0-9 ]/g, "");
          newValue = newValue.replace(/\s/g, "");

          if (newValue.length > 10) {
            return true;
          }
          return false;
        }
      )
  });

  useEffect(() => {
    if (!inviteToken) {
      return;
    }
    getInvite(inviteToken)
      .then((res: any) => {
        startLoader();
        const { firstName, lastName, email, temperatureScale, id } = res;
        setInvite({
          firstName,
          lastName,
          email,
          temperatureScale
        });
        setInviteId(id);
      })
      .catch((error: any) => {
        addMessage({ message: error.message });
        history.push("/");
      })
      .finally(() => {
        finishLoader();
      });
  }, [inviteToken, getInvite, addMessage, startLoader, finishLoader, history]);

  useEffect(() => {
    if (!tempOptions) {
      return;
    }
    const dropdownkeys = Object.keys(tempOptions);
    const dropdownOptions: [object] = [{}];
    dropdownOptions.shift();

    dropdownkeys.map((key) => {
      if (tempOptions[key] !== "unknown") {
        dropdownOptions.push({ value: +key, label: tempOptions[key] });
      }
      return key;
    });

    setTemperatureScaleOptions(dropdownOptions);
    setTemperatureScale(dropdownOptions[0]);

    const timeOptions = types.timeFormat.map((item: any)=> {return {value: item.value, label: item.text}} )
    const dateOptions = types.dateFormat.map((item: any)=> {return {value: item.value, label: item.text}} )
    setDateFormatOptions(dateOptions)
    setTimeFormatOptions(timeOptions)

  }, [tempOptions]);

  const handleFormdSubmit = (values: any, actions: any) => {
    const { phone } = values;
    const newPhone = phone.replace(/[+]/g, "");

    const userData = {
      ...values,
      phone: newPhone,
      confrimPassword: undefined,
      temperatureScale: temperatureScale.value,
      token: undefined,
      timeFormat: timeFormat.value,
      dateFormat: dateFormat.value,
    };

    startLoader();
    checkUsernameAvailability(userData.username)
      .then((res: any) => {
        if (res) {
          actions.setFieldError("username", "username already taken");
          return null;
        } else {
          acceptInvite({
            inviteId,
            data: userData
          })
            .then(() => {
              history.push(`/`);
            })
            .catch((err: any) => {
              addMessage({
                message: err.message
              });
            });
        }
      })
      .finally(() => finishLoader());
  };

  const goBack = () => {
    history.push("/");
  };

  const handleTempSelectChange = (selectedOption: any) => {
    setTemperatureScale(selectedOption);
  };

  return (
    <div className={classes.screenContainer}>
      <Grid container className={classes.container}>
        <div className={classes.logoSection} onClick={goBack}>
          <AppLogo className={classes.logoStyle} />
          <AppTitle className={classes.logoTitleStyle} />
        </div>
        <Typography className={classes.typoStyle}>{t`Personal Details`}</Typography>
        <Formik
          initialValues={{
            ...invite,
            password: "",
            confrimPassword: "",
            phone: "",
            username: ""
          }}
          onSubmit={(values, actions) => handleFormdSubmit(values, actions)}
          enableReinitialize={true}
          validationSchema={validationSchema}
          render={({ values, setFieldValue, errors, ...formikProps }) => {

            return (
              <Form className={classes.inputsContainer}>
                <List
                  className={classes.list}
                  disablePadding={true}
                  dense={true}
                >
                  <ListItem>
                    <ListItemText
                      primary={t`Username`}
                      className={classes.itemText}
                    />
                    <Grid container className={classes.itemContainer}>
                      <Input
                        name="username"
                        disableUnderline={true}
                        value={values["username"]}
                        onChange={(e: any) =>
                          setFieldValue("username", e.target.value)
                        }
                        className={classes.inputs}
                      />
                    </Grid>
                  </ListItem>
                  {!!errors["username"] && (
                    <FormHelperText error={true} className={classes.errorStyle}>
                      {errors["username"]}
                    </FormHelperText>
                  )}

                  <ListItem>
                    <ListItemText
                      primary={t`First Name`}
                      className={classes.itemText}
                    />
                    <Grid container className={classes.itemContainer}>
                      <Input
                        name="firstName"
                        disableUnderline={true}
                        value={values["firstName"]}
                        onChange={(e: any) =>
                          setFieldValue("firstName", e.target.value)
                        }
                        className={classes.inputs}
                      />
                    </Grid>
                  </ListItem>
                  {!!errors["firstName"] && (
                    <FormHelperText error={true} className={classes.errorStyle}>
                      {errors["firstName"]}
                    </FormHelperText>
                  )}

                  <ListItem>
                    <ListItemText
                      primary={t`Last Name`}
                      className={classes.itemText}
                    />
                    <Grid container className={classes.itemContainer}>
                      <Input
                        name="lastName"
                        disableUnderline={true}
                        value={values["lastName"]}
                        onChange={(e: any) =>
                          setFieldValue("lastName", e.target.value)
                        }
                        className={classes.inputs}
                      />
                    </Grid>
                  </ListItem>
                  {!!errors["lastName"] && (
                    <FormHelperText error={true} className={classes.errorStyle}>
                      {errors["lastName"]}
                    </FormHelperText>
                  )}

                  <ListItem>
                    <ListItemText
                      primary={t`Email`}
                      className={classes.itemText}
                    />
                    <Grid container className={classes.itemContainer}>
                      <Input
                        name="email"
                        disableUnderline={true}
                        value={values["email"]}
                        onChange={(e: any) =>
                          setFieldValue("email", e.target.value)
                        }
                        className={classes.inputs}
                      />
                    </Grid>
                  </ListItem>
                  {!!errors["email"] && (
                    <FormHelperText error={true} className={classes.errorStyle}>
                      {errors["email"]}
                    </FormHelperText>
                  )}

                  <ListItem>
                    <ListItemText primary={t`Phone`} className={classes.phoneFieldText} />
                    <Grid container className={classes.itemContainer}>
                      <PhoneInput
                        country="US"
                        placeholder="Enter phone number"
                        value={values.phone}
                        onChange={(e: any) =>
                          setFieldValue("phone", e)
                        }
                        className={classes.phoneInput}
                        limitMaxLength={true}
                      />
                    </Grid>
                  </ListItem>
                  {!!errors["phone"] && (
                    <FormHelperText error={true} className={classes.errorStyle}>
                      {errors["phone"]}
                    </FormHelperText>
                  )}
                </List>

                <Select
                  variant="filled"
                  value={temperatureScale || ""}
                  suggestions={temperatureScaleOptions}
                  handleSelectChange={handleTempSelectChange}
                  clear={false}
                  className={classes.selectStyle}
                  placeholder={t`Temperature Scale`}
                />

                <Select
                  value={timeFormat}
                  suggestions={timeFormatOptions}
                  handleSelectChange={(value: any)=>{
                    setTimeFormat(value)
                  }}
                  clear={false}
                  search={false}
                  label={t`Time Format`}
                  className={classes.selectStyle}
                />

                <Select
                  value={dateFormat}
                  suggestions={dateFormatOptions}
                  handleSelectChange={(value: any)=>setDateFormat(value)}
                  clear={false}
                  search={false}
                  label={t`Date Format`}
                  className={classes.selectStyle}
                />



                <List
                  className={classes.list}
                  disablePadding={true}
                  dense={true}
                >
                  <ListItem>
                    <ListItemText
                      primary={t`Password`}
                      className={classes.itemText}
                    />
                    <Grid container className={classes.itemContainer}>
                      <Input
                        name="password"
                        disableUnderline={true}
                        value={values["password"]}
                        onChange={(e: any) =>
                          setFieldValue("password", e.target.value)
                        }
                        className={classes.inputs}
                      />
                    </Grid>
                  </ListItem>
                  {!!errors["password"] && (
                    <FormHelperText error={true} className={classes.errorStyle}>
                      {errors["password"]}
                    </FormHelperText>
                  )}

                  <ListItem>
                    <ListItemText
                      primary={t`Password`}
                      className={classes.itemText}
                    />
                    <Grid container className={classes.itemContainer}>
                      <Input
                        name="confrimPassword"
                        disableUnderline={true}
                        value={values["confrimPassword"]}
                        onChange={(e: any) =>
                          setFieldValue("confrimPassword", e.target.value)
                        }
                        className={classes.inputs}
                      />
                    </Grid>
                  </ListItem>
                  {!!errors["confrimPassword"] && (
                    <FormHelperText error={true} className={classes.errorStyle}>
                      {errors["confrimPassword"]}
                    </FormHelperText>
                  )}
                </List>

                <Typography className={classes.textStyle}>
                  {t`Your password should include at least 8 characters, 1
                  number, 1 capital letter, 1 special characters`}
                </Typography>
                <Button type="submit" variant="contained">
                  {t`Submit`}
                </Button>
              </Form>
            );
          }}
        />
      </Grid>
    </div>
  );
};

export default CreateUserForm;

import {
  InputAdornment,
  makeStyles,
  Typography,
  Grid,
  Paper,
  Link
} from '@material-ui/core';
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import { AppLogo, AppTitle, ArrowBack } from "../../svgComponents";
import { SupportStyle } from "./supportRegister.style";
import technicalSupportSrc from "../../images/technical-support.png";
import registrationSrc from "../../images/registration.png";
import configurationSrc from "../../images/configuration.png";
import technicalSupportTwoSrc from "../../images/technical-support-2.png";
import backgroundImagFotolia from "../../images/fotolia-82235918-subscription-monthly-xxl.png";
import {findProduct} from "./supportParser";

const SupportRegister: React.FC<any> = (props) => {

  const {
    history,
    history: { location: { search = "" } = {} } = {}
  } = props;


  const useStyles = makeStyles(SupportStyle);
  const classes = useStyles();

  const styles = {
    paperContainer: {
      backgroundImage: `url(${backgroundImagFotolia})`
    }
  };
  const [parsedProduct, setParsedProduct] = useState<string>("unknown");
  const [serial, setSerial] = useState<string>("unknown");
  const [pin, setPin] = useState<string>("unknown");
  const [installationLink, setInstallationLink] = useState<string>("https://coolautomation.com/support/#contact");
  const [userManualLink, setUserManualLink] = useState<string>("https://coolautomation.com/support/#contact");

  useEffect(() => {
    if (!search) {
      return;
    }
    const searchParams = new URLSearchParams(search);
    const paramSerial = searchParams.get("serial") || "";
    const paramPin = searchParams.get("pin") || "";

    const metaData = findProduct(paramSerial);

    setSerial(paramSerial);
    setPin(paramPin);
    setParsedProduct(metaData.product);
    setInstallationLink(metaData.installationLink);
    setUserManualLink (metaData.userManualLink);

  }, [search]);



  const canClickInstallation = true;
  const canClickConfiguration = true;


  const showProduct = parsedProduct === "unknown" ? false : true



  const goBack = () => {
    history.push("/");
  };

  const onRegistration = ()=>{
    history.push(`/device-registration?serial=${serial}&pin=${pin}`);

  };

  const onInstallation = ()=>{
    if(canClickInstallation){
      window.location.href = installationLink;
    }
  };

  const onConfiguration = ()=>{
    if(canClickConfiguration){
      window.location.href = userManualLink;
    }
  };

  const onSupport = ()=>{
      window.location.href = `https://coolautomation.com/support/#contact`;
  };


  return (
    <Paper className={classes.screenContainer} style={styles.paperContainer}>
      <div className={classes.container}>

          <div className={classes.logoSection} onClick={goBack}>
            <AppLogo className={classes.logoStyle} />
            <AppTitle className={classes.logoTitleStyle} />
          </div>

        <div className={classes.pageContent}>
          <div
            className={classes.header}
          >
            <Typography
              className={classes.pageTitle}
            >{t`Welcome to Support Center`}</Typography>
            <Typography
              className={classes.subTitle}
            >{t`Please select an option from below`}</Typography>
        </div>

          <Grid container className={classes.bodyContainer}>
            <Grid container >
              {showProduct &&
              <Grid item xs={12}>
                <Typography
                  className={classes.metaDateTitle}
                >{t`Product: `}</Typography>

                <Typography
                  className={classes.metaDateValue}
                >{parsedProduct}</Typography>
              </Grid>

              }
              <Grid item xs={12}>
              <Typography
                className={classes.metaDateTitle}
              >{t`Serial Number: `}</Typography>
              <Typography
                className={classes.metaDateValue}
              >{serial}</Typography>
              </Grid>

            </Grid>

            <Grid container className={classes.optionContainer} >

              <Grid item xs={6}>
                <Paper className={classes.options} onClick={onInstallation}>
                  <img src={technicalSupportSrc} className={classes.btnIcon}/>
                  <Typography
                    className={classes.optionTitle}
                  >{t`Installation guidelines `}</Typography>
                </Paper>
              </Grid>

              <Grid item xs={6}>
                <Paper className={classes.options} onClick={onRegistration}>
                  <img src={registrationSrc} className={classes.btnIcon}/>
                  <Typography
                    className={classes.optionTitle}
                  >{t`Registration to the app `}</Typography>
                </Paper>
              </Grid>

              <Grid item xs={6}>
                <Paper className={classes.options} onClick={onConfiguration}>
                  <img src={configurationSrc} className={classes.btnIcon}/>
                  <Typography
                    className={classes.optionTitle}
                  >{t`Configuration and setup `}</Typography>
                </Paper>
              </Grid>

              <Grid item xs={6}>
                <Paper className={classes.options} onClick={onSupport}>
                  <img src={technicalSupportTwoSrc} className={classes.btnIcon}/>
                  <Typography
                    className={classes.optionTitle}
                  >{t`Technical Support `}</Typography>
                </Paper>
              </Grid>

            </Grid>

            <Grid item xs={12} className={classes.footerStyle} >
              <Link href="https://www.coolautomation.com"
                    className={classes.footerText}
              >
                www.coolautomation.com
              </Link>
            </Grid>
          </Grid>


      </div>
    </div>
    </Paper>
  );
};

export default SupportRegister;

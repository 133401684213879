import React, { useEffect, useState } from "react";
import { t } from "ttag";
import clsx from "clsx";
import { Dialog, DialogContentText, makeStyles } from "@material-ui/core";
import { Button } from "../../widgets";
import { messageStyles } from "./message.style";

const Message = (props: any) => {
  const [open, setOpen] = useState(false);
  const { message } = props;
  const {
    message: messageText,
    buttonText,
    onClick,
    textNotCentered
  } = message;

  const useStyles = makeStyles(messageStyles);
  const classes = useStyles();

  useEffect(() => {
    if (message) {
      setOpen(true);
    }
  }, [message]);

  const onOkClick = () => {
    setOpen(false);
    onClick && onClick();
  };
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="responsive-dialog-title"
      classes={{ paper: classes.dialogStyle }}
    >
      <DialogContentText
        className={clsx(classes.contentStyle, {
          [classes.textNotCentered]: textNotCentered
        })}
      >{messageText}</DialogContentText>
      <Button onClick={onOkClick} variant="contained">
        {buttonText || t`Ok`}
      </Button>
    </Dialog>
  );
};

export default Message;

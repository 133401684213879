import { createStyles } from "@material-ui/styles";
import { fade } from "@material-ui/core";

export default (theme: any) =>
  createStyles({
    screenContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      backgroundImage: theme.gradient.forgotPassword,
      overflow: "auto"
    },
    passwordRecoveryScreenContent: {
      width: "100%",
      maxWidth: "740px"
    },
    logoSection: {
      margin: "12% 0",
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
      alignSelf: "center",
      width: "100%",
      maxHeight: "50px"
    },
    logoStyle: { width: "auto", maxHeight: "60px", marginRight: "10px" },
    logoTitleStyle: { maxHeight: "27px", width: "auto" },
    resetPasswordContainer: {
      width: "100%",
      height: "50%",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      padding: "25px",
      color: theme.palette.colors.white
    },
    passwordContainerStyle: {
      marginTop: "10px",
      marginBottom: "10px",
      "& input:focus + fieldset": {
        borderWidth: "1px"
      },
      "& div": {
        backgroundColor: fade(theme.palette.colors.white, 0.1),
        borderRadius: "4px"
      },
      "& ::placeholder": {
        color: fade(theme.palette.colors.white, 0.5)
      }
    },
    buttonStyle: {
      marginBottom: "15px",
      maxWidth: "45%",
      "& span": { fontSize: "15px" }
    },
    outlinedButtonStyle: {
      border: `1px solid ${fade(theme.palette.colors.white, 0.6)}`,
      boxShadow: `0 2px 3px 0 ${fade(theme.palette.colors.black, 0.1)}`
    },
    inputClass: {
      color: theme.palette.colors.white,
      fontSize: "16px",
      fontFamily: "Roboto",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      padding: "16px 0px",
      paddingLeft: "20px"
    },
    errorStyle: {
      fontSize: "12px",
      marginTop: "3px",
      color: theme.palette.secondary.main
    },
    lockIconStyle: {
      marginRight: "2px"
    },
    buttonsContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: "5px"
    },
    textStyle: {
      color: theme.palette.colors.white,
      fontSize: "14px",
      textAlign: "center",
      lineHeight: 1.5,
      opacity: 0.6,
      marginBottom: "28px",
      marginTop: "2px",
      padding: "0 35px"
    },
    textFieldsStyle: {
      marginTop: "0px",
      marginBottom: "10px"
    },
    resetPasswordMessegeStyle: {
      marginBottom: "15px"
    }
  });

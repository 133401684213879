import { fade } from "@material-ui/core";
import { createStyles } from "@material-ui/styles";

export const SupportStyle = (theme: any) =>
  createStyles({
    screenContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      // backgroundImage: 'url()',

      overflow: "auto",
      flexFlow: "column nowrap"
    },
    container: {
      display: "flex",
      flexDirection: "column",
      overflow: "auto",
      width: "100%",
      flex: 1,
      maxWidth: "600px",
      // maxHeight: "fit-content",
      background: "linear-gradient(to bottom, rgba(66, 26, 64, 0) 2%, #29132e 64%) !important",
    },
    logoSection: {
      paddingTop: "15px",
      margin: "20px 0",
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
      alignSelf: "center",
      width: "80%",
      maxHeight: "60px",
      minHeight: "60px"
    },
    logoTitleStyle: {
      maxHeight: "27px",
      width: "auto"
    },
    logoStyle: {
      width: "auto",
      maxHeight: "60px",
      marginRight: "10px"
    },
    pageContent: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      flex: 1,
      padding: "0 25px",
      boxSizing: "border-box"
    },
    header: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "2rem",
      marginTop: "1rem",
      marginBottom: "2rem"
    },
    subTitle:{
      fontFamily: "RobotoLight !important",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      textAlign: "center",
      fontSize: "1rem",
      color: theme.palette.colors.lightWhite
    },
    pageTitle: {
      fontFamily: "Roboto",
      fontSize: "1.5rem",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      textAlign: "center",
      color: theme.palette.colors.lightWhite,
      marginBottom: "15px"
    },
    addMargin: {
      marginTop: "27px"
    },
    widthStyle: { width: "210px" },
    bodyContainer:{

    },
    optionContainer:{
      marginTop: "20px"
    },
    metaDateTitle:{
      fontFamily: "Roboto",
      fontSize: ".9rem",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      // textAlign: "left",
      color: theme.palette.colors.lightWhite,
      margin: "10px 3px 0 0",
      display: "inline-block"

    },
    metaDateValue:{
      display: "inline-block",
      color: "#C9C4CA",
      fontFamily: "RobotoLight",
      fontSize: ".9rem",

    },
    options:{
      background: "rgba(255, 255, 255, 0.15)",
      width: "9rem",
      height: "9.5rem",
      margin: "0 2px 12px 0",
      borderRadius: "6px",
      display: "flex",
      flexDirection: "column"
    },
    optionTitle:{
      fontFamily: "RobotoLight !important",
      fontSize: "20px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "0.38px",
      marginLeft: ".7rem",
      // textAlign: "left",
      color: theme.palette.colors.lightWhite,
      width: "70%"
    },
    btnIcon:{
      width: "4rem",
      height: "4rem",
      margin: ".7rem",
      color: "white"
    },
    footerStyle:{
      display: "flex",
      justifyContent: "center",
      marginTop: "10px"
    },
    footerText:{
      fontSize: ".8rem",
      color: theme.palette.colors.lightWhite,
    }
  })

import { createStyles } from "@material-ui/styles";

import { fade } from "@material-ui/core/styles";

export default (theme: any) =>
  createStyles({
    clickableRow: {
      border: "none",
      backgroundColor: fade(theme.palette.colors.lightWhite, 0.1),
      justifyContent: "space-between",
      alignItems: "center",
      color: theme.palette.colors.white,
      height: "50px",
      borderRadius: "4px",
      padding: "0 5px 0 8px",
      marginBottom: "10px",
      "&:active": {
        backgroundColor: fade(theme.palette.colors.lightWhite, 0.2)
      },
      "&:hover": {
        backgroundColor: fade(theme.palette.colors.lightWhite, 0.2)
      }
    },
    userAvatarStyle: {
      marginRight: "10px",
      minWidth: "34px"
    },
    titleSubTitleWrapper: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center"
    },
    titleStyle: {
      fontSize: "16px",
      lineHeight: "16px",
      width: "100%",
      overflow: "hidden",
      display: "-webkit-box",
      "-webkit-line-clamp": 1,
      "-webkit-box-orient": "vertical",
      maxHeight: "60px",
      "& p": {
        whiteSpace: "unset",
        textOverflow: "unset"
      }
    },
    subtitleStyle: {
      fontFamily: "sans-serif",
      fontSize: "16px",
      lineHeight: "16px",
      color: fade(theme.palette.colors.white, 0.4)
    },
    forwardArrowButton: {
      padding: "5px"
    },
    forwardArrow: {
      color: fade(theme.palette.colors.lightWhite, 0.6),
      fontSize: "15px"
    },
    avatarTitleWrapper: {
      display: "flex",
      flexFlow: "row nowrap",
      alignItems: "center",
      flex: 0.85
    }
  });

import { makeStyles, Slide, Typography } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import _ from "lodash";
import moment from "moment-timezone";
import weather from "openweather-apis";
import React, { Fragment, useEffect, useState } from "react";
import Geocode from "react-geocode";
import { Redirect } from "react-router-dom";
import { t } from "ttag";
import { GroupController, Header } from "../../components";
import { useStoreActions, useStoreState } from "../../Stores/typedHooks";
import { HomeFooter, StatisticsDialog } from "./";
import { homeSrcreenStyles } from "./homeScreen.style";
import UnitView from "./UnitView";

const HomeScreen = (props: any) => {
  const [userHasNoSites, setUserHasNoSites] = useState<boolean>(false);
  const [siteTemp, setSiteTemp] = useState<any>(null);
  const [selectedSiteId, setSelectedSiteId] = useState<any>(null);
  const [selectedItemId, setSelectedItemId] = useState<any>(null);
  const [isGroupSelected, setIsGroupSelected] = useState<any>(false);
  const [isSensorSelected, setIsSensorSelected] = useState<any>(false);
  const [controlIsOpen, setControlIsOpen] = useState<any>(false);
  const [siteSensors, setSiteSensors] = useState<any>({});
  const [isStatisticsDialogOpen, setIsStatisticsDialogOpen] = useState<any>(
    false
  );

  const typesStore = useStoreState((state) => state.typesStore);

  const { powerOnVal, powerOffVal } = useStoreState((state) => state.typesStore);

  const temperatureScaleRange = useStoreState(
    (state) => state.userStore.temperatureScaleRange
  );
  const temperatureSymbol = useStoreState(
    (state) => state.userStore.temperatureSymbol
  );
  const temperatureScaleMirror = useStoreState(
    (state) => state.typesStore.temperatureScaleMirror
  );

  const config = useStoreState((state) => state.configStore.config);
  const sites = useStoreState((state) => state.siteStore.userSites);
  const user = useStoreState((state) => state.userStore.user);
  const features = useStoreState((state) => state.userStore.features);
  const siteUnits = useStoreState((state) => state.siteStore.siteIndoorUnits);
  const groupSiteMapping = useStoreState(
    (state) => state.groupStore.groupSiteMapping
  );
  const customerGroups = useStoreState((state) => state.groupStore.groups);
  const groupsUpdated = useStoreState((state) => state.groupStore.groupsCalled);
  const unitsGroupsMap = useStoreState(
    (state) => state.groupStore.groupUnitsMapping
  );
  const tempSymbol = useStoreState((state) => state.userStore.temperatureSymbol);
  const operationModesMirror = useStoreState(
    (state) => state.typesStore.operationModesMirror
  );
  const webSocketUnitUpdate = useStoreState(
    (state) => state.siteStore.webSocketUnitUpdate
  );
  const operationStatusesMirror = useStoreState(
    (state) => state.typesStore.operationStatusesMirror
  );
  const fanModesMirror = useStoreState(
    (state) => state.typesStore.fanModesMirror
  );
  const swingModesMirror = useStoreState(
    (state) => state.typesStore.swingModesMirror
  );

  const { getSiteSensors } = useStoreActions((actions) => actions.sensorStore);
  const setSelectedSiteStore = useStoreActions(
    (state) => state.siteStore.setSelectedSite
  );
  const getSites = useStoreActions((actions) => actions.siteStore.getUserSites);
  const getSiteDevices = useStoreActions((actions) => actions.deviceStore.getDevicesToStore);
  const getSiteUnits = useStoreActions((state) => state.siteStore.getUnits);
  const getFullGroups = useStoreActions(
    (state) => state.groupStore.getFullGroups
  );
  const updateGroupWebSocket = useStoreActions(
    (state) => state.groupStore.updateGroupUnit
  );

  const startLoader = useStoreActions(
    (actions) => actions.loaderStore.startLoader
  );
  const finishLoader = useStoreActions(
    (actions) => actions.loaderStore.finishLoader
  );
  const addMessage = useStoreActions(
    (actions) => actions.messageStore.addMessage
  );
  const logout = useStoreActions((actions) => actions.userStore.logout);
  const changeSitePowerStatus = useStoreActions(
    (actions) => actions.siteStore.changeSitePowerStatus
  );
  const groupPowerControl = useStoreActions(
    (state) => state.groupStore.changePowerState
  );
  const changeGroupSetPoint = useStoreActions(
    (state) => state.groupStore.changeSetPoint
  );
  const updateGroup = useStoreActions((state) => state.siteStore.updateGroup);
  const getUnitStats = useStoreActions((state) => state.unitStore.getUnitStats);
  const setActiveSetpoint = useStoreActions(
    (state) => state.unitStore.setActiveSetpoint
  );
  const setActiveFanMode = useStoreActions(
    (state) => state.unitStore.setActiveFanMode
  );
  const setActiveSwingMode = useStoreActions(
    (state) => state.unitStore.setActiveSwingMode
  );
  const setActiveOperationMode = useStoreActions(
    (state) => state.unitStore.setActiveOperationMode
  );
  const setActiveOperationStatus = useStoreActions(
    (state) => state.unitStore.setActiveOperationStatus
  );
  const refreshUnit = useStoreActions((state) => state.unitStore.refreshUnit);

  const { history, match } = props;
  const { params } = match;
  const { siteId, unitId } = params;
  const useStyles = makeStyles(homeSrcreenStyles);
  const classes = useStyles();

  const { types, unitTypes } = typesStore;
  const {sensorTypes} = types;
  const { temperatureScale } = user;
  const site = sites[selectedSiteId];
  const timezone = site && site.timezone || moment.tz.guess();

  useEffect(() => {
    if (
      !!webSocketUnitUpdate &&
      !!webSocketUnitUpdate.id &&
      _.has(unitsGroupsMap, webSocketUnitUpdate.id)
    ) {
      const groupId = unitsGroupsMap[webSocketUnitUpdate.id];
      updateGroupWebSocket({ groupId, unit: webSocketUnitUpdate });
    }
  }, [
    webSocketUnitUpdate,
    selectedSiteId,
    updateGroupWebSocket,
    unitsGroupsMap
  ]);

  useEffect(() => {
    getSites().then((userHasNoSites: any) => {
      setUserHasNoSites(userHasNoSites);
    });
  }, [getSites]);

  useEffect(() => {
    if (!!selectedSiteId) {
      return;
    }

    const localStoredSite = siteId || localStorage.getItem("selectedSite");
    const localStoredItem = unitId || localStorage.getItem("selectedItem");
    const localStoredIsGroup = localStorage.getItem("isGroup");

    setSelectedSiteId(
      localStoredSite ? localStoredSite : Object.keys(sites)[0]
    );
    localStoredItem && setSelectedItemId(localStoredItem);

    setIsGroupSelected(
      localStoredIsGroup && localStoredIsGroup === "true"
        ? unitId && siteId
          ? false
          : true
        : false
    );
    if (!localStoredSite || !localStoredItem) {
      setControlIsOpen(false);
    } else {
      setControlIsOpen(true);
    }
  }, [selectedSiteId, siteId, sites, unitId]);

  useEffect(() => {
    Promise.all([getFullGroups()]);
  }, [getFullGroups]);

  useEffect(() => {
    const { groups } = groupSiteMapping;
    if (
      _.isEmpty(groups) &&
      groupsUpdated &&
      selectedSiteId === "groups" &&
      sites
    ) {
      setSelectedSiteId(Object.keys(sites)[0]);
    }
  }, [groupSiteMapping, groupsUpdated, selectedSiteId, sites]);

  useEffect(() => {
    if (_.isEmpty(sites)) {
      return;
    }

    if (!selectedSiteId) {
      return;
    }

    startLoader();
    if (selectedSiteId !== "groups") {
      setSelectedSiteStore(sites[selectedSiteId]);
      getSiteSensors(selectedSiteId)
        .then((res: any) => {
          const sensors: any = {};
          Object.values(res).forEach((sensor: any) => {
            if (sensorTypes[sensor.type]?.enableView){
              sensors[sensor.id] = sensor;
            }
          });
          setSiteSensors(sensors);
        });
      Promise.all([getSiteDevices(selectedSiteId), getSiteUnits(selectedSiteId)]).then(() => finishLoader());
    } else {
      finishLoader();
    }
  }, [
    finishLoader,
    getSiteUnits,
    getSiteSensors,
    selectedSiteId,
    setSelectedSiteStore,
    sites,
    startLoader
  ]);

  useEffect(() => {
    if (_.isEmpty(sites)) {
      return;
    }

    if (selectedItemId && !isGroupSelected && siteUnits[selectedItemId]) {
      refreshUnit({ unitId: selectedItemId });
    }
  }, [isGroupSelected, refreshUnit, selectedItemId, siteUnits, sites]);

  useEffect(() => {
    setSiteTemp(null);

    if (_.isEmpty(site) || _.isEmpty(temperatureScaleMirror)) {
      return;
    }

    setSiteTemp("loading");
    const { state, country, city, address, postalCode } = site;

    let fullAddress = "";

    if (!state) {
      fullAddress = country + ", " + city;
    } else {
      fullAddress += address && address;
      fullAddress += `, ${city && city}`;
      fullAddress += `, ${state && state}`;
      fullAddress += `, ${postalCode && postalCode}`;
      fullAddress += `, ${country && country}`;
    }
    Geocode.setApiKey("AIzaSyBJIN2UpLEwA0WENCrw5eahjzwq7aMzRBY");
    Geocode.setLanguage("en");
    Geocode.enableDebug();
    weather.setAPPID("5ffcc928baffef1bcb16c2772e1023f8");
    weather.setLang("it");

    Geocode.fromAddress(fullAddress).then(
      (response: any) => {
        const { lat, lng } = response.results[0].geometry.location;
        weather.setCoordinate(lat, lng);
        (async () =>
          weather.getTemperature((err: any, temp: any) => {
            if (+temperatureScaleMirror.fahrenheit === temperatureScale) {
              temp = temp * (9 / 5) + 32;
            }
            setSiteTemp(temp);
          }))();
      },
      () => {
        setSiteTemp(null);
        addMessage({
          message: `Please update the site address`
        });
      }
    );
  }, [addMessage, site, temperatureScale, temperatureScaleMirror]);

  const changeControlSectionOpenState = (state: boolean) => {
    setControlIsOpen(state);
    localStorage.removeItem("selectedItem");

    if (!isGroupSelected) {
      history.push("/site");
    }
  };

  const openStatisticsDialog = () => {
    setIsStatisticsDialogOpen(true);
  };

  const closeStatisticsDialog = () => {
    setIsStatisticsDialogOpen(false);
  };

  const navigateUnitToSchedules = (siteId: string, unitId: string) => {
    const { history } = props;
    history.push(`/unit/${unitId}/schedules`);
  };

  const navigateGroupToSchedules = (siteId: string, groupId: string) => {
    const { history } = props;
    history.push(`/group/${groupId}/schedules`);
  };

  const changeSitePower = (powerOn: boolean, siteId: string) => {
    const state = powerOn ? powerOnVal : powerOffVal;
    changeSitePowerStatus({ siteId, state });
  };

  const changeGroupPower = (powerOn: boolean, groupId: string) => {
    startLoader();
    const state = powerOn ? powerOnVal : powerOffVal;
    groupPowerControl({ groupId, state })
      .catch((error: any) => {
        addMessage({ message: error.message });
      })
      .finally(() => {
        finishLoader();
      });
  };

  const selectItem = (
    siteId: string,
    itemId: string,
    isGroup: boolean,
    isSensor: boolean,
    openControl: boolean
  ) => {

    if (isGroup) {
      setIsGroupSelected(isGroup);
      setIsSensorSelected(isSensor);
      setSelectedSiteId(siteId);
      setSelectedItemId(itemId);
      setControlIsOpen(openControl);
    }

    if (itemId === "") {
      setSelectedSiteId(siteId);
      setSelectedItemId(null);
      setControlIsOpen(false);
      return;
    }

    let recentsCopy: any = localStorage.getItem("recents")
      ? JSON.parse(localStorage.getItem("recents") as string)
      : {};

    if (recentsCopy && recentsCopy[siteId]) {
      if (recentsCopy[siteId].indexOf(itemId) > -1) {
        recentsCopy[siteId].splice(recentsCopy[siteId].indexOf(itemId), 1);
      } else {
        if (recentsCopy[siteId].length > 2) {
          recentsCopy[siteId].pop();
        }
      }
      recentsCopy[siteId].unshift(itemId);
    } else {
      recentsCopy[siteId] = [itemId];
    }
    localStorage.setItem("recents", JSON.stringify(recentsCopy));
    localStorage.setItem("selectedSite", siteId);
    localStorage.setItem("isGroup", `${isGroup}`);
    localStorage.setItem("selectedItem", itemId);

    if (!isGroup) {
      history.push(`/site/${siteId}/unit/${itemId}`);
      setIsGroupSelected(isGroup);
      setIsSensorSelected(isSensor);
      setSelectedSiteId(siteId);
      setSelectedItemId(itemId);
      setControlIsOpen(openControl);
    }
  };

  const modeHaveTemperature = (mode: number) => {
    return !!types.operationModesWithTemperatures[mode];
  };

  const updateLocalSensor = (id: string, value: number) => {
    if (siteSensors[id]) {
    siteSensors[id].readingValue = value;
    setSiteSensors(siteSensors);
  }
};

  const selectedItem = isGroupSelected
    ? customerGroups[selectedItemId]
    : isSensorSelected ? siteSensors[selectedItemId] : siteUnits[selectedItemId];
  if (
    _.has(features, "canViewControlApp") &&
    !_.get(features, "canViewControlApp")
  ) {
    return (
      <div>
        <Redirect to="/no-access" />
      </div>
    );
  }

  const sitesAreLoaded = !_.isEmpty(sites);
  const isCelsius = +temperatureScaleMirror.celsius === +temperatureScale;
  return (
    <div className={classes.container}>
      <Header
        history={history}
        userInfo={user}
        logoutCb={logout}
        selectedSiteId={selectedSiteId}
        openStatisticsDialog={openStatisticsDialog}
        siteTemp={siteTemp}
      />
      <div className={classes.pageContent}>
        {userHasNoSites ? (
          <Typography
            className={classes.noSitesMesasge}
          >{t`you have no sites`}</Typography>
        ) : !sitesAreLoaded ? (
          <div />
        ) : (
              <Fragment>
                <HomeFooter
                  selectedSiteId={selectedSiteId}
                  sites={sites}
                  selectItem={selectItem}
                  classes={classes}
                  selectedItem={selectedItem}
                  changeSitePower={changeSitePower}
                  onHeaderClick={changeControlSectionOpenState}
                  setSelectedSite={setSelectedSiteId}
                  units={siteUnits}
                  groups={customerGroups}
                  unitTypes={unitTypes}
                  startLoader={startLoader}
                  finishLoader={finishLoader}
                  temperatureScale={temperatureScale}
                  groupSiteMapping={groupSiteMapping}
                  loggedUser={user}
                  siteSensors={siteSensors}
                  isCelsius={isCelsius}
                  updateLocalSensor={updateLocalSensor}
                />

                {selectedItem && (
                  <Slide
                    direction="up"
                    in={controlIsOpen}
                    mountOnEnter
                    unmountOnExit
                  >
                    <div className={classes.controlSliderWrapper}>
                      <div className={classes.controlSectionHeader}>
                        <div
                          className={classes.expandMoreIconWrapper}
                          onClick={() => changeControlSectionOpenState(false)}
                        >
                          <ExpandMore className={classes.expandMoreIcon} />
                        </div>
                      </div>
                      <div className={classes.controlContainer}>
                        {selectedItem ? (
                          isGroupSelected ? (
                            <GroupController
                              group={selectedItem}
                              user={user}
                              types={types}
                              startLoader={startLoader}
                              finishLoader={finishLoader}
                              addMessage={addMessage}
                              navigateToSchedules={navigateGroupToSchedules}
                              changeGroupPower={changeGroupPower}
                              changeGroupSetPoint={changeGroupSetPoint}
                              updateGroup={updateGroup}
                              tempSymbol={tempSymbol}
                              temperatureScale={temperatureScale}
                              operationStatusesMirror={operationStatusesMirror}
                              isCelsius={isCelsius}
                            />
                          ) : (
                              <UnitView
                                types={types}
                                key={selectedItemId}
                                unit={selectedItem}
                                navigateToSchedules={navigateUnitToSchedules}
                                user={user}
                                modeHaveTemperature={modeHaveTemperature}
                                unitRemoteDesign={config.unitRemoteDesign}
                                selectedSiteId={selectedSiteId}
                                temperatureSymbol={temperatureSymbol}
                                temperatureScaleRange={temperatureScaleRange}
                                setActiveSetpoint={setActiveSetpoint}
                                setActiveOperationMode={setActiveOperationMode}
                                setActiveFanMode={setActiveFanMode}
                                setActiveSwingMode={setActiveSwingMode}
                                setActiveOperationStatus={setActiveOperationStatus}
                                operationModesMirror={operationModesMirror}
                                fanModesMirror={fanModesMirror}
                                swingModesMirror={swingModesMirror}
                                operationStatusesMirror={operationStatusesMirror}
                                temperatureScale={temperatureScale}
                                addMessage={addMessage}
                                startLoader={startLoader}
                                finishLoader={finishLoader}
                                isCelsius={isCelsius}
                                isSensor={isSensorSelected}
                                timezone={timezone}
                                updateLocalSensor={updateLocalSensor}
                              />
                            )
                        ) : (
                            <div />
                          )}
                      </div>
                    </div>
                  </Slide>
                )}
              </Fragment>
            )}
      </div>
      {isStatisticsDialogOpen && (
        <StatisticsDialog
          open={isStatisticsDialogOpen}
          goBack={closeStatisticsDialog}
          siteName={selectedSiteId && site.name}
          temperatureScale={
            +temperatureScaleMirror.fahrenheit === temperatureScale
              ? "°F"
              : "°C"
          }
          units={selectedSiteId && siteUnits}
          startLoader={startLoader}
          finishLoader={finishLoader}
          getUnitStats={getUnitStats}
          site={site}
        />
      )}
    </div>
  );
};

export default HomeScreen;

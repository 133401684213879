import { createStyles, fade } from "@material-ui/core/styles";

const aboutUsStyle = (theme: any) =>
  createStyles({
    screenContainer: {
      display: "flex",
      flexFlow: "column nowrap",
      width: "100%"
    },
    container: {
      height: "100%",
      padding: "24px 0px",
      overflowY: "auto",
      boxSizing: "border-box",
      background: theme.palette.colors.blackFontShadow
    },

    text: {
      fontSize: "18px"
    },
    subtitles: {
      marginBottom: "36px",
      fontSize: "16px",
      opacity: "60%"
    },
    devicesContainer: { backgroundImage: theme.gradient.default },
    buttonContainer: { padding: "31px 20px" },
    expand: {
      transform: "rotate(0deg)",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest
      })
    },
    expandOpen: {
      transform: "rotate(-180deg)",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest
      })
    },
    titleRow: {
      display: "flex",
      flexFlow: "row nowrap",
      justifyContent: "space-between",
      alignItems: "center",
      paddingLeft: "20px",
      height: "53px"
    },
    divider: { backgroundColor: fade(theme.palette.colors.lightWhite, 0.2) },
    deviceRow: {
      borderBottom: `1px solid ${fade(theme.palette.colors.lightWhite, 0.2)}`,
      height: "53px",
      display: "flex",
      flexFlow: "row nowrap",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "0 20px"
    },
    expandMoreStyle: {
      padding: 0,
      color: theme.palette.colors.lightWhite,
      fontSize: "24px",
      opacity: 0.6
    },
    title: {
      color: theme.palette.colors.lightWhite,
      fontSize: "18px",
      lineHeight: "normal",
      opacity: 0.89
    },
    deviceName: {
      color: theme.palette.colors.lightWhite,
      fontSize: "16px",
      maxWidth: "45%",
      minWidth: "45%",
      display: "-webkit-box",
      "-webkit-line-clamp": 1,
      "-webkit-box-orient": "vertical",
      maxHeight: "55px",
      justifyContent: "space-between",
      alignItems: "center",
      whiteSpace: "unset",
      textOverflow: "unset",
      paddingRight: "10px",
      overflow: "hidden"
    },
    selectStyle: {
      margin: 0
    },
    try: { maxHeight: "30px" }
  });

export default aboutUsStyle;

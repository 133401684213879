import { fade } from "@material-ui/core";
import { createStyles } from "@material-ui/styles";

export default (theme: any) =>
  createStyles({
    screenContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      backgroundImage: theme.gradient.forgotPassword,
      overflow: "auto"
    },
    passwordRecoveryScreenContent: {
      width: "100%",
      maxWidth: "740px"
    },
    logoSection: {
      margin: "12% 0",
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
      alignSelf: "center",
      width: "100%",
      maxHeight: "50px"
    },
    logoStyle: { width: "auto", maxHeight: "60px", marginRight: "10px" },
    logoTitleStyle: { maxHeight: "27px", width: "auto" },
    requestResetPasswordContainer: {
      width: "100%",
      height: "60%",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",
      alignItems: "center",
      padding: "25px",
      color: theme.palette.colors.white,
      minHeight: "max-content"
    },
    errorStyle: {
      fontSize: "12px",
      marginTop: "1px",
      paddingLeft: "5px"
    },

    buttonStyle: {
      flex: 0.45,
      fontSize: "15px"
    },

    supportInfoStyle: {
      fontSize: "14px",
      marginTop: "10px",
      overflowWrap: "break-word",
      width: "75%",
      textAlign: "center",
      color: fade(theme.palette.colors.white, 0.6)
    },

    recoverPasswordMessegeStyle: {
      fontSize: "14px",
      overflowWrap: "break-word",
      width: "90%",
      opacity: 0.6,
      textAlign: "center"
    },
    buttonsWrapper: {
      justifyContent: "space-between"
    },
    radioGroupContainer: {
      width: "100%",
      boxSizing: "border-box",
      borderRadius: "4px",
      padding: "20px 5px",
      marginTop: "5px",
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
      minHeight: "max-content"
    },
    inputFeildStyle: {
      marginLeft: "16px"
    },
    fullWidthField: {
      width: "100%",
      "& .MuiTypography-body1": {
        width: "100%"
      }
    }
  });

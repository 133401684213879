import { createStyles, fade } from "@material-ui/core";

export default (theme: any) =>
  createStyles({
    dialogStyle: {
      padding: "31px 26px",
      minWidth: "276px",
      width: "276px",
      height: "auto"
    },
    dialogTitle: {
      padding: 0,
      fontSize: "26px",
      fontWeight: 600,
      lineHeight: "normal",
      color: theme.palette.colors.mainIconSelected,
      marginBottom: "13px"
    },
    dialogButtons: {
      color: theme.palette.colors.gray,
      fontWeight: "bold"
    },
    titleStyle: {
      fontSize: "26px",
      fontWeight: "bold",
      lineHeight: "normal",
      color: theme.palette.colors.mainIconSelected
    },
    contentStyle: {
      fontSize: "18px",
      color: theme.palette.colors.lightBlack,
      lineHeight: "normal",
      fontWeight: "normal",
      margin: 0
    },
    warning: {
      backgroundColor: theme.palette.colors.warning,
      "&:hover": {
        letterSpacing: "normal",
        color: theme.palette.colors.white,
        backgroundColor: fade(theme.palette.colors.warning, 0.8)
      },
      "&:focus": {
        letterSpacing: "normal",
        color: theme.palette.colors.white,
        backgroundColor: fade(theme.palette.colors.warning, 0.8),
        "& span:nth-child(2)": {
          "& span": {
            display: "none"
          }
        }
      }
    },
    error: {},
    success: {
      backgroundColor: theme.palette.colors.success,
      "&:hover": {
        letterSpacing: "normal",
        color: theme.palette.colors.white,
        backgroundColor: fade(theme.palette.colors.success, 0.8)
      },
      "&:focus": {
        letterSpacing: "normal",
        color: theme.palette.colors.white,
        backgroundColor: fade(theme.palette.colors.success, 0.8),
        "& span:nth-child(2)": {
          "& span": {
            display: "none"
          }
        }
      }
    },
    info: {
      backgroundColor: theme.palette.colors.info,
      "&:hover": {
        letterSpacing: "normal",
        color: theme.palette.colors.white,
        backgroundColor: fade(theme.palette.colors.info, 0.8)
      },
      "&:focus": {
        letterSpacing: "normal",
        color: theme.palette.colors.white,
        backgroundColor: fade(theme.palette.colors.info, 0.8),
        "& span:nth-child(2)": {
          "& span": {
            display: "none"
          }
        }
      }
    },
    confirmButtonStyle: {
      color: theme.palette.colors.lightBlack,
      border: `solid 1px ${theme.palette.colors.iconSelected}`,
      width: "129px",
      boxShadow:"none"
    },
    cancelButtonStyle: { width: "129px" },
    dialogTitleText: {
      marginLeft: "10px"
    },
    dialogContentStyle: { padding: 0, marginBottom: "26px" },
    dialogActionStyle: { padding: 0, margin: 0, justifyContent: "flex-start" },
    actionsSpacing: { "& :not(:first-child)": { marginLeft: "15px" } },
    normal: {
      background: theme.gradient.toolbarBackground,
      boxShadow: 'none'
    },

  });

import React, { useState } from "react";
import { Typography, makeStyles } from "@material-ui/core";
import { t } from "ttag";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import resetPasswordStyle from "./resetPassword.style";
import { FormikField, Button } from "../../widgets";
import {
  AppLogo,
  AppTitle,
  ShowPassword,
  HidePassword,
  Lock
} from "../../svgComponents";
import { ResetPasswordResult } from "../../components/ResetPasswordResult";
import { useStoreActions } from "../../Stores/typedHooks";

interface IComponentToDisplay {
  resetPassword: boolean;
  resultScreen: boolean;
}

interface IResetPasswordProps {
  history: any;
  match: any;
}
interface IPasswordObject {
  newPassword: boolean;
  confirmPassword: boolean;
}

const ResetPassword: React.FC<IResetPasswordProps> = ({ history, match }) => {
  const { params: { token = "" } = {} } = match;
  const [componentToDisplay, setComponentToDisplay] = useState<
    IComponentToDisplay
  >({
    resetPassword: true,
    resultScreen: false
  });
  const [passwordVisibility, setPasswordVisibility] = useState<IPasswordObject>(
    {
      newPassword: false,
      confirmPassword: false
    }
  );
  const resetUserPassword = useStoreActions(
    actions => actions.userStore.resetPassword
  );

  const startLoader = useStoreActions(
    actions => actions.loaderStore.startLoader
  );
  const finishLoader = useStoreActions(
    actions => actions.loaderStore.finishLoader
  );
  const addMessage = useStoreActions(
    actions => actions.messageStore.addMessage
  );

  const useStyles = makeStyles(resetPasswordStyle);
  const classes = useStyles();

  const validationSchema = Yup.object({
    password: Yup.string()
      .required(t`required field`)
      .min(8, t`Password is too short - should be 8 chars minimum.`)
      .matches(/[A-Z]/, t`password must include capital letters`)
      .matches(/[a-z]/, t`password must include lower case letters`)
      .matches(/[0-9]/, t`password must include numbers`),
    confrimPassword: Yup.string()
      .required(t`verify new password is required`)
      .oneOf([Yup.ref("password"), null], t`Passwords must match`)
  });

  const handleFormSubmit = (values: any) => {
    const { password } = values;
    resetUserPassword({ token, password })
      .then(() => {
        setComponentToDisplay({
          resetPassword: false,
          resultScreen: true
        });
      })
      .catch((error: any) => {
        addMessage({ message: error.message });
      })
      .finally(() => finishLoader());
  };
  const backToLogin = () => {
    history.push("/");
  };
  const handlePasswordVisibility = (passwordType: string, show: boolean) => {
    setPasswordVisibility({ ...passwordVisibility, [passwordType]: show });
  };

  return (
    <div className={classes.screenContainer}>
      <div className={classes.passwordRecoveryScreenContent}>
        <div className={classes.logoSection}>
          <AppLogo className={classes.logoStyle} />
          <AppTitle className={classes.logoTitleStyle} />
        </div>
        {componentToDisplay.resultScreen ? (
          <ResetPasswordResult
            backToLogin={backToLogin}
            startLoader={startLoader}
            finishLoader={finishLoader}
            addMessage={addMessage}
          />
        ) : (
            <div className={classes.resetPasswordContainer}>
              <Typography
                className={classes.resetPasswordMessegeStyle}
              >{t`Reset Password `}</Typography>
              <Formik
                initialValues={{
                  password: "",
                  confrimPassword: ""
                }}
                onSubmit={values => handleFormSubmit(values)}
                enableReinitialize={true}
                validationSchema={validationSchema}
                render={({ values, setFieldValue, ...formikProps }) => {
                  return (
                    <Form>
                      <FormikField
                        name="password"
                        variant="filled"
                        disableUnderline
                        type={
                          passwordVisibility.newPassword ? "text" : "password"
                        }
                        placeholder={t`New password`}
                        formikProps={formikProps}
                        className={classes.textFieldsStyle}
                        endAdornment={
                          passwordVisibility.newPassword ? (
                            <ShowPassword
                              onClick={() =>
                                handlePasswordVisibility("newPassword", false)
                              }
                            />
                          ) : (
                              <HidePassword
                                onClick={() =>
                                  handlePasswordVisibility("newPassword", true)
                                }
                              />
                            )
                        }
                      />
                      <FormikField
                        name="confrimPassword"
                        variant="filled"
                        disableUnderline
                        type={
                          passwordVisibility.confirmPassword ? "text" : "password"
                        }
                        placeholder={t`Verify new password`}
                        formikProps={formikProps}
                        className={classes.textFieldsStyle}
                        endAdornment={
                          passwordVisibility.confirmPassword ? (
                            <ShowPassword
                              onClick={() =>
                                handlePasswordVisibility("confirmPassword", false)
                              }
                            />
                          ) : (
                              <HidePassword
                                onClick={() =>
                                  handlePasswordVisibility("confirmPassword", true)
                                }
                              />
                            )
                        }
                      />
                      <Typography className={classes.textStyle}>
                        {t`Your password should include at least 8 characters, 1
                      number, 1 capital letter, 1 special characters`}
                      </Typography>
                      <Button type="submit" variant="contained">
                        {t`Reset password`}
                      </Button>
                    </Form>
                  );
                }}
              />
            </div>
          )}
      </div>
    </div>
  );
};

export default ResetPassword;

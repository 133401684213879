import { Sensor as SensorSdk } from "coolremote-sdk";
import {
    Action,
    action,
    thunk,
    Thunk
} from "easy-peasy";

export interface ISensor {
    id: string;
    name: string;
    readingValue?: string;
    availableReadingValues ?: any;
    readingValueTimestamp?: number;
    type: number;
    enableOperationMode: boolean;
    readingValueMeasurementUnits: number;
    device: string;
    deviceSerial: string;
    site: string;
    dataAddress: string;
}

export interface ISensorMap {
    [key: string]: ISensor;
}

export interface ISensorsModel {
    allSensors: ISensorMap;
    siteSensors: ISensorMap;
    getSiteSensors: Thunk<ISensorsModel, string>;
    getAllSensors: Thunk<ISensorsModel, any>;
    getSensorById: Thunk<ISensorsModel, any>;

    updateSensor: Thunk<ISensorsModel, { id: string; updatedData: any }>;
    updateSensorValue: Thunk<ISensorsModel, { id: string; data: {value: number} }>;
    setSiteSensors: Action<ISensorsModel, any>;
    setAllSensors: Action<ISensorsModel, any>;
    _storeCreateSensor: Action<ISensorsModel, { id: string; data: ISensor }>;
    _storeUpdateSensor: Action<ISensorsModel, { id: string; data: any }>;
}

export const sensorStore: ISensorsModel = {
    allSensors: {},
    siteSensors: {},

    getAllSensors: thunk(async (actions, payload) => {
        const allSensors = await SensorSdk.getMySensors();
        return allSensors;
    }),

    getSiteSensors: thunk(async (actions, payload) => {
        const siteSensors = SensorSdk.getSiteSensors(payload);
        return siteSensors;
    }),

    getSensorById: thunk(async (actions, payload) => {
        return SensorSdk.getSensorById(payload);
    }),

    updateSensor: thunk(async (actions, payload) => {
        const data = await SensorSdk.update(payload.id, payload.updatedData);

        // Why call _storeCreate_storeCreateSensor()? Because it assigns the answer as-is.
        actions._storeCreateSensor({ id: data.id, data });
        return data;
    }),

    setSiteSensors: action((state, payload) => {
        state.siteSensors = payload;
    }),

    setAllSensors: action((state, payload) => {
        state.allSensors = payload;
    }),

    _storeCreateSensor: action((state, payload) => {
        if (state.allSensors[payload.id]) {
          state.allSensors[payload.id] = { ...state.allSensors[payload.id], ...payload.data };
        }
        else {
          state.allSensors = { ...state.allSensors, [payload.id]: payload.data };
        }
      }),

    _storeUpdateSensor: action((state, payload) => {
        if (state.allSensors[payload.id]) {
          state.allSensors = { ...state.allSensors, [payload.id]: { ...state.allSensors[payload.id], ...payload.data } };
        }
      }),
    updateSensorValue: thunk(async (actions, payload) => {
          const {id, data} = payload;
          const response = await SensorSdk.updateSensorValues(id, data);

          return response;
      })
};

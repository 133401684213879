import { action, Action } from "easy-peasy";

export interface ILoaderModel {
  isLoaderShowing: boolean;
  startLoader: Action<ILoaderModel>;
  finishLoader: Action<ILoaderModel>;
}

const loaderStore: ILoaderModel = {
  isLoaderShowing: false,
  startLoader: action(state => {
    state.isLoaderShowing = true;
  }),
  finishLoader: action(state => {
    state.isLoaderShowing = false;
  })
};

export default loaderStore;

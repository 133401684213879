import { createStyles, fade } from "@material-ui/core/styles";

export default (theme: any) =>
  createStyles({
    rootField: {
      color: theme.palette.colors.lightWhite,
      fontSize: "16px",
      lineHeight: "normal",
      padding: 0,
      height: "50px",
      "& ::placeholder": {
        color: theme.palette.colors.lightWhite,
        fontSize: "16px",
        opacity: 1
      }
    },
    disabledInput: {
      color: fade(theme.palette.colors.lightWhite, 0.6),
      "& ::placeholder": { opacity: 0.6 }
    },
    inputAdornment: {
      marginLeft: "13px",
      marginRight: "15px",
      width: "21px"
    },
    notchedOutline: {
      borderColor: `${fade(theme.palette.colors.lightWhite, 0.3)} !important`
    },
    maxWidth: {
      width: "100%",
      "& div.MuiFormControl-root": { width: "100%" }
    }
  });

import {
  Dialog,
  Grid,
  makeStyles,
  Tab,
  Tabs,
  Typography
} from "@material-ui/core";
import _ from "lodash";
import moment from "moment-timezone";
import React, { useCallback, useEffect, useState } from "react";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis
} from "recharts";
import { t } from "ttag";
import { Toolbar } from "../../components";
import { useStoreActions } from "../../Stores/typedHooks";
import { ArrowBack } from "../../svgComponents";
import graphProps from "./graph.style";
import statisticsDialogStyle from "./statisticsDialog.style";
import UnitSelection from "./UnitSelection";

const defaultSeries = [
  { name: "working Hours", type: "column", data: [] },
  { name: "Set Point", type: "line", data: [] },

  {
    name: "Room Temperature",
    type: "area",
    data: []
  }
];

const CustomizedXAxisTick = (props: any) => {
  const {
    x, y, stroke, payload, isDay
  } = props;

  const format = isDay ? "HH:mm" : "DD/MM";
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dx={40} dy={10} textAnchor="end" fill="#666" transform="rotate(35)">{moment(payload.value).format(format)}</text>
    </g>
  );
};

const CustomizedYAxisTick = (props: any) => {
  const {
    x, y, stroke, payload, unit
  } = props;
  const val = payload.value;
  const dx = 18;
  const rotation = 0;
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dx={dx} dy={5} textAnchor="end" fill="#666">{val}</text>
      <text x={0} y={0} dx={dx} dy={25} textAnchor="end" fill="#666" transform={`rotate(${rotation})`}>{unit}</text>
    </g>
  );
};

const CustomizedY2AxisTick = (props: any) => {
  const {
    x, y, stroke, payload, unit, isDayRange
  } = props;
  const val = (isDayRange ? `${Math.round(payload.value / (1000 * 60))}` : `${Math.round(payload.value / (1000 * 60 * 60))}`);
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dx={10} dy={0} textAnchor="end" fill="#666" transform={`rotate(-90)`}>{`${val} ${unit}`}</text>
      {/* <text x={0} y={0} dx={dx} dy={25} textAnchor="end" fill="#666" transform={`rotate(${rotation})`}>{unit}</text> */}
    </g>
  );
};

const StatisticsDialog = (props: any) => {
  const fetchUnitDiagByParams = useStoreActions((a) => a.unitStore.fetchUnitDiagByParams);

  const [selectedUnitId, setSelectedUnitId] = useState<any>("");
  const [results, setResults] = useState<any>({ buckets: [] });
  const [start, setStart] = useState<any>(null);
  const [end, setEnd] = useState<any>(null);
  const [paramsData, setParamsData] = useState<any>(null);
  const [tabIndex, setTabIndex] = useState<any>(0);

  const {
    open,
    goBack,
    temperatureScale,
    units,
    siteName,
    getUnitStats,
    startLoader,
    finishLoader,
    site
  } = props;
  const useStyles = makeStyles(statisticsDialogStyle);
  const classes = useStyles();

  const setRange = useCallback(() => {

    const { timezone } = site;
    const startTime = moment().tz(timezone).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    const endTime = moment().tz(timezone).set({ hour: 23, minute: 59, second: 59, millisecond: 0 }).add(1, "second");

    const startTimeGMT = startTime.clone().tz("gmt");
    const endTimeGMT = endTime.clone().tz("gmt");

    if (tabIndex === 1) {
      return {
        bucketSizeMsec: 24 * 60 * 60 * 1000,
        startTimeGMT: startTimeGMT.subtract(7, "days").valueOf(),
        endTimeGMT: endTimeGMT.valueOf()
      };
    }

    if (tabIndex === 2) {
      return {
        bucketSizeMsec: 24 * 60 * 60 * 1000,
        startTimeGMT: startTimeGMT.subtract(1, "months").valueOf(),
        endTimeGMT: endTimeGMT.valueOf()
      };
    }

    return { bucketSizeMsec: 60 * 60 * 1000, startTimeGMT: startTimeGMT.valueOf(), endTimeGMT: endTimeGMT.valueOf() };
  }, [tabIndex]);

  const fetchData = useCallback(() => {
    const { bucketSizeMsec, startTimeGMT, endTimeGMT } = setRange();

    if (!selectedUnitId) {
      finishLoader();
      return;
    }

    getUnitStats({
      unitId: selectedUnitId,
      startTime: startTimeGMT,
      endTime: endTimeGMT,
      size: bucketSizeMsec
    })
      .then((basicState: any) => {
        const { buckets } = basicState;

        setResults(basicState);
        setStart(startTimeGMT);
        setEnd(endTimeGMT);

        return fetchUnitDiagByParams({
          unitId: selectedUnitId,
          startTime: startTimeGMT,
          endTime: endTimeGMT,
          params: ["49", "50"]
        });
      })
      .then((diag: any) => {
        setParamsData(diag);
      })
      .catch((err: any) => {
        setResults({});
      })
      .finally(() => finishLoader());
  }, [
    finishLoader,
    getUnitStats,
    selectedUnitId,
    setRange,
    tabIndex
  ]);

  useEffect(() => {
    if (!selectedUnitId) {
      return;
    }
    startLoader();
    fetchData();
  }, [fetchData, selectedUnitId]);

  const handleSelectedUnitChange = (selectedUnitId: number) => {
    setSelectedUnitId(selectedUnitId);
  };

  const handleTabsChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabIndex(newValue);
  };

  const isWeekRange = tabIndex === 1;
  const isDayRange = tabIndex === 0;
  const ticksArr = results.buckets.map((bucket: any) => bucket.timestamp) || [];

  return (
    <div className={classes.screenContainer}>
      <Dialog
        fullScreen
        open={open}
        PaperProps={{ classes: { root: classes.dialogStyle } }}
      >
        <Toolbar
          title={t`Statistics`}
          leftIconComponent={<ArrowBack />}
          leftAction={goBack}
        />
        <div className={classes.unitsGraphWrapper}>
          <UnitSelection
            siteName={siteName}
            units={units}
            handleSelectedUnitChange={handleSelectedUnitChange}
          />
          <Grid container direction="column" className={classes.graphSection}>
            <Tabs
              classes={{ root: classes.tabStyle, indicator: classes.indicator }}
              variant="fullWidth"
              value={tabIndex}
              onChange={handleTabsChange}
              indicatorColor="primary"
              centered
            >
              <Tab label={t`Day`} classes={{ root: classes.tabMedia }} />
              <Tab label={t`last 7 days`} classes={{ root: classes.tabMedia }} />
              <Tab label={t`Last 30 days`} classes={{ root: classes.tabMedia }} />
            </Tabs>
            {selectedUnitId && (
              <>
                <Grid className={classes.graphContainer}>
                  <ResponsiveContainer width="100%" height="80%">

                    <ComposedChart height={500} data={results ? results.buckets : []}>
                      <XAxis
                        xAxisId={"mainX"}
                        dataKey="timestamp"
                        height={60}
                        interval={0}
                        type="number"
                        tickCount={isDayRange ? 8 : (isWeekRange ? 7 : 10)}
                        domain={[start, end]}
                        padding={{ left: 10, right: 10 }}
                        tick={<CustomizedXAxisTick isDay={isDayRange} />}
                        ticks={ticksArr.length > 10 ? ticksArr.filter((tick: any, index: any) => index % 3 === 0) : ticksArr}
                      />
                      <YAxis
                        yAxisId={"left"}
                        width={23}
                        type={"number"}
                        domain={isDayRange ? [0, (1000 * 60 * 60) + (5000)] : [0, (1000 * 60 * 60 * 24) + 60000]}
                        allowDecimals={false}
                        padding={{ bottom: 10, top: 10 }}
                        tick={<CustomizedY2AxisTick unit={isDayRange ? "Mins" : "Hours"} isDayRange={isDayRange} />}
                      />
                      <YAxis
                        yAxisId={"right"}
                        width={23}
                        type={"number"}
                        allowDecimals={false}
                        orientation={"right"}
                        domain={["auto", "auto"]}
                        padding={{ bottom: 10, top: 10 }}
                        tick={<CustomizedYAxisTick unit={temperatureScale} />}
                      />
                      <Legend />
                      <CartesianGrid stroke="#f5f5f5" />
                      <Bar yAxisId={"left"} name="Cool" xAxisId={"mainX"} dataKey="unitBucketCoolTime" stackId={"a1"} barSize={10} fill="#35a8e0" />
                      <Bar yAxisId={"left"} name="Heat" xAxisId={"mainX"} dataKey="unitBucketHeatTime" stackId={"a1"} barSize={10} fill="#f05146" />
                      <Bar yAxisId={"left"} name="Other" xAxisId={"mainX"} dataKey="unitBucketOtherTime" stackId={"a1"} barSize={10} fill="#aaa2aa" />

                      {paramsData &&
                        (
                          <Line
                            name={t`Room Temp`}
                            xAxisId={"mainX"}
                            yAxisId={"right"}
                            data={paramsData}
                            dataKey={49}
                            type="monotone"
                            stroke={"#fccf03"}
                            dot={false}
                          />

                        )}

                      {paramsData &&
                        (
                          <Line
                            name={t`Set Point`}
                            xAxisId={"mainX"}
                            yAxisId={"right"}
                            data={paramsData}
                            dataKey={50}
                            type="monotone"
                            stroke={"#00ff11"}
                            dot={false}
                          />

                        )}

                    </ComposedChart>
                  </ResponsiveContainer>
                </Grid>
                <div className={classes.workingHoursContainer}>
                  <Typography>{results && results.onTime ? (results.onTime / (1000 * 60 * 60)).toFixed(2) : 0}</Typography>
                  <Typography
                    display="inline"
                    variant="inherit"
                  >{t`Hrs`}</Typography>
                  <Typography className={classes.workingHoursTextStyle}>
                    {t`Working Hours`}
                  </Typography>
                </div>
              </>
            )}
          </Grid>
        </div>
      </Dialog>
    </div>
  );
};

export default StatisticsDialog;

import { fade } from "@material-ui/core";
import { createStyles } from "@material-ui/styles";

export default (theme: any) =>
  createStyles({
    screenContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      backgroundColor: theme.palette.colors.profileBackground
    },
    iconStyle: {
      fontSize: "22px",
      color: theme.palette.colors.white
    },
    dialogStyle: {
      "@media (min-width: 769px)": {
        maxWidth: "375px",
        maxHeight: "812px"
      },
      "@media (min-height: 1025px)": {
        maxWidth: "375px",
        maxHeight: "812px"
      }
    },
    tabStyle: {
      padding: "0px 20px",
      color: theme.palette.colors.lightWhite,
      backgroundColor: fade(theme.palette.colors.lightWhite, 0.09)
    },
    tabMedia: {
      "@media (min-width: 600px)": {
        minWidth: "unset"
      }
    },
    indicator: {
      backgroundColor: theme.palette.colors.button2Color
    },
    graphSection: {
      backgroundImage: theme.gradient.default,
      height: "70%",
      marginTop: "10px",
      display: "flex",
      flexFlow: "column nowrap",
      paddingBottom: "20px",
      flex: 1
    },
    unitsGraphWrapper: {
      height: "calc(100% - 45px)",
      backgroundColor: theme.palette.colors.black,
      display: "flex",
      flexDirection: "column"
    },
    workingHoursContainer: {
      color: theme.palette.colors.white,
      borderRadius: "6px",
      backgroundColor: fade(theme.palette.colors.lightWhite, 0.1),
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "center",
      alignItems: "center",
      width: "35%",
      margin: "0 auto",
      padding: "8px",
      fontSize: "13px",
      textAlign: "center",
      height: "8%"
    },
    workingHoursTextStyle: {
      fontSize: "13px",
      width: "90%"
    },
    graphContainer: {
      width: "100%",
      height: "78%",
      display: "flex",
      alignItems: "center"
    }
  });

import { Action, action, Computed, computed, Thunk, thunk } from "easy-peasy";
import _ from "lodash";
import locale from "../services/localeService";
import { Injections } from "./";

interface IChangePasswordPayload {
  oldPassword: string;
  newPassword: string;
}

interface ILoginPayload {
  username: string;
  password: string;
}

interface ICreateUSerPayload {
  name: string;
  token: string;
  userData: { username: string; password: string; appId: string };
}
interface IRecoverPassword {
  data: string;
  type: string;
}
interface IResetPassword {
  token: string;
  password: string;
}
interface IUpdateSubUser {
  userId: string;
  data: object;
}

export interface IUserModel {
  user: any;
  displayFlags: any;
  dateFormat: Computed<any>;
  timeFormat: Computed<any>;
  features: any;
  temperatureSymbol: Computed<IUserModel>;
  temperatureScaleRange: Computed<IUserModel>;
  setUser: Action<IUserModel, any>;
  setUserFeatrues: Action<IUserModel, any>;
  setDisplayFlags: Action<IUserModel, any>;
  changePassword: Thunk<IUserModel, IChangePasswordPayload>;
  login: Thunk<IUserModel, ILoginPayload, Injections>;
  checkLoggedin: Thunk<IUserModel, void, Injections>;
  updateUser: Thunk<IUserModel, object, Injections>;
  logout: Thunk<IUserModel, void, Injections>;
  checkUsernameAvailability: Thunk<IUserModel, string, Injections>;
  createUserUsingDeviceToken: Thunk<IUserModel, ICreateUSerPayload, Injections>;
  getUserFeatures: Thunk<IUserModel, void, Injections>;
  deleteUser: Thunk<IUserModel, any, Injections>;
  getPasswordRecoveryToken: Thunk<IUserModel, IRecoverPassword, Injections>;
  resetPassword: Thunk<IUserModel, IResetPassword, Injections>;
  updateSubUser: Thunk<IUserModel, IUpdateSubUser, Injections>;
  getUserById: Thunk<IUserModel, String, Injections>;
  userAcceptedTOU: Action<IUserModel, any>;
}

const userStore: IUserModel = {
  user: { isLoggedIn: false, timeFormat: "0", dateFormat: "0" },
  timeFormat: computed([
    (state) => state.user,
    (state, storeState: any) => storeState.typesStore.types
  ], (user, types) => {
    if (!types || !types.timeFormat){
      return "HH:mm";
    }
    const { timeFormat: selectedTime= "0" } = user;
    const timeFormatObject = types.timeFormat[selectedTime];
    if (timeFormatObject.text === "24 hours"){
      return "HH:mm";
    }
    if (timeFormatObject.text === "12 hours"){
      return "hh:mma";
    }
  }),
  dateFormat: computed([
    (state) => state.user,
    (state, storeState: any) => storeState.typesStore.types
  ], (user, types) => {
    if (!types || !types.dateFormat){
      return "DD/MM/YY";
    }
    const { dateFormat: selectedDate= "0" } = user;
    return types.dateFormat[selectedDate].text;
  }),
  displayFlags: {},
  features: null,
  temperatureSymbol: computed((state) => {
    const { user } = state;
    const { temperatureScale } = user;

    if (temperatureScale === 2) {
      return "°F";
    }

    return "°C";
  }),
  temperatureScaleRange: computed((state) => {
    const { user } = state;
    const { temperatureScale } = user;

    if (temperatureScale === 2) {
      return [15, 105];
    }

    return [-15, 45];
  }),
  setUser: action((state, payload) => {
    locale.setLocale(payload.language || "en");
    state.user = payload;
  }),
  setUserFeatrues: action((state, payload) => {
    state.features = payload;
  }),
  setDisplayFlags: action((state, payload) => {
    state.displayFlags = payload;
  }),
  changePassword: thunk((actions, payload, { injections, getState }) => {
    const { oldPassword, newPassword } = payload;
    const { sdkUser } = injections;
    const { user: { id } } = getState();

    return sdkUser.updatePassword(id, oldPassword, newPassword);
  }),
  logout: thunk(async (actions, payload, { injections, getStoreActions }) => {
    actions.setUser({ isLoggedIn: false });

    localStorage.removeItem("token");
    localStorage.removeItem("selectedSite");
    localStorage.removeItem("selectedItem");
    localStorage.removeItem("isGroup");

    const storeActions: any = getStoreActions();
    const { reset } = storeActions;
    reset();

    const { sdkUser } = injections;
    sdkUser.closeWebSocket();
    sdkUser.logout();
  }),
  login: thunk(async (actions, payload, { injections, getStoreState }) => {
    const { username, password } = payload;
    const { sdkUser, sdkXhr, coolRemoteSDK } = injections;
    await actions.logout();

    const { configStore: { appId } }: any = getStoreState();
    const { data, message } = await sdkUser.connect(username, password, appId);

    if (message) {
      return { message };
    }

    const token = data.token;
    localStorage.setItem("token", token);
    sdkXhr.setToken(token);

    // fetch user
    const user = await sdkUser.getMe();
    const displayFlags = await coolRemoteSDK.ControlApplication.getFlags();
    actions.setDisplayFlags(displayFlags);

    if (!displayFlags.globalEnable) {
      actions.logout();
      return {
        message: "Your user is not allowed to access the control application"
      };
    }

    if (!user) {
      actions.logout();
      return { message: "your session has been expired please login again" };
    }
    const updatedUser = { ...user, isLoggedIn: true };

    actions.setUser(updatedUser);
    return updatedUser;
  }),
  checkLoggedin: thunk(async (actions, payload, { injections }) => {
    const { sdkUser, sdkXhr, coolRemoteSDK } = injections;
    const token = localStorage.getItem("token");

    if (!token) {
      actions.logout();
      return;
    }

    sdkXhr.setToken(token);
    const user = await sdkUser.getMe();
    if (!user) {
      actions.logout();
      return;
    }
    const displayFlags = await coolRemoteSDK.ControlApplication.getFlags();
    actions.setDisplayFlags(displayFlags);
    const updatedUser = { ...user, isLoggedIn: true };
    actions.setUser(updatedUser);
    return updatedUser;
  }),
  userAcceptedTOU: action((state, payload) => {
    const updatedUser = { ...state.user, isAcceptedTOU: payload };
    state.user = updatedUser;
  }),
  checkUsernameAvailability: thunk(async (actions, payload, { injections }) => {
    const { sdkUser } = injections;
    return await sdkUser.isUsernameAvailable(payload);
  }),
  updateUser: thunk(async (actions, payload, { injections, getState }) => {
    const { sdkUser } = injections;
    const { user: { id } } = getState();
    const user = await sdkUser.update(id, payload);

    if (!user) {
      return;
    }
    locale.setLocale(user.language || "en");
    user["isLoggedIn"] = true;
    actions.setUser(user);
    actions.getUserFeatures();
  }),
  getUserFeatures: thunk((actions, payload, { injections }) => {
    const { coolRemoteSDK } = injections;
    coolRemoteSDK.Services.getFeatures().then((res: any) => {
      actions.setUserFeatrues(res.data);
    });
  }),
  createUserUsingDeviceToken: thunk(
    async (actions, payload, { injections, getStoreState }) => {
      const { sdkCustomer } = injections;
      const { name, token, userData } = payload;

      const { configStore: { appId } }: any = getStoreState();

      const newCustomer = await sdkCustomer.createCustomerUsingDevice({
        name,
        token
      });

      if (!newCustomer) {
        return;
      }

      const { id } = newCustomer;
      userData.appId = appId;
      const newUser = await sdkCustomer.createUserUsingDevice(id, userData);

      if (!newUser) {
        return;
      }

      const { username, password } = userData;
      actions.login({ username, password });

      return newCustomer;
    }
  ),
  deleteUser: thunk(async (actions, payload, { injections }) => {
    const { sdkUser } = injections;
    return await sdkUser.delete(payload);
  }),
  getPasswordRecoveryToken: thunk(async (actions, payload, { injections }) => {
    const { sdkUser } = injections;
    const { data, type } = payload;
    return await sdkUser.getRecoveryToken(data, type);
  }),
  resetPassword: thunk(async (actions, payload, { injections }) => {
    const { sdkUser } = injections;
    const { token, password } = payload;
    return await sdkUser.resetPassword(token, password);
  }),
  updateSubUser: thunk(async (actions, payload, { injections, getState }) => {
    const { sdkUser } = injections;
    const { userId, data } = payload;

    return await sdkUser.update(userId, data);
  }),
  getUserById: thunk((actions, payload, { injections, getState }) => {
    const { sdkUser } = injections;

    return sdkUser.getUserById(payload);
  })
};

export default userStore;

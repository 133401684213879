import React from "react";
import { makeStyles, Switch as MUISwitch } from "@material-ui/core";
import styles from "./switch.style";

const Switch = ({ switchChange, ...props }: any) => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();

  return (
    <MUISwitch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      onChange={switchChange}
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked
      }}
      {...props}
    />
  );
};

export default Switch;

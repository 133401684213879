import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Collapse,
  TextField,
  Typography,
  InputAdornment
} from "@material-ui/core";
import clsx from "clsx";
import { Edit, Check, Close } from "@material-ui/icons";
import { t } from "ttag";
import _ from "lodash";
import hexToAscii from "../../services/hexToAscii";
import { Toolbar } from "../../components";
import { Button, Select } from "../../widgets";

import { useStoreActions, useStoreState } from "../../Stores/typedHooks";
import { ArrowDown } from "../../svgComponents";

import pageStyles from "./editSystems.style";

const EditDetectedSystems = (props: any) => {
  const [dataByLine, setDataByLine] = useState<any>({});
  const [selectedLine, setSelectedLine] = useState<any>(null);
  const [selectedUnit, setSelectedUnit] = useState<any>(null);
  const [selectedUnitName, setSelectedUnitName] = useState<any>(null);
  const [internalIds, setInternalIds] = useState<any>({});

  const { history, match: { params: { deviceId = "" } = {} } = {} } = props;

  const useStyles = makeStyles(pageStyles);
  const classes = useStyles();

  const unitsTypes = useStoreState(state => state.typesStore.unitTypes);

  const getDeviceTree = useStoreActions(
    actions => actions.deviceStore.getDeviceTree
  );
  const updateUnitSystem = useStoreActions(
    action => action.unitStore.updateUnitSystem
  );
  const updateUnitAction = useStoreActions(
    action => action.unitStore.updateUnit
  );
  const addMessage = useStoreActions(action => action.messageStore.addMessage);
  const getInternalIds = useStoreActions(
    action => action.deviceStore.getDeviceDaikin
  );

  useEffect(() => {
    if (!deviceId) {
      history.push("/");
    }
    getDeviceTree(deviceId).then((device: any) => {
      let data: any = {};
      const deviceUnits = device.units;
      const deviceSystems = device.systems;
      if (!_.isEmpty(deviceUnits)) {
        const deviceUnitsArr = Object.values(deviceUnits);
        for (let unitIdx in deviceUnitsArr) {
          const unit: any = deviceUnitsArr[unitIdx];
          const unitLine = unit.line;

          if (_.has(data, unitLine)) {
            data[unitLine].units[unit.id] = unit;
          } else {
            data[unitLine] = {
              units: { [unit.id]: unit },
              systems: {},
              isDaikinLine: false
            };
          }
        }
      }

      if (!_.isEmpty(deviceSystems)) {
        const deviceSystemsArr = Object.values(deviceSystems);
        for (let sysIdx in deviceSystemsArr) {
          const sys: any = deviceSystemsArr[sysIdx];
          const sysOption: any = {
            value: sys.id,
            label: sys.name,
            brand: sys.brand
          };
          const sysLine = sys.line;

          if (_.has(data, sysLine)) {
            data[sysLine].systems[sys.id] = sysOption;
          } else {
            data[sysLine] = { systems: { [sys.id]: sysOption } };
          }

          if (sysOption.brand === "DAIKIN_D3NET") {
            data[sysLine] = { ...data[sysLine], isDaikinLine: true };
          }
        }
      }
      setDataByLine(data);
    });
  }, [deviceId, getDeviceTree, history]);

  useEffect(() => {
    if (_.isEmpty(dataByLine)) {
      return;
    }

    const lines = Object.keys(dataByLine);

    for (let i in lines) {
      const lineNumber = lines[i];
      getInternalIds({
        deviceId: deviceId as string,
        lineId: lineNumber as string
      }).then((result: any) => {
        let lineInternals: any = {};
        for (let proIdIdx in result) {
          const internalId = result[proIdIdx];
          lineInternals[internalId.proId] = {
            value: internalId.proId,
            label: `${hexToAscii(internalId.proId)}/${internalId.airNet}`
          };
        }

        setInternalIds((internalIds: any) => {
          return { ...internalIds, [lineNumber]: lineInternals };
        });
      });
    }
  }, [dataByLine, getInternalIds, deviceId]);

  const selectLine = (key: any) => {
    if (selectedLine === key) {
      setSelectedLine(null);
    } else {
      setSelectedLine(key);
    }
  };

  const updateSystem = (
    unitId: string,
    oldSystem: string,
    newSystem: string,
    lineNumber: any
  ) => {
    updateUnitSystem({ unitId, oldSystem, newSystem })
      .then(() => {
        var dataCopy = { ...dataByLine };
        dataCopy[lineNumber].units[unitId].system = newSystem;
        setDataByLine(dataCopy);
      })
      .catch((error: any) => {
        addMessage({ message: error.message });
      });
  };

  const changeEditName = (lineNumber: any, unitId?: string) => {
    if (!lineNumber || !unitId) {
      setSelectedUnitName("");
      setSelectedUnit(null);
      return;
    }
    setSelectedUnitName(dataByLine[lineNumber].units[unitId].name);
    setSelectedUnit(unitId);
  };

  const updateNameLocally = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedUnitName(event.target.value);
  };

  const updateUnit = (lineNumber: any, unitId: string, unit: any) => {
    updateUnitAction({ unitId, data: unit })
      .then(() => {
        let copyData: any = { ...dataByLine };
        copyData[lineNumber].units[unitId] = {
          ...copyData[lineNumber].units[unitId],
          ...unit
        };
        setDataByLine(copyData);
        changeEditName(null);
      })
      .catch((error: any) => {
        addMessage({ message: error.message });
      });
  };

  const goNext = () => {
    history.push("/site");
  };

  return (
    <div className={classes.screenContainer}>
      <div className={classes.pageContainer}>
        <Toolbar title={t`Found Systems and Units`} />
        <div className={classes.pageContent}>
          {Object.keys(dataByLine).map((lineNumber: any) => {
            const units = Object.values(dataByLine[lineNumber].units);
            const systems = dataByLine[lineNumber].systems;
            systems["null"] = { value: null, label: "None" };
            const lineSystemOptions = Object.values(systems);

            return (
              <div key={`line-${lineNumber}`} className={classes.lineContainer}>
                <div
                  onClick={() => selectLine(lineNumber)}
                  className={classes.collapseHeader}
                >
                  <Typography
                    className={classes.normalText}
                  >{`Line ${lineNumber}`}</Typography>{" "}
                  <ArrowDown />
                </div>
                <Collapse in={lineNumber === selectedLine}>
                  {units.map((unit: any) => {
                    const {
                      id: unitId,
                      name: unitName,
                      type: unitType,
                      system: unitSystem,
                      proId: unitProId
                    } = unit;
                    const isSelected = selectedUnit === unitId;
                    const unitSystemObj =
                      dataByLine[lineNumber].systems[unitSystem];

                    const isDaikin =
                      unitSystemObj &&
                      unitSystemObj.brand &&
                      unitSystemObj.brand === "DAIKIN_D3NET";
                    const { isDaikinLine } = dataByLine[lineNumber];

                    const isIndoor = unitsTypes[unitType] === "indoor";

                    if (
                      selectedLine &&
                      isDaikin &&
                      isIndoor &&
                      !internalIds[lineNumber]
                    ) {
                      getInternalIds({
                        deviceId: deviceId as string,
                        lineId: lineNumber as string
                      }).then((result: any) => {
                        let lineInternals: any = {};
                        for (let proIdIdx in result) {
                          const internalId = result[proIdIdx];
                          lineInternals[internalId.proId] = {
                            value: internalId.proId,
                            label: `${hexToAscii(internalId.proId)}/${
                              internalId.airNet
                              }`
                          };
                        }
                        setInternalIds({ [lineNumber]: lineInternals });
                      });
                    }

                    return (
                      <div
                        key={`line-${lineNumber}--unit-${unitId}`}
                        className={classes.unitContainer}
                      >
                        <div className={classes.detailsRow}>
                          <Typography
                            className={classes.labelText}
                          >{t`Unit Name`}</Typography>
                          <TextField
                            variant={"outlined"}
                            className={classes.unitNameStyle}
                            disabled={!isSelected}
                            value={isSelected ? selectedUnitName : unitName}
                            onChange={updateNameLocally}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  {isSelected ? (
                                    <>
                                      <Check
                                        className={classes.iconStyle}
                                        onClick={() =>
                                          updateUnit(lineNumber, unitId, {
                                            name: selectedUnitName
                                          })
                                        }
                                      />
                                      <Close
                                        className={clsx(
                                          classes.iconStyle,
                                          classes.cancelIcon
                                        )}
                                        onClick={() => changeEditName(null)}
                                      />
                                    </>
                                  ) : (
                                      <Edit
                                        className={classes.iconStyle}
                                        onClick={() =>
                                          changeEditName(lineNumber, unitId)
                                        }
                                      />
                                    )}
                                </InputAdornment>
                              )
                            }}
                          />
                        </div>
                        <div className={classes.detailsRow}>
                          <Typography
                            className={classes.labelText}
                          >{t`Unit Type`}</Typography>
                          <Typography
                            className={classes.normalText}
                          >{unitsTypes[unitType] + t`Unit`}</Typography>
                        </div>

                        {isDaikinLine && isIndoor && (
                          <div className={classes.detailsRow}>
                            <Typography
                              className={classes.labelText}
                            >{t`Internal ID`}</Typography>
                            <Select
                              placeholder={t`Internal ID`}
                              value={
                                unitProId && internalIds[lineNumber]
                                  ? internalIds[lineNumber][unitProId]
                                  : {
                                    value: "",
                                    label: "Not Assigned"
                                  }
                              }
                              suggestions={
                                _.isEmpty(internalIds)
                                  ? [{ value: null, label: "Not Assigned" }]
                                  : [
                                    { value: null, label: "Not Assigned" },
                                    ...Object.values(
                                      internalIds[lineNumber] || {}
                                    )
                                  ]
                              }
                              handleSelectChange={(option: any) =>
                                updateUnit(lineNumber, unitId, {
                                  proId: option.value
                                })
                              }
                              clear={false}
                              search={false}
                            />
                          </div>
                        )}

                        <div className={classes.detailsRow}>
                          <Typography
                            className={classes.labelText}
                          >{t`Unit System`}</Typography>
                          <Select
                            placeholder={t`Unit Systems`}
                            value={systems[unitSystem] || systems["null"]}
                            suggestions={lineSystemOptions}
                            handleSelectChange={(option: any) =>
                              updateSystem(
                                unitId,
                                unitSystem,
                                option.value,
                                lineNumber
                              )
                            }
                            clear={false}
                            search={false}
                          />
                        </div>
                      </div>
                    );
                  })}
                </Collapse>
              </div>
            );
          })}
          <Button variant="contained" onClick={goNext}>
            {t`Next`}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EditDetectedSystems;

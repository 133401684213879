import { Collapse, IconButton, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ExpandMore } from "@material-ui/icons";
import clsx from "clsx";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import { ConfirmationDialog, Toolbar } from "../../components";
import { useStoreActions, useStoreState } from "../../Stores/typedHooks";
import { ArrowBack } from "../../svgComponents";
import { Button } from "../../widgets";
import styles from "./unitsManagement.style";

const UnitsManagement = (props: any) => {
  const { history } = props;

  const [units, setUnits] = useState<any>({});
  const [fetchedUnits, setFetchedUnits] = useState<boolean>(false);
  const [selectedSite, setSelectedSite] = useState<any>(null);
  const [openDialog, setOpenDialog] = useState<any>(false);
  const [selectedUnitId, setSelectedUnitId] = useState<any>("");

  const sites = useStoreState((state) => state.siteStore.userSites);
  const types = useStoreState((state) => state.typesStore.types);
  const getSites = useStoreActions((state) => state.siteStore.getUserSites);
  const startLoader = useStoreActions(
    (actions) => actions.loaderStore.startLoader
  );
  const finishLoader = useStoreActions(
    (actions) => actions.loaderStore.finishLoader
  );
  const getUnits = useStoreActions((actions) => actions.unitStore.getUserUnits);
  let sitesArray = Object.values(sites);
  const addMessage = useStoreActions((action) => action.messageStore.addMessage);
  const clearFilter = useStoreActions((action) => action.unitStore.clearFilter);

  const { unitTypes } = types;

  useEffect(() => {
    if (!_.isEmpty(sites)) {
      return;
    }
    startLoader();
    getSites().finally(() => finishLoader());
  }, [sites, getSites, startLoader, finishLoader]);

  useEffect(() => {
    if (fetchedUnits) {
      return;
    }
    getUnits().then((units: any) => {
      setUnits(units);
      setFetchedUnits(true);
    });
  }, [fetchedUnits, getUnits]);

  const toggleSite = (siteId: string) => {
    if (sitesArray.length === 1) {
      return;
    }

    if (siteId === selectedSite) {
      setSelectedSite(null);
    } else {
      setSelectedSite(siteId);
    }
  };

  const goToUnit = (unitId: string) => {
    history.push(`/unit-management/${unitId}`);
  };

  const showConfirmation = (unitId: string) => {
    setOpenDialog(true);
    setSelectedUnitId(unitId);
  };

  const useStyles = makeStyles(styles);

  const clearUnitFilter = (unitId: string, filter: any) => {
    startLoader();
    clearFilter(unitId)
      .then(() => {
        setUnits({ ...units, [unitId]: { ...units[unitId], ...filter } });
        setOpenDialog(false);
      })
      .catch((err: any) =>
        addMessage({
          message: err.message
        })
      )
      .finally(() => finishLoader());
  };

  const classes = useStyles();

  return (
    <div className={classes.screenContainer}>
      <Toolbar
        title={t`Units Management`}
        leftIconComponent={<ArrowBack />}
        leftAction={() => history.push({ pathname: "/site", menuOpen: true })}
      />
      <div className={classes.pageContent}>
        {sitesArray.map((site: any, i: number) => {
          const { id: siteId, name, units: siteUnits } = site;
          const isSiteOpen = sitesArray.length === 1 || selectedSite === siteId;
          return (
            <div key={`site-item-${siteId}`} className={classes.siteContainer}>
              <div
                onClick={() => toggleSite(siteId)}
                className={clsx(
                  classes.siteHeader,
                  !isSiteOpen && classes.closedSite
                )}
              >
                <Typography className={classes.siteNameStyle}>
                  {name}
                </Typography>
                <IconButton
                  id={`site-button-arrow-down-${siteId}`}
                  className={isSiteOpen ? classes.expandOpen : classes.expand}
                >
                  <ExpandMore id={`site-${siteId}`} />
                </IconButton>
              </div>
              <Collapse
                in={isSiteOpen}
                timeout="auto"
                unmountOnExit
                classes={{
                  entered:
                    sitesArray.length === 1 ? "" : classes.collapseWrapper
                }}
              >
                {siteUnits && !_.isEmpty(siteUnits) ? (
                  siteUnits.map((unitId: any) => {
                    const unit = units[unitId];
                    if (!unit || _.isEmpty(unit)) {
                      return null;
                    }
                    const { name: unitName, type, filter } = unit;

                    if (type === unitTypes.outdoor) {
                      return null;
                    }
                    return (
                      <div
                        key={`${siteId}-${unitId}`}
                        className={classes.unitContainer}
                      >
                        <div
                          className={classes.unitNameContainer}
                          onClick={() => goToUnit(unitId)}
                        >
                          <Typography className={clsx(classes.unitNameStyle)}>
                            {unitName}
                          </Typography>
                        </div>

                        {filter && (
                          <Button
                            onClick={() => showConfirmation(unitId)}
                            variant="contained"
                            className={classes.deleteButton}
                          >
                            {t`Reset filter`}
                          </Button>
                        )}
                      </div>
                    );
                  })
                ) : (
                    <Typography
                        className={classes.noUnitsStyle}
                      >{t`this site has no units`}</Typography>
                  )}
              </Collapse>
            </div>
          );
        })}
      </div>
      <ConfirmationDialog
        openDialog={openDialog}
        onCancel={() => setOpenDialog(false)}
        onClose={() => setOpenDialog(false)}
        onConfrim={() => clearUnitFilter(selectedUnitId, { filter: false })}
        title={t`Reset filter`}
        text={t`Are you sure you want to clear the filter of the unit?`}
        confrimLabel={"Yes"}
        cancelLabel={"No"}
        dialogType={"normal"}
      />
    </div>
  );
};

export default UnitsManagement;

import { createStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";

export const selectionMenuStyles = (theme: any) =>
  createStyles({
    container: {
      position: "absolute",
      display: "flex",
      flexDirection: "column",
      flexWrap: "nowrap",
      flex: 1,
      height: "100%"
    },
    tabContainer: {
      padding: "5px",
      display: "flex",
      alignItems: "flex-start",
      flex: 1,
      maxHeight: "85%",
      overflowY: "auto"
    },
    scrollIcon: {
      color: theme.palette.colors.white,
      "& svg": {
        fontSize: "25px"
      }
    },
    indicatorStyle: {
      backgroundColor: theme.palette.colors.white
    },
    appBarStyle: {
      backgroundColor: "transparent",
      boxShadow: "unset",
      padding: "0 5px"
    },
    unitsWrapper: {
      display: "flex",
      width: "100%",
      flexWrap: "wrap",
      justifyContent: "center"
    },
    stepperDot: {
      backgroundColor: theme.palette.colors.white,
      opacity: 0.4
    },
    stepperActiveDot: {
      backgroundColor: theme.palette.colors.white,
      opacity: 1
    },
    stepper: {
      background: "transparent"
    },
    navigationButton: {
      color: theme.palette.colors.white,
      "&:disabled": {
        color: theme.palette.colors.white,
        opacity: 0.4
      }
    },

    tabStyle: {
      "& .MuiTab-textColorInherit": {
        color: fade(theme.palette.colors.white, 0.6)
      },
      "& .MuiTab-textColorInherit.Mui-selected": {
        color: theme.palette.colors.white
      }
    },
    powerOffButton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "92px",
      height: "34px",
      borderRadius: "6px",
      boxShadow: "0 2px 3px 0 rgba(0, 0, 0, 0.1)",
      backgroundColor: theme.palette.colors.powerOffButton,
      "&:hover": {
        backgroundColor: fade(theme.palette.colors.powerOffButton, 0.7)
      },
      "&:focus": {
        backgroundColor: theme.palette.secondary.dark
      },
      "&:active": {
        backgroundColor: theme.palette.colors.powerOffButton
      }
    },
    powerOffButtonText: {
      height: "20px",
      fontSize: "14px",
      fontWeight: 500,
      color: theme.palette.colors.white
    },
    selectionMenuHeader: {
      marginTop: "10px",
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "4%",
      boxSizing: "border-box"
    },
    searchBox: {
      display: "flex",
      position: "relative",
      borderRadius: "4px",
      backgroundColor: fade(theme.palette.colors.white, 0.1),
      "&:hover": {
        backgroundColor: fade(theme.palette.colors.white, 0.25)
      },
      flex: 0.9,
      height: "34px",
      paddingLeft: "7px",
      boxSizing: "border-box"
    },

    inputRoot: {
      color: theme.palette.colors.white,
      fontFamily: "sans-serif",
      fontSize: "14px",
      width: "100%",
      display: "flex",
      flexDirection: "row"
    },
    closeIconStyle: {
      color: "white",
      padding: "7px",
      "& svg": {
        fontSize: "24px"
      }
    },
    spanishVersion: {
      fontSize: "12px",
      height: "unset",
      fontWeight: 500,
      color: theme.palette.colors.white
    },
    powerOffPT: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      width: "73%",
      color: theme.palette.colors.white
    }
  });

import { createMuiTheme } from "@material-ui/core";
import { MuiThemeProvider } from "@material-ui/core/styles";
import React, { Fragment, useEffect } from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { useStoreActions, useStoreState } from "../../Stores/typedHooks";

import {
  AboutUs,
  AddEditSchedule,
  AddEditSystem,
  ConnectSmartThermostat,
  CreateSite,
  CreateUserForm,
  EcobeeSmartThermostat,
  EditDetectedSystems,
  EditSubUser,
  GroupAddEdit,
  GroupManagement,
  HomeScreen,
  InviteAccept,
  LoginScreen,
  NestSmartThermostat,
  NewInvite,
  PasswordRecoveryScreen,
  ProfileScreen,
  RegisterNewDevice,
  ResetPassword,
  ScheduleList,
  Site,
  SiteInfoForm,
  SupportRegister,
  SystemDetection,
  TermsOfUseAgreement,
  UnitManagement,
  UnitsManagement,
  UsersManagementPage
} from "../";

import PrivateComponent from "../../components/PrivateComponent/PrivateComponent";
import SplashScreen from "../../components/SplashScreen/SplashScreen";
import { Loader, Message } from "../../widgets/";
import Impersonate from "../Impersonate/Impersonate";

const Routes = (props: any) => {
  const messageObj = useStoreState((state) => state.messageStore.message);
  const appConfig = useStoreState((state) => state.configStore.config);
  const themeFile = useStoreState((state) => state.configStore.theme);

  const splashScreenShow = useStoreState(
    (state) => state.configStore.splashScreenShow
  );
  const types = useStoreActions((actions) => actions.typesStore.types);

  const handleSplashShow = useStoreActions(
    (actions) => actions.configStore.handleSplashShow
  );
  const hideMessage = useStoreActions(
    (actions) => actions.messageStore.hideMessage
  );
  const startLoader = useStoreActions(
    (actions) => actions.loaderStore.startLoader
  );
  const finishLoader = useStoreActions(
    (actions) => actions.loaderStore.finishLoader
  );
  const prepareConfig = useStoreActions(
    (actions) => actions.configStore.prepareConfig
  );
  const prepareTheme = useStoreActions(
    (actions) => actions.configStore.prepareTheme
  );
  const checkLogged = useStoreActions(
    (actions) => actions.userStore.checkLoggedin
  );
  const getTypes = useStoreActions((actions) => actions.typesStore.getTypes);
  const isLoaderShowing = useStoreState(
    (state) => state.loaderStore.isLoaderShowing
  );

  useEffect(() => {
    if (!prepareTheme || !prepareConfig || !getTypes) {
      return;
    }
    prepareTheme();
    prepareConfig();
    getTypes();
  }, [prepareTheme, prepareConfig, getTypes]);

  useEffect(() => {
    if (!themeFile || !appConfig || types) {
      startLoader();
    } else {
      finishLoader();
      checkLogged();
    }
  }, [themeFile, appConfig, types, startLoader, finishLoader, checkLogged]);

  useEffect(() => {
    if (!appConfig || !handleSplashShow) {
      return;
    }

    const { splashScreenTimeout } = appConfig;
    setTimeout(() => {
      handleSplashShow();
    }, splashScreenTimeout);
  }, [appConfig, handleSplashShow]);

  if (!themeFile || !appConfig) {
    return <></>;
  }
  const theme = createMuiTheme(themeFile);

  const { spinnerName, spinnerColor, isSplashScreen } = appConfig;
  const isSplashShowing = isSplashScreen && splashScreenShow;

  return (
    <Fragment>
      <MuiThemeProvider theme={theme}>
        {isSplashShowing ? (
          <SplashScreen
            isSplashScreen={isSplashScreen}
            splashScreenShow={splashScreenShow}
          />
        ) : (
            <>
              <Loader
                showLoader={isLoaderShowing}
                spinnerName={spinnerName}
                spinnerColor={spinnerColor}
              />
              <Router>
                <Switch>
                  <Route exact path="/" component={LoginScreen} />

                  <PrivateComponent
                    exact={true}
                    path="/site"
                    component={HomeScreen}
                  />
                  <PrivateComponent
                    exact={true}
                    path="/site/:siteId/unit/:unitId"
                    component={HomeScreen}
                  />
                  <PrivateComponent
                    exact={true}
                    path="/site/:siteId"
                    component={SiteInfoForm}
                  />
                  <PrivateComponent
                    exact={true}
                    path="/site-management/:siteId?"
                    component={Site}
                  />
                  <PrivateComponent
                    exact={true}
                    path="/site-management/:deviceId/add-edit-system/:systemId?"
                    component={AddEditSystem}
                  />

                  <Route
                    exact={true}
                    path="/invite/:inviteToken"
                    component={InviteAccept}
                  />
                  <PrivateComponent
                    exact={true}
                    path="/profile"
                    component={ProfileScreen}
                  />

                  <PrivateComponent
                    exact={true}
                    path="/group-management"
                    component={GroupManagement}
                  />
                  <PrivateComponent
                    exact={true}
                    path="/unit/:unitId/schedules"
                    component={ScheduleList}
                  />
                  <PrivateComponent
                    exact={true}
                    path="/device/:deviceId/system-detection"
                    component={SystemDetection}
                  />
                  <Route
                    exact={true}
                    path="/device-registration/device/:deviceId/system-detection"
                    component={SystemDetection}
                  />
                  <Route
                    exact={true}
                    path="/device-registration/device/:deviceId/edit-detected-systems"
                    component={EditDetectedSystems}
                  />
                  <PrivateComponent
                    exact={true}
                    path="/device/:deviceId/edit-detected-systems"
                    component={EditDetectedSystems}
                  />
                  <PrivateComponent
                    exact={true}
                    path="/group/:groupId/schedules"
                    component={ScheduleList}
                  />
                  <PrivateComponent
                    exact={true}
                    path="/unit/:unitId/schedule/:scheduleId?"
                    component={AddEditSchedule}
                  />
                  <PrivateComponent
                    exact={true}
                    path="/group/:groupId/schedule/:scheduleId?"
                    component={AddEditSchedule}
                  />
                  <PrivateComponent
                    exact={true}
                    path="/group-management/group/:groupId?"
                    component={GroupAddEdit}
                  />
                  <Route
                    exact={true}
                    path="/support"
                    component={SupportRegister}
                  />
                  <Route
                    exact={true}
                    path="/support/:serial"
                    component={SupportRegister}
                  />
                  <Route
                    exact={true}
                    path="/support/:serial/:pin"
                    component={SupportRegister}
                  />

                  <Route
                    exact={true}
                    path="/support/:serial/:pin/:product"
                    component={SupportRegister}
                  />

                  <Route
                    exact={true}
                    path="/device-registration"
                    component={RegisterNewDevice}
                  />
                  <PrivateComponent
                    exact={true}
                    path="/device-registration/:siteId/user-selection/:customerId"
                    component={RegisterNewDevice}
                  />
                  <Route
                    exact={true}
                    path="/device-registration/user-registration"
                    component={CreateUserForm}
                  />
                  <Route
                    exact={true}
                    path="/device-registration/customer/:customerId/new-site"
                    component={CreateSite}
                  />
                  <PrivateComponent
                    exact={true}
                    path="/site-management/customer/:customerId/add-site"
                    component={CreateSite}
                  />

                  <PrivateComponent
                    exact={true}
                    path="/unit-management/:unitId"
                    component={UnitManagement}
                  />
                  <PrivateComponent
                    exact={true}
                    path="/users-management"
                    component={UsersManagementPage}
                  />
                  <PrivateComponent
                    exact={true}
                    path="/new-invite"
                    component={NewInvite}
                  />
                  <Route
                    exact={true}
                    path="/forgot-password"
                    component={PasswordRecoveryScreen}
                  />
                  <Route
                    exact={true}
                    path="/reset-password/:token?"
                    component={ResetPassword}
                  />
                  <Route
                    exact={true}
                    path="/impersonate/:token"
                    component={Impersonate}
                  />
                  <PrivateComponent
                    exact={true}
                    path="/users-management/customer/:customerId/user/:userId"
                    component={EditSubUser}
                  />
                  <PrivateComponent
                    exact={true}
                    path="/view-invite/:inviteToken"
                    component={EditSubUser}
                  />
                  <PrivateComponent
                    exact={true}
                    path="/unit-settings"
                    component={UnitsManagement}
                  />
                  <PrivateComponent
                    exact={true}
                    path="/terms-of-use-agreement"
                    component={TermsOfUseAgreement}
                  />
                  <PrivateComponent
                    exact={true}
                    path="/smart-thermostat/connect_nest"
                    component={ConnectSmartThermostat}
                  />
                  <PrivateComponent
                    exact={true}
                    path="/smart-thermostat/connect_ecobee"
                    component={ConnectSmartThermostat}
                  />
                  <PrivateComponent
                    exact={true}
                    path="/smart-thermostat/ecobee"
                    component={EcobeeSmartThermostat}
                  />
                  <PrivateComponent
                    exact={true}
                    path="/smart-thermostat/nest"
                    component={NestSmartThermostat}
                  />
                  <Route exact={true} path="/about-us" component={AboutUs} />
                  <Redirect to="/" />
                </Switch>
              </Router>
              {messageObj.message && (
                <Message message={messageObj} hideMessage={hideMessage} />
              )}
            </>
          )}
      </MuiThemeProvider>
    </Fragment>
  );
};

export default Routes;

import { fade } from "@material-ui/core";
import { createStyles } from "@material-ui/styles";

export default (theme: any) =>
  createStyles({
    screenContainer: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      backgroundColor: theme.palette.colors.blackFontShadow
    },
    container: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      maxWidth: "768px"
    },
    content: {
      display: "flex",
      width: "100%",
      flex: 1,
      overflowY: "auto"
    },
    formStyle: {
      padding: 0,
      margin: "11px 0px",
      display: "flex",
      flexFlow: "column",
      width: "100%"
    },
    fieldsContainer: {
      padding: "10px 5%",
      boxShadow: `0 1px 2px 0 ${theme.palette.colors.fieldsShadow}`,
      backgroundImage: theme.gradient.default
    },
    address: {
      color: "#fdfdfe",
      opacity: "0.5",
      marginRight: "3rem",
      fontSize: "14px"
    },
    inputFieldStyle: {
      marginBottom: "10px",
      "& fieldset": { borderColor: `rgba(253, 253, 254, 0.3)` },
      "& input:focus + fieldset": {
        borderColor: "rgba(253, 253, 254, 0.3) !important"
      }
    },
    errorStyle: {
      marginTop: "0px",
      fontSize: "12px",
      color: theme.palette.colors.error
    },
    inputClass: {
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: theme.palette.colors.lightWhite
    },
    fieldContainer: {
      margin: "15px 0",
      "& *": {
        borderRadius: "6px !important",
        borderColor: `${theme.palette.colors.fieldsBorder} !important`
      }
    },
    labelClass: {
      color: `${theme.palette.colors.fontGreyColor} !important`,
      paddingLeft: "10px",
      fontSize: "16px"
    },
    rowContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      marginBottom: "15px"
    },
    selectLable: {
      color: theme.palette.colors.white,
      marginBottom: "-8px",
      marginLeft: "20px",
      backgroundImage: theme.gradient.default,
      zIndex: 5,
      padding: "0 5px",
      fontSize: "16px"
    },
    systemUnitsSection: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      margin: "20px 0",
      flex: "0 1 auto",
      boxSizing: "border-box"
    },
    saveButtonStyle: {
      width: "90%",
      alignSelf: "center",
      margin: "10px 0px"
    },
    hide: {
      display: "none"
    },
    unitsHeader: {
      padding: "15px",
      backgroundImage: theme.gradient.default,
      color: theme.palette.colors.white
    },
    unitsContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      width: "100%"
    },
    unitContainer: {
      backgroundImage: theme.gradient.default,
      marginBottom: "10px",
      display: "flex",
      flexDirection: "column",
      padding: "15px",

      margin: "20px 0",
      "& *": {
        color: theme.palette.colors.white
      }
    },
    unitNameTypeWrapper: {
      display: "flex",
      justifyContent: "space-between",
      height: "50px",
      marginBottom: "15px"
    },
    fieldsetStyle: { border: "none" },
    unitNameWrapper: { height: "50px" },
    unitNameField: {
      border: `1px solid ${fade(theme.palette.colors.white, 0.3)}`,
      borderRadius: "4px",
      "& div": {
        paddingLeft: "3px",
        paddingRight: "0px"
      }
    },
    systemSelectContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      marginBottom: "15px"
    },
    unitTypeStyle: {
      fontSize: "14px",
      backgroundColor: fade(theme.palette.colors.white, 0.2),
      borderRadius: "4px",
      padding: "10px",
      display: "flex",
      alignItems: "center",
      marginLeft: "10px"
    },
    systemLabelClass: {
      color: `${theme.palette.colors.fontGreyColor} !important`,
      padding: "0 10px",
      fontSize: "18px"
    },
    iconPadding: { padding: "10px", marginRight: "-10px" },
    deleteIconStyle: {
      alignSelf: "flex-end",
      padding: "10px",
      marginRight: "-10px"
    },
    textFieldsStyle: { flexGrow: 1 },
    textFieldInputStyle: {
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: theme.palette.colors.lightWhite,
      "&:disabled": {
        opacity: 0.5
      }
    },
    rowStyle: {
      height: "43px",
      display: "inline-flex",
      alignItems: "center",
      width: "100%"
    },
    labelStyle: {
      width: "90px",
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: theme.palette.colors.lightWhite
    },
    dividerStyle: {
      width: "100%",
      height: "2px",
      backgroundColor: fade(theme.palette.colors.lightWhite, 0.1)
    },
    unitContainerStyle: {
      boxShadow: `0 1px 2px 0 ${fade(
        theme.palette.colors.blackFontShadow,
        0.09
      )}`,
      backgroundImage: theme.gradient.default,
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "center",
      padding: "0px 20px",
      margin: "20px 0"
    },
    highlighted: {
      boxShadow: `0 0 7px ${theme.palette.secondary.main}`
    },
    opacityStyle: { opacity: 0.5 },
    iconColor: { color: theme.palette.colors.lightWhite, fontSize: "22px" },
    selectContainer: { marginTop: 0 },
    disabled: {
      "&>div>div>div": {
        "& div:last-of-type": {
          opacity: "60%"
        }
      }
    }
  });

export const dropDownStyle = {
  indicatorsContainer: (styles: any) => {
    return {
      ...styles,
      "& div": {
        color: "rgba(253, 253, 254, 0.6)"
      },
      "& span": {
        display: "none"
      }
    };
  },
  control: (styles: any, isDisabled: boolean) => ({
    ...styles,
    borderRadius: "6px",
    border: "none",
    backgroundColor: "transparent",
    maxHeight: "40px",
    "& div": { padding: 0 }
  }),
  option: (styles: any) => {
    return {
      ...styles,
      color: "#000",
      cursor: "default",
      fontSize: "12px",
      fontWeight: "bold"
    };
  },
  input: (styles: any) => ({
    ...styles,
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#fdfdfe",
    padding: 0,
    paddingLeft: "15px",
    "&:disabled": { backgroundColor: "transparent" }
  }),
  placeholder: (styles: any) => ({ ...styles, marginLeft: 0 }),
  singleValue: (styles: any) => ({
    ...styles,
    fontFamily: "Roboto",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#fdfdfe",
    padding: 0,
    paddingLeft: "15px",
    opacity: 0.5
  })
};

import { InputAdornment, makeStyles, Typography } from "@material-ui/core";
import { HelpOutlineOutlined } from "@material-ui/icons";
import clsx from "clsx";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import QrReader from "react-qr-reader";
import { t } from "ttag";
import * as Yup from "yup";
import { Toolbar } from "../../components";
import deviceImg from "../../images/coolmaster.png";
import QRImg from "../../images/QR.png";
import { useStoreActions, useStoreState } from "../../Stores/typedHooks";
import { AppLogo, AppTitle, ArrowBack } from "../../svgComponents";
import { Button, FormikField, Select } from "../../widgets";
import { registerNewDeviceStyle } from "./registerNewDevice.style";

const RegisterNewDevice: React.FC<any> = (props) => {
  const [selectedSite, setSelectedSite] = useState<any>({
    label: t`Select Site`,
    value: ""
  });
  const [siteOptions, setSiteOptions] = useState<any>({});
  const [isCameraOpen, setIsCameraOpen] = useState<boolean>(false);
  const [serial, setSerial] = useState<string>("");
  const [pin, setPin] = useState<string>("");
  const user = useStoreState((states) => states.userStore.user);
  const startLoader = useStoreActions(
    (actions) => actions.loaderStore.startLoader
  );
  const finishLoader = useStoreActions(
    (actions) => actions.loaderStore.finishLoader
  );
  const addMessage = useStoreActions(
    (actions) => actions.messageStore.addMessage
  );
  const getDeviceToken = useStoreActions(
    (actions) => actions.deviceStore.getDeviceToken
  );
  const getCustomerSites = useStoreActions(
    (actions) => actions.customerStore.getCustomerSites
  );
  const addNewDevice = useStoreActions(
    (actions) => actions.deviceStore.addNewDevice
  );

  const {
    match: {
      params: { siteId, customerId }
    },
    history,
    history: { location: { search = "" } = {} } = {}
  } = props;

  const { isLoggedIn: userIsLoggedIn, customer } = user;

  const isLoggedIn = user && userIsLoggedIn;
  const isSiteSelected = siteId && siteId !== "sites";

  const useStyles = makeStyles(registerNewDeviceStyle);
  const classes = useStyles();

  useEffect(() => {
    if (!search) {
      return;
    }
    const searchParams = new URLSearchParams(search);
    setSerial(searchParams.get("serial") || "");
    setPin(searchParams.get("pin") || "");
  }, [search]);

  useEffect(() => {
    if (!isLoggedIn || !customer) {
      return;
    }
    history.push(
      `/device-registration/${siteId ||
      "sites"}/user-selection/${customer}${search}`
    );
  }, [isLoggedIn, customer, history, siteId, search]);

  useEffect(() => {
    if (!customerId || !isLoggedIn) {
      return;
    }
    startLoader();
    getCustomerSites(customerId)
      .then((sites: any) => {
        const sitesList = Object.values(sites).map((site: any) => ({
          label: site.name,
          value: site.id
        }));
        setSiteOptions(sitesList);
        setSelectedSite(isSiteSelected ? siteId : "");
      })
      .catch((error: any) => {
        addMessage({ message: error.message });
      })
      .finally(() => {
        finishLoader();
      });
  }, [
    addMessage,
    customerId,
    finishLoader,
    getCustomerSites,
    isLoggedIn,
    isSiteSelected,
    siteId,
    startLoader
  ]);

  const selectNewSite = (option: any) => {
    setSelectedSite(option);
  };
  const validationSchema = Yup.object({
    serial: Yup.string().required(t`required field`),
    pin: Yup.string().required(t`required field`)
  });

  const addDevice = (values: any) => {
    startLoader();
    const { serial, pin } = values;
    addNewDevice({ siteId: selectedSite.value, name: serial, serial, pin })
      .then((createdDevice: any) => {
        const deviceId = createdDevice.id;
        history.push(`/device/${deviceId}/system-detection`);
      })
      .catch((err: any) => {
        addMessage({
          message: err.message
        });
      })
      .finally(() => {
        finishLoader();
      });
  };
  const handleSubmit = (values: any) => {
    const { pin, serial } = values;

    if (customerId) {
      addDevice(values);
      return;
    }

    startLoader();

    getDeviceToken({ serial, pin })
      .then((res: any) => {
        const { token } = res;
        localStorage.setItem("regToken", token);
        localStorage.setItem("deviceSerial", serial);
        localStorage.setItem("devicePin", pin);
        history.push("/device-registration/user-registration");
      })
      .catch((error: any) => {
        addMessage({ message: error.message });
      })
      .finally(() => {
        finishLoader();
      });
  };

  const goBack = () => {
    history.push("/");
  };

  const goBackToSites = () => {
    history.push("/site-management");
  };

  const toggleCamera = () => {
    setIsCameraOpen(!isCameraOpen);
  };

  const onScanningQR = (qrCode: string | null) => {
    if (qrCode) {
      const qrCodeParts = qrCode.split("/");
      const serial = qrCodeParts[4];
      const pin = qrCodeParts[5];
      toggleCamera();
      history.push(`/support?serial=${serial}&pin=${pin}`);
    }
  };

  const endAdornment = (
    <InputAdornment position="end">
      <HelpOutlineOutlined className={classes.hintIcon} />
    </InputAdornment>
  );
  const backToLogin = () => {
    history.push("/");
  };

  return (
    <div className={classes.screenContainer}>
      {customerId && (
        <Toolbar
          title={t`Connect Device`}
          leftIconComponent={<ArrowBack />}
          leftAction={goBackToSites}
        />
      )}
      <div className={classes.container}>
        {!customerId && (
          <div className={classes.logoSection} onClick={goBack}>
            <AppLogo className={classes.logoStyle} />
            <AppTitle className={classes.logoTitleStyle} />
          </div>
        )}
        <div className={classes.pageContent}>
          <div
            className={clsx(classes.header, {
              [classes.addMargin]: customerId
            })}
          >
            <Typography
              className={clsx(classes.pageTitle, {
                [classes.widthStyle]: customerId
              })}
            >{t`Lets connect your HVAC Device`}</Typography>
            <div className={classes.deviceImageContainer}>
              <div className={classes.deviceImageShadow} />
              <img
                src={deviceImg}
                alt={"HVAC"}
                className={classes.deviceImgStyle}
              />
            </div>
          </div>

          <div className={classes.formsWrapper}>
            <div className={classes.manualFormWrapper}>
              <Formik
                initialValues={{ serial, pin }}
                onSubmit={(values) => handleSubmit(values)}
                enableReinitialize={true}
                validationSchema={validationSchema}
                render={({ values, setFieldValue, ...formikProps }) => {
                  return (
                    <Form>
                      <div
                        className={customerId ? classes.addDeviceContainer : ""}
                      >
                        {customerId && (
                          <Typography className={classes.textStyle}>
                            {t`Add Device`}
                          </Typography>
                        )}
                        <FormikField
                          name="serial"
                          variant={customerId ? "outlined" : "filled"}
                          placeholder={t`Device SN MAC`}
                          formikProps={formikProps}
                          iconComponent={endAdornment}
                          disableUnderline
                          className={
                            customerId
                              ? classes.wrapperStyle2
                              : classes.wrapperStyle
                          }
                        />
                        <FormikField
                          name="pin"
                          variant={customerId ? "outlined" : "filled"}
                          placeholder={t`Device PIN`}
                          formikProps={formikProps}
                          iconComponent={endAdornment}
                          disableUnderline
                          className={classes.wrapperStyle}
                        />
                        {isCameraOpen && (
                          <QrReader
                            delay={1000}
                            onError={() => { }}
                            onScan={onScanningQR}
                            className={classes.qrReaderStyle}
                          />
                        )}
                        <Typography
                          className={classes.qrTypoStyle}
                        >{t`OR`}</Typography>
                        <div className={classes.QRWrapper}>
                          <div
                            className={clsx(classes.QRForm, {
                              [classes.removeMargin]: customerId
                            })}
                            onClick={toggleCamera}
                          >
                            <div className={classes.qrDetailsContainer}>
                              <img
                                className={classes.QRImgStyle}
                                src={QRImg}
                                alt={"QR code"}
                              />
                              <Typography
                                className={classes.qrTypoStyle}
                              >{t`Scan QR code`}</Typography>
                            </div>
                            <HelpOutlineOutlined className={classes.hintIcon} />
                          </div>
                        </div>
                      </div>

                      {customerId && (
                        <Select
                          value={selectedSite || ""}
                          variant="filled"
                          suggestions={siteOptions}
                          handleSelectChange={selectNewSite}
                          clear={true}
                          placeholder={t`Select Site`}
                          className={classes.selectWrapperStyle}
                        />
                      )}
                      <Button
                        type="submit"
                        variant="contained"
                        className={classes.buttonStyle}
                      >
                        {isLoggedIn ? t`Next` : t`CONTINUE`}
                      </Button>
                    </Form>
                  );
                }}
              />
            </div>
            <Button
              variant="outlined"
              onClick={backToLogin}
              className={classes.backBtn}
            >{t`Back to login`}</Button>
          </div>

        </div>
      </div>
    </div>
  );
};

export default RegisterNewDevice;

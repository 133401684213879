import React from "react";

const SvgPower = props => (
  <svg width={18} height={19} {...props}>
    <g fill="#FDFDFE">
      <path d="M12.916 2.94a.715.715 0 0 1 1.005-.162 8.522 8.522 0 0 1 3.497 6.886c0 4.716-3.817 8.537-8.52 8.537-4.704 0-8.52-3.82-8.52-8.537a8.523 8.523 0 0 1 3.496-6.885.715.715 0 0 1 1.006.161.734.734 0 0 1-.16 1.018 7.06 7.06 0 0 0-2.902 5.706 7.08 7.08 0 0 0 14.16 0 7.06 7.06 0 0 0-2.902-5.706.734.734 0 0 1-.16-1.017z" />
      <path d="M8.722.37c.368 0 .67.277.715.637l.005.091v7.765a.724.724 0 0 1-.72.728.723.723 0 0 1-.714-.637l-.006-.091V1.098c0-.402.323-.729.72-.729z" />
    </g>
  </svg>
);

export default SvgPower;

import { createStyles, fade } from "@material-ui/core";

export default (theme: any) =>
  createStyles({
    selectContainer: {
      flexGrow: 1,
      marginTop: "10px",
    },
    helperStyle: {
      marginTop: "3px",
      marginLeft: "15px",
      fontSize: "12px",
      lineHeight: "normal",
      color: fade(theme.palette.colors.lightWhite, 0.5),
    },
    errorStyle: {
      marginTop: "3px",
      marginLeft: "15px",
      fontSize: "12px",
      lineHeight: "normal",
      color: theme.palette.secondary.main,
    },
  });

export const inlineStyle = {
  control: (styles: any, state: any) => ({
    ...styles,
    borderRadius: "6px",
    backgroundColor: "transparent",
    height: "50px",
    border: 0,
    boxShadow: 0,
    "&:hover": {
      border: 0,
    },
    "& div:first-of-type": {
      paddingLeft: "0px",
    },
  }),
};
export const smallFilledStyle = {
  control: (styles: any, state: any) => ({
    ...styles,
    borderRadius: "6px",
    backgroundColor: "rgba(255,255,255,0.1)",
    minHeight: "34px",
    border: 0,
    boxShadow: 0,
    "&:hover": {
      border: 0,
    },
    "& div:first-of-type": {
      paddingLeft: "15px",
    },
  }),
};

export const filledStyle = {
  control: (styles: any, state: any) => ({
    ...styles,
    borderRadius: "6px",
    backgroundColor: "rgba(255,255,255,0.1)",
    height: "50px",
    border: 0,
    boxShadow: 0,
    "&:hover": {
      border: 0,
    },
    "& div:first-of-type": {
      paddingLeft: "15px",
    },
  }),
};

export const selectStyles = (height: string) => {
  return {
    container: (styles: any) => ({ ...styles, margin: 0 }),
    menu: (styles: any) => ({
      ...styles,
      zIndex: 10,
    }),
    indicatorSeparator: () => ({ display: "none" }),
    clearIndicator: () => ({ display: "none" }),
    indicatorsContainer: (styles: any, state: any) => {
      return {
        ...styles,
        display: state.isDisabled ? "none" : "flex",
        "& div": {
          color: "rgba(253, 253, 254, 0.6)",
        },
        "& span": {
          background: "rgba(253, 253, 254, 0.6)",
        },
      };
    },
    control: (styles: any, state: any) => ({
      ...styles,
      borderRadius: "6px",
      backgroundColor: "transparent",
      height: height,
      border: "solid 1px rgba(253, 253, 254, 0.3)",
      boxShadow: 0,
      "&:hover": {
        border: "solid 1px rgba(253, 253, 254, 0.3)",
      },
      "& div:first-of-type": {
        overflow: "unset",
        paddingLeft: "15px",
      },
    }),
    option: (styles: any, state: any) => {
      return {
        ...styles,
        color: "#000",
        cursor: "default",
        fontSize: "12px",
        fontWeight: "bold",
      };
    },
    input: (styles: any) => ({
      ...styles,
      fontSize: 16,
      color: "#fdfdfe",
      padding: 0,
      "&:disabled": { backgroundColor: "transparent" },
      "& div:first-of-type": {
        paddingLeft: "0 !important",
      },
      paddingLeft: "0 !important",
    }),
    placeholder: (styles: any, state: any) => {
      if (state.hasValue || state.selectProps.inputValue) {
        return {
          ...styles,
          top: "-11px",
          fontSize: 12,
          background: "#2c1430",
          paddingLeft: "5px !important",
          paddingRight: "5px",
          marginLeft: "-5px",
          color: "#fff",
          transition: "top 0.1s, font-size 0.1s",
        };
      }
      return {
        ...styles,
        position: "absolute",
        top: "50%",
        transition: "top 0.1s, font-size 0.1s",
        paddingLeft: "0px !important",
        marginLeft: 0,
        opacity: 0.5,
        fontSize: 16,
        color: "#fdfdfe",
      };
    },
    singleValue: (styles: any) => ({
      ...styles,
      fontSize: 16,
      color: "#fdfdfe",
      padding: 1,
      paddingLeft: "0 !important",
      margin: 0,
    }),
  };
};

import { Grid, makeStyles } from "@material-ui/core";
import { Form, Formik } from "formik";
import _ from "lodash";
import { tz } from "moment-timezone";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import * as Yup from "yup";
import { Toolbar } from "../../components";
import { useStoreActions, useStoreState } from "../../Stores/typedHooks";
import { ArrowBack } from "../../svgComponents";
import { Button, FormikField, Select } from "../../widgets";
import { countries } from "./countryList";
import styles from "./siteInfoForm.style";
import { stateList } from "./stateList";

interface SiteProps {
  history: any;
  match: any;
}

const timeZones = tz.names().map((timezone) => ({
  value: timezone,
  label: `${timezone}  ${tz(timezone).format("Z")}`
}));

const Site: React.FC<SiteProps> = ({ history, match }) => {
  const [timezoneOption, setTimezoneOption] = useState<any>("");
  const [stateOption, setStateOption] = useState<any>("");
  const [site, setSite] = useState<any>({});
  const [countryOption, setCountryOption] = useState<any>("");

  const startLoader = useStoreActions(
    (actions) => actions.loaderStore.startLoader
  );
  const finishLoader = useStoreActions(
    (actions) => actions.loaderStore.finishLoader
  );
  const updateSite = useStoreActions((actions) => actions.siteStore.updateSite);
  const addMessage = useStoreActions(
    (actions) => actions.messageStore.addMessage
  );
  const updateSiteLocally = useStoreActions(
    (actions) => actions.siteStore.setSelectedSite
  );
  const setSelectedSite = useStoreActions(
    (state) => state.siteStore.setSelectedSite
  );
  const selectedSite = useStoreState((state) => state.siteStore.selectedSite);
  const userSites = useStoreState((state) => state.siteStore.userSites);
  const getSite = useStoreActions((actions) => actions.siteStore.getSite);
  const setUserSites = useStoreActions(
    (actions) => actions.siteStore.setUserSites
  );

  const validationSchema = Yup.object({
    name: Yup.string().required(t`required field`),
    country: Yup.string().required(t`required field`),
    city: Yup.string().required(t`required field`),
    address: Yup.string().required(t`required field`),
    state: Yup.string().when("country", {
      is: (value) => value && value === "US",
      then: Yup.string().required(
        "Required"
      ),
      otherwise: Yup.string()
    })
  });

  const { params } = match;
  const { siteId } = params;
  const { name, description, country, city, address, postalCode, state } = site;

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const fillStateTimezone = (siteObject: any) => {
    const { timezone = "", state = "", country = "" } = siteObject;
    const timezoneLabel = `${timezone}  ${tz(timezone).format("Z")}`;
    let stateLabel = "";

    stateList.forEach((stateOption: any) => {
      if (stateOption.value === state) {
        stateLabel = stateOption.label;
      }
    });

    let countryObj = countries.find((countryObj: any) => country === countryObj.value);

    if (country && countryObj) {
      setCountryOption({ value: country, label: countryObj.label });
    }
    if (state) {
      setStateOption({ value: state, label: stateLabel });
    }

    setTimezoneOption({ value: timezone, label: timezoneLabel });
  };

  useEffect(() => {
    if (siteId === "") {
      history.push("/site");
      return;
    }

    if (!_.isEmpty(selectedSite) && siteId === selectedSite.id) {
      fillStateTimezone(selectedSite);
      setSite(selectedSite);
    } else {
      startLoader();
      getSite(siteId)
        .then((response: any) => {
          fillStateTimezone(response);
          setSite(response);
        })
        .catch((err: any) => {
          addMessage({
            message: err.message
          });
          history.push("/");
        })
        .finally(finishLoader());
    }
  }, [
    addMessage,
    finishLoader,
    getSite,
    history,
    selectedSite,
    siteId,
    startLoader
  ]);

  const goBack = () => {
    history.push("/site-management");
  };

  const handleTimezoneSelectChange = (value: any) => {
    setTimezoneOption(value);
  };

  const handleStateSelectChange = (value: any) => {

    setStateOption(value);
  };
  const handleCountrySelectChange = (value: any) => {

    setCountryOption(value);
  };

  const handleSubmit = (values: any) => {
    if (!timezoneOption) {
      return;
    }

    const siteInfo = {
      id: siteId,
      ...values,
      state: countryOption.value === "US" && stateOption ? stateOption.value : "",
      timezone: timezoneOption.value,
      postalCode: values.zip,
      country: (countryOption && countryOption.value) || ""
    };

    startLoader();

    updateSite(siteInfo)
      .then(() => {
        if (siteId === selectedSite.id) {
          setSelectedSite(siteInfo);
        }

        userSites[siteId] = { ...userSites[siteId], ...siteInfo };
        setUserSites(userSites);
        updateSiteLocally(siteInfo);
      })
      .catch((err: any) =>
        addMessage({
          message: err.message
        })
      )
      .finally(() => finishLoader());
  };

  return (
    <div className={classes.screenContainer}>
      <Grid container className={classes.gridStyle}>
        <Toolbar
          title={t`Site Information`}
          leftIconComponent={<ArrowBack />}
          leftAction={goBack}
        />
        <div className={classes.formContainer}>
          <Formik
            initialValues={{
              name: name,
              description: description,
              city: city,
              country: country,
              address: address,
              zip: postalCode,
              state: state
            }}
            onSubmit={(values) => handleSubmit(values)}
            enableReinitialize={true}
            validationSchema={validationSchema}
            render={({ values, setFieldValue, touched, errors, ...formikProps }) => {
              return (
                <Form className={classes.formStyle}>
                  <div className={classes.fieldsContainer}>
                    <FormikField
                      name="name"
                      label={t`Site Name`}
                      formikProps={formikProps}
                      className={classes.inputFieldStyle}
                    />
                    <FormikField
                      name="description"
                      label={t`Description`}
                      formikProps={formikProps}
                      className={classes.inputFieldStyle}
                    />
                    <FormikField
                      name="address"
                      label={t`Street Address`}
                      formikProps={formikProps}
                      className={classes.inputFieldStyle}
                    />

                    <Select
                      clear={true}
                      label={t`Country`}
                      value={countryOption || ""}
                      suggestions={countries}
                      handleSelectChange={(value: any) => {
                        setFieldValue("country", value.value);
                        handleCountrySelectChange(value);
                      }
                      }
                      error={errors.country ? errors.country as string : ""}
                      className={errors.country && touched.country ? classes.selectErrorStyle : classes.selectStyle}
                    />
                    <FormikField
                      name="city"
                      label={t`City`}
                      formikProps={formikProps}
                      className={classes.inputFieldStyle}
                    />
                    {
                      countryOption.value === "US" &&
                      <Select
                        clear={true}
                        label={t`State`}
                        value={stateOption || ""}
                        suggestions={stateList}
                        handleSelectChange={(value: any) => {
                          setFieldValue("state", value.value);
                          handleStateSelectChange(value);
                        }}
                        error={errors.state && touched.state ? errors.state as string : ""}
                        className={errors.state && touched.state ? classes.selectErrorStyle : classes.selectStyle}
                      />

                    }

                    <FormikField
                      name="zip"
                      label={t`Zip`}
                      formikProps={formikProps}
                      className={classes.inputFieldStyle}
                    />

                    <Select
                      label={t`Timezone`}
                      clear={true}
                      value={timezoneOption}
                      suggestions={timeZones}
                      handleSelectChange={handleTimezoneSelectChange}
                      error={!timezoneOption ? "required" : ""}
                      className={classes.selectStyle}
                    />
                  </div>
                  <Button
                    type="submit"
                    variant="contained"
                    className={classes.saveButtonStyle}
                  >
                    {t`Save`}
                  </Button>
                </Form>
              );
            }}
          />
        </div>
      </Grid>
    </div>
  );
};

export default Site;

import { Thunk, thunk } from "easy-peasy";
import { Injections } from "./";

export interface ISystemModel {
  getSystems: Thunk<ISystemModel, void, Injections>;
  getDeviceSystems: Thunk<ISystemModel, string, Injections>;
  getSystem: Thunk<ISystemModel, string, Injections>;
  changeSystemPowerState: Thunk<ISystemModel, ISystemPayload, Injections>;
  updateSystemOperationMode: Thunk<ISystemModel, ISystemPayload, Injections>;
  createSystem: Thunk<ISystemModel, ICreateSystem, Injections>;
  deleteSystem: Thunk<ISystemModel, string, Injections>;
  updateSystem: Thunk<ISystemModel, IUpdateSystem, Injections>;
  removeUnitFromSystem: Thunk<ISystemModel, IUpdateSystemUnits, Injections>;
  addUnitToSystem: Thunk<ISystemModel, IUpdateSystemUnits, Injections>;
}

interface ISystemPayload {
  systemId: string;
  state: number;
}

interface ICreateSystem {
  deviceId: string;
  data: any;
}

interface IUpdateSystem {
  systemId: string;
  data: object;
}
interface IUpdateSystemUnits {
  systemId: string;
  unitId: string;
}

const systemStore: ISystemModel = {
  getSystems: thunk((actions, payload, { injections }) => {
    const { sdkSystem } = injections;
    return sdkSystem.getSystems();
  }),
  getDeviceSystems: thunk((actions, payload, { injections }) => {
    const { sdkDevice } = injections;
    return sdkDevice.getSystems(payload);
  }),
  getSystem: thunk((actions, payload, { injections }) => {
    const { sdkSystem } = injections;
    return sdkSystem.getSystemById(payload);
  }),
  changeSystemPowerState: thunk((actions, payload, { injections }) => {
    const { systemId, state } = payload;
    const { sdkSystem } = injections;
    return sdkSystem.changeSystemPower(systemId, state);
  }),
  updateSystemOperationMode: thunk((actions, payload, { injections }) => {
    const { systemId, state } = payload;
    const { sdkSystem } = injections;
    return sdkSystem.changeSystemOperationMode(systemId, state);
  }),
  createSystem: thunk((actions, payload, { injections }) => {
    const { deviceId, data } = payload;
    const { sdkDevice } = injections;

    return sdkDevice.createSystem(deviceId, data);
  }),
  deleteSystem: thunk(async (actions, payload, { injections }) => {
    const { sdkSystem } = injections;
    return sdkSystem.delete(payload);
  }),
  updateSystem: thunk(async (actions, payload, { injections }) => {
    const { sdkSystem } = injections;
    const { systemId, data } = payload;
    return sdkSystem.update(systemId, data);
  }),
  removeUnitFromSystem: thunk(async (actions, payload, { injections }) => {
    const { sdkSystem } = injections;
    const { systemId, unitId } = payload;
    return sdkSystem.removeUnit(systemId, unitId);
  }),
  addUnitToSystem: thunk(async (actions, payload, { injections }) => {
    const { sdkSystem } = injections;
    const { systemId, unitId } = payload;
    return sdkSystem.addUnit(systemId, unitId);
  })
};

export default systemStore;

import { thunk, Thunk } from "easy-peasy";
import { ICreateNewInvitePayload } from "../screens/NewInvite/NewInvite";

interface ICreateGroup {
  id: string;
  data: any;
}

export interface ICustomerModel {
  getCustomer: Thunk<ICustomerModel, any, any>;
  getCustomerUsers: Thunk<ICustomerModel, any, any>;
  getCustomerSites: Thunk<ICustomerModel, any, any>;
  createInvite: Thunk<ICustomerModel, ICreateNewInvitePayload, any>;
  getCustomerInvites: Thunk<ICustomerModel, any, any>;
  getCustomerGroups: Thunk<ICustomerModel, any, any>;
  createGroup: Thunk<ICustomerModel, ICreateGroup, any>;
}

const customerStore: ICustomerModel = {
  getCustomer: thunk(async (actions, payload, { injections }) => {
    const { sdkCustomer } = injections;
    return sdkCustomer.getCustomerById(payload);
  }),
  getCustomerUsers: thunk(async (actions, payload, { injections }) => {
    const { sdkCustomer } = injections;
    return sdkCustomer.getUsers(payload);
  }),
  getCustomerSites: thunk(async (actions, payload, { injections }) => {
    const { sdkCustomer } = injections;
    return sdkCustomer.getSites(payload);
  }),
  createInvite: thunk(async (actions, payload, { injections }) => {
    const { sdkCustomer } = injections;
    const { customerId, inviteObj } = payload;
    return sdkCustomer.createInvite(customerId, inviteObj);
  }),
  getCustomerInvites: thunk(async (actions, payload, { injections }) => {
    const { sdkCustomer } = injections;
    return sdkCustomer.getInvites(payload);
  }),
  getCustomerGroups: thunk(async (actions, payload, { injections }) => {
    const { sdkCustomer } = injections;
    return await sdkCustomer.getGroups(payload);
  }),
  createGroup: thunk(async (actions, payload, { injections }) => {
    const { sdkCustomer } = injections;
    const { id, data } = payload;
    return await sdkCustomer.createGroup(id, data);
  })
};
export default customerStore;

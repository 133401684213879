import {
  FormControlLabel,
  Grid,
  RadioGroup,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import { t } from "ttag";
import {
  validateEmail,
  validateUsername
} from "../../services/customValidators";
import { useStoreActions } from "../../Stores/typedHooks";
import { AppLogo, AppTitle } from "../../svgComponents";
import { Button, RadioButton, TextField } from "../../widgets";
import passwordRecoveryScreenStyle from "./passwordRecoveryScreen.style";

interface IPasswordRecoveryProps {
  history: any;
}

const PasswordRecoveryScreen: React.FC<IPasswordRecoveryProps> = ({
  history
}) => {
  const [resetPasswordBy, chooseResetPasswordBy] = useState<string>("username");
  const [username, setUsername] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  const [emailError, setEmailError] = useState<string>("");
  const [usernameError, setUsernameError] = useState<string>("");

  const startLoader = useStoreActions(
    (actions) => actions.loaderStore.startLoader
  );
  const finishLoader = useStoreActions(
    (actions) => actions.loaderStore.finishLoader
  );
  const addMessage = useStoreActions(
    (actions) => actions.messageStore.addMessage
  );

  const requestPasswordRecovery = useStoreActions(
    (actions) => actions.userStore.getPasswordRecoveryToken
  );

  const useStyles = makeStyles(passwordRecoveryScreenStyle);
  const classes = useStyles();

  const handleResetPasswordByChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setUsername("");
    setEmail("");
    chooseResetPasswordBy((event.target as HTMLInputElement).value);
  };
  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    const usernameError = validateUsername(value as string);
    setUsernameError(usernameError);
    setUsername(value);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    const emailError = validateEmail(value as string);
    setEmailError(emailError);

    setEmail(value);
  };

  const handleRequestResetPassword = () => {
    if (resetPasswordBy === "username" && !username) {
      setUsernameError("This field is required");
      return;
    }
    if (resetPasswordBy === "email" && !email) {
      setEmailError("This field is required");
      return;
    }
    const data = resetPasswordBy === "username" ? username : email;

    startLoader();
    requestPasswordRecovery({ data, type: resetPasswordBy })
      .then((data: any) => {
        if (data) {
          addMessage({
            message: `Please check your email for the password recovery link`
          });
          backToLogin();
        } else{
          resetPasswordBy === "username" ? addMessage({ message: "User not found"}) : addMessage({ message: "Email not found"});
        }
      })
      .catch((error: any) => {
        addMessage({ message: error.message });
      })
      .finally(() => finishLoader());
  };

  const backToLogin = () => {
    history.push("/");
  };

  return (
    <div className={classes.screenContainer}>
      <div className={classes.passwordRecoveryScreenContent}>
        <div className={classes.logoSection}>
          <AppLogo className={classes.logoStyle} />
          <AppTitle className={classes.logoTitleStyle} />
        </div>
        <Grid classes={{ root: classes.requestResetPasswordContainer }}>
          <Typography>{t`Password Recovery`}</Typography>
          <Typography
            className={classes.recoverPasswordMessegeStyle}
          >{t`No worries, we'll help you get back to work.
          Verify your username and account email address to continue.`}</Typography>

          <RadioGroup
            aria-label="position"
            name="position"
            value={resetPasswordBy}
            onChange={handleResetPasswordByChange}
            classes={{ root: classes.radioGroupContainer }}
          >
            <Grid container>
              <FormControlLabel
                value="username"
                control={<RadioButton />}
                label={
                  <TextField
                  variant="filled"
                  disableUnderline
                  placeholder="Your username"
                  value={username}
                  onChange={handleUsernameChange}
                  className={classes.inputFeildStyle}
                  error={usernameError}
                  />
                }
                labelPlacement="end"
                onClick={() => {chooseResetPasswordBy("username"); setEmail(""); setEmailError(""); }}
                className={classes.fullWidthField}
              />
            </Grid>
            <Grid container>
              <FormControlLabel
                value="email"
                control={<RadioButton />}
                label={
                  <TextField
                  variant="filled"
                  disableUnderline
                  placeholder="Your email"
                  value={email}
                  onChange={handleEmailChange}
                  className={classes.inputFeildStyle}
                  error={emailError}
              />
                }
                labelPlacement="end"
                onClick={() => {chooseResetPasswordBy("email"); setUsername(""); setUsernameError(""); }}
                className={classes.fullWidthField}
              />
            </Grid>
          </RadioGroup>

          <Grid container className={classes.buttonsWrapper}>
            <Button
              variant="outlined"
              onClick={backToLogin}
              className={classes.buttonStyle}
            >{t`Back to login`}</Button>

            <Button
              variant="contained"
              onClick={handleRequestResetPassword}
              className={classes.buttonStyle}
            >{t`Reset password`}</Button>
          </Grid>

          <Typography
            className={classes.supportInfoStyle}
          >{t`Forgot your username or email? please contact support@coolRemote.com`}</Typography>
        </Grid>
      </div>
    </div>
  );
};
export default PasswordRecoveryScreen;

import React from "react";
import { Radio, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import radioButtonStyle from "./radioButton.style";

interface IRadioButtonProps {
  className?: string;
  onChange?: () => void;
  disabled?: boolean;
  name?: string;
  checked?: boolean;
  value?: string;
}

const RadioButton: React.FC<IRadioButtonProps> = ({
  className,
  checked,
  value,
  name,
  onChange,
  disabled
}) => {

  const useStyles = makeStyles(radioButtonStyle);
  const classes = useStyles();

  return (
    <Radio
      classes={{ disabled: classes.disabledStyle }}
      checked={checked}
      onChange={onChange}
      value={value}
      name={name}
      disabled={disabled}
      className={clsx(classes.root, className)}
    />
  );
};

export default RadioButton;

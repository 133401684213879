import { createStyles } from "@material-ui/styles";
import { fade } from "@material-ui/core/styles";

export default (theme: any) =>
  createStyles({
    paper: {
      padding: "10px 0px",
      borderRadius: "31px",
      width: "62px",
      boxShadow: `0 2px 4px 0 ${fade(theme.palette.colors.black, 0.5)}`,
      backgroundColor: theme.palette.colors.lightWhite,
      display: "flex",
      flexFlow: "column",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: "10px",
      "& li": {
        display: "flex",
        justifyContent: "center",
        borderRadius: "25px",
        padding: 0,
        width: "50px",
        height: "50px"
      }
    },
    selected: { background: fade(theme.palette.colors.iconSelected, 0.3) },
    mainIconsStyle: {
      padding: "11px",
      background: theme.palette.colors.mainIconSelected,
      width: "62px",
      height: "62px",
      color: fade(theme.palette.colors.lightWhite, 0.6),
      "&:hover": {
        background: theme.palette.colors.modesIconPressed
      }
    },
    iconContainer: { objectFit: "contain", zIndex: 99999 },
    unitView: {
      width: "100%",
      height: "100%",
      background: theme.gradient.unitView,
      display: "flex",
      flexFlow: "column",
      justifyContent: "space-between",
      alignItems: "center",
      borderTopRightRadius: "25px",
      borderTopLeftRadius: "25px",
      padding: 0,
      paddingTop: "40px",
      paddingBottom: "20px",
      minHeight: "500px",
      overflow: "auto",
      maxHeight: "100%",
      boxSizing: "border-box"
    },
    firstRowContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      justifyItems: "center",
      alignItems: "center",
      alignContent: "center",
      boxSizing: "border-box",
      padding: "0 20px"
    },
    nameStyle: {
      color: theme.palette.colors.white,
      fontFamily: "Roboto",
      fontSize: "22px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      maxWidth: "30%",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      display: "inline-block"
    },
    powerContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    },
    powerIconStyle: { padding: 0, zIndex: 100 },
    powerTextStyle: {
      marginTop: "8px",
      color: "white",
      height: "16px",
      opacity: 0.5,
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: 500,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal"
    },
    powerOnButtonClick: {
      "&:active": {
        transition: "all 1s",
        "& circle": {
          fill: "#fff",
          opacity: 1
        },
        "& path": {
          stroke: "#f05146"
        }
      }
    },
    powerOffButtonClick: {
      "&:active": {
        transition: "all 1s",
        "& circle": {
          fill: "#fff"
        },
        "& path": {
          fill: "#000"
        }
      }
    },
    secondRowContainer: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexFlow: "column nowrap",
      paddingBottom: "20px"
    },
    lastRowContainer: {
      display: "flex",
      height: "62px",
      alignItems: "flex-end",
      justifyContent: "center",
      width: "100%"
    },
    menuIconsStyle: {
      "& path": {
        stroke: theme.palette.colors.lightWhite,
        opacity: 0.4
      }
    },
    hiddenStyle: {
      width: "100%",
      height: "80px",
      visibility: "hidden"
    },
    controlDiv: {
      maxHeight: "326px",
      maxWidth: "336px",
      padding: 0,
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "326px",
      minWidth: "336px",
      "@media (max-width: 328px)": {
        minWidth: "312px"
      }
    },
    sliderContainer: {
      marginTop: "-20px",
      maxWidth: "280px",
      maxHeight: "280px",
      zIndex: 1
    },
    innerControls: {
      position: "absolute",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",
      marginTop: "-3%",
      alignItems: "center"
    },
    controlArrow: {
      zIndex: 100,
      width: "60px",
      height: "60px",
      margin: "-10px 0",

      "&:active": {
        "& path": {
          stroke: `${theme.palette.secondary.main} !important`
        },
        transition: "all 1s"
      }
    },
    setPointStyle: {
      fontSize: "65px",
      color: theme.palette.colors.white
    },
    ampTempStyle: {
      fontSize: "24px",
      color: theme.palette.colors.white
    },
    ampTempContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%"
    },
    controlWrapperStyle: {
      position: "absolute",
      "@media (max-width: 328px)": {
        transform: "scale(0.93)"
      }
    },
    modeTitle: {
      fontSize: "50px",
      color: theme.palette.colors.white,
      fontWeight: "bold",
      position: "absolute"
    },
    hideStyle: {
      visibility: "hidden"
    },
    completeHide: {
      display: "none"
    },
    showStyle: { visibility: "visible" },
    tempSvgStyle: { "& path": { transform: "scale(0.7)" } },
    smallRingStyle: { marginTop: "-36px", marginLeft: "-15px" },
    opacityStyle: { opacity: 0.4 },
    loaderContainer: {
      width: "40px",
      height: "40px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    loaderStyle: { color: fade(theme.palette.colors.white, 0.7) },
    alertMessageStyle: {
      fontSize: "12px",
      color: theme.palette.secondary.dark
    },
    alertContainer: {
      display: "flex",
      flexDirection: "column",
      justifyItems: "center",
      alignContent: "center"
    },
    smallFont: { fontSize: "40px" },
    addMargin: { marginTop: "-4.5%" },
    iconStyle: {
      fontSize: "17.5px"
    },
    modeIcon: {
      margin: "0 5px"
    },
    smallIcons: {
      transform: "scale(0.5)",
      margin: "-10px",
      marginRight: "-4px"
    },
    nameSection: {
      justifyContent: "space-between",
      alignItems: "center",
      fontSize: "22px",
      fontWeight: "bold",
      width: "34%",
      overflow: "hidden",
      display: "-webkit-box",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
      maxHeight: "60px",
      "& p": {
        whiteSpace: "unset",
        textOverflow: "unset",
        color: theme.palette.colors.white,
        fontFamily: "Roboto",
        fontSize: "22px"
      }
    }
  });

import { createStyles } from "@material-ui/styles";

export default (theme: any) =>
  createStyles({
    header: {
      boxShadow: "0px 0px 0px",
      backgroundColor: theme.palette.colors.transparent,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "18px 20px 5px 20px",
      minHeight: "55px"
    },
    smallAvatar: {
      width: 37,
      height: 37,
      backgroundColor: theme.palette.colors.menuHeader
    },
    gridLeft: { marginLeft: "10px" },
    menuIcon: {
      color: theme.palette.colors.menuHeader,
      fontSize: "25px"
    },
    tempText: {
      opacity: 0.5,
      fontFamily: "Roboto-Regular",
      fontSize: "26px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      color: theme.palette.colors.white,
      display: "flex"
    },
    tempSymbolStyle: { fontSize: "14px", marginLeft: "3px" },
    refreshStyle: {
      fontSize: "20px",
      "-webkit-animation": "$spinRefresh 4s linear infinite",
      "-moz-animation": "$spinRefresh 4s linear infinite",
      animation: "$spinRefresh 4s linear infinite"
    },
    " @-webkit-keyframes spinRefresh": {
      "100%": {
        "-webkit-transform": "rotate(360deg)"
      }
    },
    "@keyframes spinRefresh": {
      "100%": {
        "-webkit-transform": "rotate(360deg)",
        transform: "rotate(360deg)"
      }
    }
  });

import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { t } from "ttag";
import { Button, ModesList, Switch, TextField } from "../../widgets";

import { ArrowBack } from "../../svgComponents";

import { ConfirmationDialog, Toolbar } from "../../components";
import { useStoreActions, useStoreState } from "../../Stores/typedHooks";
import unitManagementStyles from "./unitManagement.style";

const UnitManagement = (props: any) => {
  const { history, match } = props;

  const [unit, setUnit] = useState<any>({});
  const [device, setDevice] = useState<any>({});

  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const types = useStoreState((states) => states.typesStore.types);

  const addMessage = useStoreActions(
    (actions) => actions.messageStore.addMessage
  );
  const updateUnit = useStoreActions((actions) => actions.unitStore.updateUnit);
  const getUnitById = useStoreActions((actions) => actions.unitStore.getUnitById);
  const getDeviceById = useStoreActions((actions) => actions.deviceStore.getDeviceById);
  const { params } = match;
  const { unitId } = params;

  useEffect(() => {
    if (!unitId) {
      return;
    }

    getUnitById(unitId).then((unit: any) => {
      getDeviceById(unit.device).then((device: any) => {
        setDevice(device);
      });
      setUnit(unit);
    });

  }, [unitId, getUnitById]);

  const goBack = () => {
    history.push("/unit-settings");
  };

  const getUnitKeyOfMode = (typesMode: string) => {
    let unitModeName: any = "";
    switch (typesMode) {
      case "operationModes":
        unitModeName = "supportedOperationModes";
        break;
      case "fanModes":
        unitModeName = "supportedFanModes";
        break;
      case "swingModes":
        unitModeName = "supportedSwingModes";
        break;

      default:
        unitModeName = null;
        break;
    }
    return unitModeName;
  };

  const updateLocalUnit = (modeName: string, modeNumber: any) => {
    const locUnit = { ...unit };
    const unitModeName = getUnitKeyOfMode(modeName);
    if (!unitModeName) {
      return;
    }

    const checkIndex = locUnit[unitModeName].indexOf(+modeNumber);
    if (checkIndex > -1) {
      locUnit[unitModeName].splice(checkIndex, 1);
    } else {
      locUnit[unitModeName] = [...locUnit[unitModeName], +modeNumber];
    }
    setIsSaveDisabled(false);
    setUnit(locUnit);
  };

  const saveUnit = () => {
    const {
      name,
      isVisible,
      supportedOperationModes,
      supportedFanModes,
      supportedSwingModes
    } = unit;
    updateUnit({
      unitId,
      data: {
        name,
        isVisible,
        supportedOperationModes,
        supportedFanModes,
        supportedSwingModes
      }
    }).then(() => {
      setIsSaveDisabled(true);
      goBack();
    }).catch((error: any) => {
      addMessage({ message: error.message });
    });
  };

  const updateUnitName = (e: any) => {
    const { value } = e.target;
    setUnit({ ...unit, name: value });
    setIsSaveDisabled(false);
  };

  const changeSupportedValues = (e: any, mode: string) => {
    const isChecked = e.target.checked;
    const unitModeName = getUnitKeyOfMode(mode);
    const updatedUnit = { ...unit };

    if (!unitModeName) {
      return;
    }
    updatedUnit[unitModeName] = !isChecked
      ? []
      : Object.keys(types[mode]).map((key: any) => +key);
    setUnit(updatedUnit);
    setIsSaveDisabled(false);
  };

  const changeUnitVisibility = (isVisible: boolean) => {
    setUnit({ ...unit, isVisible: isVisible });
    setIsSaveDisabled(false);
  };

  const useStyles = makeStyles(unitManagementStyles);
  const classes = useStyles();
  const checkIfCanGoBack = () => {
    setOpenDialog(true);
  };
  const cancel = () => {
    setOpenDialog(false);
  };
  const arrowBack = <ArrowBack />;
  const {
    name,
    isVisible,
    supportedOperationModes,
    supportedFanModes,
    supportedSwingModes,
    line,
    privateId
  } = unit;

  return (
    <div className={classes.root}>
      <ConfirmationDialog
        onCancel={cancel}
        onConfrim={goBack}
        onClose={cancel}
        title="Discard Changes?"
        text="Are you sure you want to discard changes made on this page?"
        confrimLabel="Discard"
        cancelLabel="No"
        dialogType="normal"
        openDialog={openDialog}
      />
      <Toolbar
        title={t`Unit Management`}
        leftIconComponent={arrowBack}
        leftAction={checkIfCanGoBack}
      />
      <div className={classes.pageContent}>
        <div className={classes.nameSection}>
          <TextField
            value={name || ""}
            onChange={updateUnitName}
            className={classes.textStyle}
            label={t`Unit Name`}
          />
        </div>
        <div className={classes.moreInfo}>
          <TextField
            value={device.serial || ""}
            disabled
            // onChange={updateUnitName}
            className={classes.textStyle}
            label={t`Device SN`}
          />
        </div>
        <div className={classes.moreInfo}>
          <TextField
            value={privateId && line ? `L${line}.${privateId}` : ""}
            disabled
            // onChange={updateUnitName}
            className={classes.textStyle}
            label={t`Unit address`}
          />
        </div>

        <div className={classes.unitListVisibility}>
          <Typography>{t`Show unit in list`}</Typography>
          <Switch
            checked={isVisible ? isVisible : ""}
            disableRipple={true}
            switchChange={(e: any) => changeUnitVisibility(!isVisible)}
            value="checkedA"
          />
        </div>

        <div className={clsx(classes.section, classes.controlSection)}>
          <div className={classes.controlSectionHead}>
            <Typography
              className={classes.controlSectionTitle}
            >{t`Mode Settings`}</Typography>
            <Switch
              checked={
                !!(supportedOperationModes && supportedOperationModes.length)
              }
              switchChange={(e: any) =>
                changeSupportedValues(e, "operationModes")
              }
            />
          </div>
          <div className={classes.controlSectionBody}>
            <ModesList
              modeName={"operationModes"}
              activeModes={supportedOperationModes}
              action={updateLocalUnit}
            />
          </div>
        </div>

        <div className={clsx(classes.section, classes.controlSection)}>
          <div className={classes.controlSectionHead}>
            <Typography
              className={classes.controlSectionTitle}
            >{t`Louver Control`}</Typography>
            <Switch
              checked={!!(supportedSwingModes && supportedSwingModes.length)}
              switchChange={(e: any) => changeSupportedValues(e, "swingModes")}
            />
          </div>
          <div className={classes.controlSectionBody}>
            <ModesList
              modeName={"swingModes"}
              activeModes={supportedSwingModes}
              action={updateLocalUnit}
            />
          </div>
        </div>

        <div className={clsx(classes.section, classes.controlSection)}>
          <div className={classes.controlSectionHead}>
            <Typography
              className={classes.controlSectionTitle}
            >{t`Fan Speed Control`}</Typography>
            <Switch
              checked={!!(supportedFanModes && supportedFanModes.length)}
              switchChange={(e: any) => changeSupportedValues(e, "fanModes")}
            />
          </div>
          <div className={classes.controlSectionBody}>
            <ModesList
              modeName={"fanModes"}
              activeModes={supportedFanModes}
              action={updateLocalUnit}
            />
          </div>
        </div>

        <Button
          disabled={isSaveDisabled}
          variant={"contained"}
          className={classes.saveButton}
          onClick={() => saveUnit()}
        >{t`Save`}</Button>
      </div>
    </div>
  );
};

export default UnitManagement;

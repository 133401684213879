import { createStyles } from "@material-ui/core/styles";

export const groupManagementStyles = (theme: any) =>
  createStyles({
    container: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      background: theme.palette.colors.black
    },
    groupsList: {
      flexDirection: "column",
      flex: 1,
      justifyContent: "flex-start",
      background: theme.palette.colors.black
    },
    labelStyle: {
      fontSize: "18px",
      color: theme.palette.colors.lightWhite
    },

    groupsTitle: {
      padding: "15px",
      display: "flex",
      backgroundImage: theme.gradient.default,
      marginBottom: "10px"
    },
    rowStyle: {
      backgroundImage: theme.gradient.default,
      padding: "0 5px 0 15px"
    }
  });

import { Grid, IconButton, Typography, withStyles } from "@material-ui/core";
import { ArrowForwardIos } from "@material-ui/icons";
import clsx from "clsx";
import React from "react";
import { UserPhoto } from "../../svgComponents";
import clickableRowStyle from "./clickableRow.style";

interface ClickableRowProps {
  classes?: any;
  rowStyle?: any;
  hasUserAvatar?: any;
  userAvatarClassName?: any;
  title?: string;
  titleClassName?: any;
  subTitle?: string;
  subTitleClassName?: any;
  contentText?: string;
  contentTextClassName?: string;
  forwardArrowClassName?: string;
  handleClick?: any;
}
const ClickableRow: React.FC<ClickableRowProps> = ({
  classes,
  rowStyle = null,
  hasUserAvatar,
  userAvatarClassName,
  title,
  titleClassName,
  subTitle,

  subTitleClassName,
  contentText,
  handleClick,

  ...props
}) => {
  return (
    <Grid
      container
      className={clsx(classes.clickableRow, rowStyle)}
      onClick={handleClick}
    >
      <Grid container className={classes.avatarTitleWrapper}>
        {hasUserAvatar && (
          <UserPhoto
            className={clsx(classes.userAvatarStyle, userAvatarClassName)}
          />
        )}
        <div className={classes.titleSubTitleWrapper}>
          <Typography className={clsx(classes.titleStyle, titleClassName)}>
            {title}
          </Typography>
          <Typography
            className={clsx(classes.subtitleStyle, subTitleClassName)}
          >
            {subTitle}
          </Typography>
        </div>
      </Grid>

      <div>
        <Typography className={classes.subtitleStyle}>{contentText}</Typography>
      </div>

      <IconButton classes={{ root: classes.forwardArrowButton }}>
        <ArrowForwardIos classes={{ root: classes.forwardArrow }} />
      </IconButton>
    </Grid>
  );
};
export default withStyles(clickableRowStyle)(ClickableRow);

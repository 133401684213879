import React from "react";
import { TextField, makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { t } from "ttag";
import textFieldStyle from "./textField.style";

interface ITextFieldProps {
  onChange?: (e: any) => void;
  onBlur?: () => void;
  className?: any;
  disabled?: boolean;
  variant?: any;
  name?: string;
  placeholder?: string;
  label?: string;
  margin?: "normal" | "none" | "dense" | undefined;
  fullWidth?: boolean;
  value?: string | number;
  type?: string;
  error?: string | number | symbol | any;
  disableUnderline?: boolean;
  endAdornment?: any;
  rootClass?: any;
}

const CustomTextField: React.FC<ITextFieldProps> = ({
  className,
  name,
  label,
  placeholder,
  onChange,
  onBlur,
  value,
  variant = "outlined",
  margin = "normal",
  type = "text",
  fullWidth = true,
  disableUnderline,
  disabled = false,
  error,
  endAdornment,
  rootClass,
  ...props
}) => {
  const useStyles = makeStyles(textFieldStyle);
  const classes: { [index: string]: any } = useStyles();

  const inputPropsClasses = {
    root: clsx(
      classes[`${variant === "outlined" ? "outlined" : "filled"}InputRoot`],
      rootClass
    ),
    notchedOutline: variant === "outlined" ? classes.notchedOutline : "",
    disabled: classes.disabledInput,
    underline: variant !== "outlined" ? classes.underline : ""
  };

  return (
    <TextField
      variant={variant}
      name={name}
      label={label}
      error={!!error}
      placeholder={placeholder}
      margin={margin}
      fullWidth={fullWidth}
      InputProps={
        variant === "outlined"
          ? {
            classes: inputPropsClasses,
            endAdornment: endAdornment
          }
          : {
            classes: inputPropsClasses,
            endAdornment: endAdornment,
            disableUnderline
          }
      }
      InputLabelProps={{
        classes: {
          root: classes[`${variant}LabelRoot`],
          disabled: classes.disabled,
          shrink: classes.shrink,
          focused: classes.focused,
          formControl: label ? classes.formControl : classes.formControlNoLabel
        }
      }}
      className={clsx(classes[`${variant}Root`], className)}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
      type={type}
      helperText={error && `${error}`}
      FormHelperTextProps={{ classes: { error: classes.errorHeplerText } }}
      {...props}
    />
  );
};

export default CustomTextField;

import { createStyles } from "@material-ui/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";

export default (theme: any) =>
  createStyles({
    container: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      margin: "auto"
    },
    dayStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "41px",
      borderRadius: "6px",
      backgroundColor: fade(theme.palette.colors.white, 0.1),
      margin: "4px",
      minWidth: "33px",
      maxWidth: "12%",
      width: "50px",
      flex: 1,
      "& *": {
        color: theme.palette.colors.white,
        fontSize: "14px"
      }
    },
    activeDayStyle: {
      backgroundColor: theme.palette.secondary.main
    }
  });

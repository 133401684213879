import React from "react";

const SvgHotWhite = props => (
  <svg width={34} height={34} {...props}>
    <path
      d="M25.334 16.948a8.389 8.389 0 11-16.777-.002 8.389 8.389 0 0116.777.002zm-8.388 16.776v-4.438m0-24.677V.171m8.388 31.398l-2.259-3.911M10.735 6.284L8.56 2.515m19.03 20.671h.004l3.882 2.244M6.221 10.848L2.418 8.652m31.306 8.39h-4.442m-24.67-.002H.171m31.305-8.388l-3.802 2.196M2.418 25.43l3.883-2.244h.002M25.334 2.514l-2.175 3.769M10.817 27.659l-2.257 3.91"
      fill="none"
      fillRule="evenodd"
      stroke="#FFF"
      strokeLinecap="round"
      strokeWidth={2}
      opacity={props.opacity}
    />
  </svg>
);

export default SvgHotWhite;

import { Thunk, thunk } from "easy-peasy";
import { Injections } from "./";

export interface IEcobeeModel {
  connectEcobeeAcount: Thunk<IEcobeeModel, any, Injections>;
  connectEcobeeAcountURL: Thunk<IEcobeeModel, any, Injections>;
  findEcobeeDevices: Thunk<IEcobeeModel, any, Injections>;
  isConnected: Thunk<IEcobeeModel, any, Injections>;
  disconnectEcobeeAcount: Thunk<IEcobeeModel, any, Injections>;
}

const ecobeeStore: IEcobeeModel = {
  connectEcobeeAcount: thunk((actions, payload, { injections }) => {
    const { sdkEcobee } = injections;
    return sdkEcobee.connectEcobeeAcount();
  }),
  connectEcobeeAcountURL: thunk((actions, payload, { injections }) => {
    const { sdkEcobee } = injections;
    return sdkEcobee.connectEcobeeAcountURL();
  }),
  findEcobeeDevices: thunk((actions, payload, { injections }) => {
    const { sdkEcobee } = injections;
    return sdkEcobee.find();
  }),
  isConnected: thunk((actions, payload, { injections }) => {
    const { sdkEcobee } = injections;
    return sdkEcobee.isConnected();
  }),
  disconnectEcobeeAcount: thunk((actions, payload, { injections }) => {
    const { sdkEcobee } = injections;
    return sdkEcobee.disconnectEcobeeAcount();
  })
};

export default ecobeeStore;

import { Thunk, thunk } from "easy-peasy";
import { Injections } from "./";

export interface INestModel {
  connectNestAcount: Thunk<INestModel, any, Injections>;
  connectNestAcountURL: Thunk<INestModel, any, Injections>;
  findNestDevices: Thunk<INestModel, any, Injections>;
  isConnected: Thunk<INestModel, any, Injections>;
  disconnectNestAcount: Thunk<INestModel, any, Injections>;
}

const nestStore: INestModel = {
  connectNestAcount: thunk((actions, payload, { injections }) => {
    const { sdkNest } = injections;
    return sdkNest.connectNestAcount();
  }),
  connectNestAcountURL: thunk((actions, payload, { injections }) => {
    const { sdkNest } = injections;
    return sdkNest.connectNestAcountURL();
  }),
  findNestDevices: thunk((actions, payload, { injections }) => {
    const { sdkNest } = injections;
    return sdkNest.find();
  }),
  isConnected: thunk((actions, payload, { injections }) => {
    const { sdkNest } = injections;
    return sdkNest.isConnected();
  }),
  disconnectNestAcount: thunk((actions, payload, { injections }) => {
    const { sdkNest } = injections;
    return sdkNest.disconnectNestAcount();
  })
};

export default nestStore;

import React from "react";

const CO2 = props => (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} {...props} viewBox="0 0 80 80">
    <g fill="none" fill-rule="evenodd">
        <g>
            <g>
                <g>
                    <g stroke="#FFF" stroke-width="1.8">
                        <path d="M32 14.84l26.529 19.896V67.67H5.47V34.736L32 14.839z" transform="translate(-505 -53) translate(505 53) translate(8 6)"/>
                        <g stroke-linecap="round">
                            <path d="M62.483 19.099L56.317 23.225M31.144 0L31.271 7.073M49.489 5.009L46.419 11.179" transform="translate(-505 -53) translate(505 53) translate(8 6)"/>
                            <path d="M5.937 19.099L0 23.071" transform="translate(-505 -53) translate(505 53) translate(8 6) matrix(-1 0 0 1 5.937 0)"/>
                            <path d="M17.345 5.009L14.258 11.213" transform="translate(-505 -53) translate(505 53) translate(8 6) matrix(-1 0 0 1 31.603 0)"/>
                        </g>
                    </g>
                    <g fill="#FFF" fill-rule="nonzero">
                        <path d="M5.987 14.902c1.7 0 3.07-.449 4.108-1.345 1.04-.897 1.616-2.125 1.729-3.686H8.835c-.066.883-.327 1.536-.782 1.958-.454.421-1.143.632-2.066.632-1.023 0-1.772-.362-2.247-1.086-.474-.723-.712-1.869-.712-3.436V6.843c.013-1.5.267-2.608.762-3.322s1.24-1.07 2.236-1.07c.917 0 1.6.21 2.047.632.449.422.71 1.1.782 2.037h2.989c-.16-1.62-.75-2.879-1.773-3.775C9.048.448 7.7 0 6.026 0c-1.208 0-2.27.284-3.182.852-.913.567-1.615 1.383-2.107 2.445C.246 4.36 0 5.588 0 6.983v.886c0 2.185.533 3.903 1.599 5.155 1.066 1.252 2.528 1.878 4.388 1.878zm13.556 0c1.216 0 2.293-.293 3.233-.877.94-.584 1.662-1.414 2.166-2.49.505-1.076.757-2.328.757-3.756v-.647c0-1.421-.257-2.674-.772-3.76-.514-1.086-1.242-1.92-2.181-2.5C21.806.29 20.732 0 19.523 0c-1.208 0-2.282.29-3.222.872-.94.58-1.667 1.414-2.181 2.5-.515 1.086-.772 2.342-.772 3.77v.717c.006 1.395.269 2.628.787 3.7.518 1.073 1.248 1.898 2.191 2.476.943.578 2.015.867 3.217.867zm0-2.45c-1.022 0-1.806-.41-2.35-1.226-.545-.817-.817-1.966-.817-3.447v-.707c.006-1.507.282-2.651.827-3.431.544-.78 1.318-1.17 2.32-1.17 1.01 0 1.787.394 2.331 1.185.545.79.817 1.945.817 3.466v.707c-.007 1.508-.279 2.655-.817 3.442-.538.787-1.308 1.18-2.31 1.18zM33.628 18.102v-1.547h-4.157l2.198-2.317c.602-.66 1.027-1.238 1.275-1.734s.372-.983.372-1.46c0-.873-.276-1.548-.827-2.026-.55-.478-1.325-.717-2.32-.717-.651 0-1.234.138-1.747.415-.514.277-.911.658-1.192 1.145-.281.487-.422 1.025-.422 1.614h1.926c0-.487.125-.88.375-1.179.25-.299.592-.448 1.026-.448.403 0 .713.123.93.369.217.245.325.583.325 1.012 0 .315-.103.647-.309.996-.205.35-.523.76-.952 1.229L27 16.788v1.314h6.627z" transform="translate(-505 -53) translate(505 53) translate(8 6) translate(14.504 39.352)"/>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
);

export default CO2;

import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Typography,
  IconButton,
  Collapse,
  Divider
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import _ from "lodash";
import { t } from "ttag";
import { useStoreActions, useStoreState } from "../../Stores/typedHooks";
import { Toolbar } from "../../components";
import { ArrowBack } from "../../svgComponents";
import { Button, Select } from "../../widgets";
import styles from "./nestSmartThermostat.style";

interface IProps {
  history: any;
}


export const NestSmartThermostat: React.FC<IProps> = ({ history }) => {
  const [open, openCollapse] = useState<boolean>(true);
  const [dataFetched, setDataFetched] = useState<boolean>(false);
  const [assignedUnits, setAssignedUnits] = useState<any>({});
  const [units, setUnits] = useState<any>({});
  const [nestDevices, setNestDevices] = useState<any>([]);
  const [selectedOption, setSelectedOption] = useState<any>("");
  const [selectOptions, setSelectOptions] = useState<any>({
    "": { value: "", label: "None", unitInternalId: "" }
  });

  const addMessage = useStoreActions(
    actions => actions.messageStore.addMessage
  );
  const getUserUnits = useStoreActions(
    actions => actions.unitStore.getUserUnits
  );
  const findNestDevices = useStoreActions(
    actions => actions.nestStore.findNestDevices
  );
  const disconnectNestAcount = useStoreActions(
    actions => actions.nestStore.disconnectNestAcount
  );
  const updateUnit = useStoreActions(actions => actions.unitStore.updateUnit);
  const user = useStoreState(state => state.userStore.user);
  const setUser = useStoreActions(actions => actions.userStore.setUser);
  const { nestStatus } = user;

  const useStyles = makeStyles(styles);
  const classes = useStyles();

  const goBack = () => {
    history.push({ pathname: "/site", menuOpen: true });
  };

  const handleUnitSelect = (selectedOption: any, index: number) => {
    if (selectedOption.value === "" && !nestDevices[index].unit) {
      return;
    }

    updateUnit({
      unitId: selectedOption.unitInternalId
        ? selectedOption.value
        : selectOptions[nestDevices[index].unit].value,
      data: {
        nestThermostat: selectedOption.unitInternalId
          ? nestDevices[index].identifier
          : ""
      }
    })
      .then(() => {
        if (
          assignedUnits[selectedOption.unitInternalId] ||
          assignedUnits[selectedOption.unitInternalId] === 0
        ) {
          nestDevices[assignedUnits[selectedOption.unitInternalId]] = {
            ...nestDevices[assignedUnits[selectedOption.unitInternalId]],
            unit: ""
          };
        }

        nestDevices[index] = {
          ...nestDevices[index],
          unit: selectedOption.unitInternalId
        };

        assignedUnits[selectedOption.unitInternalId] = index;

        setAssignedUnits({ ...assignedUnits });
        setNestDevices([...nestDevices]);
      })
      .catch((error: any) =>
        addMessage({
          message: error.message
        })
      );
  };

  useEffect(() => {
    if (dataFetched || nestStatus !== "CONNECTED") {
      return;
    }

    setDataFetched(true);
    Promise.all([getUserUnits(), findNestDevices()]).then((response: any) => {
      const userUnits = _.reduce(response[0], (obj: any, unit: any)=>{
        if(unit.type === 1 ){
          obj[unit.id] = unit;
        }
        return obj
      },{});
      const nestDevices = response[1];

      setUnits(userUnits);
      setNestDevices(nestDevices);

      nestDevices.map((ecobee: any, index: number) => {
        if (!ecobee.unit) {
          return;
        }
        assignedUnits[ecobee.unit] = index;
      });

      Object.values(userUnits).map((unit: any) => {
        selectOptions[unit.internalId] = {
          value: unit.id,
          label: unit.name,
          unitInternalId: unit.internalId
        };
      });
      setAssignedUnits({ ...assignedUnits });
      setSelectOptions({ ...selectOptions });
    });
  }, [
    assignedUnits,
    dataFetched,
    nestDevices,
    nestStatus,
    findNestDevices,
    getUserUnits,
    selectOptions,
    units
  ]);

  const disconnect = () => {
    disconnectNestAcount()
      .then(() => setUser({ ...user, nestStatus: "DISCONNECTED" }))
      .catch((error: any) =>
        addMessage({
          message: error.message
        })
      );
  };

  if (nestStatus !== "CONNECTED") {
    history.push("/smart-thermostat/connect");
  }

  return (
    <div className={classes.screenContainer}>
      <Toolbar
        title="nest"
        leftIconComponent={<ArrowBack />}
        leftAction={goBack}
      />
      <div className={classes.container}>
        <div className={classes.devicesContainer}>
          <div className={classes.titleRow} onClick={() => openCollapse(!open)}>
            <Typography
              className={classes.title}
            >{t`Linked nest devices`}</Typography>
            <IconButton className={open ? classes.expandOpen : classes.expand}>
              <ExpandMore className={classes.expandMoreStyle} />
            </IconButton>
          </div>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Divider className={classes.divider} />
            {nestDevices.length !== 0 &&
              nestDevices.map((nest: any, index: number) => (
                <div
                  key={`nest-device${nest._id}`}
                  className={classes.deviceRow}
                >
                  <Typography className={classes.deviceName}>
                    {nest.name}
                  </Typography>
                  <Select
                    value={
                      nest.unit
                        ? selectOptions[nest.unit]
                        : { value: "", label: "None" }
                    }
                    variant="filled"
                    suggestions={Object.values(selectOptions)}
                    handleSelectChange={(option: any) =>
                      handleUnitSelect(option, index)
                    }
                    clear={false}
                    placeholder={t`Unit`}
                    className={classes.selectStyle}
                    height="30px"
                  />
                </div>
              ))}
            <div className={classes.buttonContainer}>
              <Button
                variant="contained"
                onClick={disconnect}
              >{t`Disconnect`}</Button>
            </div>
          </Collapse>
        </div>
      </div>
    </div>
  );
};
